import React from "react";
import useStyles, { BadgeCount } from "./styles";
import Slider from "react-slick";

import { Box, Container, Grid } from "@material-ui/core";

import banner from "../../assets/images/banner_1.jpg";
import collection001 from "../../assets/images/banner-1.webp";
import collection002 from "../../assets/images/banner-2.webp";
import prod001 from "../../assets/images/prod001.webp";
import Paragraph from "../../components/Typography/Paragraph";
import { ITEMS } from "./ITEMS";
import {
  get_all_products,
  get_banner,
  get_banner_collection,
  get_banner_marketplace,
  get_categories,
  get_popular_products,
  get_product,
  get_products_by_category,
  get_products_by_subcategory,
} from "../../api/API";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormInputNoLabel from "../../components/FormInput/FormInputNoLabel/FormInputNoLabel";
import "./styles.css";
import {
  Close,
  MenuBookRounded,
  MenuOutlined,
  Message,
  ShoppingCartOutlined,
} from "@material-ui/icons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useCart from "../../hooks/useCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CachedIcon from "@mui/icons-material/Cached";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function Marketplace() {
  const classes = useStyles();
  // SLIDER SETTING
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
  };

  const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [tabs, setTabs] = React.useState(0);

  const handleChangeTab = (txt) => {
    setTabs(txt);
  };

  // MARKETPLACE

  const [marketplace, setMarketplace] = React.useState([]);
  const [marketplaceItem, setMarketplaceItem] = React.useState([]);

  const getMarketplace = () => {
    // get_marketplace().then((json) => {
    //   setMarketplace(json.data);
    // setTabs(json.data[0].marketplace_id);
    // });
  };

  const getMarketplaceWithItem = () => {
    // get_marketplace_with_item({marketplace_id: tabs}).then((json) => {
    //   setMarketplaceItem(json.data);
    // });
  };

  const [categories, setCategories] = React.useState([]);

  const getCategories = () => {
    get_categories().then((json) => {
      setCategories(json.data);
    });
  };

  const [chooseCategory, setChooseCategory] = React.useState(1);

  const [expanded, setExpanded] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(1);
  const [selectedSubcategory, setSelectedSubcategory] = React.useState(1);

  // const handleChooseCategory = (categoryId) => {
  //   // const selectedCategoryId = event.target.value;
  //   setSelectedCategory(categoryId);
  //   // Clear the selected subcategory when a category is chosen
  //   setSelectedSubcategory("");
  // };

  const handleChooseCategory = (categoryId) => {
    console.log("cat", categoryId);
    setSelectedCategory(categoryId);
    // setSelectedSubcategory("");

    const selectedCategory = categories.find(
      (category) => category.category_id === categoryId
    );

    if (selectedCategory && selectedCategory.subcategories.length > 0) {
      // Create an array of subcategory IDs from the selected category's subcategories
      const subcategoryIds = selectedCategory.subcategories.map(
        (subcategory) => subcategory.subcategory_id
      );
      setSelectedSubcategory(subcategoryIds);
    } else {
      // Handle the case when the category has no subcategories
      setSelectedSubcategory([]);
    }

    // if (expanded === categoryId) {
    //   setExpanded(null);
    // } else {
    //   setExpanded(categoryId);
    // }
  };

  const handleToggleAccordion = (categoryId) => {
    if (expanded === categoryId) {
      setExpanded(null);
    } else {
      setExpanded(categoryId);
    }
  };

  const handleChooseSubCategory = (event) => {
    const subcategoryId = event.target.value;
    setSelectedSubcategory(subcategoryId);

    // Find the category associated with the selected subcategory
    const subcategory = categories.find((category) =>
      category.subcategories.some(
        (subcategory) => subcategory.subcategory_id === subcategoryId
      )
    );

    if (subcategory) {
      setSelectedCategory(subcategory.category_id);
    } else {
      // Handle the case when the subcategory doesn't belong to any category
      setSelectedCategory(null); // or any other appropriate action
    }

    // Call the API with the selected subcategory ID here
    getProducts(subcategoryId);
  };
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    getMarketplace();
    getMarketplaceWithItem();
    getCategories();
    getProducts(selectedSubcategory);
    getProductsCategory(selectedCategory);
  }, [tabs, selectedSubcategory, selectedCategory]);

  const getProducts = (subcategory) => {
    get_products_by_subcategory(subcategory).then((json) => {
      if (json.status) {
        setProducts(json.data);
      } else {
        console.error(json.message);
      }
    });
  };

  const [productCat, setProductCat] = React.useState([]);

  const getProductsCategory = (category) => {
    get_products_by_category(category).then((json) => {
      if (json.status) {
        setProductCat(json.data);
      } else {
        console.error(json.message);
      }
    });
  };

  // const getProducts = async (category) => {
  //   try {
  //     const response = await get_products_by_subcategory(category);
  //     if (response.status === true) {
  //       setProducts(response.data);
  //     } else {
  //       console.error("Server response not successful:", response);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //   }
  // };

  const [banners, setBanners] = React.useState([]);

  const getBanner = () => {
    get_banner().then((json) => {
      setBanners(json.data);
    });
  };

  React.useEffect(() => {
    getBanner();
  }, []);

  // const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  // React.useEffect(() => {
  //   // Fetch products when selectedCategoryId changes
  //   if (chooseCategory !== null) {
  //     fetchProducts(chooseCategory);
  //   }
  // }, [chooseCategory]);

  // const fetchProducts = async (categoryId) => {
  //   try {
  //     const response = await fetch(
  //       "https://indor.my/admin/get_products_by_category",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           category: categoryId,
  //         }),
  //       }
  //     );
  //     const json = await response.json();
  //     setProducts(json.data);
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //   }
  // };

  // CART

  const { getCart, clearCart } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  const [categoryHover, setCategoryHover] = React.useState("");

  const handleCategoryHover = (categoryId) => {
    setCategoryHover(categoryId);
  };

  const handleCategoryMouseLeave = () => {
    setCategoryHover(null);
  };

  const [popularProduct, setPopularProduct] = React.useState([]);

  const getPopularProduct = () => {
    get_popular_products({}).then((json) => {
      if (json.status) {
        setPopularProduct(json.data);
      } else {
        console.error(json.message);
      }
    });
  };
  const [allProduct, setAllProduct] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const productsPerPage = 10;

  const getAllProduct = (page) => {
    get_all_products({ page, per_page: productsPerPage }).then((json) => {
      if (json.status) {
        setAllProduct(json.data.data);

        const totalCount = json.data.total_records;
        const pages = Math.ceil(totalCount / productsPerPage);
        setTotalPages(pages);

        console.log("count", totalCount);
      } else {
        console.error(json.message);
      }
    });
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      // Increment the current page and fetch data for the next page
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      getAllProduct(nextPage);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      // Decrement the current page (if not on the first page) and fetch data for the previous page
      const previousPage = currentPage - 1;
      setCurrentPage(previousPage);
      getAllProduct(previousPage);
    }
  };

  React.useEffect(() => {
    getPopularProduct();
    getAllProduct(currentPage); // Load initial page data
  }, [currentPage]);

  // BANNER
  const [bannerC, setBannerC] = React.useState([]);

  const getBannerCollection = () => {
    get_banner_collection({}).then((json) => {
      setBannerC(json.data);
    });
  };

  React.useEffect(() => {
    getBannerCollection();
  }, []);

  return (
    <React.Fragment>
      <div style={{ padding: "7rem 0" }}>
        {window.innerWidth >= 800 ? (
          <div
            style={{
              backgroundColor: "#f0f0f0",
              padding: "1rem 0",
              position: "fixed",
              width: "100%",
              zIndex: "999",
            }}
          >
            <Container>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {categories.map((category) => (
                  <>
                    <div
                      key={category.category_id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        position: "relative", // Position relative for subcategories
                      }}
                      onMouseEnter={() =>
                        handleCategoryHover(category.category_id)
                      }
                      onMouseLeave={handleCategoryMouseLeave}
                    >
                      <Paragraph>{category.name}</Paragraph>
                      <ExpandMoreIcon />
                      {categoryHover === category.category_id && (
                        <a href={`/categoryproduct/${category.category_id}`}>
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "0",
                              padding: "1rem",
                              background: "#fff",
                              border: "1px solid #ccc",
                              zIndex: "100",
                              minWidth: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {category.subcategories.map((subcategory) => (
                              <div key={subcategory.subcategory_id}>
                                <Paragraph margin=".4rem 0">
                                  {subcategory.subcategory_name}
                                </Paragraph>
                              </div>
                            ))}
                          </div>
                        </a>
                      )}
                    </div>
                  </>
                ))}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <FormInputNoLabel
                    placeholder="Search Here"
                    border="1px solid #acacac"
                    // onChange={handleSearch}
                    // value={searchKey}
                    bg="white"
                  />
                  <a
                    href="/cart"
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "1.5rem",
                    }}
                  >
                    <ShoppingCartOutlined style={{ color: "#000" }} />
                    <BadgeCount bg="#000" color="#fff">
                      {cart.length}
                    </BadgeCount>
                  </a>
                </div>
              </div>
            </Container>
          </div>
        ) : (
          <></>
        )}

        <div className={classes.paddingBox}>
          <Slider className={classes.root} {...settings}>
            {banners.map((item) => (
              <div className={classes.theBanner}>
                <img src={item.image} />
              </div>
            ))}
          </Slider>
        </div>
        <Box sx={{ background: "#FFF" }}>
          <div style={{ padding: "4rem 0 0 0" }}>
            <Container>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3} className="grid-item">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <LocalShippingIcon
                        sx={{ fill: "#2f8dcd", fontSize: "48px" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0 0 0 1rem",
                        }}
                      >
                        <Paragraph size="500" bold="600" margin="0 0 .4rem 0">
                          Free Delivery
                        </Paragraph>
                        <Paragraph bold="300">
                          For all orders over MYR 99
                        </Paragraph>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-item">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CachedIcon sx={{ fill: "#2f8dcd", fontSize: "48px" }} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0 0 0 1rem",
                        }}
                      >
                        <Paragraph size="500" bold="600" margin="0 0 .4rem 0">
                          90 Days Return
                        </Paragraph>
                        <Paragraph bold="300">If goods have problems</Paragraph>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-item">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CreditCardIcon
                        sx={{ fill: "#2f8dcd", fontSize: "48px" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0 0 0 1rem",
                        }}
                      >
                        <Paragraph size="500" bold="600" margin="0 0 .4rem 0">
                          Secure Payment
                        </Paragraph>
                        <Paragraph bold="300">100% secure payment</Paragraph>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-item">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <QuestionAnswerIcon
                        sx={{ fill: "#2f8dcd", fontSize: "48px" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0 0 0 1rem",
                        }}
                      >
                        <Paragraph size="500" bold="600" margin="0 0 .4rem 0">
                          24/7 Support
                        </Paragraph>
                        <Paragraph bold="300">Dedicated support</Paragraph>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </div>
          <Container>
            <Box sx={{ padding: "4rem 0 2rem 0" }}>
              {/* <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <div className={classes.theCardBox}>
                    <img src={collection001} />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "5%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <Paragraph style={{ fontSize: "20px" }}>
                        NEW PRODUCT COLLECTION
                      </Paragraph>
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className={classes.theCardBox}>
                    <img src={collection002} />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "5%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <Paragraph style={{ fontSize: "20px" }}>
                        BASKET COLLECTION
                      </Paragraph>
                    </Box>
                  </div>
                </Grid>
              </Grid> */}
              <Slider className={classes.root} {...settings}>
                {bannerC.map((item) => (
                  <div className={classes.theBanner}>
                    <a href={item.url}>
                      <img src={item.image} />
                    </a>
                    {/* <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "5%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <Paragraph style={{ fontSize: "20px" }}>
                        {item.name}
                      </Paragraph>
                    </Box> */}
                  </div>
                ))}
              </Slider>
            </Box>

            <Box sx={{ padding: "2rem 0 2rem 0" }}>
              <Box sx={{ padding: "0 0 2rem 0" }}>
                <Paragraph center size="400" bold="600">
                  BEST SELLER PRODUCTS
                </Paragraph>
              </Box>
              <Box sx={{ padding: "0 0 2rem 0" }}>
                <Box sx={{ padding: "0 0 0 0" }}>
                  {popularProduct &&
                  popularProduct.best_seller_products &&
                  popularProduct.best_seller_products.length > 0 ? (
                    <Slider className={classes.root2} {...settings2}>
                      {popularProduct.best_seller_products.map((item) => (
                        <div key={item.product_id}>
                          <a href={`../productdetail/${item.product_id}`}>
                            <div className={classes.theProduct}>
                              <div className={classes.theProdImg}>
                                <img src={item.image} alt={item.product_name} />
                              </div>
                              <div>
                                <Paragraph
                                  color="#06c"
                                  bold="300"
                                  margin="0 0 .5rem 0"
                                >
                                  {item.product_name.toUpperCase()}
                                </Paragraph>
                                <div className={classes.theLine}></div>
                                <Paragraph color="#f30" margin="0 0 .6rem 0">
                                  {item.currency} {item.price}
                                </Paragraph>
                                {/* <Paragraph bold="600">
                                {item.seller_name}
                              </Paragraph> */}
                              </div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <div className="loading-spinner"></div>
                  )}
                </Box>
              </Box>
            </Box>

            <Box sx={{ padding: "0 0 2rem 0" }}>
              <Box sx={{ padding: "0 0 2rem 0" }}>
                <Paragraph center size="400" bold="600">
                  SHOP BY CATEGORY
                </Paragraph>
              </Box>
              <Box sx={{ padding: "0 0 2rem 0" }}>
                {/* <Grid container spacing={3}> */}
                <div
                  style={{
                    display: "flex",
                    alignContent: "flex-start",
                    flexWrap: "wrap",
                  }}
                >
                  {categories.map((cat) => (
                    // <Grid item xs={4} sm={4} md={3}>
                    <a href={`/categoryproduct/${cat.category_id}`}>
                      <div style={{ padding: ".5rem" }}>
                        <div className={classes.theCategory}>
                          <div className={classes.theCatImg}>
                            <img src={cat.image} />
                          </div>
                          <div>
                            <Paragraph bold="600" center>
                              {cat.name}
                            </Paragraph>
                          </div>
                        </div>
                      </div>
                    </a>
                    // </Grid>
                  ))}
                </div>
                {/* </Grid> */}
              </Box>
            </Box>

            <Box sx={{ padding: "0 0 2rem 0" }}>
              <Box sx={{ padding: "0 0 2rem 0" }}>
                <Paragraph center size="400" bold="600">
                  TRENDING PRODUCTS
                </Paragraph>
              </Box>
              <Box sx={{ padding: "0 0 2rem 0" }}>
                {popularProduct &&
                popularProduct.trending_products &&
                popularProduct.trending_products.length > 0 ? (
                  <Slider className={classes.root2} {...settings2}>
                    {popularProduct.trending_products.map((item) => (
                      <div key={item.product_id}>
                        <a href={`../productdetail/${item.product_id}`}>
                          <div className={classes.theProduct}>
                            <div className={classes.theProdImg}>
                              <img src={item.image} alt={item.product_name} />
                            </div>
                            <div>
                              <Paragraph
                                color="#06c"
                                bold="300"
                                margin="0 0 .5rem 0"
                              >
                                {item.product_name.toUpperCase()}
                              </Paragraph>
                              <div className={classes.theLine}></div>
                              <Paragraph color="#f30" margin="0 0 .6rem 0">
                                {item.currency} {item.price}
                              </Paragraph>
                              {/* <Paragraph bold="600">
                                {item.seller_name}
                              </Paragraph> */}
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <div className="loading-spinner"></div>
                )}
              </Box>
            </Box>

            <Box sx={{ padding: "0 0 2rem 0" }}>
              <Box sx={{ padding: "0 0 2rem 0" }}>
                <Paragraph center size="400" bold="600">
                  ALL PRODUCTS
                </Paragraph>
              </Box>
              <Box sx={{ padding: "0 0 2rem 0" }}>
                {/* <Grid container spacing={3}>
                  {productCat.map((item) => (
                    <Grid item xs={4} sm={4} md={3}>
                      <div key={item.product_id}>
                        <div style={{ display: "flex" }}>
                          <Card className={classes.theProduct}>
                            <div className={classes.theProdImg}>
                              <img src={item.image} alt={item.product_name} />
                            </div>
                            <div>
                              <Paragraph margin="0 0 .2rem 0">
                                {item.product_name}
                              </Paragraph>
                              <Paragraph margin="0 0 .6rem 0">
                                {item.currency} {item.price}
                              </Paragraph>
                              <Paragraph bold="600">
                                {item.seller_name}
                              </Paragraph>
                            </div>
                          </Card>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid> */}
                <div
                  style={{
                    display: "flex",
                    alignContent: "flex-start",
                    flexWrap: "wrap",
                  }}
                >
                  {allProduct.map((item) => (
                    <div key={item.product_id}>
                      <div style={{ display: "flex" }}>
                        <a href={`../productdetail/${item.product_id}`}>
                          <div className={classes.theProduct}>
                            <div className={classes.theProdImg}>
                              <img src={item.image} alt={item.product_name} />
                            </div>
                            <div>
                              <Paragraph
                                color="#06c"
                                bold="300"
                                margin="0 0 .5rem 0"
                              >
                                {item.product_name.toUpperCase()}
                              </Paragraph>
                              <div className={classes.theLine}></div>
                              <Paragraph color="#f30" margin="0 0 .6rem 0">
                                {item.currency} {item.price}
                              </Paragraph>
                              {/* <Paragraph bold="600">
                                {item.seller_name}
                              </Paragraph> */}
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2rem 0 0 0",
                  }}
                >
                  <div
                    style={{
                      backgroundColor:
                        currentPage === 1 ? "#CCCCCC" : "#2f8dcd",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: ".3rem",
                      cursor: "pointer",
                    }}
                    onClick={handlePreviousPage}
                  >
                    <KeyboardArrowLeftIcon
                      sx={{
                        fill: "#fff",
                      }}
                    />
                  </div>

                  <Paragraph margin="0 1rem" size="300">
                    {currentPage} of {totalPages}
                  </Paragraph>

                  <div
                    style={{
                      backgroundColor:
                        currentPage === totalPages ? "#CCCCCC" : "#2f8dcd",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: ".3rem",
                      cursor: "pointer",
                    }}
                    onClick={handleNextPage}
                  >
                    <KeyboardArrowRightIcon
                      sx={{
                        fill: "#fff",
                      }}
                    />
                  </div>
                </div>
              </Box>
            </Box>

            {/* <Box sx={{ padding: "2rem 0 2rem 0" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={3}>
                  <h3
                    style={{
                      borderTop: "2px solid #2f8dcd",
                      borderLeft: "1px solid rgba(129,129,129,.2)",
                      borderRight: "1px solid rgba(129,129,129,.2)",
                      color: "#303030",
                      padding: "15px",
                      fontSize: "17px",
                      fontWeight: "500",
                      margin: 0,
                    }}
                  >
                    CATEGORIES
                  </h3>
                  <Box
                    sx={{
                      background: "#ffffff none repeat scroll 0 0",
                      border: "1px solid #81818133",
                      padding: "20px 0",
                    }}
                  >
                    <div>
                      {categories.map((category) => (
                        <div key={category.category_id}>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: ".4rem 1rem" }}>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-category-group-label"
                              value={selectedCategory}
                              name="radio-buttons-category-group"
                            >
                              <FormControlLabel
                                key={category.category_id}
                                value={category.category_id}
                                control={<Radio />}
                                label={category.name}
                                onChange={() => handleChooseCategory(category.category_id)}
                              />
                            </RadioGroup>
                            <div
                              onClick={() => handleToggleAccordion(category.category_id)}
                              style={{ cursor: "pointer" }}
                            >
                              <AddIcon />
                            </div>
                          </div>

                          {expanded === category.category_id && (
                            <Accordion>
                              <AccordionDetails>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-subcategory-group-label"
                                  value={selectedSubcategory}
                                  name="radio-buttons-subcategory-group"
                                >
                                  {category.subcategories.map((subcategory) => (
                                    <FormControlLabel
                                      key={subcategory.subcategory_id}
                                      value={subcategory.subcategory_id}
                                      control={<Radio />}
                                      label={subcategory.subcategory_name}
                                      onChange={handleChooseSubCategory}
                                    />
                                  ))}
                                </RadioGroup>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </div>
                      ))}
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <Grid container spacing={3}>
                    {products.map((item, i) => (
                      <Grid item xs={12} sm={4} md={4}>
                        <a href={`../productdetail/${item.product_id}`}>
                          <Card className={classes.cardContainer}>
                            <div className={classes.theProdBox}>
                              <div className={classes.theGallery}>
                                <img src={item.image} />
                              </div>
                              <Paragraph
                                color="#444"
                                style={{ fontSize: "16px" }}
                                center
                              >
                                {item.product_name}
                              </Paragraph>
                              <ul className={classes.product__price}>
                                <li >
                                  <span class="money" data-currency-usd="$150.00">
                                    MYR {item.price}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </Card>
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box> */}
          </Container>
        </Box>
      </div>
    </React.Fragment>
  );
}
