import { Card, TextField, Grid, IconButton, MenuItem, Button, Drawer } from "@material-ui/core";
import ContractorLayout from "../ContractorLayout";
import { Pin } from "@mui/icons-material";
import { AttachMoney, CalendarTodayOutlined, PinDrop, Search } from "@material-ui/icons";
import './styles.scss';
import { useHistory } from "react-router-dom";
import { useState } from "react";

const MyPost = () => {
    const navigate = useHistory();
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleNavigate = (id) => {
        console.log(id);
        navigate.push("/contractors/mypost/bid/" + id);
    }

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    }

    const handleOpenDrawer = (id) => {
        setOpenDrawer(true);
    }

    return (
        <>
            <ContractorLayout>
                <div className="contractor_dashboard mypost">
                    <Drawer
                        className="bid_drawer_container"
                        anchor="right"
                        open={openDrawer}
                        onClose={handleCloseDrawer}
                    >
                        <div className="bid_drawer">
                            {BIDS.map(r => (
                                <Card className="_project_card">
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <div className="_profile">
                                                <div className="thumbnail">
                                                    <img src="https://cdn1.npcdn.net/images/np_23950_1661319124.png" />
                                                </div>
                                                <p>Indor Malaysia</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={10} onClick={() => {
                                            handleNavigate(r);
                                        }}>
                                            <div className="_card_content">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim </p>
                                                <div style={{
                                                    display: "flex",
                                                    marginBottom: 4
                                                }}>
                                                    <AttachMoney />

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}>
                                                        10,000 MYR
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>

                                    </Grid>
                                </Card>
                            ))}

                        </div>
                    </Drawer>

                    {TEST.map(r => (
                        <Card className="_project_card">
                            <Grid container>
                                <Grid item xs={8} onClick={() => {
                                    handleOpenDrawer(r)
                                }}>
                                    <div className="_card_content">
                                        <h3>Water Plumbing required</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim </p>
                                        <div style={{
                                            display: "flex",
                                            marginBottom: 4
                                        }}>
                                            <PinDrop />

                                            <div style={{
                                                marginLeft: 10,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                Taman Megah
                                            </div>
                                        </div>

                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <CalendarTodayOutlined />

                                            <div style={{
                                                marginLeft: 10,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                22 Oct 2023 - 26 Oct 2023
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2} className="_left_border">
                                    <div className="_bids">
                                        <h1>100</h1>
                                        <p>VIEWS</p>
                                    </div>
                                </Grid>
                                <Grid item xs={2} className="_left_border">
                                    <div className="_bids">
                                        <h1>2</h1>
                                        <p>BIDS</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    ))}
                </div>
            </ContractorLayout>
        </>
    )
};

export default MyPost;

const TEST = [4, 5];
const BIDS = [1, 2, 3];