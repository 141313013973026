import React from "react";
import Container from '@mui/material/Container';
import './styles.css';
import GoogleHeader from "../GoogleHeader";



export default function Termandcondition() {
    return (
        <React.Fragment>
            <GoogleHeader />
            <Container>
                <div>
                    <h5 className="h_font_1"
                        style={{
                            textDecoration: 'underline'
                        }}>
                        Terms and Conditions
                    </h5>
                    <p className="p_font_New2">
                        General terms of business for the use of the website Indor.my and Indor applications
                    </p>

                    <h5 className="h_font_1">
                        1. Area of application
                    </h5>
                    <p className="p_font_New2">
                        As a user of the website Indor.my and of all the websites and internet services (Applications, Newsletter, downloads, links) of Indor connected with it, you recognise the following general terms and conditions (“GTC for online use“) and the warranty and liability restrictions which are defined below. Indor expressly reserves the right, without prior notice, to modify, supplement, or delete parts of the web pages or the entire service, or to close down the domain. In particular Indor has the right to amend these GTC for online use at any time. These GTC for online use should be read through again from time to time.
                    </p>
                    <p className="p_font_New2">
                        If any individual formulations of these terms of business for online use do not comply, no longer comply or do not comply fully with the applicable legal regulations, this will not affect the contents or validity of the remaining parts of these terms of business.
                    </p>

                    <h5 className="h_font_1">
                        2. Information on Indor.my and use of the website and Indor applications
                    </h5>
                    <p className="p_font_New2">
                        At Indor.my, Indor provides an information e-platform for interior designers , contractors, suppliers, agencies and consumers. Indor’s service is divided into sections: Service Library, Materials & Constructions, Design Collector and News & Trends. The Service Library is a search structure. It enables users to search for high-quality services from the premium segment in accordance with varying search criteria. The services which are shown are selected by the Indor team. The selection does not claim to be exhaustive and is oriented towards the interests of the targeted groups. Indor does not guarantee that the internet service will be available online at all times. This applies in particular to interference, interruptions or the possible breakdown of the internet site.
                    </p>
                    <p className="p_font_New2">
                        As a user you recognise that the contents of the website Indor.my and all the websites and internet services connected with it, in particular text, pictorial, video and sound material of all kinds, as well as the names of manufacturers, designers, auction houses and architects are protected under copyright, patent, trademark, branding, design, company, industrial property and/or other legal protection. It is not permitted to users, neither for their own nor for outside commercial purposes, to copy, exploit, distribute or publish any part of the contents of Indor and of all the websites and internet services connected with it or to use such contents for other purposes. In particular it is forbidden to change or remove logos, brand names, product or design names, references to copyright or other details from the contents.
                    </p>
                    <p className="p_font_New2">
                        The website Indor.my and all the websites and internet services connected with it contain non-binding information which is in no respect part of a business relationship. Indor regards the website Indor.my merely as a database containing a selection of manufacturers and products. Professional advice and reliable information must always be obtained from the respective manufacturer or specialist retailer. Any references to products and services, together with outside textual contributions, are prepared carefully in order to provide our users with valuable information. Where the contents and data are derived from third parties, Indor takes all the usual steps in order to make sure that the information is correct and up to date. However, if any information is found to be incorrect users are requested to inform Indor without delay.
                    </p>

                    <h5 className="h_font_1">
                        3. Warranty and liability restrictions
                    </h5>
                    <p className="p_font_New2">
                        Indor accepts no liability and provides no warranty of any kind for any damage which may result from the use of the website Indor.my and all the websites and internet services connected with it.
                    </p>
                    <p className="p_font_New2">
                        This applies particularly to the correctness, completeness and up-to-dateness of the content and data, the download and use of files contained on the site and links to websites of third parties and for the correct functioning of such links. This also applies where the links are provided with comments. When including links to third-party websites Indor takes all reasonable care to ensure that such sites do not contain illegal contents at the time the link is created. Indor has no influence on the further
                        development of these websites and hereby distances itself from any possible illegal content.
                    </p>
                    <p className="p_font_New2">
                        No liability is accepted particularly for any direct and indirect damage, for any loss of profit, failure of performance of any kind on the part of the internet, misuse by third parties or loss of programmes or other data in the user's information systems. These terms are governed by by Malaysian Law and any dispute will be resolved exclusively by the Malaysian Courts.
                    </p>

                    <h5 className="h_font_1">
                        4. Fraudulent or suspicious activities
                    </h5>
                    <p className="p_font_New2">
                        If Indor, in its sole discretion, believes that you may have in face engaged in any fraudulent or suspicious activities using Indor’s platform, we may take various actions to protect Indor, designers, contractors, suppliers, other third parties or you from Claims, penalties, fines, and any other liabilities. The actions we may take include but are not limited to the following:
                    </p>
                    <p className="p_font_New2">&nbsp;&nbsp; A) We may close, suspend, or limit your access to Indor’s Services, and/or suspend the processing of any transaction;</p>
                    <p className="p_font_New2">&nbsp;&nbsp; B) We may refuse to provide Indor’s Services to you now and in the future;</p>

                    <h5 className="h_font_1">
                        5. Indemnity
                    </h5>
                    <p className="p_font_New2">
                        You agree to indemnify, defend and hold harmless Indor, and its shareholders, subsidiaries, affiliates, directors, officers, agents, co-branders or other partners, and employees (collectively, the "Indemnified Parties") from and against any and all claims, actions, proceedings, and suits and all related liabilities, damages, settlements, penalties, fines, costs and expenses (including, without limitation, any other dispute resolution expenses) incurred by any Indemnified Party arising out of or relating to: (a) any transaction or dealings made on the Indor.my, or any dispute in relation to such transaction (except where Indor or its Affiliates is part of the transaction or dealings that the dispute relates to, in which case the claimable damages would be up to RM 500,000) (b) the Indor’s e-platform, (c) the hosting, operation, management and/or administration of the Services by or on behalf of Indor, (d) your violation or breach of any term of these Terms of Service or any policy or guidelines referenced herein, (e) your use or misuse of the Services, (f) your breach of any law or any rights of a third party, or (g) any Content uploaded by you.
                    </p>

                    <h5 className="h_font_1">
                        6. Refund and Compensation Policy
                    </h5>
                    <p className="p_font_New2">
                        It is the policy of Indor to issue refunds and/or compensation, where appropriate, to users who wish to cancel a payment or in any other situations covered by this policy.
                    </p>
                    <p className="p_font_New2">
                        The policy applies to users who are registered on Indor’s website and have conducted a business dealing using the Indor’s platform. Any form of deposit or renovation transfer fee has to go through Indor’s platform for this policy to be applicable. Client can request for a refund only in the event where construction or interior design works has not begun. There will be no refunds for ongoing projects. The amount of refund provided will be determined after deduction of any miscellaneous fees incurred by Indor.
                    </p>
                    <p className="p_font_New2">
                        Any transaction dealings or renovation project carried out using Indor’s platform requires a purchase of insurance security, with the maximum compensation fee of RM 500,000.00. Indor reserves the right to be indemnified to any disputes.
                    </p>

                </div>
            </Container>
        </React.Fragment>
    );
}