import "./styles.css";
import LocationBanner from "../../assets/images/location_banner.jpg";

import House1 from "../../assets/images/house_1.jpg";
import House2 from "../../assets/images/house_2.jpg";
import House3 from "../../assets/images/house_3.jpg";
import House4 from "../../assets/images/house_4.jpg";
import House5 from "../../assets/images/house_5.jpg";
import House6 from "../../assets/images/house_6.jpg";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import DesignItem from "../../components/design_item/design_item";
import Slider from "@material-ui/core/Slider";
import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  get_home_projects,
  get_area,
  get_tag_by_type,
  filter_projects,
} from "../../api/API";
import { Button, Drawer } from "@material-ui/core";
import GoogleHeader from "../../components/GoogleHeader";

const Location = (props) => {
  const [area, setArea] = useState([]);
  const [stylesTag, setStylesTag] = React.useState([]);
  const [areaTag, setAreaTag] = React.useState([]);
  const [propertyTag, setPropertyTag] = React.useState([]);
  const [workTag, setWorkTag] = React.useState([]);
  const [selectedStylesTag, setSelectedStylesTag] = React.useState([]);
  const [selectedAreaTag, setSelectedAreaTag] = React.useState([]);

  const [selectedArea, setSelectedArea] = React.useState([]);

  const [selectedPropertyTag, setSelectedPropertyTag] = React.useState([]);
  // const [selectedTag, setSelectedWorkTag] = React.useState([]);
  const data = [
    ...selectedStylesTag,
    ...selectedAreaTag,
    ...selectedArea,
    ...selectedPropertyTag,
  ];
  const params = useParams();
  console.log(params);

  let { Location } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Location]);

  const get_tags = () => {
    if (params.hasOwnProperty("selected_id")) {
      var selectedTagIds = params.selected_id;
    } else {
      var selectedTagIds = [];
    }

    if (params.hasOwnProperty("location_name")) {
      var location_name = params.location_name;
    } else {
      var location_name = [];
    }

    get_area({}).then((res) => {
      var selected_data = [];
      var temp_data = [];
      for (var i = 0; i < res.data.length; i++) {
        if (location_name.includes(res.data[i].slug)) {
          temp_data.push({
            ...res.data[i],
            checked: true,
          });
        } else {
          temp_data.push({
            ...res.data[i],

            checked: false,
          });
        }
      }
      temp_data.map((data) => {
        if (data.checked == true) {
          console.log(data.checked);
          selected_data.push(data.area_id);
        } else {
          selected_data.slice(data.area_id);
        }
      });
      setArea(temp_data);
      setSelectedArea(selected_data);
    });

    get_tag_by_type(1).then((res) => {
      var selected_data = [];
      var temp_data = [];
      for (var i = 0; i < res.data.length; i++) {
        if (selectedTagIds.includes(res.data[i].tag_id)) {
          temp_data.push({
            ...res.data[i],
            checked: true,
          });
        } else {
          temp_data.push({
            ...res.data[i],
            checked: false,
          });
        }
      }
      temp_data.map((data) => {
        if (data.checked == true) {
          console.log(data.checked);
          selected_data.push(data.tag_id);
        } else {
          selected_data.slice(data.tag_id);
        }
      });
      setPropertyTag(temp_data);
      setSelectedPropertyTag(selected_data);
    });
    get_tag_by_type(2).then((res) => {
      var temp_data = [];
      for (var i = 0; i < res.data.length; i++) {
        if (selectedTagIds.includes(res.data[i].tag_id)) {
          temp_data.push({
            ...res.data[i],
            checked: true,
          });
        } else {
          temp_data.push({
            ...res.data[i],
            checked: false,
          });
        }
      }
      // for (var i = 0; i < res.data.length; i++) {
      //     temp_data.push({
      //         ...res.data[i],
      //         checked: false
      //     });
      // }
      var selected_data = [];

      temp_data.map((data) => {
        if (data.checked == true) {
          selected_data.push(data.tag_id);
        } else {
          selected_data.slice(data.tag_id);
        }
      });
      setAreaTag(temp_data);
      setSelectedAreaTag(selected_data);
    });
    get_tag_by_type(3).then((res) => {
      var temp_data = [];
      for (var i = 0; i < res.data.length; i++) {
        if (selectedTagIds.includes(res.data[i].tag_id)) {
          temp_data.push({
            ...res.data[i],
            checked: true,
          });
        } else {
          temp_data.push({
            ...res.data[i],
            checked: false,
          });
        }
      }
      var selected_data = [];
      temp_data.map((data) => {
        if (data.checked == true) {
          selected_data.push(data.tag_id);
        } else {
          selected_data.slice(data.tag_id);
        }
      });
      setSelectedStylesTag(selected_data);
      // for (var i = 0; i < res.data.length; i++) {
      //     temp_data.push({
      //         ...res.data[i],
      //         checked: false
      //     });
      // }
      setStylesTag(temp_data);
    });

    get_tag_by_type(4).then((res) => {
      var temp_data = [];

      for (var i = 0; i < res.data.length; i++) {
        if (selectedTagIds.includes(res.data[i].tag_id)) {
          temp_data.push({
            ...res.data[i],
            checked: true,
          });
        } else {
          temp_data.push({
            ...res.data[i],
            checked: false,
          });
        }
      }
      // for (var i = 0; i < res.data.length; i++) {
      //     temp_data.push({
      //         ...res.data[i],
      //         checked: false
      //     });
      // }
      setWorkTag(temp_data);
    });
  };

  const handlecheckbox = (index) => {
    var selected_data = [];
    var temp_data = [...propertyTag];
    temp_data[index]["checked"] = !temp_data[index]["checked"];

    temp_data.map((data) => {
      if (data.checked == true) {
        console.log(data.checked);
        selected_data.push(data.tag_id);
      } else {
        selected_data.slice(data.tag_id);
      }
    });
    console.log(selected_data, temp_data);
    setPropertyTag(temp_data);
    setSelectedPropertyTag(selected_data);
  };

  const handlecheckboxstyle = (index) => {
    var temp_data = [...stylesTag];

    var selected_data = [];
    temp_data[index]["checked"] = !temp_data[index]["checked"];

    temp_data.map((data) => {
      if (data.checked == true) {
        selected_data.push(data.tag_id);
      } else {
        selected_data.slice(data.tag_id);
      }
    });
    console.log(temp_data);
    setStylesTag(temp_data);
    setSelectedStylesTag(selected_data);
  };

  const handlecheckboxarea = (index) => {
    var temp_data = [...area];

    var selected_data = [];
    temp_data[index]["checked"] = !temp_data[index]["checked"];

    temp_data.map((data) => {
      if (data.checked == true) {
        selected_data.push(data.area_id);
      } else {
        selected_data.slice(data.area_id);
      }
    });
    console.log(temp_data);
    setArea(temp_data);
    setSelectedArea(selected_data);
  };

  const handlecheckboxarrea = (index) => {
    var temp_data = [...areaTag];

    var selected_data = [];
    temp_data[index]["checked"] = !temp_data[index]["checked"];

    temp_data.map((data) => {
      if (data.checked == true) {
        selected_data.push(data.tag_id);
      } else {
        selected_data.slice(data.tag_id);
      }
    });
    console.log(temp_data);
    setAreaTag(temp_data);
    setSelectedAreaTag(selected_data);
  };

  const handleFilter = () => {
    let data = [
      ...selectedStylesTag,
      ...selectedAreaTag,
      ...selectedPropertyTag,
    ];
    filter_projects({
      tag: JSON.stringify(data),
      budget: price,
      filter_id: 1,
    }).then((res) => {
      setProjects(res.data);
    });
  };

  const [price, setPrice] = React.useState([10000, 1000000]);

  const [filterID, setFilterID] = useState(params.filter_id)

  const handleChangeFilter = (e) => {
    setFilterID(e)
    if (e == 0) {
      console.log('reset')
      var temp_propertydata = [...propertyTag];
      var temp_areadata = [...area];
      var temp_areatagdata = [...areaTag];
      var temp_stylesdata = [...stylesTag];
      for (var i = 0; i < temp_propertydata.length; i++) {
        temp_propertydata[i].checked = false
      }
      for (var i = 0; i < temp_areadata.length; i++) {
        temp_areadata[i].checked = false
      }
      for (var i = 0; i < temp_areatagdata.length; i++) {
        temp_areatagdata[i].checked = false
      }
      for (var i = 0; i < temp_stylesdata.length; i++) {
        temp_stylesdata[i].checked = false
      }
      setPropertyTag(temp_propertydata);
      setSelectedPropertyTag([]);
      setArea(temp_areadata);
      setSelectedArea([])
      setStylesTag(temp_stylesdata);
      setSelectedStylesTag([]);
      setAreaTag(temp_areatagdata);
      setSelectedAreaTag([]);
    }
  }

  const handleChangePrice = (event, newValue) => {
    setPrice(newValue);
  };

  const [toggleDrawer, setToggleDrawer] = React.useState(false);

  const [projects, setProjects] = React.useState([]);
  const get_data = () => {
    let data = [
      ...selectedStylesTag,
      ...selectedAreaTag,
      ...selectedPropertyTag,
    ];
    let area = [...selectedArea];
    get_home_projects({
      tag: JSON.stringify(data),
      budget: price,
      area: JSON.stringify(area),
      filter_id: filterID,
    }).then((res) => {
      setProjects(res.data);
    });
  };

  const [expanded, setExpanded] = React.useState('property_type');

  const handleChangeAccordian = (accordian) => {
    if (expanded == accordian) {
      setExpanded('test')
    } else {
      setExpanded(accordian)
    }
  }

  React.useEffect(() => {
    // get_data();
    get_tags();
  }, []);
  React.useEffect(() => {
    get_data();
  }, [
    selectedAreaTag,
    selectedPropertyTag,
    selectedStylesTag,
    price,
    selectedArea,
    filterID,
  ]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const openProject = (project_id) => {
    props.history.push("/project/" + project_id);
  };

  return (
    <div className="location_page">
      <GoogleHeader />
      {/* <div className="location_intro ">
                <div className="page_padding">
                    <h3>Projects in Mount Austin</h3>
                    <p>Have a unit in Mount Austin? Browse all designs made specifically according to units in Mount Austin</p>
                </div>
            </div> */}
      <hr
        style={{
          border: "none",
          borderBottom: "1px solid #dedede",
          padding: 0,
          margin: 0,
        }}
      />
      {/* <div className="banner">
                <img src={LocationBanner}></img>
            </div> */}
      <div className="page_padding">
        <div className="location_content">
          <div className=" hide_desktop mobile_filter_toggle" style={{}}>
            <Button
              style={{
                border: "1px solid #d1d1d1",
                width: "100%",
                color: "#515151",
              }}
              onClick={() => setToggleDrawer(true)}
            >
              Show Filter
            </Button>
          </div>
          <div className="location_filter_wrapper hide_mobile">
            <div className="location_filter">
              <Accordion
                expanded={expanded == 'property_type'}
                onChange={() => handleChangeAccordian('property_type')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className="AccordianTitle">Property Type</p>
                </AccordionSummary>
                <AccordionDetails>

                  {propertyTag.map((property, key) => (
                    <>
                      <FormControlLabel
                        // name={property.tag}
                        onChange={(event) => handlecheckbox(key)}
                        checked={property.checked}
                        value={property.tag_id}
                        control={<Checkbox color="primary" />}
                        label={property.tag}
                        labelPlacement="end"
                      />
                      <br />
                    </>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded == 'style'}
                onChange={() => handleChangeAccordian('style')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className="AccordianTitle">Style</p>
                </AccordionSummary>
                <AccordionDetails>
                  {stylesTag.map((style, key) => (
                    <>
                      <FormControlLabel
                        onChange={(event) => handlecheckboxstyle(key)}
                        checked={style.checked}
                        value={style.tag_id}
                        control={<Checkbox color="primary" />}
                        label={style.tag}
                        labelPlacement="end"
                      />
                      <br />
                    </>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded == 'Project'}
                onChange={() => handleChangeAccordian('Project')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className="AccordianTitle">Project</p>
                </AccordionSummary>
                <AccordionDetails>
                  {areaTag.map((area, key) => (
                    <>
                      <FormControlLabel
                        onChange={(event) => handlecheckboxarrea(key)}
                        checked={area.checked}
                        value={area.tag_id}
                        control={<Checkbox color="primary" />}
                        label={area.tag}
                        labelPlacement="end"
                      />
                      <br />
                    </>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded == 'Area'}
                onChange={() => handleChangeAccordian('Area')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className="AccordianTitle">Area</p>
                </AccordionSummary>
                <AccordionDetails>
                  {area.map((area, key) => (
                    <>
                      <FormControlLabel
                        onChange={(event) => handlecheckboxarea(key)}
                        checked={area.checked}
                        value={area.area_id}
                        control={<Checkbox color="primary" />}
                        label={area.area}
                        labelPlacement="end"
                      />
                      <br />
                    </>
                  ))}
                </AccordionDetails>
              </Accordion>
              <hr />

              <p>
                Budget RM {price[0]} - {price[1]}
              </p>
              <Slider
                value={price}
                onChange={handleChangePrice}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={10000}
                max={1000000}
              />
            </div>
          </div>
          <div className="location_projects">
            <div className="c-selectType">
              <FormControl className="homeSelect" fullWidth>
                <Select
                  name="property_type"
                  value={filterID}
                  // multiple
                  onChange={(e) => handleChangeFilter(e.target.value)}
                >
                  <MenuItem value={0}>
                    All
                  </MenuItem>
                  <MenuItem value={1}>
                    Latest
                  </MenuItem>
                  <MenuItem value={2}>
                    Popular
                  </MenuItem>
                  <MenuItem value={3}>
                    Nearby
                  </MenuItem>
                  <MenuItem value={4}>
                    Favorite
                  </MenuItem>

                </Select>
              </FormControl>
            </div>
            {/* <h3>
              {filterID == 1 ? "Latest" : null}
              {filterID == 2 ? "Popular" : null}
              {filterID == 3 ? "Nearby" : null}
              {filterID == 4 ? "Favorite" : null}
            </h3> */}
            <div className="design_container">
              {projects.map((proj) => (
                <DesignItem
                  onClick={() => {
                    openProject(proj.project_id);
                  }}
                  images={proj.images}
                  name={proj.title}
                  tag={proj.tags}
                  designer={proj.user.company_name}
                  designerarray={proj.user.image}
                ></DesignItem>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        className="drawer_filter"
        anchor="left"
        open={toggleDrawer}
        onClose={() => {
          setToggleDrawer(false);
        }}
      >
        <div className="drawer_filter_container">
          <Accordion
            expanded={expanded == 'property_type'}
            onChange={() => handleChangeAccordian('property_type')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className="AccordianTitle">Property Type</p>
            </AccordionSummary>
            <AccordionDetails>

              {propertyTag.map((property, key) => (
                <>
                  <FormControlLabel
                    // name={property.tag}
                    onChange={(event) => handlecheckbox(key)}
                    checked={property.checked}
                    value={property.tag_id}
                    control={<Checkbox color="primary" />}
                    label={property.tag}
                    labelPlacement="end"
                  />
                  <br />
                </>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded == 'style'}
            onChange={() => handleChangeAccordian('style')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className="AccordianTitle">Style</p>
            </AccordionSummary>
            <AccordionDetails>
              {stylesTag.map((style, key) => (
                <>
                  <FormControlLabel
                    onChange={(event) => handlecheckboxstyle(key)}
                    checked={style.checked}
                    value={style.tag_id}
                    control={<Checkbox color="primary" />}
                    label={style.tag}
                    labelPlacement="end"
                  />
                  <br />
                </>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded == 'Project'}
            onChange={() => handleChangeAccordian('Project')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className="AccordianTitle">Project</p>
            </AccordionSummary>
            <AccordionDetails>
              {areaTag.map((area, key) => (
                <>
                  <FormControlLabel
                    onChange={(event) => handlecheckboxarrea(key)}
                    checked={area.checked}
                    value={area.tag_id}
                    control={<Checkbox color="primary" />}
                    label={area.tag}
                    labelPlacement="end"
                  />
                  <br />
                </>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded == 'Area'}
            onChange={() => handleChangeAccordian('Area')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className="AccordianTitle">Area</p>
            </AccordionSummary>
            <AccordionDetails>
              {area.map((area, key) => (
                <>
                  <FormControlLabel
                    onChange={(event) => handlecheckboxarea(key)}
                    checked={area.checked}
                    value={area.area_id}
                    control={<Checkbox color="primary" />}
                    label={area.area}
                    labelPlacement="end"
                  />
                  <br />
                </>
              ))}
            </AccordionDetails>
          </Accordion>
          <hr />
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Budget RM {price[0]} - {price[1]}
          </p>
          <Slider
            value={price}
            onChange={handleChangePrice}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            min={10000}
            max={1000000}
          />
        </div>
      </Drawer>
    </div >
  );
};

export default Location;
