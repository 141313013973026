import { Card, TextField } from "@mui/material";
import ContractorLayout from "../ContractorLayout";
import { Button, Grid, InputAdornment, MenuItem } from "@material-ui/core";
import { AttachMoney, CalendarToday, Label, PinDrop } from "@material-ui/icons";
import './styles.scss';

const PostProject = () => {

    return (
        <>
            <ContractorLayout>
                <div className="post_project">
                    <Card style={{
                        padding: "20px"
                    }}>
                        <h2>POST A NEW JOB</h2>
                        <hr />
                        <h4 style={{
                            marginBottom: 6
                        }}>Title</h4>
                        <TextField label="Title"
                            style={{
                                width: "100%",
                                marginBottom: 20
                            }}
                        ></TextField>
                        <h4 style={{
                            marginBottom: 6
                        }}>Job Info</h4>
                        <TextField label="Describe Your Job"
                            style={{
                                width: "100%",
                                marginBottom: 20
                            }}
                            multiline={true}
                            rows={4}
                        ></TextField>
                        <h4 style={{
                            marginBottom: 12
                        }}>Details</h4>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <h5 style={{
                                    marginTop: 0,
                                    marginBottom: 6
                                }}>Area</h5>
                                <TextField label="Area"
                                    style={{
                                        width: "100%",
                                        marginBottom: 20
                                    }}
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start"><PinDrop /></InputAdornment>)
                                    }}
                                    select
                                >
                                    <MenuItem value={1}>Johor</MenuItem>
                                    <MenuItem value={2}>Malacca</MenuItem>
                                    <MenuItem value={3}>Selangor</MenuItem>
                                    <MenuItem value={4}>Negeri Sembilan</MenuItem>
                                    <MenuItem value={5}>Pahang</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <h5 style={{
                                    marginTop: 0,
                                    marginBottom: 6
                                }}>Timeline</h5>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField label="Project Start"
                                            style={{
                                                width: "100%",
                                                marginBottom: 20
                                            }}
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start"><CalendarToday /></InputAdornment>)
                                            }}
                                            type="date"
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label="Project End"
                                            style={{
                                                width: "100%",
                                                marginBottom: 20
                                            }}
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start"><CalendarToday /></InputAdornment>)
                                            }}
                                            type="date"
                                        >
                                        </TextField>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={4}>
                                <h5 style={{
                                    marginTop: 0,
                                    marginBottom: 6
                                }}>Budget</h5>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField label="Starting From"
                                            style={{
                                                width: "100%",
                                                marginBottom: 20
                                            }}
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start"><AttachMoney /></InputAdornment>)
                                            }}
                                            type="number"
                                        >
                                        </TextField>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label="Limit"
                                            style={{
                                                width: "100%",
                                                marginBottom: 20
                                            }}
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start"><AttachMoney /></InputAdornment>)
                                            }}
                                            type="number"
                                        >
                                        </TextField>

                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>

                        <h4 style={{
                            marginBottom: 6
                        }}>Attach Images</h4>
                        <div className="_attach_image">
                            <label for="upload_attachment" style={{
                                width: "100%"
                            }}>
                                <div className=""
                                    style={{
                                        width: "100%"
                                    }}
                                >
                                    + add image
                                </div>
                            </label>
                            <input type="file" id="upload_attachment" style={{
                                visibility: "hidden"
                            }}></input>
                        </div>

                        <h4 style={{
                            marginBottom: 6
                        }}>Attach Documents (blueprint/quotations/etc...)</h4>
                        <div className="_attach_image">
                            <label for="upload_attachment" style={{
                                width: "100%"
                            }}>
                                <div className=""
                                    style={{
                                        width: "100%"
                                    }}
                                >
                                    + add file
                                </div>
                            </label>
                            <input type="file" id="upload_attachment" style={{
                                visibility: "hidden"
                            }}></input>
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end"
                        }}>
                            <Button variant="contained" color="primary">
                                Post Job
                            </Button>
                        </div>
                    </Card>
                </div>
            </ContractorLayout>
        </>
    )
};

export default PostProject;