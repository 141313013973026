import React, {
    useState,
    useEffect,
    useRef
} from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    FormControl,
    Select,
    Button,
} from "@mui/material";
import Section from "../../../components/Section/Section";
import FormInput from "../../../components/FormInput/FormInput";
import Paragraph from "../../../components/Typography/Paragraph";
import Card from "../../../components/Card/Card";
import toast from "react-simple-toasts";
import Loader from "react-js-loader";
// import { Button } from "../../components/Button/Button";
import {
    SideBarContainer,
    SideBarBox,
    Icon,
    Circle,
    SiderBarList,
    NavFlexLink,
    SmallIcon,
    SmallIconIMG,
    ActiveBorder,
} from "../styles";
// import "./Account.css";
import useStyles from "../styles";
import './styles.css';
import useUser from "../../../hooks/useUser";
import {
    get_enquiry_listing,
    get_plan_role,
    create_user,
    check_plan_limit,
    delete_user,
    edit_user
} from "../../../api/API";
import GoogleHeader from "../../../components/GoogleHeader";


const Enquiry = (props) => {
    const history = useHistory();
    const classes = useStyles();

    const { user, set_user } = useUser();

    const [loading, setLoading] = useState(false)

    //Pagination
    const [page, setPage] = React.useState(1);
    const [totalCount, setTotalCount] = React.useState(1);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const [formData, setFormData] = React.useState({
        email: "",
        name: "",
        contact: "",
        password: "",
        password2: "",
        plan_role_id: "",
        user_type: 1,
        join_type: 2,
    });

    const [enquiryDetail, setEnquiryDetail] = React.useState(null);

    //Get User List
    const [userList, setUserList] = useState(null)
    const [planRoleList, setPlanRoleList] = useState(null)
    const get_data = () => {
        setLoading(true)
        get_enquiry_listing({ page: page }).then(res => {
            setUserList(res.data);
        });
        get_plan_role({}).then(res => {
            setPlanRoleList(res.data);
            setFormData({
                ...formData,
                plan_role_id: res.data[0].plan_role_id,
            });
            setLoading(false)
        });
    }

    useEffect(() => {
        get_data();
    }, [page])

    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState("paper");

    const handleClickOpen = (scrollType) => () => {

        check_plan_limit().then(reslimit => {
            // setProjectLimit(res);
            if (reslimit.data.name == 'Diamond Plan') {
                setOpen(true);
                setScroll(scrollType);
            } else {
                if (reslimit.user_count < reslimit.data.account_no) {
                    setOpen(true);
                    setScroll(scrollType);
                } else {
                    toast("You have reach your project user limit");
                }
            }
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const handleFormChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleForm = () => {
        if (formData.email.length == 0) {
            toast('Email is required');
            return
        }
        if (formData.name.length == 0) {
            toast('Name is required');
            return
        }
        if (formData.contact.length == 0) {
            toast('Contact is required');
            return
        }
        if (formData.password.length == 0) {
            toast('Password is required');
            return
        }
        if (formData.password2.length == 0) {
            toast('Confirm Password is required');
            return
        }
        create_user(formData).then((res) => {
            if (res.status) {
                toast("User Added");
                handleClose();
                setFormData({
                    email: "",
                    name: "",
                    contact: "",
                    password: "",
                    password2: "",
                    plan_role_id: "",
                    user_type: 1,
                    join_type: 2,
                });
                get_data();
            } else {
                toast(res.message);
            }
        });
    };

    const [openEdit, setOpenEdit] = useState(false);
    const [scrollEdit, setScrollEdit] = useState("paper");

    const descriptionElementEditRef = useRef(null);
    useEffect(() => {
        if (openEdit) {
            const { current: descriptionElement } = descriptionElementEditRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openEdit]);

    const handleEdit = (row) => {
        setEnquiryDetail(row);
        handleOpenEdit("paper")
    }

    const handleOpenEdit = (scrollType) => {
        setOpenEdit(true);
        setScrollEdit(scrollType);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleDelete = (row) => {
        var cfm = window.confirm('Are you sure you want to delete this user?');
        if (cfm) {
            delete_user({ user_id: row.user_id }).then((res) => {
                if (res.status) {
                    toast("User Deleted");
                    get_data();
                } else {
                    toast(res.message);
                }
            });
        }
    }

    // console.log(userList.length)
    return (
        <div className="account_page">
            <GoogleHeader />
            {loading ?
                <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
                :
                <React.Fragment>
                    <div className={classes.theHeaderProfile}>

                        <div style={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            margin: '0 0 1rem'
                        }}>
                            <Paragraph
                                size="600"
                                color="#0d2964"
                                bold="600"
                            // margin="0 0 1rem 0"
                            >
                                My Enquiry List
                            </Paragraph>
                            {/* <div
                                onClick={handleClickOpen("paper")}
                            >
                                <Paragraph
                                    size="300"
                                    color="#0d2964"
                                    bold="500"
                                // margin="0 0 1rem 0"
                                >
                                    Add User
                                </Paragraph>
                            </div> */}
                        </div>

                        <hr />
                        {/* <Paragraph size="500" color="#000" bold="700" margin="1rem 0">
                    Account Information
                </Paragraph> */}
                    </div>
                    {userList != null && userList.length != 0 ?
                        <div>
                            <TableContainer>
                                <Table
                                    sx={{
                                        // minWidth: 650
                                    }} aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                align="left"
                                            >
                                                <Paragraph
                                                    size="14px"
                                                    bold="500"
                                                    color="#000"
                                                >
                                                    No.
                                                </Paragraph>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                            >
                                                <Paragraph
                                                    size="14px"
                                                    bold="500"
                                                    color="#000"
                                                >
                                                    Name
                                                </Paragraph>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                            >
                                                <Paragraph
                                                    size="14px"
                                                    bold="500"
                                                    color="#000"
                                                >
                                                    Contact
                                                </Paragraph>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                            >
                                                <Paragraph
                                                    size="14px"
                                                    bold="500"
                                                    color="#000"
                                                >
                                                    Email
                                                </Paragraph>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                            >
                                                <Paragraph
                                                    size="14px"
                                                    bold="500"
                                                    color="#000"
                                                >
                                                    Budget
                                                </Paragraph>
                                            </TableCell>
                                            <TableCell>
                                                <Paragraph
                                                    center
                                                    size="14px"
                                                    bold="500"
                                                    color="#000"
                                                >
                                                    Action
                                                </Paragraph>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userList != null && userList.map((row, index) =>
                                            <TableRow
                                                key={row.user_id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell
                                                    align="left"
                                                >
                                                    <Paragraph
                                                        color="#211D4E"
                                                        size="16px"
                                                        bold="500"
                                                    >
                                                        {index + 1}
                                                    </Paragraph>
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                >
                                                    <Paragraph
                                                        color="#211D4E"
                                                        size="16px"
                                                        bold="500"
                                                    >
                                                        {row.name}
                                                    </Paragraph>
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                >
                                                    <Paragraph
                                                        color="#211D4E"
                                                        size="16px"
                                                        bold="500"
                                                    >
                                                        {row.contact}
                                                    </Paragraph>
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                >
                                                    <Paragraph
                                                        color="#211D4E"
                                                        size="16px"
                                                        bold="500"
                                                    >
                                                        {row.address}
                                                    </Paragraph>
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                >
                                                    <Paragraph
                                                        color="#211D4E"
                                                        size="16px"
                                                        bold="500"
                                                    >
                                                        {row.budget}
                                                    </Paragraph>
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                >
                                                    <Button
                                                        style={{ margin: '0 4px' }}
                                                        onClick={() => handleEdit(row)}
                                                        variant="outlined"
                                                    >
                                                        View
                                                    </Button>
                                                    {/* <Button
                                                            style={{ margin: '0 4px' }}
                                                            onClick={() => handleDelete(row)}
                                                            variant="outlined" color="error"
                                                        >
                                                            Delete
                                                        </Button> */}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>


                            {totalCount != 0 &&
                                <div style={{ margin: "20px 0 0" }}>
                                    <div>
                                        <Pagination
                                            count={totalCount}
                                            variant="outlined"
                                            page={page}
                                            onChange={handleChangePage}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div>
                            <Paragraph
                                center
                                color="#211D4E"
                                size="500"
                                bold="600"
                                style={{ margin: "10px 0" }}
                            >
                                No Enquiry Yet
                            </Paragraph>
                        </div>
                    }

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        scroll={scroll}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogTitle id="scroll-dialog-title">Add User Form</DialogTitle>
                        <DialogContent dividers={scroll === "paper"}>
                            <DialogContentText
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Email:</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Name:</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Contact:</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="contact"
                                            value={formData.contact}
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Role:</p>
                                        <FormControl fullWidth>
                                            <Select
                                                name="plan_role_id"
                                                onChange={handleFormChange}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                value={formData.plan_role_id}
                                            >
                                                {planRoleList != null && planRoleList.map((res) =>
                                                    <MenuItem value={res.plan_role_id}>{res.name}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Password:</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleFormChange}
                                            variant="outlined"
                                            type="password"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Confirm Password:</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="password2"
                                            value={formData.password2}
                                            onChange={handleFormChange}
                                            variant="outlined"
                                            type="password"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleForm}>Submit</Button>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>

                    {enquiryDetail != null &&
                        <Dialog
                            open={openEdit}
                            onClose={handleCloseEdit}
                            scroll={scrollEdit}
                            fullWidth={true}
                            maxWidth={'sm'}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogTitle id="scroll-dialog-title">Enquiry Form</DialogTitle>
                            <DialogContent dividers={scrollEdit === "paper"}>
                                <DialogContentText
                                    id="scroll-dialog-description"
                                    ref={descriptionElementEditRef}
                                    tabIndex={-1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <p style={{ margin: 0 }}>Name:</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <p style={{ margin: 0 }}>{enquiryDetail.name}</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <p style={{ margin: 0 }}>Contact:</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <p style={{ margin: 0 }}>{enquiryDetail.contact}</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <p style={{ margin: 0 }}>Email:</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <p style={{ margin: 0 }}>{enquiryDetail.address}</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <p style={{ margin: 0 }}>Budget:</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <p style={{ margin: 0 }}>{enquiryDetail.budget}</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <p style={{ margin: 0 }}>House Type:</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <p style={{ margin: 0 }}>{enquiryDetail.house_type}</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <p style={{ margin: 0 }}>Design Style:</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <p style={{ margin: 0 }}>{enquiryDetail.design_style}</p>
                                        </Grid>
                                    </Grid>
                                    {
                            /* <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <p>Email:</p>
                                    <TextField
                                        className="theHome"
                                        id="outlined-basic"
                                        name="email"
                                        value={enquiryDetail.email}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <p>Name:</p>
                                    <TextField
                                        className="theHome"
                                        id="outlined-basic"
                                        name="name"
                                        value={enquiryDetail.name}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <p>Contact:</p>
                                    <TextField
                                        className="theHome"
                                        id="outlined-basic"
                                        name="contact"
                                        value={enquiryDetail.contact}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid> */}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                {/* <Button onClick={handleEditForm}>Save</Button> */}
                                <Button onClick={handleCloseEdit}>Close</Button>
                            </DialogActions>
                        </Dialog>
                    }
                </React.Fragment>
            }
        </div >
    )
};

export default Enquiry;