import { Card, TextField, Grid, IconButton } from "@material-ui/core";
import ContractorLayout from "../ContractorLayout";
import { Pin } from "@mui/icons-material";
import { CalendarTodayOutlined, PinDrop, Search } from "@material-ui/icons";
import './styles.scss';
import { useHistory } from "react-router-dom";

const ContractorBids = () => {
    const navigate = useHistory();

    const handleNavigate = (id) => {
        console.log(id);
        navigate.push("/contractors/jobs/" + id);
    }
    return (
        <>
            <ContractorLayout>
                <div className="contractor_dashboard">
                    <Grid container style={{
                        width: "100%"
                    }}>
                        <Grid item xs={12}>
                            <TextField
                                style={{
                                    width: "100%",
                                    background: "white",
                                }}
                                InputProps={{
                                    endAdornment: <IconButton><Search /></IconButton>
                                }}
                                variant="outlined"
                                placeholder={"Search"}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    {TEST.map(r => (
                        <Card className="_project_card">
                            <Grid container>
                                <Grid item xs={10} onClick={() => {
                                    handleNavigate(r);
                                }}>
                                    <div className="_card_content">
                                        <h3>Water Plumbing required</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim </p>
                                        <div style={{
                                            display: "flex",
                                            marginBottom: 4
                                        }}>
                                            <PinDrop />

                                            <div style={{
                                                marginLeft: 10,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                Taman Megah
                                            </div>
                                        </div>

                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <CalendarTodayOutlined />

                                            <div style={{
                                                marginLeft: 10,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                22 Oct 2023 - 26 Oct 2023
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    <div className="_profile">
                                        <div className="thumbnail">
                                            <img src="https://cdn1.npcdn.net/images/np_23950_1661319124.png" />
                                        </div>
                                        <p>Indor Malaysia</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    ))}
                </div>
            </ContractorLayout>
        </>
    )
};

export default ContractorBids;

const TEST = [1, 2, 3];