import React from "react";
import Container from '@mui/material/Container';
import GoogleHeader from "../GoogleHeader";


export default function Joinus() {
    return (
        <React.Fragment>
            <GoogleHeader />
            <Container>
                <div>
                    <p>
                        <b>For Interior Designer</b><br></br>
                        <br></br>
                        Are you an interior designer looking for opportunity expanding your customer base and earning potential customers?<br></br>
                        <br></br>
                        Indor is the perfect place for you!<br></br>
                        <br></br>
                        We offer a dynamic way to showcase your works to thousands of potential clients with lucrative payouts.
                        Take advantage of our growing medium and become part of Indor’s community today!<br></br>
                        <br></br>
                        <b>For Subcontractor</b><br></br>
                        <br></br>
                        As a sub-contractor, you may be losing lucrative contracts because of your lack of opportunity to be noticed by more customers.<br></br>
                        <br></br>
                        Indor gives contractors/ sub-contractors the opportunity to advertise their businesses and services to our large network of potential clients.<br></br>
                        <br></br>
                        Work with us today and settle for nothing less than the best opportunity for your business.<br></br>
                        <br></br>
                        <b>For Direct Supplier</b><br></br>
                        <br></br>
                        Due to the rise of reseller platforms, direct suppliers are getting less opportunity to sell directly to consumers.<br></br>
                        <br></br>
                        Indor is a brand new Business-to-Customer marketplace featuring all your products. We want you to be in our directory so you can enjoy your own store banner on our homepage and other sections of the platform.<br></br>
                        <br></br>
                        Learn more about Indoor and see how our approach can be beneficial for you.<br></br>
                    </p>
                </div>
            </Container>
        </React.Fragment>
    );
}