import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  FormControl,
  Select,
  // Button,
} from "@mui/material";
import toast from "react-simple-toasts";
import Loader from "react-js-loader";
import { login_user, send_email_forgot_password } from "../../api/API";
import useUser from "../../hooks/useUser";
import "./styles.css";
import bg from "../../assets/images/login_bg.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import GoogleHeader from "../../components/GoogleHeader";

const Login = (props) => {
  let { Login } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Login]);

  const { set_user } = useUser();

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingForgot, setLoadingForgot] = useState(false);
  const [form, setForm] = React.useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    if (form.username.length == 0) {
      toast("Email is required");
      return;
    }
    if (form.password.length == 0) {
      toast("Password is required");
      return;
    }
    setLoadingLogin(true);
    login_user(form).then((res) => {
      if (res.status) {
        setLoadingLogin(false);
        set_user(res.data);
        // if (res.data.user_type == "1") {
        //     props.history.push("/dashboard");
        // } else {
        props.history.push("/");
        // }
      } else {
        setLoadingLogin(false);
      }
    });
  };

  const [openEdit, setOpenEdit] = useState(false);
  const [scrollEdit, setScrollEdit] = useState("paper");

  const descriptionElementEditRef = useRef(null);
  useEffect(() => {
    if (openEdit) {
      const { current: descriptionElement } = descriptionElementEditRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openEdit]);

  const handleOpenEdit = (scrollType) => {
    setOpenEdit(true);
    setScrollEdit(scrollType);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleEditForm = () => {
    if (form.username.length == 0) {
      toast("Email is required");
      return;
    }
    setLoadingForgot(true);
    send_email_forgot_password({ email: form.username }).then((res) => {
      if (res.status) {
        toast("New Password Send to your email");
        handleCloseEdit();
        setLoadingForgot(false);
      } else {
        toast(res.message);
      }
    });
  };

  return (
    <div className="page_padding">
      <GoogleHeader />
      <div className="c-login">
        <div className="c-loginBOX">
          <div className="c-loginLeft">
            <div className="c-lImg">
              <img src={bg} />
            </div>
            <div className="c-overlayText">
              <h1>Welcome</h1>
              <h1>Back!</h1>
            </div>
          </div>
          <div className="c-loginRight">
            <div className="login_form">
              <h3 className="c-lTitle">Login</h3>
              <TextField
                label="Email"
                variant="outlined"
                className="text_input"
                margin="dense"
                placeholder="John@example.com"
                name="username"
                onChange={handleChange}
                value={form.username}
              ></TextField>

              <TextField
                label="Password"
                variant="outlined"
                className="text_input"
                onChange={handleChange}
                margin="dense"
                type="password"
                name="password"
                value={form.password}
                placeholder="password"
              ></TextField>
              {loadingLogin ? (
                <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button onClick={handleSubmit} className="btn">
                    Submit
                  </Button>

                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "block", textAlign: "ceter" }}>
                      <a className="c-label" href="/join">
                        Don’t have an account yet?
                        <span style={{ color: "red" }}> Sign up here.</span>
                      </a>
                    </Box>

                    <div className="theHorizontalText">
                      <div
                        style={{
                          flexGrow: 1,
                          height: 1,
                          backgroundColor: "#000",
                        }}
                      />
                      <p
                        style={{
                          flexGrow: 0,
                          margin: "0 8px 0",
                        }}
                      >
                        Or
                      </p>
                      <div
                        style={{
                          flexGrow: 1,
                          height: 1,
                          backgroundColor: "#000",
                        }}
                      />
                    </div>

                    <Box sx={{ display: "block", textAlign: "ceter" }}>
                      <a
                        className="c-label"
                        onClick={() => handleOpenEdit("paper")}
                      >
                        Forgot your password?{" "}
                        <span style={{ color: "red" }}>Reset here.</span>
                      </a>
                    </Box>
                  </Box>
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
        scroll={scrollEdit}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Reset Password</DialogTitle>
        <DialogContent dividers={scrollEdit === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementEditRef}
            tabIndex={-1}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <p>Email:</p>
                <TextField
                  className="theHome"
                  id="outlined-basic"
                  name="username"
                  value={form.username}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loadingForgot ? (
            <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
          ) : (
            <Button onClick={handleEditForm}>Send</Button>
          )}
          <Button onClick={handleCloseEdit}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
    // <div className="login_page page_padding">

    //     <div className="join_container ">

    //         <div className="join_form login_form">

    //             <h3>Login</h3>
    //             <TextField
    //                 label="Email"
    //                 variant="outlined"
    //                 className="text_input"
    //                 margin="dense"
    //                 placeholder="John@example.com"
    //                 name="username"
    //                 onChange={handleChange}
    //                 value={form.username}
    //             ></TextField>

    //             <TextField
    //                 label="Password"
    //                 variant="outlined"
    //                 className="text_input"
    //                 onChange={handleChange}
    //                 margin="dense"
    //                 type="password"
    //                 name="password"
    //                 value={form.password}
    //                 placeholder="password"
    //             ></TextField>

    //             <Button
    //                 onClick={handleSubmit}
    //                 className="btn">Submit</Button>
    //             <br />
    //             <hr />
    //             <Button
    //                 style={{
    //                     background: "#8d80ec"
    //                 }}
    //                 onClick={handleSubmit}
    //                 className="btn">Don't have an account ? Register</Button>
    //         </div>
    //     </div>
    // </div>
  );
};

export default Login;
