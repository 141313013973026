import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  useHistory,
} from "react-router-dom";
import { ArrowBackIos, Close } from "@mui/icons-material";
import { Box, Container, Grid } from "@material-ui/core";
import FormInput from "../../../components/FormInput/FormInput";
import Paragraph from "../../../components/Typography/Paragraph";
import useStyles from "./styles";

import Button from "../../../components/Button/Button";
import useCart from "../../../hooks/useCart";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";

import { checkout, get_profile, submit_order } from "../../../api/API";

export default function Payment(props) {
  const classes = useStyles();
  const navigate = useHistory();

  const { addProduct, getCart, removeProduct, getTotalCart, clearCart } =
    useCart();

  const [token, setToken] = React.useState(null);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    contact: "",
    address: "",
  });

  React.useEffect(() => {
    getToken();
    getData();
  }, []);

  const getToken = () => {
    let tokenres = window.localStorage.getItem("token");
    if (tokenres) {
      setToken(tokenres);
    }
  };

  const getData = async () => {
    // var resProfile = await get_profile({});
    // if (resProfile.status) {
    //   console.log(resProfile.data.name);
    //   setProfileData({
    //     name: resProfile.data.name,
    //     email: resProfile.data.username,
    //     contact: "",
    //     address: "",
    //     postcode: "",
    //   });
    // }
  };

  const handleChange = (event) => {
    setProfileData({
      ...profileData,
      [event.target.name]: event.target.value,
    });
  };

  const [chooseAddress, setChooseAddress] = React.useState("");

  const handleChooseAddresss = (e) => {
    setChooseAddress(e);
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [checkoutStatus, setCheckoutStatus] = useState(null);

  const cartData = getCart(); // Assuming getCart() returns an object
  console.log("GET CART", cartData); // Check the structure of cartData

  // const handlePurchase = async () => {

  //   var cartLength = getCart().length;
  //   if (cartLength == 0) {
  //     alert("Your cart is empty.");
  //     setIsLoading(false);
  //     return false;
  //   }

  //   if (profileData.name.length == 0) {
  //     toast("Please enter name");
  //     setIsLoading(false);
  //     return;
  //   }
  //   if (profileData.email.length == 0) {
  //     toast("Please enter email");
  //     setIsLoading(false);
  //     return;
  //   }
  //   if (profileData.contact.length == 0) {
  //     toast("Please enter contact");
  //     setIsLoading(false);
  //     return;
  //   }
  //   if (profileData.address.length == 0) {
  //     toast("Please enter address");
  //     setIsLoading(false);
  //     return;
  //   }


  //   try {
  //     const cartData = getCart();
  //     const cartItem = cartData;

  //     const cartArray = cartItem.map((item) => ({
  //       product_id: item.product_id,
  //       quantity: item.quantity
  //     }))

  //     const data = {
  //       ...profileData,
  //         cart: cartArray,
  //         promo_code: 'indor-promo'
  //     };

  //     const response = await checkout(data);

  //     if (response.status) {
  //       setCheckoutStatus(response);
  //       localStorage.setItem('token', response.token);
  //       window.href = "/bankdetails"
  //     } else {
  //       alert(response.message)
  //     }

  //     // window.localStorage.removeItem("cart")
  //     // window.location = "/"
  //   } catch (error) {
  //     console.error('Error during checkout:', error);
  //   }
  // };

  // const handlePurchase = () => {

  //   const cartData = getCart();
  //   const cartItem = cartData;

  //   const cartArray = cartItem.map((item) => ({
  //     product_id: item.product_id,
  //     quantity: item.quantity
  //   }))

  //   const postparam = {
  //     name: profileData.name,
  //     email: profileData.email,
  //     contact: profileData.contact,
  //     address: profileData.address,
  //     cart: cartArray
  //   };

  //   const headers = {
  //     Authorization: `Bearer ${token}`
  //   };


  //   checkout(JSON.stringify(postparam), headers).then((json) => {
  //     if (json.status) {
  //       toast("submitted")
  //       // window.location = "/bankdetails"
  //       localStorage.setItem('token', json.token);
  //       // window.location.reload()
  //     } else {
  //       console.error(json.message)
  //     }
  //   })
  // }


  const handlePurchase = () => {
    const cartData = getCart();
    const cartItem = cartData;
    // const promoCode = "XYZ123";

    const cartArray = {
      cart: [
        {
          // promo_code: promoCode,
          cart: cartData.map((item) => ({
            product_id: item.product_id,
            quantity: item.quantity,
            product_variant_id: item.product_variant_id || null,
          })),
        },
      ],
    };
  

    // const cartArray = cartItem.map((item) => ({
    //   product_id: item.product_id,
    //   quantity: item.quantity,
    //   product_variant_id: item.product_variant_id,
    // }));
  
    const formData = new FormData();
    formData.append('name', profileData.name);
    formData.append('email', profileData.email);
    formData.append('contact', profileData.contact);
    formData.append('address', profileData.address);
    formData.append('cart', JSON.stringify(cartArray))
    
    // // Add cart items to the FormData
    // cartArray.forEach((item, index) => {
    //   formData.append(`cart[${index}][product_id]`, item.product_id);
    //   formData.append(`cart[${index}][quantity]`, item.quantity);
    // });
  
    const headers = {
      Authorization: `Bearer ${token}`
    };
  
    checkout(formData, headers).then((json) => {
      if (json.status) {
        toast("submitted");
        localStorage.setItem('token', json.token);
      } else {
        console.error(json.message);
      }
    });
  };

  
  return (
    <React.Fragment>
      <Box className={classes.thePadding}>
        <Container>
          <Box style={{ padding: "3rem 0" }}>
            {token ? (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Paragraph bold="600">Contact Infomation</Paragraph>
                    </div>
                    <FormInput
                      placeholder="Name"
                      value={profileData.name}
                      name="name"
                      onChange={handleChange}
                      border="1px solid #707070"
                    />
                    <FormInput
                      placeholder="Email"
                      value={profileData.email}
                      name="email"
                      onChange={handleChange}
                      border="1px solid #707070"
                    />
                    <FormInput
                      placeholder="Contact Number"
                      value={profileData.contact}
                      type="number"
                      name="contact"
                      onChange={handleChange}
                      border="1px solid #707070"
                    />

                    <Paragraph bold="600" margin="0 0 1rem 0">
                      Shipping Address
                    </Paragraph>

                    <FormInput
                      placeholder="Address"
                      name="address"
                      onChange={handleChange}
                      value={profileData.address}
                      border="1px solid #707070"
                      multiline
                      rows={4}
                    />

                    {/* <FormInput
                      placeholder="Postcode"
                      name="postcode"
                      onChange={handleChange}
                      value={profileData.postcode}
                      border="1px solid #707070"
                    /> */}
                    {/* <div className={classes.theListAddress}>
											<div>
												<Paragraph margin=" 0 0 .6rem 0">Manuel Cooper</Paragraph>
											</div>
											<Paragraph>123 Jalan ABC 81100</Paragraph>
											<Paragraph size="14px" color="#637381">
												123456789
											</Paragraph>
										</div> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    {/* {cart !== undefined && cart.length > 0
                    ? cart.map((item) => (
                        <div className={classes.theFlex}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div className={classes.theProdIMG}>
                              <img src={item.images} />
                            </div>
                            <Paragraph>{item.name}</Paragraph>
                          </div>
                          <Paragraph
                            style={{ textAlign: "right", width: "25%" }}
                          >
                            RM {item.price}
                          </Paragraph>
                          <a style={{ display: "block", marginLeft: "1rem" }}>
                            <Close />
                          </a>
                        </div>
                      ))
                    : null} */}

                    <div className={classes.theFlex}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Paragraph>Product</Paragraph>
                      </div>
                      <Paragraph
                        style={{ textAlign: "center", width: "25%" }}
                      >
                        Quantity
                      </Paragraph>
                      <Paragraph
                        style={{ textAlign: "center", width: "25%" }}
                      >
                        Price
                      </Paragraph>
                      <a
                        // onClick={() => removeProduct(res)}
                        style={{ display: "block", marginLeft: "1rem" }}
                      >
                        <Close sx={{ color: "#fff" }} />
                      </a>
                    </div>

                    {getCart().length > 0 &&
                      getCart().map((res) => (
                        <div className={classes.theFlex}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div className={classes.theProdIMG}>
                              <img src={res.images} />
                            </div>
                            <Paragraph>{res.name}</Paragraph>
                          </div>
                          <Paragraph
                            style={{ textAlign: "center", width: "25%" }}
                          >
                            {res.quantity}
                          </Paragraph>
                          <Paragraph
                            style={{ textAlign: "right", width: "25%" }}
                          >
                            MYR {parseFloat(res.price).toFixed(2)}
                          </Paragraph>
                          <a
                            onClick={() => removeProduct(res)}
                            style={{ display: "block", marginLeft: "1rem" }}
                          >
                            <Close sx={{ color: "#F90000" }} />
                          </a>
                        </div>
                      ))}
                    {/* <div className={classes.theFlex_}>
												<div className={classes.theWidth}>
													<FormInput
														placeholder="Promo Code"
														border="1px solid #707070"
														style={{ width: "100%" }}
													/>
												</div>

												<Button label="APPLY" color="#fff" bg="#D5AE35" br="6px" />
											</div> */}

                    {/* <div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													marginBottom: "1rem",
													paddingTop: "1rem",
													borderTop: "1px solid #d7d7d7",
												}}
											>
												<Paragraph size="1.2rem">Subtotal</Paragraph>
												<Paragraph bold="500" size="1.2rem">
													RM {getTotalCart()}
												</Paragraph>
											</div>

											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													marginBottom: "1rem",
												}}
											>
												<Paragraph size="1.2rem">Shipping Fee</Paragraph>
												<Paragraph bold="500" size="1.2rem">
													RM 0.00
												</Paragraph>
											</div> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "1rem",
                        paddingTop: "1rem",
                        borderTop: "1px solid #d7d7d7",
                      }}
                    >
                      <Paragraph style={{ fontSize: "1.2rem" }}>
                        Total
                      </Paragraph>
                      <Paragraph
                        color="#000"
                        bold="700"
                        style={{ fontSize: "1.2rem" }}
                      >
                        MYR {getTotalCart().toFixed(2)}
                      </Paragraph>
                    </div>
                    <br />

                    {isLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // height: "600px",
                        }}
                      >
                        <Loader
                          type="snipper-default"
                          bgColor={"#878787"}
                          size={30}
                        />
                      </div>
                    ) : (
                      // <>
                      //   <Button
                      //     label="PAY WITH CARD"
                      //     color="#fff"
                      //     bg="#2f8dcd"
                      //     br="6px"
                      //     onClick={() => handlePurchase("card")}
                      //   />
                      //   <br />
                      //   <Button
                      //     label="PAY WITH FPX"
                      //     color="#fff"
                      //     bg="#2f8dcd"
                      //     br="6px"
                      //     onClick={() => handlePurchase("fpx")}
                      //   />
                      // </>

                      <div className={classes.theFlexx}>
                        <div className={classes.theRight}>
                          <Button
                            href="/cart"
                            label="Edit Cart"
                            br="4px"
                            color="#2f8dcd"
                            bd="1px solid #2f8dcd"
                            pd=".6rem 1rem"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div>
                          <Button
                            label="Pay Now"
                            color="#fff"
                            bg="#2f8dcd"
                            br="6px"
                            pd=".6rem 4rem"
                            style={{ cursor: "pointer" }}
                            onClick={() => handlePurchase()}
                          />
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={7}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Paragraph bold="600">Contact Infomation</Paragraph>
                      <Paragraph>
                        Already have account?{" "}
                        <a
                          style={{
                            color: "#2f8dcd",
                            textDecoration: "underline",
                          }}
                        >
                          Log in
                        </a>
                      </Paragraph>
                    </div>
                    <FormInput
                      placeholder="Email"
                      border="1px solid #707070"
                      name="email"
                      onChange={handleChange}
                    />
                    <br />
                    <Paragraph bold="600">Shipping Address</Paragraph>

                    <FormInput
                      placeholder="Name"
                      border="1px solid #707070"
                      name="name"
                      onChange={handleChange}
                    />
                    <FormInput
                      placeholder="Phone"
                      border="1px solid #707070"
                      name="contact"
                      type="number"
                      onChange={handleChange}
                    />
                    <FormInput
                      placeholder="Address"
                      name="address"
                      onChange={handleChange}
                      border="1px solid #707070"
                      multiline
                      rows={4}
                    />

                    {/* <FormInput
                      placeholder="Postcode"
                      name="postcode"
                      onChange={handleChange}
                      border="1px solid #707070"
                    /> */}

                    <br />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ArrowBackIos style={{ color: "#2f8dcd" }} />
                      <a href="/marketplace" style={{ color: "#2f8dcd" }}>
                        Return Marketplace
                      </a>
                    </div>
                    <br />
                    <br />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    {getCart() !== undefined && getCart().length > 0 ? (
                      getCart().map((item) => (
                        <div className={classes.theFlex}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div className={classes.theProdIMG}>
                              <img src={item.images} />
                            </div>
                            <Paragraph>{item.name}</Paragraph>
                          </div>
                          <Paragraph
                            style={{ textAlign: "right", width: "25%" }}
                          >
                            RM {item.price}
                          </Paragraph>
                          {/* <a style={{ display: "block", marginLeft: "1rem" }}>
                            <Close />
                          </a> */}
                        </div>
                      ))
                    ) : (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                    {/* <div className={classes.theFlex}>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														width: "100%",
													}}
												>
													<div className={classes.theProdIMG}>
														<img src={prodimg001} />
													</div>
													<div>
														<Paragraph>Blue Dream Mattress</Paragraph>
														<Paragraph size="12px">Size L x W x H</Paragraph>
													</div>
												</div>
												<Paragraph style={{ textAlign: "right", width: "25%" }}>
													RM 100.00
												</Paragraph>
												<a style={{ display: "block", marginLeft: "1rem" }}>
													<Close />
												</a>
											</div> */}
                    {/* <div className={classes.theFlex_}>
												<div className={classes.theWidth}>
													<FormInput
														placeholder="Promo Code"
														border="1px solid #707070"
														style={{ width: "100%" }}
													/>
												</div>

												<Button label="APPLY" color="#fff" bg="#D5AE35" br="6px" />
											</div> */}

                    {/* <div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													marginBottom: "1rem",
													paddingTop: "1rem",
													borderTop: "1px solid #d7d7d7",
												}}
											>
												<Paragraph size="1.2rem">Subtotal</Paragraph>
												<Paragraph bold="500" size="1.2rem">
													RM {getTotalCart()}
												</Paragraph>
											</div> */}

                    {/* <div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													marginBottom: "1rem",
												}}
											>
												<Paragraph size="1.2rem">Shipping Fee</Paragraph>
												<Paragraph bold="500" size="1.2rem">
													RM 10.00
												</Paragraph>
											</div> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "1rem",
                        paddingTop: "1rem",
                        borderTop: "1px solid #d7d7d7",
                      }}
                    >
                      <Paragraph style={{ fontSize: "1.2rem" }}>
                        Total
                      </Paragraph>
                      <Paragraph
                        color="#000"
                        bold="700"
                        style={{ fontSize: "1.2rem" }}
                      >
                        {/* RM 100.00 */}
                        RM {getTotalCart().toFixed(2)}
                      </Paragraph>
                    </div>
                    <br />

                    {isLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // height: "600px",
                        }}
                      >
                        <Loader
                          type="snipper-default"
                          bgColor={"#878787"}
                          size={30}
                        />
                      </div>
                    ) : (
                      // <>
                      //   <Button
                      //     label="PAY WITH CARD"
                      //     color="#fff"
                      //     bg="#2f8dcd"
                      //     br="6px"
                      //     onClick={() => handlePurchase("card")}
                      //   />
                      //   <br />
                      //   <Button
                      //     label="PAY WITH FPX"
                      //     color="#fff"
                      //     bg="#2f8dcd"
                      //     br="6px"
                      //     onClick={() => handlePurchase("fpx")}
                      //   />
                      // </>
                      <Button
                        label="PAY NOW"
                        color="#fff"
                        bg="#2f8dcd"
                        br="6px"
                        pd=".6rem 1rem"
                        style={{ cursor: "pointer" }}
                        onClick={() => handlePurchase()}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
}
