
import SliderBanner from "react-slick";

import RNF from '../../assets/images/rnf.jpeg.png';
import Skudai from '../../assets/images/skudai.jpg.png';
import Sutera from '../../assets/images/sutera.jpg.png';
import PuteriHarbour from '../../assets/images/puteri_harbour.jpg.png';
import Kulai from '../../assets/images/kulai.jpg.png';
import MountAustin from '../../assets/images/mount_austin.jpg';
import CloseIcon from '@material-ui/icons/Close';
import CropFreeIcon from '@mui/icons-material/CropFree';
import DesignerImg from '../../assets/images/designer_img_1.jpg';
import './Project.css';
import Close from "@material-ui/icons/Close";
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LinkIcon from '@material-ui/icons/Link';
import { Modal, TextField } from "@material-ui/core";
import React from "react";
import {
    get_project,
    get_enquiry,
} from "../../api/API";
import { useParams, useLocation } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Slider from "@material-ui/core/Slider";
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Loader from "react-js-loader";
import { Helmet } from "react-helmet";
import GoogleHeader from "../../components/GoogleHeader";

import Carousel, { CarouselItem } from '../../components/Carousel/Carousel'
import {
    // EmailShareButton,
    FacebookShareButton,
    // HatenaShareButton,
    // InstapaperShareButton,
    // LineShareButton,
    LinkedinShareButton,
    // LivejournalShareButton,
    // MailruShareButton,
    // OKShareButton,
    // PinterestShareButton,
    // PocketShareButton,
    // RedditShareButton,
    // TelegramShareButton,
    // TumblrShareButton,
    // TwitterShareButton,
    // ViberShareButton,
    // VKShareButton,
    WhatsappShareButton,
    // WorkplaceShareButton
} from "react-share";
import { useState } from "react";

const Project = (props) => {
    const params = useParams();

    var fixedText = ""

    let { Project } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [Project]);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    arrows: false
                }
            }
        ]
    };
    const [loadingButton, setLoadingButton] = React.useState(false)
    const [currentURL, setCurrentURL] = React.useState('');

    function onLinkClick(event) {
        console.log('onLinkClick'); // never called
    }

    const label = (
        <span>*Find out how to get a guarantee of MYR 500,000 to&nbsp;
            <a
                href="/termandcondition"
                target="_blank"
                onClick={onLinkClick}
                style={{ color: "blue", textDecoration: "underline" }}
            >
                indor.my
            </a>
            &nbsp;for damages.
        </span>
    )

    const handleShareFacebook = () => {
        window.open({
            url:
                "https://www.facebook.com/sharer/sharer.php?u=http://indor.cysoft.co/project/" +
                params.project_id,
        });
    }

    const handleShareWhatsapp = () => {
        window.open({
            url:
                "whatsapp://send?text=Check This Project!"
        });
    }

    const handleCopy = (currentURL) => {

        navigator.clipboard.writeText(currentURL);
        alert('Link Copied')
    }
    const [images, setImages] = React.useState([]);
    const [project, setProject] = React.useState(null);

    const [projectLimit, setProjectLimit] = React.useState(null)

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };


    React.useEffect(() => {
        setCurrentURL(window.location.href)
        get_data(props.match.params.project_id);
    }, []);
    const get_data = (project_id) => {

        get_project(project_id).then((res) => {
            setProject(res.data[0]);
        });
    }

    const [price, setPrice] = React.useState([10000, 1000000]);

    const handleChangePrice = (event, newValue) => {
        setPrice(newValue);
    };

    const [enquiryData, setEnquiryData] = React.useState({

        name: "",
        contact: "",
        address: "",
        house_type: "",

    });
    console.log(enquiryData)

    const names = [
        'Minimalist',
        'Modern Contemporary',
        'Modern Classic',
        'Urban',
        'Muji',
        'Industrial',
        'Zen',
    ];

    const [designStyle, setDesignStyle] = React.useState([]);

    const handleDesignChange = (event) => {
        const {
            target: { value },
        } = event;
        setDesignStyle(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChange = (e) => {
        setEnquiryData({
            ...enquiryData,
            [e.target.name]: e.target.value,
        });
    };


    const [checked, setChecked] = React.useState(false);

    const handleCheckChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleSubmit = () => {

        // if (enquiryData.name.length == 0
        //     || enquiryData.contact.length == 0
        //     || enquiryData.address.length == 0
        //     || enquiryData.budget.length == 0) {
        //     alert("All Field Required");
        //     return
        // }
        // console.log(enquiryData)
        setLoadingButton(true)
        get_enquiry({
            ...enquiryData,
            budget: price,
            design_style: designStyle,
            is_checked: checked,
            company_id: project.company_id,
            project_id: props.match.params.project_id,
        }).then((json) => {
            if (json.status) {
                setLoadingButton(false)
                alert('Enquiry Sent')
                window.location.reload();
                setOpen(false);
            } else {
                setLoadingButton(false)
                alert(json.message)
            }
        });
    };

    function addAdminPrefixIfNeeded(imageUrl) {
        // Check if the URL already starts with "https://indor.my/admin"
        if (imageUrl.startsWith("https://indor.my/admin")) {
          return imageUrl; // No need to add the prefix, so return the original URL
        } else {
          // Add the prefix if it's missing
          return `https://indor.my/admin${imageUrl}`;
        }
      }

    const [on, setOn] = React.useState(false);

    const handleClickOn = () => {
        setOn(true);
    };

    const handleOff = () => {
        setOn(false);
    };
    return (
        <React.Fragment>
            <GoogleHeader />
            {project ?
                <div className="project_page">

                    <Helmet>
                        <title>{project.title}</title>
                        <meta name="description" content={project.description} />
                        <meta property='og:title' content={project.title} />
                        <meta property='og:image' content={addAdminPrefixIfNeeded(project.images[0].image)} />
                        <meta property='og:description' content={project.description} />
                    </Helmet>
                    <div className="project_images">
                        {/* <SliderBanner {...settings}> */}

                        <Carousel
                            image="image"
                        >
                            {project.images.map((img, key) => (
                                <CarouselItem>
                                    <div className="flex_center c-relative" image={addAdminPrefixIfNeeded(img.image)}>
                                        <img src={addAdminPrefixIfNeeded(img.image)} className="c-PI" />
                                        {/* <CropFreeIcon className="c-PICON" /> */}
                                    </div>
                                </CarouselItem>
                            ))}
                        </Carousel>
                        {/* </SliderBanner> */}
                    </div>
                    <div className="project_designer">
                        <div className="close_wrapper">
                            <CloseIcon onClick={() => { props.history.goBack() }}></CloseIcon>
                        </div>
                        <div className="designer"
                            onClick={() => {
                                props.history.push("/designer/" + project.user.user_id)
                            }}
                        >
                            <div className="img_wrapper">
                                <img src={project.user.image}></img>
                            </div>
                            <div className="designer_info flex_center">
                                <p><small>Designed By:</small></p>
                                <p>{project.user.company_name}</p>
                            </div>
                        </div>
                        {project.plan_id == '1' && project.is_expired == 1 ?
                            <React.Fragment></React.Fragment>
                            :
                            <React.Fragment>
                                <div className="btn_enquire" onClick={() => { setOpen(true) }}>
                                    Enquire
                                </div>

                                <div className="drawing_info">
                                    <h2>{project.title}</h2>
                                    {/* <p>2 Bedroom Unit</p> */}

                                    <div className="designer_contact_info">
                                        <p>Contact this designer</p>
                                        <div style={{
                                            display: 'flex',
                                        }}>
                                            <EmailIcon></EmailIcon>
                                            <a
                                                style={{
                                                    margin: "0 0 0 6px"
                                                }}
                                                href={"mailto: " + project.user.email}>
                                                <p className='theLabel'>Email:</p>
                                                <p className='theText'>{project.user.email}</p>
                                            </a>
                                        </div>
                                        {project.plan_id > 1 &&
                                            <React.Fragment>
                                                <div style={{
                                                    display: 'flex',
                                                }}>
                                                    <WhatsAppIcon></WhatsAppIcon>
                                                    <a
                                                        style={{
                                                            margin: "0 0 0 6px"
                                                        }}
                                                        target={"_blank"}
                                                        href={"https://wa.me/" + project.user.contact}>
                                                        <p className='theLabel'>WhatsApp:</p>
                                                        <p className='theText'>https://wa.me/{project.user.contact}</p>
                                                    </a>
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                }}>
                                                    <CallIcon></CallIcon>
                                                    <a
                                                        style={{
                                                            margin: "0 0 0 6px"
                                                        }}
                                                        href={"tel:" + project.user.contact}>
                                                        <p className='theLabel'>Phone Number:</p>
                                                        <p className='theText'>{project.user.contact}</p>
                                                    </a>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                    <h2>
                                        About this design
                                    </h2>
                                    <p style={{ fontSize: "0.8em" }}
                                        dangerouslySetInnerHTML={{
                                            __html: project.description
                                        }}
                                    />

                                    <p><small>Tags: </small></p>
                                    <div className="tag_wrapper">
                                        {project.tags.map(tag => (
                                            <div className="tag">
                                                {tag.tag}
                                            </div>
                                        ))
                                        }


                                    </div>
                                </div>
                            </React.Fragment>
                        }


                        <p style={{
                            paddingLeft: 20
                        }}>Share this design</p>
                        <div className="sharing_info">
                            <FacebookShareButton
                                url={currentURL}
                                // quote={"フェイスブックはタイトルが付けれるようです"}
                                hashtag={"#" + project.user.company_name}
                                description={"aiueo"}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon></FacebookIcon>
                            </FacebookShareButton>
                            {/* <div className="share_item"
                                onClick={() => handleShareFacebook()}
                            >
                                <FacebookIcon></FacebookIcon>
                            </div> */}
                            <WhatsappShareButton
                                url={currentURL}
                                // quote={"フェイスブックはタイトルが付けれるようです"}
                                hashtag={"#hashtag"}
                                description={"aiueo"}
                                className="Demo__some-network__share-button"
                            >
                                <WhatsAppIcon></WhatsAppIcon>
                            </WhatsappShareButton>
                            {/* <a className="share_item" href={"https://wa.me/?text=" + currentURL} target="_blank">
                                <WhatsAppIcon></WhatsAppIcon>
                            </a> */}
                            {/* <LinkedinShareButton
                                url={currentURL}
                                // quote={"フェイスブックはタイトルが付けれるようです"}
                                hashtag={"#hashtag"}
                                description={"aiueo"}
                                className="Demo__some-network__share-button"
                            >
                                <LinkedInIcon></LinkedInIcon>
                            </LinkedinShareButton> */}
                            <a className="share_item" href={"https://www.linkedin.com/sharing/share-offsite/?url=" + currentURL} target="_blank">
                                <LinkedInIcon></LinkedInIcon>
                            </a>
                            <div className="share_item"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleCopy(currentURL)}>
                                <input style={{ position: 'absolute', zIndex: -1, opacity: 0 }} id="url-input" value={currentURL} />
                                <LinkIcon></LinkIcon>
                            </div>
                        </div>

                    </div>


                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        className="flex_center"
                    >

                        <div className="form_container">
                            <div className="title_flex">
                                <div>
                                    <h3>Get Your Quotation</h3>
                                </div>
                                {/* <div>
                                        <div style={{ cursor: "pointer" }} onClick={handleClickOn}>
                                            <QuestionMarkIcon />
                                        </div>
                                        <Dialog
                                            open={on}
                                            onClose={handleOff}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                {"Privacy Policy"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    如果经过platform 找到装修商，有提供特定保障<br></br>
                                                    <br></br>
                                                    You will enjoy the peace of mind with Indor marketplace platform.
                                                    Indor provides after-sales support for any services purchased or made through our platform.
                                                    The purchase contract has to happen through our platform to enjoy the warranty.
                                                    However, if you deal directly with the interior designer/ sub-contractor/ direct suppler,
                                                    we do not provide any guarantee or offer any after-sales support.
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleOff}>Close</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </div> */}
                            </div>
                            <TextField
                                className="text_field"
                                label="Name"
                                placeholder="Name"
                                name="name"
                                variant="outlined"
                                onChange={handleChange}
                            ></TextField>
                            <TextField
                                className="text_field"
                                label="Contact Number"
                                placeholder="Contact"
                                name="contact"
                                variant="outlined"
                                onChange={handleChange}
                            ></TextField>
                            <TextField
                                className="text_field"
                                label="Email"
                                placeholder="Email"
                                name="address"
                                variant="outlined"
                                onChange={handleChange}
                            ></TextField>
                            <div style={{ marginBottom: "0.5rem" }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">House Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="house_type"
                                        label="House Type"
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        <MenuItem value="Studio">Studio</MenuItem>
                                        <MenuItem value="Apartment - 2 rooms">Apartment - 2 rooms</MenuItem>
                                        <MenuItem value="Apartment - 3 rooms">Apartment - 3 rooms</MenuItem>
                                        <MenuItem value="Town house">Town house</MenuItem>
                                        <MenuItem value="Double storey terrace house">Double storey terrace house</MenuItem>
                                        <MenuItem value="Double storey house corner or end lot">Double storey house corner or end lot</MenuItem>
                                        <MenuItem value="Three storey terrace house">Three storey terrace house</MenuItem>
                                        <MenuItem value="Cluster House">Cluster House</MenuItem>
                                        <MenuItem value="Land to build">Land to build</MenuItem>
                                        <MenuItem value="Semi-Detached">Semi-Detached</MenuItem>
                                        <MenuItem value="Bungalow">Bungalow</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ marginBottom: "0.5rem" }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Design Style</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="design_style"
                                        label="Design Style"
                                        onChange={handleDesignChange}
                                        // multiple
                                        value={designStyle}
                                    >
                                        {names.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        justifyContent: "left",
                                        display: "flex"
                                    }}
                                >
                                    Budget RM {price[0]} - {price[1]}

                                </p>
                                <Slider
                                    name="budget"
                                    value={price}
                                    onChange={handleChangePrice}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"
                                    min={10000}
                                    max={1000000}
                                />
                            </div>
                            <div>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleCheckChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />}

                                    label={label}
                                />
                            </div>
                            <br />
                            <div style={{
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end"
                            }}>
                                {loadingButton ?
                                    <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
                                    :
                                    <div className="_cta" onClick={() => {
                                        handleSubmit()
                                    }}>
                                        Get Quote
                                    </div>
                                }
                            </div>
                        </div>

                    </Modal >
                </div >
                :
                (<></>)
            }
        </React.Fragment>
    )
}

export default Project;