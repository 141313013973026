import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { md } from "../../styles/responsive";

const config = {
  theBanner: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    "& img": {
      width: "100%",
    },
  },
  theOverlay: {
    position: "absolute",
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    top: "0",
    left: "0",
    height: "99.3%",
    width: "100%",
  },
  cProcedure: {
    padding: "2rem 0 4rem",
    ...md("sm", {
      padding: "2rem 0",
    }),
  },

  procedureCard: {
    // border: "1px solid #000",
    padding: "10px",
    boxShadow: "0px 3px 6px rgb(0 0 0 / 9%)",
    backgroundColor: "#fff",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "flex-start",
    ...md("sm", {
      height: "500px",
    }),
    ...md("md", {
      height: "490px",
    }),
    ...md("xmd", {
      height: "530px",
    }),
  },

  procedureIMG: {
    width: "100%",

    "& img": {
      width: "100%",
    },
  },

  cslider: {
    // "& .slick-dots": {
    // 	position: "absolute",
    // 	bottom: "8px",
    // 	...md("sm", {
    // 		bottom: "20px",
    // 	}),
    // },
  },

  procedureTOP: {
    height: "auto",
    ...md("sm", {
      height: "400px",
    }),
    ...md("md", {
      height: "345px",
    }),
    ...md("xmd", {
      height: "415px",
    }),
  },

  cTitle: {
    // margin: '0',
    fontSize: "14px",

    ...md("md", {
      fontSize: "16px",
    }),
  },

  cDesc: {
    marginTop: "0",
    fontSize: "12px",

    ...md("md", {
      fontSize: "14px",
    }),
  },

  theCurveRight: {
    width: "55.8%",
    position: "absolute",
    transform: `translate(80%, 0%)`,
    top: "0",
  },

  theBigTitle: {
    position: "absolute",
    top: "50%",
    right: "10%",
    transform: `translate(-50%,-50%)`,
    color: "#fff",
    fontSize: "1.5rem",
    fontWeight: "700",
    textShadow: "4px 7px 20px #000000",
    ...md("sm", {
      //   top: "51.5%",
      //   right: "30%",
      fontSize: "4rem",
    }),
    ...md("md", {
      //   top: "51.5%",
      //   right: "29%",
      fontSize: "5rem",
    }),
    ...md("xmd", {
      fontSize: "7.1rem",
    }),
  },

  theSection: {
    position: "relative",
    margin: "2rem 0",
  },

  theIMG: {
    position: "relative",
    width: "100%",
    margin: "0 auto",

    ...md("md", {
      width: "80%",
    }),

    "& img": {
      width: "100%",
    },
  },

  theDetail: {
    padding: "0 2rem",

    ...md("md", {
      padding: "0 4rem",
    }),
  },

  theBtn: {
    width: "60%",

    ...md("sm", {
      width: "50%",
    }),

    ...md("xmd", {
      width: "34%",
    }),
  },

  theProduct: {
    position: "relative",
    width: "100%",
    display: "flex",
    "& img": {
      width: "100%",
    },
  },

  theProd: {
    position: "relative",
    width: "100%",
    margin: "0 auto",
    display: "block",

    ...md("sm", {
      width: "100%",
    }),
    "& img": {
      width: "100%",
    },
  },

  theCount: {
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    padding: "2rem 0",
  },

  theTextBox: {
    position: "relative",
    width: "25%",
    borderRight: "1px solid #eaeaea",

    "&:last-child": {
      borderRight: "unset",
    },
  },

  theIconList: {
    position: "relative",
    // alignItems: "center",
    // justifyContent: "space-between",
    // display: "flex",
    padding: "2rem",
    background: "#eaeaea",
  },

  theIconText: {
    width: "100%",
    margin: "0 auto",

    ...md("sm", {
      width: "50%",
    }),
  },

  theIcon: {
    position: "relative",
    width: "50%",
    margin: "0 auto",
    "& img": {
      width: "100%",
    },
  },

  theBackground: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theTitle: {
    position: "absolute",
    top: "50%",
    left: "45%",
    transform: `translate(-50%,-50%)`,
  },

  theTitle_: {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: `translate(-50%,-50%)`,
  },

  thePadd: {
    alignItems: "center",
    display: "flex",
  },

  theMobile: {
    display: "flex",
    flexDirection: "column-reverse",

    ...md("sm", {
      flexDirection: "row",
    }),
  },

  theTextBannerStyle: {
    bottom: "0",
    width: "102%",
  },

  theBtmImg: {
    minWidth: "100%",
    width: "unset",
    maxWidth: "100%",
    height: "200px",
    objectFit: "cover",
    objectPosition: "center",
    transition: "all 5s ease-in-out",
    ...md("sm", {
      height: "100%",
    }),
    ...md("md", {
      height: "400px",
    }),
  },

  theBtmImgSmall: {
    minWidth: "100%",
    width: "unset",
    maxWidth: "100%",
    height: "200px",
    objectFit: "cover",
    objectPosition: "center",
    transition: "all 5s ease-in-out",
    ...md("sm", {
      height: "250px",
    }),
    ...md("md", {
      height: "200px",
    }),
  },

  BigOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    transition: "all 0.3s ease-in-out",
    position: "absolute",
    width: "calc(100% - 4px)",
    zIndex: "2",
    color: "black",
    bottom: "-17.5px",
    left: "10px",
    ...md("sm", {
      bottom: "-15px",
    }),
    ...md("md", {
      bottom: "-11px",
    }),
    ...md("xmd", {
      bottom: "-11px",
      left: "20px",
    }),
  },

  theTextBanner: {
    position: "absolute",
    width: "102%",
    bottom: "0",
    display: "block",
    borderColor: "transparent",
    ...md("sm", {
      bottom: "0",
    }),
    ...md("md", {
      bottom: "45%",
      display: "none",
    }),
  },

  theTextBannerSmall: {
    position: "absolute",
    width: "102%",
    bottom: "0",
    display: "none",
    ...md("sm", {
      bottom: "0",
    }),
    ...md("md", {
      bottom: "45%",
      display: "block",
    }),
  },

  SmallOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    transition: "all 0.3s ease-in-out",
    position: "absolute",
    width: "calc(100% - 4px)",
    zIndex: "2",
    color: "black",
    bottom: "-17px",
    left: "5px",
    display: "-webkit-box",
    webkitBoxOrient: "vertical",
    webkitLineClamp: "1",
    overflow: "hidden",
    ...md("sm", {
      bottom: "-14px",
    }),
    ...md("md", {
      fontSize: "15px",
      left: "5px",
      bottom: "37%",
    }),
    ...md("xmd", {
      bottom: "55px",
      left: "20px",
    }),
  },

  theBlogBOX: {
    position: "relative",
    backgroundColor: "#fff",
    height: "100%",
    cursor: "pointer",
    ...md("md", {
      height: "400px",
    }),
  },

  theBlogBanner: {
    position: "absolute",
    bottom: "10%",
    width: "90%",
    right: "0",
  },

  theSmallBlogBanner: {
    position: "absolute",
    bottom: "10%",
    width: "90%",
    right: "0",
    ...md("md", {
      bottom: "30%",
    }),
  },
  thePadding: {
    padding: "4rem 0",

    ...md("md", {
      padding: "6rem 0",
    })
  },
};

export default makeStyles(config);
