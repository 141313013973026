import { Button, TextField } from "@material-ui/core"
import { AddCircleOutline } from "@material-ui/icons";
// import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import Project_images from "./Project_images";
import Project_info from "./Project_info";
import './styles.css';
import {
    check_plan_limit
} from '../../../api/API';
import GoogleHeader from "../../../components/GoogleHeader";

const CreateProject = (props) => {

    const [projectLimit, setProjectLimit] = React.useState(null)
    React.useEffect(() => {
        get_data();
    }, []);
    const get_data = () => {
        check_plan_limit().then(res => {
            setProjectLimit(res);
        });
    }
    const [step, setStep] = React.useState(1);
    const [projectId, setProjectId] = React.useState(null);
    const onSubmitStep1 = (projectid) => {
        console.log(projectid)
        setProjectId(projectid.project_id);
        setStep(1);
    }

    return (
        <div className="create_project_page">
            <GoogleHeader />
            {step == 1 && (
                <>
                    <Project_info projectId={projectId} {...props}></Project_info>
                </>
            )}

            {step == 0 && (
                <Project_images submit={onSubmitStep1} ></Project_images>
            )}


        </div>
    )
}

const top100Films = [
    { title: 'Minimalistic', year: 2000 },
    { title: 'Modern', year: 2000 },
    { title: 'Zen', year: 2000 },

];

export default CreateProject;