import React from 'react'
import useStyle from "./styles"
import GoogleHeader from '../../../components/GoogleHeader'
import Loader from "react-js-loader";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paragraph from '../../../components/Typography/Paragraph';
import { get_orders_by_seller, get_orders_by_user, get_user_profile } from '../../../api/API';
import Shop2Icon from '@mui/icons-material/Shop2';

export default function Purchases() {
    const classes = useStyle()

    const [loading, setLoading] = React.useState(false);
    const [token, setToken] = React.useState("");

    React.useEffect(() => {
        let token = window.localStorage.getItem("token");
        setToken(token || "");
        console.log("CURRENT TOKEN", token);
    }, []);

    const [user, setUser] = React.useState([])
    const getProfile = () => {
        get_user_profile({}).then((json) => {
            setUser(json.data)
            setLoading(false)
        })
    }

    const [sellerOrders, setSellerOrders] = React.useState([])

    const getSeller = () => {
        get_orders_by_seller({}).then((json) => {
            setSellerOrders(json.data)
        })
    }

    const [userOrders, setUserOrders] = React.useState([]);

    const getUserOrder = () => {
        get_orders_by_user({}).then((json) => {
            setUserOrders(json.data)
        })
    }

    React.useEffect(() => {
        getSeller()
        getProfile()
        getUserOrder()
    }, [])

    return (
        <div className="account_page">
            <GoogleHeader />
            {loading ? (
                <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
            ) : (
                <React.Fragment>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Paragraph
                            size="600"
                            color="#0d2964"
                            bold="600"
                            margin="0 0 1rem 0"
                        >
                            My Purchases
                        </Paragraph>

                        {/* <Button
                    style={{ margin: "0 0 1rem 0" }}
                    onClick={() => handleCreateProduct()}
                >
                    Add Product
                </Button> */}
                    </div>
                    <hr />

                    <div style={{ margin: "1rem 0 0 0" }}>
                        <TableContainer style={{ height: "500px", }}>
                            <Table>
                                <TableHead style={{ backgroundColor: "#e7e7e7" }}>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: "800" }}>Date Created</TableCell>
                                        <TableCell style={{ fontWeight: "800" }}>Order ID</TableCell>
                                        <TableCell style={{ fontWeight: "800" }}>Product</TableCell>
                                        <TableCell style={{ fontWeight: "800" }}>Quantity</TableCell>
                                        <TableCell style={{ fontWeight: "800" }}>Delivery Address</TableCell>
                                        <TableCell style={{ fontWeight: "800" }}>Total Amount</TableCell>
                                        <TableCell style={{ fontWeight: "800" }}>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        userOrders.map((item) => (
                                            <>
                                                {
                                                    item.products.map((product) => (
                                                        <a style={{ display: "contents" }}
                                                            href={`/dashboard/invoice/${item.order_id}`}
                                                        >
                                                            <TableRow>
                                                                <TableCell>{item.created_date}</TableCell>
                                                                <TableCell>{item.order_id}</TableCell>
                                                                <TableCell>{product.product_name}</TableCell>
                                                                <TableCell>{product.quantity}</TableCell>
                                                                <TableCell>{item.address}</TableCell>
                                                                <TableCell>MYR {product.total_price}</TableCell>
                                                                <TableCell>{item.status}</TableCell>
                                                            </TableRow>
                                                        </a>
                                                    ))
                                                }
                                            </>
                                        ))
                                    }
                                </TableBody >
                            </Table>
                        </TableContainer>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}
