import DesignItem from '../../../components/design_item/design_item';
import './styles.css';
import { Link } from 'react-router-dom';
import toast from "react-simple-toasts";
import Loader from "react-js-loader";
import {
    get_my_projects,
    check_plan_limit
} from '../../../api/API';
import React from 'react';
import GoogleHeader from '../../../components/GoogleHeader';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paragraph from '../../../components/Typography/Paragraph';
import useStyles from "./styles"

const DashboardProject = (props) => {
    const classes = useStyles()
    // console.log(props.location.pathname)

    const [projects, setProjects] = React.useState([]);
    const [projectLimit, setProjectLimit] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    React.useEffect(() => {
        get_data();
    }, []);
    const get_data = () => {
        setLoading(true)
        get_my_projects().then(res => {
            if (Array.isArray(res.data)) {
                setProjects([...res.data]);
            }
            setLoading(false)
        });
        check_plan_limit().then(reslimit => {
            if (Array.isArray(reslimit)) {
                setProjectLimit([...reslimit]);
            }
        });
    }

    const openDashboardProject = (project_id) => {
        props.history.push("/dashboard/projectdetail/" + project_id);
    }

    const handleCreateProject = () => {
        props.history.push("/dashboard/createproject");

        // if (projectLimit.data.name == 'Diamond Plan') {
        //     props.history.push("/dashboard/createproject");
        // } else {
        //     if (projectLimit.project_count < projectLimit.data.design_no) {
        //         props.history.push("/dashboard/createproject");
        //     } else {
        //         toast("You have reach your project limit");
        //     }
        // }
        // console.log(projectLimit.data.design_no < projectLimit.project_count, projectLimit.data.design_no, projectLimit.project_count)
    }

    return (
        <div className="dashboardproject_page">
            <GoogleHeader />
            <div className="">

                <div style={{
                    display: "flex",
                    margin: 0
                }}>
                    <div>
                        <h2>My Projects</h2>
                    </div>
                    <div
                        onClick={() => handleCreateProject()}
                        style={{
                            marginLeft: "auto"
                        }}
                        className="flex_center"
                    >
                        <div >
                            Create Project
                        </div>
                    </div>
                    {/* <Link
                    to="/dashboard/createproject"
                    style={{
                        marginLeft: "auto"
                    }} className="flex_center">
                    <div >
                        Create Project
                    </div>
                </Link> */}
                </div>
                <hr />
                <div className="design_container">
                    {loading ?
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
                        </div>
                        :

                        // <React.Fragment>


                        //     <div style={{ margin: "2rem 0 0 0" }}>
                        //         <TableContainer>
                        //             <Table>
                        //                 <TableHead style={{ backgroundColor: "#e7e7e7" }}>
                        //                     <TableRow>
                        //                         <TableCell style={{ fontWeight: "600", fontSize: "18px" }}>Products</TableCell>
                        //                         <TableCell style={{ fontWeight: "600", fontSize: "18px", display: "flex", justifyContent: "center", alignItems: "center" }}>Product Name</TableCell>
                        //                         <TableCell style={{ fontWeight: "600", fontSize: "18px" }}>Company</TableCell>
                        //                         {/* <TableCell>Edit</TableCell> */}

                        //                     </TableRow>
                        //                 </TableHead>
                        //             </Table>
                        //         </TableContainer>
                        //         <TableContainer style={{ maxHeight: "600px", overflowY: "scroll" }}>
                        //             <Table>
                        //                 <TableBody>
                        //                     {projects.map((item) => (
                        //                         <TableRow>
                        //                             <Link to={`/dashboard/projectdetail/${item.project_id}`} style={{ display: "contents" }}>
                        //                                 {item.images.map((image) => (
                        //                                     <TableCell style={{ width: "32.5%" }}>
                        //                                         <div className={classes.imageCenter}>
                        //                                             <img src={image.image}
                        //                                                 style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        //                                             />
                        //                                         </div>

                        //                                     </TableCell>
                        //                                 ))}

                        //                                 <TableCell style={{ width: "30.5%" }}>
                        //                                     <Paragraph
                        //                                         color="#444"
                        //                                         center
                        //                                         style={{ fontSize: "16px" }}
                        //                                     >
                        //                                         {item.title}
                        //                                     </Paragraph>
                        //                                 </TableCell>
                        //                                 <TableCell>
                        //                                     {item.company_name}
                        //                                 </TableCell>
                        //                             </Link>

                        //                             {/* <Link style={{ textDecoration: "none" }} to={`/dashboard/editproduct/${item.product_id}`}>
                        //                         <TableCell>
                        //                             <Paragraph center="true">
                        //                                 Edit Product
                        //                             </Paragraph>
                        //                         </TableCell>
                        //                     </Link> */}

                        //                         </TableRow>
                        //                     ))}
                        //                 </TableBody>
                        //             </Table>
                        //         </TableContainer>
                        //     </div>
                        // </React.Fragment>


                        <React.Fragment>


                            {projects.map(proj => (
                                <DesignItem
                                    onClick={() => { openDashboardProject(proj.project_id) }}
                                    images={proj.images}
                                    name={proj.title}
                                    remarks={proj.remarks}
                                    designer={proj.company_name}
                                    pathName={props.location.pathname}
                                    designerarray={proj.company_logo}
                                ></DesignItem>
                            ))}
                        </React.Fragment>
                    }
                </div>
            </div>

        </div >)
};

export default DashboardProject;