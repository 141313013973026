import ContractorLayout from "../ContractorLayout";
import { Card, TextField, Grid, IconButton, MenuItem, Button, CircularProgress, Chip } from "@material-ui/core";
import { Pin } from "@mui/icons-material";
import { CalendarTodayOutlined, PinDrop, Search, Star } from "@material-ui/icons";
import './styles.scss';
import { useHistory } from "react-router-dom";

const ContractorList = () => {

    return (
        <>
            <ContractorLayout>
                <Grid container style={{
                    width: "100%"
                }} spacing={1}>
                    <Grid item xs={2}>
                        <TextField
                            style={{
                                width: "100%",
                                background: "white",
                            }}
                            label={"Category"}
                            variant="outlined"
                            select
                            defaultValue={0}
                        >
                            <MenuItem value={0}>All</MenuItem>
                            <MenuItem value={1}>Plumbing</MenuItem>
                            <MenuItem value={2}>Electrical</MenuItem>
                            <MenuItem value={3}>Flooring</MenuItem>
                            <MenuItem value={4}>Painting</MenuItem>
                            <MenuItem value={5}>Misc</MenuItem>

                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            style={{
                                width: "100%",
                                background: "white",
                            }}
                            label={"Area"}
                            variant="outlined"
                            select
                            defaultValue={0}
                        >
                            <MenuItem value={0}>All</MenuItem>
                            <MenuItem value={1}>Johor</MenuItem>
                            <MenuItem value={2}>Malacca</MenuItem>
                            <MenuItem value={3}>Selangor</MenuItem>
                            <MenuItem value={4}>Negeri Sembilan</MenuItem>
                            <MenuItem value={5}>Pahang</MenuItem>

                        </TextField>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            style={{
                                width: "100%",
                                background: "white",
                            }}
                            InputProps={{
                                endAdornment: <IconButton><Search /></IconButton>
                            }}
                            variant="outlined"
                            placeholder={"Search"}
                        />
                    </Grid>
                </Grid>
                <br />
                <Card className="contractor_list_profile">
                    <Grid container>
                        <Grid item xs={2}>
                            <div className="_profile">
                                <img src="https://cdn1.npcdn.net/images/np_23950_1661319124.png" />
                            </div>
                        </Grid>
                        <Grid item xs={8} className="_detail">
                            <h2>John Electrical Works</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                            </p>
                        </Grid>
                        <Grid item xs={2} className="rating_container">
                            <div className="rating">
                                <CircularProgress
                                    size={80}
                                    className="circular_progress"
                                    variant="determinate" value="90"></CircularProgress>
                                <div className="_value">4.9</div>
                                
                            
                            </div>
                        </Grid>
                    </Grid>
                    <div className="card_footer chip_container">
                        <Chip
                            style={{
                                color: "white",
                                paddingLeft: 8,
                                paddingRight: 8,
                                background: "#6291ed"
                            }}
                            label={"Electrical"}></Chip>
                        <Chip
                            style={{
                                color: "white",
                                paddingLeft: 8,
                                paddingRight: 8,
                                background: "#6291ed"
                            }}
                            label={"Plumbing"}></Chip>
                        <Chip
                            style={{
                                color: "white",
                                paddingLeft: 8,
                                paddingRight: 8,
                                background: "#6291ed"
                            }}
                            label={"Flooring"}></Chip>
                    </div>
                </Card>
            </ContractorLayout>
        </>
    )
};

export default ContractorList;