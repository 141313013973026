import React from "react";
import {
    Container,
    Grid,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import { Button } from "@mui/material";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";

import Section from "../../../components/Section/Section";
import FormInput from "../../../components/FormInput/FormInput";
import Paragraph from "../../../components/Typography/Paragraph";
import prod001 from "../../../assets/images/prod001.webp";
import Card from "../../../components/Card/Card";
// import { Button } from "../../components/Button/Button";
import {
    SideBarContainer,
    SideBarBox,
    Icon,
    Circle,
    SiderBarList,
    NavFlexLink,
    SmallIcon,
    SmallIconIMG,
    ActiveBorder,
} from "../styles";
import CopyIcon from "../../../assets/images/copy.svg";
import ShareIcon from "../../../assets/images/ShareIcon.svg";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
// import "./Account.css";
import useStyles from "./styles";
import "./styles.css";
import useUser from "../../../hooks/useUser";
import {
    get_user_profile,
    upload_logo,
    change_password,
    check_plan_limit,
    edit_profile,
    get_user_products,
} from "../../../api/API";
import GoogleHeader from "../../../components/GoogleHeader";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const DashboardProducts = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [logo, setLogo] = React.useState(null);
    const [token, setToken] = React.useState("");
    const [projectLimit, setProjectLimit] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const { user, set_user } = useUser();
    console.log(user);
    const [passwordData, setPasswordData] = React.useState({
        old_password: "",
        password: "",
        password2: "",
    });
    const handleChangePassword = (e) => {
        setPasswordData({
            ...passwordData,
            [e.target.name]: e.target.value,
        });
    };
    const handleSaveChangePassword = () => {
        if (passwordData.old_password.length == 0) {
            toast("Old Password is required");
            return;
        }
        if (passwordData.password.length == 0) {
            toast("New Password is required");
            return;
        }
        if (passwordData.password2.length == 0) {
            toast("Confirm New Password is required");
            return;
        }
        change_password({
            ...passwordData,
            user_id: user.user_id,
        }).then((res) => {
            // console.log(res);
            if (res.status) {
                toast("Password change successfully");
                setPasswordData({
                    old_password: "",
                    password: "",
                    password2: "",
                });
            } else {
                toast(res.message);
            }
        });
    };
    const handleEditProfile = () => {
        if (user.bank_name.length == 0) {
            toast("Bank name is required");
            return;
        }
        if (user.bank_acc.length == 0) {
            toast("Bank account is required");
            return;
        }
        if (user.acc_name.length == 0) {
            toast("Bank account name is required");
            return;
        }
        edit_profile({
            ...user,
            // user_id: user.user_id
        }).then((res) => {
            // console.log(res);
            if (res.status) {
                toast("Profile Save successfully");
                //     setPasswordData({
                //         old_password: "",
                //         password: "",
                //         password2: ""
                //     });
            } else {
                toast(res.message);
            }
        });
    };

    React.useEffect(() => {
        let postparam = {
            token: token,
        };
        // get_user_profile(postparam).then((json) => {
        //   setUser(json.data);
        // });
        getplan();
    }, []);

    // UPDATE PROFILE
    const handleLogoChange = (e) => {
        setLogo(e.target.files[0]);
        var data = new FormData();
        data.append("token", user.token);
        data.append("logo", e.target.files[0]);

        upload_logo(data).then((res) => {
            console.log(res);
            alert("success");
            get_data();
        });
    };
    const get_data = () => {
        setLoading(true);
        get_user_profile({}).then((res) => {
            console.log(res);
            set_user(res.data);
            console.log(user);
            setLoading(false);
        });
    };
    const getplan = () => {
        setLoading(true);
        check_plan_limit().then((resLimit) => {
            setProjectLimit(resLimit);
            setLoading(false);
        });
    };

    const [edituser, setEdituser] = React.useState({});
    const handleChange = (e) => {
        console.log(e);
        set_user({
            ...user,
            [e.target.name]: e.target.value,
        });
    };

    const handleCreateProduct = () => {
        props.history.push("/dashboard/createProduct")
    }

    const [userProducts, setUserProducts] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    const productsPerPage = 10;

    const getUserProduct = (page) => {
        get_user_products({ page: page, per_page: productsPerPage }).then((json) => {
            console.log("Received data:", json.data);
            if (json.status) {
                setUserProducts(json.data.data);

                const totalCount = json.data.total_records;
                const pages = Math.ceil(totalCount / productsPerPage);
                setTotalPages(pages);

            } else {
                console.error("Data is not an array:", json.data);
            }
        })
    }

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            // Increment the current page and fetch data for the next page
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            getUserProduct(nextPage);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            // Decrement the current page (if not on the first page) and fetch data for the previous page
            const previousPage = currentPage - 1;
            setCurrentPage(previousPage);
            getUserProduct(previousPage);
        }
    };

    React.useEffect(() => {
        getUserProduct(currentPage)
    }, [currentPage])


    return (
        <div className="account_page">
            <GoogleHeader />
            {loading ? (
                <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
            ) : (
                <React.Fragment>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Paragraph
                            size="600"
                            color="#0d2964"
                            bold="600"
                            margin="0 0 1rem 0"
                        >
                            My Products
                        </Paragraph>

                        <Button
                            style={{ margin: "0 0 1rem 0" }}
                            onClick={() => handleCreateProduct()}
                        >
                            Add Product
                        </Button>

                    </div>
                    <hr />
                    {/* <div className={classes.theHeaderProfile}>
                        <Paragraph
                            size="600"
                            color="#0d2964"
                            bold="600"
                            margin="0 0 1rem 0"
                        >
                            My Products
                        </Paragraph>

                    </div>
                    <div style={{
                        display: "flex",

                    }}>
                        <Button>Add Product</Button>
                    </div> */}
                    <div style={{ margin: "2rem 0 0 0" }}>
                        <TableContainer>
                            <Table>
                                <TableHead style={{ backgroundColor: "#e7e7e7" }}>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: "600", fontSize: "18px" }}>Products</TableCell>
                                        <TableCell style={{ fontWeight: "600", fontSize: "18px", display: "flex", justifyContent: "center", alignItems: "center" }}>Product Name</TableCell>
                                        <TableCell style={{ fontWeight: "600", fontSize: "18px" }}>Price</TableCell>
                                        {/* <TableCell>Edit</TableCell> */}

                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        <TableContainer style={{ maxHeight: "600px", overflowY: "scroll" }}>
                            <Table>
                                <TableBody>
                                    {userProducts && userProducts.length > 0 ? (
                                        userProducts.map((item) => (
                                            <TableRow>
                                                <Link to={`/dashboard/editproduct/${item.product_id}`} style={{ display: "contents" }}>
                                                    <TableCell style={{ width: "32.5%" }}>
                                                        <div className={classes.imageCenter}>
                                                            <img src={item.image}
                                                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                                            />
                                                        </div>
                                                    </TableCell>

                                                    <TableCell style={{ width: "45.5%" }}>
                                                        <Paragraph
                                                            color="#444"
                                                            center
                                                            style={{ fontSize: "16px" }}
                                                        >
                                                            {item.product_name}
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        MYR {item.price}
                                                    </TableCell>
                                                </Link>

                                                {/* <Link style={{ textDecoration: "none" }} to={`/dashboard/editproduct/${item.product_id}`}>
                                                <TableCell>
                                                    <Paragraph center="true">
                                                        Edit Product
                                                    </Paragraph>
                                                </TableCell>
                                            </Link> */}

                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={3}>
                                                <Paragraph color="#444" center style={{ fontSize: "16px" }}>
                                                    No products available
                                                </Paragraph>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', padding: "2rem 0 0 0" }}>
                            <div style={{
                                backgroundColor: currentPage === 1 ? "#CCCCCC" : "#2f8dcd",
                                borderRadius: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: ".3rem",
                                cursor: "pointer",
                            }}
                                onClick={handlePreviousPage}
                            >
                                <KeyboardArrowLeftIcon sx={{
                                    fill: "#fff",
                                }} />
                            </div>

                            <Paragraph
                                margin="0 1rem"
                                size="300"
                            >
                                {currentPage} of {totalPages}
                            </Paragraph>

                            <div style={{
                                backgroundColor: currentPage === totalPages ? "#CCCCCC" : "#2f8dcd",
                                borderRadius: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: ".3rem",
                                cursor: "pointer",
                            }}
                                onClick={handleNextPage}
                            >
                                <KeyboardArrowRightIcon sx={{
                                    fill: "#fff",
                                }} />
                            </div>
                        </div>
                        {/* <Grid container>
                            {userProducts.map((item) => (
                                <Grid item xs={12} sm={12} md={4}>
                                    <a href={`../productdetail/${item.product_id}`}>
                                        <div className={classes.theProdBox}>
                                            <img src={item.image} />
                                            <Paragraph
                                                color="#444"
                                                style={{ fontSize: "16px" }}
                                                center
                                            >
                                                {item.product_name}
                                            </Paragraph>
                                            <ul className={classes.product__price}>
                                                <li>
                                                    <span class="money" data-currency-usd="$150.00">
                                                        MYR {item.price}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </Grid>
                            ))}
                        </Grid> */}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default DashboardProducts;
