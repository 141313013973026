import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { md } from "../../../styles/responsive";

export const IncrementAction = styled.div`
  background: ${(props) => props.bg};
  border: ${(props) => props.bd};
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

const config = {
  theProdIMG: {
    position: "relative",
    height: "5rem",
    width: "20%",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },

    ...md("sm", {
      width: "20%",
    }),

    ...md("md", {
      width: "20%",
    }),
  },
  theFlex: {
    display: "flex",
    justifyContent: "space-bewtween",
    alignItems: "center",
    padding: "1rem 0",
    borderBottom: "1px solid #d7d7d7",
  },
  theBtnSizing: {
    position: "relative",
    width: "100%",
    margin: "0 auto",

    ...md("sm", {
      width: "30%",
    }),

    ...md("md", {
      width: "40%",
    }),
  },

  theIncrement: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "100%",
  },

  theBox: {
    position: "relative",
    width: "50%",
    borderRadius: "6px",
    padding: " 0.65rem 0",
  },

  theCount: {
    color: "#264653",
    fontWeight: "600",
    textAlign: "center",
    margin: 0,
  },

  theFlexx: {
    display: "block",

    ...md("sm", {
      display: "flex",
      justifyContent: "flex-end",
    }),
  },

  theRight: {
    marginBottom: "1rem",
    ...md("sm", {
      marginRight: "1rem",
      marginBottom: 0,
    }),
  },
  thePadding: {
    padding: "4rem 0",
    height: "100vh",
    justifyContent: "space-between",
    flexFlow: "column",
    display: "flex",
    background: "#fff",

    ...md("md", {
      padding: "6rem 0",
    })
  },
};

export default makeStyles(config);
