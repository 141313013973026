import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import FormInput from "../../components/FormInput/FormInput";
import default002 from "../../assets/images/floor.jpg";
import banner002 from "../../assets/images/banner_2.jpg";
import banner003 from "../../assets/images/banner_3.jpg";
import banner004 from "../../assets/images/banner_4.png";
import number001 from "../../assets/images/number_1.png";
import number002 from "../../assets/images/number_2.png";
import number003 from "../../assets/images/number_3.png";
import curve006 from "../../assets/images/curve_006.png";
import curve002 from "../../assets/images/curve_002.png";
import parallelogram from "../../assets/images/parallelogram.png";
import logo_long from "../../assets/images/logo_long.png";
import useUser from "../../hooks/useUser";
import { ReactComponent as Parallelogram } from "../../assets/images/parallelogram.svg";
import iso from "../../assets/images/home_banner.jpg";
import rectangle001 from "../../assets/images/rectangle001.png";
import rectangle002 from "../../assets/images/rectangle002.png";
import banner_ from "../../assets/images/home_banner.jpg";
import procedure1 from "../../assets/images/form_stock.jpg";
import useStyles from "./styles";
import { } from "../../api/API";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@mui/material/Button";
// import { ClassNames } from "@emotion/react";
// import { CallMissedSharp } from "@material-ui/icons";
import "./styles.css";
import GoogleHeader from "../../components/GoogleHeader";

export default function About() {
  const classes = useStyles();


  const [on, setOn] = React.useState(false);

  const handleClickOn = () => {
    setOn(true);
  };

  const handleOff = () => {
    setOn(false);
  };


  let { About } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [About]);

  const { user, get_user } = useUser();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let { Product } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Product]);

  // API

  const [isBusy, setIsBusy] = React.useState(true);

  React.useEffect(() => {
    setIsBusy(true);
  }, []);

  return (
    <React.Fragment>
      <div className={classes.thePadding}>
      <GoogleHeader />
      <>
        {/* <div className={classes.theBanner}>
          <img src={banner_} />
          <div className={classes.theOverlay}></div>
          <div className={classes.theCurveLeft}>
          <img src={curve006} />
          </div>
          <div className={classes.theBigTitle}>ABOUT</div>
        </div> */}

        <div className="aboutBanner">
          <img src={banner_} />
          <div className="aboutPosition">
            <div className="aboutCurve">
              <img src={curve006} />
              <div className="aboutWord">
                <p>ABOUT</p>
              </div>
            </div>
          </div>
        </div>

        <Container>
          <Section pd="0 1rem">
            <div className={classes.theSection}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={6}>
                  <div>
                    <div className={classes.theProdTitle}>
                      <div className={classes.theTitleAboutUS}>About Us</div>
                    </div>
                    <div className={classes.theProdDetail}>
                      <div className={classes.theDetail}>
                        Indor.my is Malaysia’s number one e-platform for interior designers (IDs), contractors and suppliers. We empower our customers in achieving their dream in building their own homes by providing efficient interior designers, capable contractors and supply of materials to you at all stages of your project.
                      </div>
                      <div className={classes.theDetail}>
                        Whether you're a contractor looking for a supplier or a homeowner who wants a new look, Indor is your one-stop shop for all things related to renovation and interior design.
                      </div>
                      <div className={classes.theDetail}>
                        The concept of Indor is based on the understanding that the demand for expertise in interior design is large, and this demand can be better satisfied by sharing expertise within a network.
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <div className={classes.theIMG}>
                    <img src={default002} />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Section>
        </Container>

        <div className={classes.theHidden}>
          <Section pd="0">
            <div className={classes.theSecondBanner}>
              <img src={banner002} />
              <div className={classes.theParallelogram}>
                <img src={parallelogram} />
              </div>
              <div className={classes.theTitleValue}>Value</div>
              <div className={classes.theNumberOne}>
                <img src={number001} />
              </div>
              <div className={classes.theFirstContentValue}>
                <p>Building an ecosystem together by</p>
                <p>sharing professional inputs and</p>
                <p>expertise.</p>
              </div>
              <div className={classes.theNumberTwo}>
                <img src={number002} />
              </div>
              <div className={classes.theSecondContentValue}>
                <p>Providing and increasing the number of </p>
                <p>business opportunities for our existing </p>
                <p>and new partners.</p>
              </div>
              <div className={classes.theNumberThree}>
                <img src={number003} />
              </div>
              <div className={classes.theThirdContentValue}>
                <p>Raising awareness of the risks associated with </p>
                <p>home renovation and provide information to </p>
                <p>ensure that renovation projects are budgeted </p>
                <p>effectively.</p>
              </div>
            </div>
          </Section>
        </div>

        <Section pd="0">
          <div className={classes.theSection}>
            <div className={classes.theCurveRight}>
              <img src={curve002} />
            </div>
            <div className={classes.theMissionVision}>
              <div>
                <div className={classes.theMissionTitle}>Our Mission</div>
                <div className={classes.theMissionContent}>
                  <p>Our mission is to provide our clients </p>
                  <p>with the most cost-effective, efficient </p>
                  <p>and complete packaging solutions.</p>
                </div>
              </div>
              <div>
                <div className={classes.theVisionTitle}>Our Vision</div>
                <div className={classes.theVisionContent}>
                  <p>Our vision is to be one of the best flexible </p>
                  <p>packaging companies in the industry – by </p>
                  <p>delivering packaging excellence defined by </p>
                  <p>topnotch quality, unmatched customer </p>
                  <p>service, constant improvement, and </p>
                  <p>constant innovation.</p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section pd="0">
          <div className={classes.theBanner}>
            <img src={banner004} />
            <div className={classes.theOverlay}></div>
            <div className={classes.theLasttitle}>
              Standard Operating Procedure
            </div>
          </div>
        </Section>

        <Section pd="0">
          <div className={classes.footer_content}>
            <Container>
              <div className={classes.footerFlex}>
                <div className={classes.footerTitle}>© 2022. INDOR.MY</div>
                <nav className={classes.footer_nav}>
                  <Link className={classes.flex_center_footer} to="/about">
                    <div className={classes.cFLink}>ABOUT US</div>
                    <div class={classes.dot}></div>
                  </Link>
                  <Link className={classes.flex_center_footer}>
                    <div className={classes.cFLink}>FAQ</div>
                    <div class={classes.dot}></div>
                  </Link>
                  <Link
                    className={classes.flex_center_footer}
                  // to="/location/mountaustin"
                  >
                    <div className={classes.cFLink}>CONTACT & ADVRTISE</div>
                    <div class={classes.dot}></div>
                  </Link>
                  <Link className={classes.flex_center_footer} to="/termandcondition">
                    <div className={classes.cFLink}>TERM & CONDITIONS</div>
                    <div class={classes.dot}></div>
                  </Link>
                  <div className={classes.flex_center_footer}>
                    <div className={classes.cFLink} onClick={handleClickOn}>
                      PRIVACY POLICY
                    </div>
                    <Dialog
                      open={on}
                      onClose={handleOff}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Privacy Policy"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">

                          <h5 className="h_font_1">
                            1. Introduction
                          </h5>
                          <p className="p_font_New2">
                            This Privacy Policy declaration informs you about how Indor.my (hereinafter also referred to as "we") handles your personal data (hereinafter also as "your data"). We attach great importance to protecting the personal rights of users (hereinafter "users" or "you") of our products, adhere to the applicable data protection regulations and take the necessary measures to protect your data.
                          </p>
                          <p className="p_font_New2">
                            Websites of other providers that can be accessed via our websites are not subject to the data protection regulations stated here. We assume no responsibility or liability for the observance of data protection by third party websites.
                          </p>

                          <h5 className="h_font_1">
                            2. Data Processing and Purposes of Use
                          </h5>
                          <h5 className="h_font_1">
                            2.1 General Information
                          </h5>
                          <p className="p_font_New2">
                            "Personal data" means any information relating to an identified or identifiable person. This includes, for example, contact data such as name, telephone number, address or email address; as well as other information that you provide to us for example, during registration, as part of an inquiry or when participating in competitions or surveys and the like.
                          </p>
                          <p className="p_font_New2">
                            Any handling of personal data, including in particular, the collection, storage, administration, use, transmission, disclosure or deletion of your data, is considered "processing".
                            Your data will only be used for the respective purposes which are communicated to you in this data protection Privacy Policy declaration. We take the necessary precautions to protect your personal data from loss, theft and misuse.
                          </p>
                          <p className="p_font_New2">
                            In this statement we will inform you in which cases we collect data about you, what data we process from you, for what purposes we use it and to whom it may be passed on. We will also explain to you what rights you have with regard to your data and how you can assert them.
                          </p>
                          <h5 className="h_font_1">
                            2.2 Non-anonymous users
                          </h5>
                          <p className="p_font_New2">
                            In the course of the registration process, request forms or the downloading of files, registration for a newsletter and participation in a competition or prize drawing, we collect personal data from you such as first and last name, address, email address; and other data such as telephone numbers or date of birth, as well as all data voluntarily entered by the registered user in his user profile.
                          </p>
                          <p className="p_font_New2">
                            We use this data to process the inquiries received, to enable and check your access, as well as to notify and/or publish the winners of competitions and prize drawings. As a basis for access to online products, data on individual usage behaviour is also collected and evaluated in order to improve our products. Your data will also be used for customer care, and for personalised marketing purposes (e.g. pop-ups).
                          </p>
                          <p className="p_font_New2">
                            The online transmission of personal data between the user's browser and Indor's servers is encrypted using the HTTPS protocol. In order to enable the functionality of this encryption technology, the user is obligated to use current browser versions.
                          </p>
                          <h5 className="h_font_1">
                            2.3 Anonymous users of our website
                          </h5>
                          <p className="p_font_New2">
                            When you visit our website, we and third parties collect, store and use data from registered and non-registered users by using "cookies" and similar technologies. Cookies are text files that are stored on the user's device and which allow an analysis of the use of the products.
                          </p>
                          <p className="p_font_New2">
                            This technology makes it possible to recognize individual users as (returning) visitors and to offer them individualized services and products. In general, the use of cookies serves to improve our services and make them more effective and secure.
                          </p>
                          <p className="p_font_New2">
                            We adopt this into the functionality of the website to optimize the user’s online services.Furthermore, the use of our digital services is measured and evaluated by means of various technological systems, mainly from third-party providers. The aforementioned technologies are used in particular to analyse the surfing behaviour of users and to measure reach. The reach measurement can be used by advertisers to determine how many targeted individuals were reached by their efforts. The information collected with such technologies can also be used for marketing purposes, to improve products and websites, to evaluate usage behaviour, and to deliver and design personalised advertising formats (e.g. by means of pop-ups).
                          </p>

                          <h5 className="h_font_1">
                            3. Disclosure of personal data to third parties
                          </h5>
                          <p className="p_font_New2">
                            We do not disclose personal data to third parties unless required to do so by law or ordered to do so by court order.
                          </p>
                          <p className="p_font_New2">
                            This does not include the disclosure of personal data to third parties:
                          </p>
                          <p className="p_font_New2">&nbsp;&nbsp; a) for the legal protection of users;</p>
                          <p className="p_font_New2">&nbsp;&nbsp; b) to meet legal requirements;</p>
                          <p className="p_font_New2">&nbsp;&nbsp; c) to defend and protect the rights of the provider;</p>
                          <p className="p_font_New2">&nbsp;&nbsp; d) to comply with the terms of use or the data protection regulations;</p>
                          <p className="p_font_New2">&nbsp;&nbsp; e) to solve technical problems of the products;</p>
                          <p className="p_font_New2">&nbsp;&nbsp; f) to process your enquiries via a form on our website, which requires the transmission of data to process your enquiry or order. In these cases, the transmission of the data is limited to the necessary minimum for their order fulfillment.
                            In connection with the "Request a quote" service for projects, we also work together with service providers to whom we forward your enquiry and the relevant personal data for processing and responding to your enquiry. The transmission of data is limited to the minimum necessary for the fulfillment of the order.
                          </p>
                          <p className="p_font_New2">&nbsp;&nbsp; g) to cooperate with service partners of the provider, who support us especially in the marketing area, for the analysis of certain technical data and for functions of the processing and/or storage of data. We work with a limited number of trusted external service partners who have been carefully selected by us and meet high data protection and security standards. Data is only disclosed to service partners to the extent that it is necessary for the provision of the services offered.
                          </p>

                          <h5 className="h_font_1">
                            4. Plug-ins and other integration of third party services
                          </h5>
                          <p className="p_font_New2">
                            Our digital services are networked in many ways with third-party functions and systems, for example by integrating plug-ins into third party social networks like Facebook, Twitter, or LinkedIn. If you have a user account with these third parties, it may also be possible for these third parties to measure and evaluate your use of our digital services. Other personal data, such as IP address, personal browser settings and other parameters, may be transmitted to and stored by these third parties. We have no control over how your personal information is being used by third parties and assume no responsibility and liability for it.
                          </p>

                          <h5 className="h_font_1">
                            5. Legal basis for data processing
                          </h5>
                          <p className="p_font_New2">
                            We always process your personal data in accordance with the applicable data protection regulations.
                          </p>
                          <p className="p_font_New2">
                            The contractual relationship serves as the legal basis for the processing of personal data for the purpose of processing the contractual relationship. This also applies to processing operations that are necessary to carry out pre-contractual measures.
                          </p>
                          <p className="p_font_New2">
                            In the case of data processing required to fulfil a legal obligation, the legal obligation in question serves as the legal basis.
                          </p>
                          <p className="p_font_New2">
                            For further processing, we rely on our primary interest. For example, to adapt and continuously improve our services to the needs of our customers; and to carry out marketing activities in order to offer you suitable products or services and display advertisements that are relevant to you. This is an important basis for the financing and further development of our services. In order to achieve these interests, the processing of personal data as described by us is necessary. However, we have taken measures to reduce the impact of data processing on our users. We have compared our interest with the interest of our users and have concluded our data processing does not unduly affect the interests or basic rights of our users and that our interest in this data processing outweigh the others accordingly. Should you disagree with this, you have the right to object to such data processing at any time within the legally permissible framework.
                          </p>
                          <p className="p_font_New2">
                            In cases where we obtain consent for the processing of data, this is regarded as the legal basis.
                          </p>

                          <h5 className="h_font_1">
                            6. Data retention
                          </h5>
                          <p className="p_font_New2">
                            We will only use and keep your data for as long as necessary for the purpose for which it was processed or for as long as there is another legal basis for doing so. We retain data which we hold on the basis of a contractual relationship with you, for at least as long as the contractual relationship exists and limitation periods for possible claims by us are running, or statutory or contractual retention obligations exist.
                          </p>

                          <h5 className="h_font_1">
                            7. Your rights
                          </h5>
                          <h5 className="h_font_1">
                            Right to information and correction
                          </h5>
                          <p className="p_font_New2">
                            You have the right to receive information from us, at any time and free of charge, as to whether and which personal data we process from you. You may also ask us to correct or complete inaccurate information about you in our systems.
                          </p>
                          <h5 className="h_font_1">
                            Right to erasure and restriction
                          </h5>
                          <p className="p_font_New2">
                            You have the right to ask us to delete or restrict the processing of your personal data.
                          </p>
                          <p className="p_font_New2">
                            Please note that even after you have requested to delete your personal data, we may have to retain it due to legal or contractual storage obligations (e.g. for billing purposes) and in this case will only restrict or block your data as necessary. Furthermore, deletion of your data may mean that you can no longer obtain or use the services you have registered for.
                          </p>
                          <h5 className="h_font_1">
                            Right to object
                          </h5>
                          <p className="p_font_New2">
                            You have the right to object to the processing of your data, which you can assert with us
                          </p>
                          <h5 className="h_font_1">
                            Right to data portability
                          </h5>
                          <p className="p_font_New2">
                            If applicable, you may also assert your right to data portability.
                          </p>
                          <h5 className="h_font_1">
                            Withdrawal of consent
                          </h5>
                          <p className="p_font_New2">
                            You can revoke your consent to data processing at any time, with effect to the future. In the event of revocation, we may no longer be able to provide you with personalised use of free and/or paid products.
                          </p>
                          <h5 className="h_font_1">
                            Right of complaint
                          </h5>
                          <p className="p_font_New2">
                            If applicable, you have the right to file a complaint with the competent supervisory authority regarding data processing. You can do this at the supervisory authority in your place of residence, your workplace or the place of the suspected data protection violation.
                          </p>

                          <h5 className="h_font_1">
                            8. Objection
                          </h5>
                          <p className="p_font_New2">
                            You have the right to object to the processing of your personal data in accordance with legal requirements at any time.
                          </p>

                          <h5 className="h_font_1">
                            9. Changes to this Privacy Policy
                          </h5>
                          <p className="p_font_New2">
                            We expressly reserve the right to change this Privacy Policy declaration at any time. If adjustments are made, we will publish them immediately on our website. It is your responsibility to check the current version of the Privacy Policy on our website, therefore we recommend that you consult this declaration regularly.
                          </p>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleOff}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </nav>
              </div>
            </Container>
          </div>
        </Section>
      </>
      </div>
    </React.Fragment>
  );
}
