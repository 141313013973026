import React from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ChevronRight, ExitToApp } from "@material-ui/icons";

import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
// import { Button } from "../../components/Button/Button";
import {
  SideBarContainer,
  SideBarBox,
  Icon,
  Circle,
  SiderBarList,
  NavFlexLink,
  SmallIcon,
  SmallIconIMG,
  ActiveBorder,
} from "./styles";
import account from "../../assets/images/account.svg";
import pin from "../../assets/images/pin.svg";
import usericon from "../../assets/images/users.svg";
import enquiryicon from "../../assets/images/enquiry.svg";
import bag from "../../assets/images/bag.svg";
import gift from "../../assets/images/gift.svg";
// import "./Account.css";
import useStyles from "./styles";
import Account from "./Account/Account";
import "./styles.css";
import { get_user_profile } from "../../api/API";
import useUser from "../../hooks/useUser";
import GoogleHeader from "../../components/GoogleHeader";
import InboxIcon from '@mui/icons-material/Inbox';
import InventoryIcon from '@mui/icons-material/Inventory';

export default function Dashboard(props) {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { set_user, user } = useUser();

  // GET PROFILE

  const [token, setToken] = React.useState("");

  React.useEffect(() => {
    let postparam = {
      token: token,
    };
    // get_user_profile(postparam).then((json) => {
    //   setUser(json.data);
    // });
    get_data();
  }, []);

  const get_data = () => {
    get_user_profile({}).then((res) => {
      if (res.status) {
        set_user(res.data);
      } else {
        if (res.message == "Please login again") {
          set_user(null);
          window.localStorage.clear();
          history.push("/");
        }
      }
    });
  };
  // UPDATE PROFILE

  const handleChange = (event) => { };

  const handleEditProfile = () => { };

  // CHNAGE PASSWORD

  const [changepass, setChangePass] = React.useState({
    old_password: "",
  });

  const handleChangePassword = () => {
    // if (changepass.password !== changepass.password2) {
    //   alert("Password Not Same");
    // } else {
    //   profile(changepass).then((json) => {
    //     if (json.status) {
    //       alert("Your password has been changed!");
    //     } else {
    //       alert(json.message);
    //     }
    //   });
    // }
  };

  const handleLogout = () => {
    set_user(null);
    window.localStorage.clear()
  }

  console.log(location.pathname.replace(/[^A-Za-z]+/g, ""));

  return (
    <React.Fragment>
      <GoogleHeader />
      {user != null && (
        <Container>
          <div className="dashboard_page">
            <div className="dashboard_padding">
              <Section>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={2} >
                    <SideBarContainer>
                      <SiderBarList>
                        <NavFlexLink to="/dashboard">
                          {location.pathname.replace(/[^A-Za-z]+/g, "") ==
                            "dashboard" && <ActiveBorder />}
                          <SmallIcon>
                            <SmallIconIMG style={{
                              maxWidth: 18
                            }} src={account} />
                          </SmallIcon>
                          <Paragraph color="#1e1e1e" bold="600" size="300">
                            My Account
                          </Paragraph>
                        </NavFlexLink>
                        {/* {user.is_expired == 0 && ( */}
                        <React.Fragment>
                          {user.user_type == "1" ? (
                            <NavFlexLink to="/dashboard/projects">
                              {location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardprojects" ||
                                location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardcreateProject" ||
                                location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardprojectdetail" ? (
                                <ActiveBorder />
                              ) : null}
                              <SmallIcon>
                                <SmallIconIMG style={{
                                  maxWidth: 18
                                }} src={pin} />
                              </SmallIcon>
                              <Paragraph color="#1e1e1e" bold="600" size="300">
                                Projects
                              </Paragraph>
                            </NavFlexLink>
                          ) : null}
                          {user.user_type == "2" ? (
                            <NavFlexLink to="/dashboard/profile">
                              {location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardprofile" ||
                                location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardprofile" ||
                                location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardprofile" ? (
                                <ActiveBorder />
                              ) : null}
                              <SmallIcon>
                                <SmallIconIMG style={{
                                  maxWidth: 18
                                }} src={pin} />
                              </SmallIcon>
                              <Paragraph color="#1e1e1e" bold="600" size="300">
                                Profile
                              </Paragraph>
                            </NavFlexLink>
                          ) : null}
                          {user.user_type == "3" ? (
                            <NavFlexLink to="/dashboard/products">
                              {location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardproducts" ||
                                location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardcreateProduct" ||
                                location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardproducts" ? (
                                <ActiveBorder />
                              ) : null}
                              <SmallIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7zm-4 6h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" /></svg>
                              </SmallIcon>
                              <Paragraph color="#1e1e1e" bold="600" size="300">
                                Products
                              </Paragraph>
                            </NavFlexLink>
                          ) : null}
                          {user.user_type == "1" ||
                            user.user_type == "2" ? (
                            <>
                              {user.plan_role_id != "3" && (
                                <NavFlexLink to="/dashboard/user">
                                  {location.pathname.replace(
                                    /[^A-Za-z]+/g,
                                    ""
                                  ) == "dashboarduser" ? (
                                    <ActiveBorder />
                                  ) : null}
                                  <SmallIcon>
                                    <SmallIconIMG style={{
                                      maxWidth: 18
                                    }} src={usericon} />
                                  </SmallIcon>
                                  <Paragraph
                                    color="#1e1e1e"
                                    bold="600"
                                    size="300"
                                  >
                                    Users
                                  </Paragraph>
                                </NavFlexLink>
                              )}
                            </>
                          ) : null}

                          <NavFlexLink to="/dashboard/orders">
                            {location.pathname.replace(/[^A-Za-z]+/g, "") ==
                              "dashboardorders" ||
                              location.pathname.replace(/[^A-Za-z]+/g, "") ==
                              "dashboardorders" ||
                              location.pathname.replace(/[^A-Za-z]+/g, "") ==
                              "dashboardorders" ? (
                              <ActiveBorder />
                            ) : null}
                            <SmallIcon>
                              <InboxIcon />
                              {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7zm-4 6h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" /></svg> */}
                            </SmallIcon>
                            <Paragraph color="#1e1e1e" bold="600" size="300">
                              Orders
                            </Paragraph>
                          </NavFlexLink>

                          {user.user_type == "3" ? (
                            <NavFlexLink to="/dashboard/purchases">
                              {location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardpurchases" ||
                                location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardpurchases" ||
                                location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardpurchases" ? (
                                <ActiveBorder />
                              ) : null}
                              <SmallIcon>
                                <InventoryIcon />
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7zm-4 6h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" /></svg> */}
                              </SmallIcon>
                              <Paragraph color="#1e1e1e" bold="600" size="300">
                                Purchases
                              </Paragraph>
                            </NavFlexLink>
                          ) : (
                            <></>
                          )}

                          {user.user_type == "1" ||
                            user.user_type == "2" ? (
                            <>
                              <NavFlexLink to="/dashboard/enquiry">
                                {location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                  "dashboardenquiry" ? (
                                  <ActiveBorder />
                                ) : null}
                                <SmallIcon>
                                  <SmallIconIMG style={{
                                    maxWidth: 18
                                  }} src={enquiryicon} />
                                </SmallIcon>
                                <Paragraph
                                  color="#1e1e1e"
                                  bold="600"
                                  size="300"
                                >
                                  Enquiry
                                </Paragraph>
                              </NavFlexLink>
                            </>
                          ) : null}

                          {user.user_type == "4" || user.user_type == "5" ? (
                            <NavFlexLink to="/dashboard/point">
                              {location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardpoint" ? (
                                <ActiveBorder />
                              ) : null}
                              <SmallIcon>
                                <SmallIconIMG src={gift} />
                              </SmallIcon>
                              <Paragraph color="#1e1e1e" bold="600" size="300">
                                Point
                              </Paragraph>
                            </NavFlexLink>
                          ) : null}

                          {user.user_type == "5" ? (
                            <NavFlexLink to="/dashboard/commission">
                              {location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardcommission" ? (
                                <ActiveBorder />
                              ) : null}
                              <SmallIcon>
                                <SmallIconIMG src={gift} />
                              </SmallIcon>
                              <Paragraph color="#1e1e1e" bold="600" size="300">
                                Commission
                              </Paragraph>
                            </NavFlexLink>
                          ) : null}

                          {user.user_type == "5" ? (
                            <NavFlexLink to="/dashboard/referral">
                              {location.pathname.replace(/[^A-Za-z]+/g, "") ==
                                "dashboardreferral" ? (
                                <ActiveBorder />
                              ) : null}
                              <SmallIcon>
                                <SmallIconIMG src={usericon} />
                              </SmallIcon>
                              <Paragraph color="#1e1e1e" bold="600" size="300">
                                Referral
                              </Paragraph>
                            </NavFlexLink>
                          ) : null}
                        </React.Fragment>
                        {/* )} */}
                        <NavFlexLink
                          // onClick={() => {
                          //   set_user(null);
                          //   window.localStorage.clear();
                          // }}
                          to="/"
                          onClick={() => handleLogout()}
                        >
                          <SmallIcon>
                            <ExitToApp></ExitToApp>
                          </SmallIcon>
                          <Paragraph color="#1e1e1e" bold="600" size="300">
                            Logout
                          </Paragraph>
                        </NavFlexLink>
                        {/* <NavFlexLink to="/Dashboard/Reviews">
                                            <SmallIcon>
                                                <SmallIconIMG src={pin} />
                                            </SmallIcon>
                                            <Paragraph color="#1e1e1e" bold="600" size="300">
                                                Reviews
                                            </Paragraph>
                                        </NavFlexLink>
                                        <NavFlexLink to="/orders">
                                            <SmallIcon className="theIcon">
                                                <SmallIconIMG src={bag} />
                                            </SmallIcon>
                                            <Paragraph color="#1e1e1e" bold="600" size="300">
                                                Orders
                                            </Paragraph>
                                        </NavFlexLink> */}
                      </SiderBarList>
                    </SideBarContainer>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9} style={{
                    background: "white",
                    minHeight: "60vh"
                  }}>
                    <Card style={{
                      height: "100%"
                    }}>{props.children}</Card>
                  </Grid>
                </Grid>
              </Section>
            </div>
          </div>
        </Container>
      )}
    </React.Fragment>
  );
}
