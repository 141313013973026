import { Button, TextField } from "@material-ui/core";
import "./styles.css";
import {
  register,
  join_us_form_new,
  register_new
} from "../../api/API";
import React from "react";
import useUser from "../../hooks/useUser";
import LookingFor from "../../assets/images/lookingfor.png";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import curve004 from "../../assets/images/curve_004.svg";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import toast from "react-simple-toasts";
import GoogleHeader from "../../components/GoogleHeader";


const Join = (props) => {
  let { Join } = useLocation();
  const params = useParams();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Join]);
  const [tab, setTab] = React.useState(2);
  const { set_user } = useUser();
  const [form, setForm] = React.useState({
    email: "",
    company: "",
    name: "",
    contact: "",
    password: "",
    password2: "",
    user_type: "",
    join_type: tab,
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const [formUser, setFormUser] = React.useState({
    refer_code: params.refer_code != undefined ? params.refer_code : "",
    email: "",
    name: "",
    contact: "",
    password: "",
    password2: "",
    user_type: 4,
    join_type: tab,
  });

  const handleChangeFormUser = (e) => {
    setFormUser({
      ...formUser,
      [e.target.name]: e.target.value,
    });
  };

  const [formAgent, setFormAgent] = React.useState({
    refer_code: params.refer_code != undefined ? params.refer_code : "",
    email: "",
    name: "",
    contact: "",
    password: "",
    password2: "",
    user_type: 5,
    join_type: tab,
  });

  const handleChangeFormAgent = (e) => {
    setFormAgent({
      ...formAgent,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    //   if (tab == 2) {
    //     if (form.email.length == 0) {
    //       toast('Email is required');
    //       return
    //     }
    //     if (form.company.length == 0) {
    //       toast('Company Name is required');
    //       return
    //     }
    //     if (form.name.length == 0) {
    //       toast('Name is required');
    //       return
    //     }
    //     if (form.contact.length == 0) {
    //       toast('Contact is required');
    //       return
    //     }
    //     if (form.password.length == 0) {
    //       toast('Password is required');
    //       return
    //     }
    //     if (form.password2.length == 0) {
    //       toast('Confirm Password is required');
    //       return
    //     }
    //   } else if (tab == 3) {
    //     if (formUser.email.length == 0) {
    //       toast('Email is required');
    //       return
    //     }
    //     if (formUser.name.length == 0) {
    //       toast('Name is required');
    //       return
    //     }
    //     if (formUser.contact.length == 0) {
    //       toast('Contact is required');
    //       return
    //     }
    //     if (formUser.password.length == 0) {
    //       toast('Password is required');
    //       return
    //     }
    //     if (formUser.password2.length == 0) {
    //       toast('Confirm Password is required');
    //       return
    //     }

    var postData = null
    var data = {
      ...form,
      join_type: tab
    }
    var dataUser = {
      ...formUser,
      join_type: tab
    }

    if (tab == 2) {
      postData = data
    } else if (tab == 3) {
      postData = dataUser
    }
    console.log(postData)

    localStorage.setItem("refer_code", formUser.refer_code);
    
    register(postData).then((res) => {
      if (res.status) {
        set_user(res.data);
        // if (res.data.user_type == "1") {
        //   props.history.push("/dashboard");
        // } else {
          props.history.push("/");
        // }
      } else {
      }
    });
  };


  // const handleSubmit = () => {
  //   if (tab == 2) {
  //     if (form.email.length == 0) {
  //       toast('Email is required');
  //       return
  //     }
  //     if (form.company.length == 0) {
  //       toast('Company Name is required');
  //       return
  //     }
  //     if (form.name.length == 0) {
  //       toast('Name is required');
  //       return
  //     }
  //     if (form.contact.length == 0) {
  //       toast('Contact is required');
  //       return
  //     }
  //     if (form.password.length == 0) {
  //       toast('Password is required');
  //       return
  //     }
  //     if (form.password2.length == 0) {
  //       toast('Confirm Password is required');
  //       return
  //     }
  //   } else if (tab == 3) {
  //     if (formUser.email.length == 0) {
  //       toast('Email is required');
  //       return
  //     }
  //     if (formUser.name.length == 0) {
  //       toast('Name is required');
  //       return
  //     }
  //     if (formUser.contact.length == 0) {
  //       toast('Contact is required');
  //       return
  //     }
  //     if (formUser.password.length == 0) {
  //       toast('Password is required');
  //       return
  //     }
  //     if (formUser.password2.length == 0) {
  //       toast('Confirm Password is required');
  //       return
  //     }
  //   } else {
  //     if (formAgent.email.length == 0) {
  //       toast('Email is required');
  //       return
  //     }
  //     if (formAgent.name.length == 0) {
  //       toast('Name is required');
  //       return
  //     }
  //     if (formAgent.contact.length == 0) {
  //       toast('Contact is required');
  //       return
  //     }
  //     if (formAgent.password.length == 0) {
  //       toast('Password is required');
  //       return
  //     }
  //     if (formAgent.password2.length == 0) {
  //       toast('Confirm Password is required');
  //       return
  //     }
  //   }
  //   var postData = null
  //   var data = {
  //     ...form,
  //     join_type: tab
  //   }
  //   var dataUser = {
  //     ...formUser,
  //     join_type: tab
  //   }
  //   var dataAgent = {
  //     ...formAgent,
  //     join_type: tab
  //   }
  //   if (tab == 2) {
  //     postData = data
  //   } else if (tab == 3) {
  //     postData = dataUser
  //   } else {
  //     postData = dataAgent
  //   }
  //   console.log(postData)
  //   register_new(postData).then((res) => {
  //     if (res.status) {
  //       set_user(res.data);
  //       if (res.data.user_type == "1") {
  //         props.history.push("/dashboard");
  //       } else {
  //         props.history.push("/");
  //       }
  //     } else {
  //     }
  //   });
  // };

  const handleSubmitFormUser = () => {
    alert("Submit Successful");
  };


  const handleChangeTab = (text) => {
    setTab(text);
  };

  return (
    <div className="page_padding">
      <GoogleHeader />
      <div className="c-join">
        <div className="c-joinCurve">
          <div className="c-joinPosition">
            <img src={curve004} />
          </div>
        </div>
        <div className="c-joinBOX">
          <div className="c-joinLeft">
            <h1 className="c-jTitle">Be A Part of Indor Today</h1>
            <div className="c-lImg">
              <img src={LookingFor} />
            </div>
          </div>
          <div className="c-joinRight">
            <div className="join_form">
              <FormControl component="fieldset" style={{ width: "100%" }}>
                <FormLabel
                  component="legend"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "2rem",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  Join as
                </FormLabel>
                <div className="joinTab">
                  {/* <div
                    className={
                      tab == 1 ? "joinColorActive" : "joinColorNonActive"
                    }
                    onClick={() => setTab(1)}
                  >
                    <p>I am Looking For</p>
                  </div> */}

                  <div
                    className={
                      tab == 2 ? "joinColorActive" : "joinColorNonActive"
                    }
                    onClick={() => setTab(2)}
                  >
                    <p>I am a</p>
                  </div>

                  <div
                    className={
                      tab == 3 ? "joinColorActive" : "joinColorNonActive"
                    }
                    onClick={() => setTab(3)}
                  >
                    <p>User</p>
                  </div>

                  {/* <div
                    className={
                      tab == 4 ? "joinColorActive" : "joinColorNonActive"
                    }
                    onClick={() => setTab(4)}
                  >
                    <p>Agent</p>
                  </div> */}
                </div>
                <div className="joinTabRadio">
                  {tab == 2 && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <RadioGroup
                        aria-label="gender"
                        value={form.user_type}
                        name="user_type"
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Interior Designers"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Sub Contractors"
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="Direct Suppliers"
                        />
                        {/* <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="User"
                  /> */}
                      </RadioGroup>
                    </div>
                  )}

                </div>
              </FormControl>
              {tab == 2 &&
                <>
                  <TextField
                    label="Work Email"
                    variant="outlined"
                    className="text_input"
                    margin="dense"
                    placeholder="John@example.com"
                    name="email"
                    onChange={handleChange}
                    value={form.email}
                  ></TextField>

                  <TextField
                    label="Company Name"
                    variant="outlined"
                    onChange={handleChange}
                    className="text_input"
                    name="company"
                    margin="dense"
                    value={form.company}
                    placeholder="ABC Design Sdn Bhd"
                  ></TextField>
                  <TextField
                    label="Name"
                    variant="outlined"
                    className="text_input"
                    name="name"
                    margin="dense"
                    onChange={handleChange}
                    placeholder="Full Name"
                    value={form.name}
                  ></TextField>
                  <TextField
                    label="Contact"
                    variant="outlined"
                    className="text_input"
                    margin="dense"
                    onChange={handleChange}
                    name="contact"
                    placeholder="+60xxxxxxxxxx"
                    value={form.contact}
                  ></TextField>

                  <TextField
                    label="Password"
                    variant="outlined"
                    className="text_input"
                    onChange={handleChange}
                    margin="dense"
                    type="password"
                    name="password"
                    value={form.password}
                    placeholder="password"
                  ></TextField>
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    onChange={handleChange}
                    className="text_input"
                    margin="dense"
                    type="password"
                    name="password2"
                    value={form.password2}
                    placeholder="Confirm your password"
                  ></TextField>
                </>
              }
              {tab == 3 &&

                <>
                  <TextField
                  style={{ display: "none" }}
                  disabled
                    label="Referral"
                    variant="outlined"
                    className="text_input"
                    margin="dense"
                    name="refer_code"
                    onChange={handleChangeFormUser}
                    value={formUser.refer_code}
                  ></TextField>
                  <TextField
                    label="Email"
                    variant="outlined"
                    className="text_input"
                    margin="dense"
                    placeholder="John@example.com"
                    name="email"
                    onChange={handleChangeFormUser}
                    value={formUser.email}
                  ></TextField>
                  <TextField
                    label="Name"
                    variant="outlined"
                    className="text_input"
                    name="name"
                    margin="dense"
                    onChange={handleChangeFormUser}
                    placeholder="Full Name"
                    value={formUser.name}
                  ></TextField>
                  <TextField
                    label="Contact"
                    variant="outlined"
                    className="text_input"
                    margin="dense"
                    onChange={handleChangeFormUser}
                    name="contact"
                    placeholder="+60xxxxxxxxxx"
                    value={formUser.contact}
                  ></TextField>

                  <TextField
                    label="Password"
                    variant="outlined"
                    className="text_input"
                    onChange={handleChangeFormUser}
                    margin="dense"
                    type="password"
                    name="password"
                    value={formUser.password}
                    placeholder="password"
                  ></TextField>
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    onChange={handleChangeFormUser}
                    className="text_input"
                    margin="dense"
                    type="password"
                    name="password2"
                    value={formUser.password2}
                    placeholder="Confirm your password"
                  ></TextField>
                </>
              }

              {tab == 4 &&

                <>
                  <TextField
                  disabled
                    label="Referral"
                    variant="outlined"
                    className="text_input"
                    margin="dense"
                    name="refer_code"
                    onChange={handleChangeFormAgent}
                    value={formAgent.refer_code}
                  ></TextField>
                  <TextField
                    label="Email"
                    variant="outlined"
                    className="text_input"
                    margin="dense"
                    placeholder="John@example.com"
                    name="email"
                    onChange={handleChangeFormAgent}
                    value={formAgent.email}
                  ></TextField>
                  <TextField
                    label="Name"
                    variant="outlined"
                    className="text_input"
                    name="name"
                    margin="dense"
                    onChange={handleChangeFormAgent}
                    placeholder="Full Name"
                    value={formAgent.name}
                  ></TextField>
                  <TextField
                    label="Contact"
                    variant="outlined"
                    className="text_input"
                    margin="dense"
                    onChange={handleChangeFormAgent}
                    name="contact"
                    placeholder="+60xxxxxxxxxx"
                    value={formAgent.contact}
                  ></TextField>

                  <TextField
                    label="Password"
                    variant="outlined"
                    className="text_input"
                    onChange={handleChangeFormAgent}
                    margin="dense"
                    type="password"
                    name="password"
                    value={formAgent.password}
                    placeholder="password"
                  ></TextField>
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    onChange={handleChangeFormAgent}
                    className="text_input"
                    margin="dense"
                    type="password"
                    name="password2"
                    value={formAgent.password2}
                    placeholder="Confirm your password"
                  ></TextField>
                </>
              }

              <Button onClick={handleSubmit} className="btn">
                Submit
              </Button>
              <p className="c-label">
                Already have an account?
                <Link to="/login">&nbsp;Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="join_us_page page_padding">

    //     <div className="join_container hide_mobile">

    //         <div className="benefits">
    //             <h3>Why Join Indor</h3>
    //         </div>
    //         <div className="join_form">

    //             <h3>Be A Part Of Indor Today</h3>
    //             <TextField
    //                 label="Work Email"
    //                 variant="outlined"
    //                 className="text_input"
    //                 margin="dense"
    //                 placeholder="John@example.com"
    //                 name="username"
    //                 onChange={handleChange}
    //                 value={form.username}
    //             ></TextField>
    //             <TextField
    //                 label="Name"
    //                 variant="outlined"
    //                 className="text_input"
    //                 name="name"
    //                 margin="dense"
    //                 onChange={handleChange}
    //                 placeholder="Full Name"
    //                 value={form.name}
    //             ></TextField>
    //             <TextField
    //                 label="Contact"
    //                 variant="outlined"
    //                 className="text_input"
    //                 margin="dense"
    //                 onChange={handleChange}
    //                 name="contact"
    //                 placeholder="+60xxxxxxxxxx"
    //                 value={form.contact}
    //             ></TextField>

    //             <TextField
    //                 label="Company Name"
    //                 variant="outlined"
    //                 onChange={handleChange}
    //                 className="text_input"
    //                 name="company"
    //                 margin="dense"
    //                 value={form.company}
    //                 placeholder="ABC Design Sdn Bhd"
    //             ></TextField>
    //             <TextField
    //                 label="Password"
    //                 variant="outlined"
    //                 className="text_input"
    //                 onChange={handleChange}
    //                 margin="dense"
    //                 type="password"
    //                 name="password"
    //                 value={form.password}
    //                 placeholder="password"
    //             ></TextField>
    //             <TextField
    //                 label="Confirm Password"
    //                 variant="outlined"
    //                 onChange={handleChange}
    //                 className="text_input"
    //                 margin="dense"
    //                 type="password"
    //                 name="password2"
    //                 value={form.password2}
    //                 placeholder="Confirm your password"
    //             ></TextField>
    //             <Button
    //                 onClick={handleSubmit}
    //                 className="btn">Submit</Button>
    //         </div>
    //     </div>
    //     <div className="join_container hide_desktop">

    //         <div className="join_form">

    //             <h3>Be A Part Of Indor Today</h3>
    //             <TextField
    //                 label="Work Email"
    //                 variant="outlined"
    //                 className="text_input"
    //                 margin="dense"
    //                 placeholder="John@example.com"
    //                 name="username"
    //                 onChange={handleChange}
    //                 value={form.username}
    //             ></TextField>
    //             <TextField
    //                 label="Name"
    //                 variant="outlined"
    //                 className="text_input"
    //                 name="name"
    //                 margin="dense"
    //                 onChange={handleChange}
    //                 placeholder="Full Name"
    //                 value={form.name}
    //             ></TextField>
    //             <TextField
    //                 label="Contact"
    //                 variant="outlined"
    //                 className="text_input"
    //                 margin="dense"
    //                 onChange={handleChange}
    //                 name="contact"
    //                 placeholder="+60xxxxxxxxxx"
    //                 value={form.contact}
    //             ></TextField>

    //             <TextField
    //                 label="Company Name"
    //                 variant="outlined"
    //                 onChange={handleChange}
    //                 className="text_input"
    //                 name="company"
    //                 margin="dense"
    //                 value={form.company}
    //                 placeholder="ABC Design Sdn Bhd"
    //             ></TextField>
    //             <TextField
    //                 label="Password"
    //                 variant="outlined"
    //                 className="text_input"
    //                 onChange={handleChange}
    //                 margin="dense"
    //                 type="password"
    //                 name="password"
    //                 value={form.password}
    //                 placeholder="password"
    //             ></TextField>
    //             <TextField
    //                 label="Confirm Password"
    //                 variant="outlined"
    //                 onChange={handleChange}
    //                 className="text_input"
    //                 margin="dense"
    //                 type="password"
    //                 name="password2"
    //                 value={form.password2}
    //                 placeholder="Confirm your password"
    //             ></TextField>
    //             <Button
    //                 onClick={handleSubmit}
    //                 className="btn">Submit</Button>
    //         </div>
    //     </div>
    // </div>
  );
};

export default Join;
