import { Card, TextField, Grid, IconButton, MenuItem, Button } from "@material-ui/core";
import ContractorLayout from "../ContractorLayout";
import { Pin } from "@mui/icons-material";
import { CalendarTodayOutlined, PinDrop, Search } from "@material-ui/icons";
import './styles.scss';
import { useHistory } from "react-router-dom";

const ContractorJobs = () => {
    const navigate = useHistory();

    const handleNavigate = (id) => {
        console.log(id);
        navigate.push("/contractors/jobs/" + id);
    }
    return (
        <>
            <ContractorLayout>
                <div className="contractor_dashboard">
                    <div style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        marginBottom: 20
                    }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                navigate.push("/contractors/post");
                            }}
                        >
                            Post A Job
                        </Button>
                    </div>
                    <Grid container style={{
                        width: "100%"
                    }} spacing={1}>
                        <Grid item xs={2}>
                            <TextField
                                style={{
                                    width: "100%",
                                    background: "white",
                                }}
                                label={"Category"}
                                variant="outlined"
                                select
                                defaultValue={0}
                            >
                                <MenuItem value={0}>All</MenuItem>
                                <MenuItem value={1}>Plumbing</MenuItem>
                                <MenuItem value={2}>Electrical</MenuItem>
                                <MenuItem value={3}>Flooring</MenuItem>
                                <MenuItem value={4}>Painting</MenuItem>
                                <MenuItem value={5}>Misc</MenuItem>

                            </TextField>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                style={{
                                    width: "100%",
                                    background: "white",
                                }}
                                label={"Area"}
                                variant="outlined"
                                select
                                defaultValue={0}
                            >
                                <MenuItem value={0}>All</MenuItem>
                                <MenuItem value={1}>Johor</MenuItem>
                                <MenuItem value={2}>Malacca</MenuItem>
                                <MenuItem value={3}>Selangor</MenuItem>
                                <MenuItem value={4}>Negeri Sembilan</MenuItem>
                                <MenuItem value={5}>Pahang</MenuItem>

                            </TextField>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                style={{
                                    width: "100%",
                                    background: "white",
                                }}
                                InputProps={{
                                    endAdornment: <IconButton><Search /></IconButton>
                                }}
                                variant="outlined"
                                placeholder={"Search"}
                            />
                        </Grid>
                    </Grid>
                    <br />

                    {TEST.map(r => (
                        <Card className="_project_card">
                            <Grid container>
                                <Grid item xs={10} onClick={() => {
                                    handleNavigate(r);
                                }}>
                                    <div className="_card_content">
                                        <h3>Water Plumbing required</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim </p>
                                        <div style={{
                                            display: "flex",
                                            marginBottom: 4
                                        }}>
                                            <PinDrop />

                                            <div style={{
                                                marginLeft: 10,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                Taman Megah
                                            </div>
                                        </div>

                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <CalendarTodayOutlined />

                                            <div style={{
                                                marginLeft: 10,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                22 Oct 2023 - 26 Oct 2023
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    <div className="_profile">
                                        <div className="thumbnail">
                                            <img src="https://cdn1.npcdn.net/images/np_23950_1661319124.png" />
                                        </div>
                                        <p>Indor Malaysia</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    ))}
                </div>
            </ContractorLayout>
        </>
    )
};

export default ContractorJobs;

const TEST = [1, 2, 3];