import { Button } from "@material-ui/core";
import {
    Dialog,
    useMediaQuery,
    DialogContent,
    DialogTitle,
    DialogContentText,
} from "@mui/material";
import { AddCircleOutline, DeleteOutlineRounded } from "@material-ui/icons";
// import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import {
    delete_image,
    upload_project_images,
    create_project,
    check_plan_limit
} from "../../../api/API";
import useStyles from "./styles"
import Paragraph from "../../../components/Typography/Paragraph";
// import Button from "../../../components/Button/Button";

const Project_images = (props) => {
    const classes = useStyles()

    const [isloadingModal, setisLoadingModal] = React.useState(false);
    const [images, setImages] = React.useState([]);
    const [projectId, setProjectId] = React.useState(null);
    // console.log(projectId)

    const handleImageChange = (e) => {
        const selectedImages = Array.from(e.target.files);
        setImages((prevImages) => [...prevImages, ...selectedImages]);
    };

    const handleDeleteImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };


    const handleFileUpload = (e) => {
        setisLoadingModal(true)
        if (projectId == null) {
            console.log('create project when first upload')
            create_project({
                ...form,
                area: '',
                area_id: '',
                property: '',
                styles: '',
                work: '',
                project_status: 1
            }).then(res => {
                setProjectId(res.data)
                check_plan_limit().then(reslimit => {
                    // setProjectLimit(res);
                    console.log(res)
                    if (images.length < reslimit.data.photo_no) {
                        var data = new FormData();

                        for (var i = 0; i < e.target.files.length; i++) {
                            data.append("images_" + i, e.target.files[i]);
                        }
                        data.append("project_id", res.data);
                        data.append("image_length", e.target.files.length);
                        upload_project_images(data).then((res) => {
                            setisLoadingModal(false)
                            console.log('res', res);
                            if (res.status) {
                                var img = [...images];
                                for (var i = 0; i < res.data.length; i++) {
                                    console.log("OK " + i);
                                    img.push(res.data[i]);
                                }
                                setImages(img);
                            }
                        });
                    } else {
                        toast("You have reach your project photo limit");
                        setisLoadingModal(false)
                    }
                });
            });
        } else {
            check_plan_limit().then(resLimit => {
                // setProjectLimit(res);
                // console.log(res)
                if (images.length < resLimit.data.photo_no) {
                    var data = new FormData();
                    for (var i = 0; i < e.target.files.length; i++) {
                        data.append("images_" + i, e.target.files[i]);
                    }
                    data.append("project_id", projectId);
                    data.append("image_length", e.target.files.length);
                    upload_project_images(data).then((res) => {
                        setisLoadingModal(false)
                        console.log('res', res);
                        if (res.status) {
                            var img = [...images];
                            for (var i = 0; i < res.data.length; i++) {
                                img.push(res.data[i]);
                            }
                            setImages(img);
                        }
                    });
                } else {
                    toast("You have reach your project photo limit");
                    setisLoadingModal(false)
                }
            });
        }

    }

    const delete_img = (index, id) => {
        delete_image(id).then((res) => {

            var new_images = [...images];
            new_images.splice(index, 1);
            setImages(new_images);
        });
    }



    const [form, setForm] = React.useState({
        title: "",
        year: 2021,
        description: "",
        styles: [],
        area: [],
        property: [],
        budget: 10000,
        duration: 6,
        unit: "",
        street: "",
        taman: "",
        postcode: "",
        city: "",
        state: "",
        address: '',
    });

    const handleSubmit = () => {

        props.submit({ project_id: projectId });
    }


    const handleSave = () => {
        create_project({
            ...form,
            area: '',
            area_id: '',
            property: '',
            styles: '',
            work: '',
            project_status: 1
        }).then(res => {
            // props.submit(res.data);
            let returndata = res.data
            props.submit({ returndata, project_status: 1 });
        });
    }

    return (
        <>
            <div>
                <Paragraph margin="1rem 0">
                    Add images to this Project
                </Paragraph>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {
                        images.map((image, index) => (
                            <div className={classes.imageCenter}>
                                <img
                                    key={index}
                                    src={URL.createObjectURL(image)} // Use createObjectURL to display the selected image
                                    alt={`Selected ${index + 1}`}
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                                <Button
                                    label="REMOVE"
                                    color="#c70000"
                                    br="5px"
                                    bg="#fff"
                                    margin=".4rem 0"
                                    pd=".7rem 1rem .6rem"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDeleteImage(index)}
                                />
                            </div>
                        ))
                    }
                    <label for="upload" className={classes.badgeCenter}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        >
                            <AddCircleOutline />
                        </div>
                    </label>
                </div>
                <input type="file"
                    id="upload" name="upload" multiple
                    style={{
                        opacity: 0
                    }}
                    onChange={handleImageChange}
                />
            </div>

            <p>Add Images to your project</p>
            <div className="image_container">
                {
                    images.map((img, index) => (
                        <div className="images flex_center">
                            <div className="delete_btn" onClick={() => delete_img(index, img.id)}>
                                <DeleteOutlineRounded></DeleteOutlineRounded>
                            </div>
                            <img src={img.image}></img>
                        </div>
                    ))
                }
                <label for="upload" className="image_placeholder images flex_center" style={{
                    textAlign: "center",
                    backgroundColor: "#ecebeb",
                }}>
                    <div className="image_placeholder flex_center"

                    >
                        <AddCircleOutline></AddCircleOutline>
                    </div>
                </label>
            </div>
            <input type="file"
                onChange={handleFileUpload}
                id="upload" name="upload" multiple style={{
                    opacity: 0
                }} />

            {/* <div style={{
                display: "flex",
                justifyContent: "flex-end"
            }}>
                <Button onClick={() => { props.history.push("/dashboard/projects") }}>Complete</Button>
            </div> */}

            <div style={{
                display: "flex",
                justifyContent: "flex-end"
            }}>
                <Button
                    onClick={handleSubmit}
                >Next</Button>
            </div>


            <Dialog
                maxWidth="sm"
                fullWidth="true"
                open={isloadingModal}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <p center color="#2D3CA5" bold="600" margin="0.5rem 0">
                        Uploading
                    </p>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
                        </div>
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            Disagree
                        </Button>
                        <Button onClick={handleClose} autoFocus>
                            Agree
                        </Button>
                    </DialogActions> */}
            </Dialog>
        </>

    )
}

export default Project_images;