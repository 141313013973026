import React from "react";
import {
  // Button,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import {
  AddCircleOutline,
  ChevronRight,
  DeleteOutlineRounded,
} from "@material-ui/icons";
import toast from "react-simple-toasts";
import Section from "../../../components/Section/Section";
import FormInput from "../../../components/FormInput/FormInput";
import Paragraph from "../../../components/Typography/Paragraph";
import Card from "../../../components/Card/Card";
// import { Button } from "../../components/Button/Button";
import {
  SideBarContainer,
  SideBarBox,
  Icon,
  Circle,
  SiderBarList,
  NavFlexLink,
  SmallIcon,
  SmallIconIMG,
  ActiveBorder,
} from "../styles";
import account from "../../../assets/images/account.svg";
import pin from "../../../assets/images/pin.svg";
import bag from "../../../assets/images/bag.svg";
import gift from "../../../assets/images/gift.svg";
import logo_long from "../../../assets/images/logo_long.png";
// import "./Account.css";
import useStyles from "./styles";
import "./styles.css";
import useUser from "../../../hooks/useUser";
import {
  get_product,
  edit_project,
  get_project,
  get_tag_by_type,
  get_area,
  get_user_profile,
  upload_logo,
  upload_project_images,
  delete_image,
  check_plan_limit,
} from "../../../api/API";
import { Autocomplete } from "@material-ui/lab";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./styles.css";
import GoogleHeader from "../../../components/GoogleHeader";
import Button from "../../../components/Button/Button";

const ProjectDetail = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { project_id } = useParams()

  const [project, setProject] = React.useState(null);

  const { user, set_user } = useUser();

  const [propertyTag, setPropertyTag] = React.useState([]);
  const [areaTag, setAreaTag] = React.useState([]);
  const [styleTag, setStyleTag] = React.useState([]);
  const [workTag, setWorkTag] = React.useState([]);
  const [locationTag, setLocationTag] = React.useState([]);

  const [currentPropertyTag, setCurrentPropertyTag] = React.useState([]);
  const [currentAreaTag, setCurrentAreaTag] = React.useState([]);
  const [currentStyleTag, setCurrentStyleTag] = React.useState([]);
  const [currentLocationTag, setCurrentLocationTag] = React.useState();
  const [currentWorkTag, setCurrentWorkTag] = React.useState([]);
  const [projectStatus, setProjectStatus] = React.useState();
  console.log("area_id", currentLocationTag);
  React.useEffect(() => {
    get_tags_data();
    // get_data();
  }, []);

  const get_tags_data = () => {
    get_tag_by_type(1).then((res) => {
      setPropertyTag(res.data);
    });
    get_tag_by_type(2).then((res) => {
      setAreaTag(res.data);
    });
    get_tag_by_type(3).then((res) => {
      setStyleTag(res.data);
    });
    get_tag_by_type(4).then((res) => {
      setWorkTag(res.data);
    });
    get_area().then((res) => {
      setLocationTag(res.data);
    });
  };

  React.useEffect(() => {
    if (
      propertyTag.length &&
      areaTag.length &&
      styleTag.length &&
      workTag.length &&
      locationTag.length
    ) {
      get_data();
    }
  }, [propertyTag, areaTag, styleTag, workTag, locationTag]);

  // UPDATE PROFILE

  const [projectImage, setProjectImage] = React.useState({
    images: []
  })

  const get_data = () => {
    var id = props.match.params.project_id;
    get_project(id).then((res) => {
      var t_propertyTag = [];
      var t_areaTag = [];
      var t_styleTag = [];
      var t_workTag = [];

      var project_tags = res.data[0].tags;

      for (var i = 0; i < project_tags.length; i++) {
        if (project_tags[i].tag_type == "1") {
          var tag = search_tag(project_tags[i].tag_id, propertyTag);
          t_propertyTag.push(project_tags[i]);
        } else if (project_tags[i].tag_type == "2") {
          var tag = search_tag(project_tags[i].tag_id, styleTag);
          t_areaTag.push(project_tags[i]);
        } else if (project_tags[i].tag_type == "3") {
          var tag = search_tag(project_tags[i].tag_id, styleTag);
          t_styleTag.push(tag);
          console.log(tag);
        } else if (project_tags[i].tag_type == "4") {
          var tag = search_tag(project_tags[i].tag_id, styleTag);
          t_workTag.push(project_tags[i]);
        }
      }

      setProjectStatus(res.data[0].project_status);
      setCurrentAreaTag(t_areaTag);
      setCurrentPropertyTag(t_propertyTag);
      setCurrentStyleTag(t_styleTag);
      setCurrentWorkTag(t_workTag);
      setCurrentLocationTag(res.data[0].area_id);
      res.data[0]["work"] = t_workTag;
      res.data[0]["property"] = t_propertyTag;
      res.data[0]["styles"] = t_styleTag;
      res.data[0]["area"] = t_areaTag;
      setProject(res.data[0]);
      setProjectImage(res.data[0]);
    });
  };

  const search_tag = (tag_id, type) => {
    console.log("TEST STYLE TAG", type);
    console.log("TEST PROP TAG", propertyTag);
    for (var i = 0; i < type.length; i++) {
      if (tag_id == type[i].tag_id) {
        return type[i];
      }
    }
  };

  const handleDeleteImage = (index) => {
    // Create a copy of the images array
    const updatedImages = [...projectImage.images];

    // Remove the image at the specified index
    updatedImages.splice(index, 1);

    // Update the state with the new images array
    setProject({
      ...project,
      images: updatedImages,
    });

    // Ensure that projectImage.removed_images is an array (initialize if necessary)
    const updatedRemovedImages = Array.isArray(projectImage.removed_images)
      ? projectImage.removed_images
      : [];

    // Add the removed image URL to the removed_images array
    updatedRemovedImages.push(project.images[index].image);

    setProjectImage({ ...projectImage, removed_images: updatedRemovedImages });
  };

  const handleChange = (e) => {
    setProject({
      ...project,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeDescription = (e) => {
    setProject({
      ...project,
      description: e,
    });
  };

  const handleSave = () => {
    edit_project({
      project_id: project.project_id,
      title: project.title,
      year: project.year,
      budget: project.budget,
      duration: project.duration,
      address: project.address,
      description: project.description,
      styles: JSON.stringify(project.styles),
      work: JSON.stringify(project.work),
      area: JSON.stringify(project.area),
      property: JSON.stringify(project.property),
      area_id: currentLocationTag,
      project_status: 3,
    }).then((res) => {
      if (res.status) {
        alert("Updated");
        get_data();
      }
    });
  };

  const handleSaveDraft = () => {
    edit_project({
      project_id: project.project_id,
      title: project.title,
      year: project.year,
      budget: project.budget,
      duration: project.duration,
      address: project.address,
      description: project.description,
      styles: JSON.stringify(project.styles),
      work: JSON.stringify(project.work),
      area: JSON.stringify(project.area),
      property: JSON.stringify(project.property),
      area_id: currentLocationTag,
      project_status: 1,
    }).then((res) => {
      if (res.status) {
        alert("Updated");
        get_data();
      }
    });
  };

  const handleSQAve = () => {
    edit_project({
      project_id: project.project_id,
      title: project.title,
      year: project.year,
      budget: project.budget,
      duration: project.duration,
      address: project.address,
      description: project.description,
      styles: JSON.stringify(project.styles),
      work: JSON.stringify(project.work),
      area: JSON.stringify(project.area),
      property: JSON.stringify(project.property),
      area_id: currentLocationTag,
      project_status: 2,
    }).then((res) => {
      if (res.status) {
        alert("Updated");
        get_data();
      }
    });
  };

  const [isLoading, setIsLoading] = React.useState(false)

  const handleSaveSubmit = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if (
      !project.title ||
      !project.description ||
      !project.area ||
      !project.styles ||
      !project.property ||
      !project.work ||
      !images
    ) {
      alert("Fields cannot be empty");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();

    formData.append("project_id", project_id)
    formData.append("title", project.title);
    formData.append("description", project.description);
    formData.append("area", JSON.stringify(project.area));
    formData.append("area_id", currentLocationTag);
    formData.append("styles", JSON.stringify(project.styles));
    formData.append("property", JSON.stringify(project.property));
    formData.append("work", JSON.stringify(project.work));
    formData.append("budget", project.budget);
    formData.append("duration", project.duration);
    formData.append("project_status", project.project_status);

    if (projectImage.removed_images && projectImage.removed_images.length > 0) {
      formData.append("removed_images", JSON.stringify(projectImage.removed_images));
    }

    // formData.append("project_status", 2);

    images.forEach((image, index) => {
      formData.append(`image_${index}`, image);
    })
    console.log(formData)

    const headers = {
      Authorization: `Bearer ${token}`
    };


    edit_project(formData, headers).then((json) => {
      if (json.status) {
        alert("Submitted");
        setIsLoading(false);
        console.log("image url", images)
        props.history.push("/dashboard/projects");
      } else {
        alert(json.message);
        setIsLoading(false);
        window.location.reload();
      }
    });
  };


  const delete_img = (index, id) => {
    delete_image(id).then((res) => {
      get_data();
    });
  };

  const [token, setToken] = React.useState("");

  React.useEffect(() => {
    let storedToken = window.localStorage.getItem("token");
    setToken(storedToken || "");
    console.log("CURRENT TOKEN", storedToken);
  }, []);

  const [images, setImages] = React.useState([])


  const handleFileUpload = (e) => {
    check_plan_limit().then((jsonlimit) => {
      if (project.images.length < jsonlimit.data.photo_no) {
        var data = new FormData();

        for (var i = 0; i < e.target.files.length; i++) {
          data.append("images_" + i, e.target.files[i]);
        }
        data.append("project_id", project.project_id);
        data.append("image_length", e.target.files.length);

        // images.forEach((image, index) => {
        //   data.append(`image_${index}`, image);
        // })

        const headers = {
          Authorization: `Bearer ${token}`
        };

        upload_project_images(data, headers).then((json) => {

          console.log("json", json);
          if (json.status) {
            get_data();
          }
        });
      } else {
        toast("You have reach your project photo limit");
      }
    });
  };



  // const [images, setImages] = React.useState([])

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...selectedImages]);
  };

  const handleDeleteImageNew = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
    <>
      <GoogleHeader />
      {project ? (
        <div className="account_page">
          <div className={classes.theHeaderProfile}>
            <Paragraph
              size="600"
              color="#7f222c"
              bold="600"
              margin="0 0 1rem 0"
            >
              Project Info
            </Paragraph>
          </div>
          <div className={classes.theForm}>
            <div className="account_info_wrapper">
              <div className="account_info">
                <Grid container spacing={1}>
                  {project.remarks.length > 0 ? (
                    <Grid item sm={12}>
                      <div className="form_wrapper form_disabled">
                        <FormInput
                          name="remark"
                          label="Remark"
                          disabled
                          value={project.remarks}
                        />
                      </div>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item sm={6}>
                    <div className="form_wrapper">
                      <FormInput
                        placeholder="Project Title"
                        name="title"
                        label="Title"
                        value={project.title}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6}>
                    <div className="form_wrapper">
                      <FormInput
                        placeholder="Year"
                        name="year"
                        label="Year"
                        type="number"
                        value={project.year}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6}>
                    <div className="form_wrapper">
                      <FormInput
                        placeholder="Budget"
                        name="budget"
                        label="Budget"
                        type="number"
                        value={project.budget}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6}>
                    <div className="form_wrapper">
                      <FormInput
                        placeholder="duration"
                        name="duration"
                        label="Duration"
                        value={project.duration}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <div className="form_wrapper">
                      <FormInput
                        placeholder="Address"
                        name="address"
                        label="Address"
                        value={project.address}
                        onChange={handleChange}
                        multiline
                        rows="4"
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                    <div className="form_wrapper">
                      <Paragraph margin="1rem 0">
                        Describe this project
                      </Paragraph>
                      <EditorToolbar />
                      <ReactQuill
                        style={{ color: "#ffffff", height: "120px" }}
                        theme="snow"
                        name="description"
                        // value={state.value}
                        onChange={handleChangeDescription}
                        modules={modules}
                        formats={formats}
                        value={project.description}
                      />

                      {/* <FormInput
                                                        placeholder="Description"
                                                        name="description"
                                                        label="Description"
                                                        value={project.description}
                                                        onChange={handleChange}
                                                        multiline
                                                    /> */}
                    </div>
                  </Grid>

                  {/* {currentLocationTag.length ? (
                                                <Grid item sm={12}>
                                                    <Autocomplete
                                                        id="tags-standard"
                                                        options={locationTag}
                                                        getOptionLabel={(option) => option.area}
                                                        onChange={(event, newValue) => {
                                                            setProject({
                                                                ...project,
                                                                area_id: newValue.area_id
                                                            });
                                                        }}
                                                        defaultValue={currentLocationTag}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                margin="dense"
                                                                value={currentLocationTag}
                                                                label="Area/Location"
                                                                placeholder="Area/Location"
                                                            />
                                                        )}
                                                    />

                                                </Grid>
                                            ) : (
                                                (
                                                    
                                                )
                                            )} */}
                  <Grid item sm={12}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      value={currentLocationTag}
                      label="Area/Location"
                      placeholder="Area/Location"
                      onChange={(e) => setCurrentLocationTag(e.target.value)}
                    >
                      {locationTag.length > 0 &&
                        locationTag.map((res) => (
                          <MenuItem value={res.area_id}>{res.area}</MenuItem>
                        ))}
                    </TextField>
                    {/* <Autocomplete
                                                    id="tags-standard"
                                                    options={locationTag}
                                                    getOptionLabel={(option) => option.area}
                                                    onChange={(event, newValue) => {
                                                        setProject({
                                                            ...project,
                                                            area_id: newValue.area_id
                                                        });
                                                    }}
                                                    // defaultValue={currentLocationTag}
                                                    value={currentLocationTag}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            margin="dense"
                                                            label="Area/Location"
                                                            placeholder="Area/Location"
                                                        />
                                                    )}
                                                /> */}
                  </Grid>

                  {currentStyleTag.length ? (
                    <Grid item sm={12}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={styleTag}
                        getOptionLabel={(option) => option.tag}
                        onChange={(event, newValue) => {
                          setProject({
                            ...project,
                            styles: newValue,
                          });
                        }}
                        defaultValue={currentStyleTag}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            value={currentStyleTag}
                            label="Styles"
                            placeholder="Styles"
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item sm={12}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={styleTag}
                        getOptionLabel={(option) => option.tag}
                        onChange={(event, newValue) => {
                          setProject({
                            ...project,
                            styles: newValue,
                          });
                        }}
                        defaultValue={currentStyleTag}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            value={currentStyleTag}
                            label="Styles"
                            placeholder="Styles"
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {currentPropertyTag.length ? (
                    <Grid item sm={12}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={propertyTag}
                        getOptionLabel={(option) => option.tag}
                        onChange={(event, newValue) => {
                          setProject({
                            ...project,
                            property: newValue,
                          });
                        }}
                        defaultValue={currentPropertyTag}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            label="Property"
                            placeholder="Property"
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item sm={12}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={propertyTag}
                        getOptionLabel={(option) => option.tag}
                        onChange={(event, newValue) => {
                          setProject({
                            ...project,
                            property: newValue,
                          });
                        }}
                        defaultValue={currentPropertyTag}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            label="Property"
                            placeholder="Property"
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {currentAreaTag.length ? (
                    <Grid item sm={12}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={areaTag}
                        getOptionLabel={(option) => option.tag}
                        onChange={(event, newValue) => {
                          setProject({
                            ...project,
                            area: newValue,
                          });
                        }}
                        defaultValue={currentAreaTag}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            label="Area"
                            placeholder="Area"
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item sm={12}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={areaTag}
                        getOptionLabel={(option) => option.tag}
                        onChange={(event, newValue) => {
                          setProject({
                            ...project,
                            area: newValue,
                          });
                        }}
                        defaultValue={currentAreaTag}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            label="Area"
                            placeholder="Area"
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {currentWorkTag.length ? (
                    <Grid item sm={12}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={workTag}
                        getOptionLabel={(option) => option.tag}
                        onChange={(event, newValue) => {
                          setProject({
                            ...project,
                            work: newValue,
                          });
                        }}
                        defaultValue={currentWorkTag}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            label="Work"
                            placeholder="Work"
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item sm={12}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={workTag}
                        getOptionLabel={(option) => option.tag}
                        onChange={(event, newValue) => {
                          setProject({
                            ...project,
                            work: newValue,
                          });
                        }}
                        defaultValue={currentWorkTag}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            label="Work"
                            placeholder="Work"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {/* <Grid item sm={12}>
                    <div
                      style={{
                        borderBottom: "1px solid #d1d1d1",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {projectStatus == 3 ? (
                        <Button
                          style={{
                            marginBottom: 6,
                            border: "1px solid #d1d1d1",
                          }}
                          onClick={handleSave}
                        >
                          Update
                        </Button>
                      ) : (
                        <>
                         
                          <Button
                            label="UPDATE"
                            color="#FFF"
                            bg="#2f8dcd"
                            br="5px"
                            pd=".7rem 1rem .6rem"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSaveSubmit()}
                          />
                        </>
                      )}
                    </div>
                  </Grid> */}
                </Grid>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
              {project.images && project.images.map((image, index) => (
                <div key={index} style={{ width: "290px", height: "290px", display: "flex", flexDirection: "column", margin: "10px" }}>
                  <img src={image.image} alt={`Product Image ${index}`} style={{ width: "100%", height: "70%", objectFit: "cover" }} />
                  {/* <Button onClick={() => handleDeleteImage(index)} style={{ color: "red" }}>Remove</Button> */}
                  <Button
                    label="REMOVE"
                    color="#c70000"
                    br="5px"
                    bg="#fff"
                    margin=".4rem 0"
                    pd=".7rem 1rem .6rem"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDeleteImage(index)}
                  />
                </div>
              ))}
            </div>

            <div>
              <Paragraph margin="1rem 0">
                Add images to this Product
              </Paragraph>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {
                  images.map((image, index) => (
                    <div className={classes.imageCenter}>
                      {/* <div className={classes.deleteButton} onClick={() => handleDeleteImage(index)}>
                                            <DeleteOutlineRounded></DeleteOutlineRounded>
                                        </div> */}
                      <img
                        key={index}
                        src={URL.createObjectURL(image)} // Use createObjectURL to display the selected image
                        alt={`Selected ${index + 1}`}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                      />
                      {/* <Button onClick={() => handleDeleteImageNew(index)} style={{ color: "red" }}>Remove</Button> */}
                      <Button
                        label="REMOVE"
                        color="#c70000"
                        br="5px"
                        bg="#fff"
                        margin=".4rem 0"
                        pd=".7rem 1rem .6rem"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteImageNew(index)}
                      />
                    </div>
                  ))
                }
                <label for="upload" className={classes.badgeCenter}>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  >
                    <AddCircleOutline />
                  </div>
                </label>
              </div>
              <input type="file"
                id="upload" name="upload" multiple
                style={{
                  opacity: 0
                }}
                onChange={handleImageChange}
              />
            </div>

            <div className={classes.theBox}>
              {/* <Button
                      onClick={() => handleEditProfile()}
                      bg="#7f222c"
                      color="#fff"
                      label="Save"
                      flex="inline-flex"
                    /> */}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {projectStatus == 3 ? (
              <Button
                label="UPDATE"
                color="#FFF"
                bg="#2f8dcd"
                br="5px"
                pd=".7rem 1rem .6rem"
                style={{ cursor: "pointer", margin: "1rem 0" }}
                onClick={() => handleSaveSubmit()}
                />
            ) : (
              <Button
                label="UPDATE"
                color="#FFF"
                bg="#2f8dcd"
                br="5px"
                pd=".7rem 1rem .6rem"
                style={{ cursor: "pointer", margin: "1rem 0" }}
                onClick={() => handleSaveSubmit()}
                />
            )}
          </div>
        </div>

      ) : (
        <div></div>
      )}
    </>
  );
};

export default ProjectDetail;
