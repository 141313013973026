import React from "react";
import { Link } from "react-router-dom";
import prod001 from "../../assets/images/product001.jpg";
import prod002 from "../../assets/images/product002.jpg";
import prod003 from "../../assets/images/product003.jpg";
import prod004 from "../../assets/images/product004.jpg";
import "./allproject.css";
import { useLocation } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import ProjectItem from "../../components/project_item/project_item";

import { get_diffrent_type_of_project } from "../../api/API";
import GoogleHeader from "../../components/GoogleHeader";

export default function Allproject({ ...props }) {
  let { Allproject } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Allproject]);
  // 1 = is lastest
  // 2= popular
  // 3 = nearby
  // 4 = favorite
  const openProject = (project_id) => {
    props.history.push("/project/" + project_id);
  };
  const [projectType, setProjectType] = React.useState([]);
  const [projectTypeTwo, setProjectTypeTwo] = React.useState([]);
  const [projectTypeThree, setProjectTypeThree] = React.useState([]);
  const [projectTypeFour, setProjectTypeFour] = React.useState([]);

  React.useEffect(() => {
    get_diffrent_type_of_project({}).then((res) => {
      setProjectType(res.data.is_latest);
      setProjectTypeTwo(res.data.is_popular);
      setProjectTypeThree(res.data.is_nearby);
      setProjectTypeFour(res.data.is_favourite);
    });
  }, []);

  return (
    <React.Fragment>
      <GoogleHeader />
      {/* <div className="projectHome">
        <Link to="/location/mountaustin/1">
          <div className="projectImageOne">
            <img src={prod001} />
            <div className="projectTitleOne">
              <h1>IS LATEST</h1>
            </div>
          </div>
        </Link>
        <Link to="/location/mountaustin/2">
          <div className="projectImageTwo">
            <img src={prod002} />
            <div className="projectTitleTwo">
              <h1>IS POPULAR</h1>
            </div>
          </div>
        </Link>
        <Link to="/location/mountaustin/3">
          <div className="projectImageThree">
            <img src={prod003} />
            <div className="projectTitleThree">
              <h1>IS NEARBY</h1>
            </div>
          </div>
        </Link>
        <Link to="/location/mountaustin/4">
          <div className="projectImageFour">
            <img src={prod004} />
            <div className="projectTitleFour">
              <h1>IS FAVOURITE</h1>
            </div>
          </div>
        </Link>
      </div> */}
      <div className="projectHome">
        <div className="projectFirst">
          <Container>
            <h1 className="c-label">IS LATEST</h1>
          </Container>
        </div>
        <div className="projectFlex">
          <Grid container spacing={2}>
            {projectType.map((item) => (
              <Grid item xs={12} md={3}>
                <ProjectItem
                  onClick={() => {
                    openProject(item.project_id);
                  }}
                  images={item.images}
                  name={item.title}
                  tag={item.tags}
                  designer={item.user.company_name}
                  designerarray={item.user.image}
                ></ProjectItem>
              </Grid>
            ))}
            <Grid item xs={12} md={3} className="projectMore">
              <Link to="/location/mountaustin/1">
                <p>SEE MORE</p>
              </Link>
            </Grid>
          </Grid>
        </div>

        <div className="projectSecond">
          <Container>
            <h1 className="c-label">IS POPULAR</h1>
          </Container>
        </div>
        <div className="projectFlex">
          <Grid container spacing={2}>
            {projectTypeTwo.map((item) => (
              <Grid item xs={12} md={3}>
                <ProjectItem
                  onClick={() => {
                    openProject(item.project_id);
                  }}
                  images={item.images}
                  name={item.title}
                  tag={item.tags}
                  designer={item.user.company_name}
                  designerarray={item.user.image}
                ></ProjectItem>
              </Grid>
            ))}
            <Grid item xs={12} md={3} className="projectMore">
              <Link to="/location/mountaustin/2">
                <p>SEE MORE</p>
              </Link>
            </Grid>
          </Grid>
        </div>

        <div className="projectThree">
          <Container>
            <h1 className="c-label">IS NEARBY</h1>
          </Container>
        </div>
        <div className="projectFlex">
          <Grid container spacing={2}>
            {projectTypeThree.map((item) => (
              <Grid item xs={12} md={3}>
                <ProjectItem
                  onClick={() => {
                    openProject(item.project_id);
                  }}
                  images={item.images}
                  name={item.title}
                  tag={item.tags}
                  designer={item.user.company_name}
                  designerarray={item.user.image}
                ></ProjectItem>
              </Grid>
            ))}
            <Grid item xs={12} md={3} className="projectMore">
              <Link to="/location/mountaustin/3">
                <p>SEE MORE</p>
              </Link>
            </Grid>
          </Grid>
        </div>

        <div className="projectFour">
          <Container>
            <h1 className="c-label">IS FAVOURITE</h1>
          </Container>
        </div>
        <div className="projectFlex">
          <Grid container spacing={2}>
            {projectTypeFour.map((item) => (
              <Grid item xs={12} md={3}>
                <ProjectItem
                  onClick={() => {
                    openProject(item.project_id);
                  }}
                  images={item.images}
                  name={item.title}
                  tag={item.tags}
                  designer={item.user.company_name}
                  designerarray={item.user.image}
                ></ProjectItem>
              </Grid>
            ))}
            <Grid item xs={12} md={3} className="projectMore">
              <Link to="/location/mountaustin/4">
                <p>SEE MORE</p>
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
}
