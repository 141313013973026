import React from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";

import Section from "../../../components/Section/Section";
import FormInput from "../../../components/FormInput/FormInput";
import Paragraph from "../../../components/Typography/Paragraph";
import prod001 from "../../../assets/images/prod001.webp";
import Card from "../../../components/Card/Card";
import {
    SideBarContainer,
    SideBarBox,
    Icon,
    Circle,
    SiderBarList,
    NavFlexLink,
    SmallIcon,
    SmallIconIMG,
    ActiveBorder,
} from "../styles";
import CopyIcon from "../../../assets/images/copy.svg";
import ShareIcon from "../../../assets/images/ShareIcon.svg";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
// import "./Account.css";
import useStyles from "./styles";
import "./styles.css";
import useUser from "../../../hooks/useUser";
import {
    get_user_profile,
    upload_logo,
    change_password,
    check_plan_limit,
    edit_profile,
    get_contractor_service,
    get_state,
    get_service,
    add_service,
    edit_service,
    get_service_details,
} from "../../../api/API";
import GoogleHeader from "../../../components/GoogleHeader";
import EditorToolbar, { modules, formats } from "../CreateProject/EditorToolbar"
import ReactQuill from 'react-quill'
import Button from "../../../components/Button/Button";

export default function EditProfile(props) {
    const history = useHistory();
    const { service_id } = useParams()
    const classes = useStyles();
    const [logo, setLogo] = React.useState(null);
    const [token, setToken] = React.useState("");
    const [projectLimit, setProjectLimit] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const { user, set_user } = useUser();
    console.log(user);

    const [contractorService, setContractorService] = React.useState([])

    const getService = () => {
        get_contractor_service({}).then((json) => {
            setContractorService(json.data)
        })
    }

    const [contractorState, setContractorState] = React.useState([])

    const getState = () => {
        get_state({}).then((json) => {
            setContractorState(json.data)
        })
    }


    const [selectedServices, setSelectedServices] = React.useState([]);
    const [selectedStates, setSelectedStates] = React.useState([]);
    const [description, setDescription] = React.useState('');

    const handleServiceCheckboxChange = (event, service) => {
        if (event.target.checked) {
            setSelectedServices([...selectedServices, service]);
        } else {
            setSelectedServices(selectedServices.filter(item => item !== service));
        }
    };

    const handleStateCheckboxChange = (event, state) => {
        if (event.target.checked) {
            setSelectedStates([...selectedStates, state]);
        } else {
            setSelectedStates(selectedStates.filter(item => item !== state));
        }
    };

    React.useEffect(() => {
        getService()
        getState()
    }, [])

    const [serviceForm, setServiceForm] = React.useState({
        description: "",
        service: [],
        state: [],
    })

    const handleChangeDescription = (e) => {
        setServiceData({
            ...serviceData,
            description: e
        });
        console.log(e)
    };

    // const getServiceDetail = () => {
    //     get_service().then((json) => {
    //         if (json.status) {
    //             setServiceForm(json.data)
    //         } else {
    //             console.error(json.message)
    //         }
    //     })
    // }

    // React.useEffect(() => {
    //     getServiceDetail()
    // }, [])


    const handleSubmit = () => {

        const dataToSend = {
            service_id: serviceData.service_id,
            contractor_service: selectedServices,
            state: selectedStates,
            description: serviceData.description,
        };

        edit_service(dataToSend).then((json) => {
            if (json.status) {
                alert("Submitted");
                window.location = "/dashboard/profile"
            } else {
                alert(json.message)
            }
        })
    }

    const [serviceData, setServiceData] = React.useState(null)

    const getServiceDetail = () => {
        get_service_details(service_id).then((json) => {
            if (json.status) {
                console.log('source', json.data)

                setServiceData(json.data)

                setSelectedServices(json.data.services.map((service) => service.contractor_service_id));

                setSelectedStates(json.data.states.map((state) => state.state_id))
            }
        })
    }

    console.log("sss", serviceData)

    React.useEffect(() => {
        getServiceDetail()
    }, [])


    return (
        <div className="account_page">
            <GoogleHeader />
            {loading || serviceData == null ? (
                <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
            ) : (
                <React.Fragment>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Paragraph
                            size="600"
                            color="#0d2964"
                            bold="600"
                            margin="0 0 1rem 0"
                        >
                            Edit Services
                        </Paragraph>

                    </div>
                    <hr />


                    <Paragraph margin="2rem 0 1rem 0">
                        Describe your Services
                    </Paragraph>
                    <div>
                        <EditorToolbar />

                        <ReactQuill
                            style={{ color: "#ffffff", height: "120px" }}
                            theme="snow"
                            name="description"
                            value={serviceData.description}
                            onChange={handleChangeDescription}
                            modules={modules}
                            formats={formats}
                        />
                    </div>

                    <Paragraph margin="2rem 0 .5rem 0">
                        Services
                    </Paragraph>
                    <Grid container spacing={1}>
                        {contractorService.map((item) => (
                            <Grid item xs={12} sm={6} md={4}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => handleServiceCheckboxChange(event, item.contractor_service_id)}
                                                checked={selectedServices.includes(item.contractor_service_id)}
                                            />
                                        }
                                        label={item.service_name}
                                    />
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                    <br />
                    <hr />

                    <Paragraph margin="2rem 0 .5rem 0">
                        Coverage Area
                    </Paragraph>
                    <Grid container spacing={1}>
                        {contractorState.map((item) => (
                            <Grid item xs={12} sm={6} md={4}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => handleStateCheckboxChange(event, item.state_id)}
                                                checked={selectedStates.includes(item.state_id)}
                                            />
                                        }
                                        label={item.state_name}
                                    />
                                </div>
                            </Grid>
                        ))}
                    </Grid>

                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "2rem"
                    }}>
                        <Button
                            label="SUBMIT"
                            color="#FFF"
                            br="5px"
                            bg="#2f8dcd"
                            pd=".7rem 1rem .6rem"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSubmit()}
                        />
                    </div>

                </React.Fragment>
            )}
        </div>
    )
}
