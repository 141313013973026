import React, { useState } from "react";

const GoogleHeader = (props) => {

    // $(".resizeIMG").height(unit_witdh);
    return (
        <React.Fragment>

            <script async src={`https://www.googletagmanager.com/gtag/js?id=G-****`} />
            <script
                dangerouslySetInnerHTML={{
                    __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-KP5G47JPW8 {
              page_path: window.location.pathname,
            });
          `,
                }}
            />
        </React.Fragment>
    );
};

export default GoogleHeader;