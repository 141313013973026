import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

export const config = {
  root: {},
  thePlan: {
    // width: '20%'
  },
  theSilverdPlanLabel: {
    width: 'fit-content',
    backgroundColor: '#72BB85',
    borderRadius: '5px',
    padding: '10px 16px',
    margin: '0 0 1rem'
  },
  theGoldPlanLabel: {
    width: 'fit-content',
    backgroundColor: '#3EA2DD',
    borderRadius: '5px',
    padding: '10px 16px',
    margin: '0 0 1rem'
  },
  thePlatinumPlanLabel: {
    width: 'fit-content',
    backgroundColor: '#636AB0',
    borderRadius: '5px',
    padding: '10px 16px',
    margin: '0 0 1rem'
  },
  theDiamondPlanLabel: {
    width: 'fit-content',
    backgroundColor: '#9574AD',
    borderRadius: '5px',
    padding: '10px 16px',
    margin: '0 0 1rem'
  },
  theSCLFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  theReferal: {
    border: "1px solid #707070",
    padding: "12px 70px",
    borderRadius: "9px",
  },
  theReferalExpired: {
    border: "1px solid #ff0000",
    padding: "12px 70px",
    borderRadius: "4px",
  },
  theCopy: {
    width: "40px",
    height: "40px",
    borderRadius: "9px",
    backgroundColor: "#1976d2",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    margin: "0 0 0 8px",

    "& img": {
      width: "50%",
    },
  },
  badgeCenter: {
    textAlign: "center",
    backgroundColor: "#ecebeb",
    minWidth: "35%",
    maxWidth: "35%",
    height: "200px",
    // margin: "1%",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  deleteButton: {
    position: "absolute",
    top: "10px",
    right: "10px"
  },
  imageCenter: {
    textAlign: "center",
    backgroundColor: "#ecebeb",
    minWidth: "23%",
    maxWidth: "23%",
    height: "200px",
    margin: "1%",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
};
export default makeStyles(config);
