import styled from "styled-components";
import { Menu } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";

export const NavbarIconLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
`;

export const BadgeCount = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: ${({ color }) => color};
  background: ${({ bg }) => bg};
  font-size: 12px;
`;

const config = {
    badgeCenter: {
        textAlign: "center",
        backgroundColor: "#ecebeb",
        minWidth: "23%",
        maxWidth: "23%",
        height: "200px",
        // margin: "1%",
        cursor: "pointer",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    deleteButton: {
        position: "absolute",
        top: "10px",
        right: "10px"
    },
    imageCenter: {
        textAlign: "center",
        // backgroundColor: "#ecebeb",
        minWidth: "23%",
        maxWidth: "23%",
        height: "200px",
        margin: "0 12px 0 0",
        cursor: "pointer",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    }
}

export default makeStyles(config);