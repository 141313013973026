import './ProjectList.css';
import React from 'react';
import {
    Breadcrumbs,
    TextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import StarIcon from '@material-ui/icons/Star';
import House1 from '../../assets/images/house_1.jpg';
import House2 from '../../assets/images/house_2.jpg';
import House3 from '../../assets/images/house_3.jpg';
import House4 from '../../assets/images/house_4.jpg';
import House5 from '../../assets/images/house_5.jpg';
import House6 from '../../assets/images/house_6.jpg';

import { useLocation } from 'react-router-dom';
import GoogleHeader from '../../components/GoogleHeader';

const ProjectList = () => {

    let { ProjectList } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [ProjectList]);


    return (
        <div className="c-ProjectList">
            <GoogleHeader />
            <div className="c-plHeaderBOX">
                <div className="page_padding">
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link className="inactive">
                            Home
                        </Link>
                        <Link className="inactive">
                            Project List
                        </Link>
                        <Link className="active">
                            Palazio Apartments
                        </Link>
                    </Breadcrumbs>
                    <div className="c-plHeader">
                        <div className="c-plhLeft">
                            <h3>Palazio Apartments</h3>
                            <p>
                                Planning a simple refresh or embarking on a major overhaul for your home? From architects, interior designers to contractor-IDs, sort and discover the right interior professional for your needs by type.
                            </p>
                        </div>
                        <div className="c-plhRight">
                            <div className="c-fb-flex">
                                <div className="c-BTM">
                                    <SearchIcon className="c-icon" />
                                </div>
                                <TextField
                                    className="form-control"
                                    type="text"
                                    placeholder="Search"
                                ></TextField>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="c-features">
                <div className="page_padding">
                    <h5 className="c-subtitle">FEATURES</h5>
                    <div className="c-listingBox">
                        <div className="c-lbLeft">
                            <div className="c-img">
                                <img src={House1} />
                            </div>
                        </div>
                        <div className="c-lbRight">
                            <div className="c-lbrTop">
                                <div className="c-title">
                                    <h3 className="c-name">KKK Sdn. Bhd.</h3>
                                    <p className="c-subname">
                                        Interior Designer / Architect
                                    </p>
                                </div>
                                <div className="c-btn">
                                    <p>Enquiry</p>
                                </div>
                            </div>
                            <div className="c-center">
                                <div className="c-rating">
                                    <h5 className="c-rateText">4.8</h5>
                                    <StarIcon className="c-rateIcon" />
                                </div>
                                <p className="c-label">20 Project</p>
                            </div>
                            <div className="c-btm">
                                <p className="c-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempus et erat nec pulvinar. Donec nulla justo, rutrum id lacinia faucibus, vestibulum quis sem. Aenean sagittis dolor eget justo pulvinar tempus.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="c-searchList">
                <div className="page_padding">
                    <h5 className="c-subtitle">10 Result Found</h5>
                    <div className="c-listingBox">
                        <div className="c-lbLeft">
                            <div className="c-img">
                                <img src={House3} />
                            </div>
                        </div>
                        <div className="c-lbRight">
                            <div className="c-lbrTop">
                                <div className="c-title">
                                    <h3 className="c-name">KKK Sdn. Bhd.</h3>
                                    <p className="c-subname">
                                        Interior Designer / Architect
                                    </p>
                                </div>
                                <div className="c-btn">
                                    <p>Enquiry</p>
                                </div>
                            </div>
                            <div className="c-center">
                                <div className="c-rating">
                                    <h5 className="c-rateText">4.8</h5>
                                    <StarIcon className="c-rateIcon" />
                                </div>
                                <p className="c-label">20 Project</p>
                            </div>
                            <div className="c-btm">
                                <p className="c-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempus et erat nec pulvinar. Donec nulla justo, rutrum id lacinia faucibus, vestibulum quis sem. Aenean sagittis dolor eget justo pulvinar tempus.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="c-listingBox">
                        <div className="c-lbLeft">
                            <div className="c-img">
                                <img src={House2} />
                            </div>
                        </div>
                        <div className="c-lbRight">
                            <div className="c-lbrTop">
                                <div className="c-title">
                                    <h3 className="c-name">KKK Sdn. Bhd.</h3>
                                    <p className="c-subname">
                                        Interior Designer / Architect
                                    </p>
                                </div>
                                <div className="c-btn">
                                    <p>Enquiry</p>
                                </div>
                            </div>
                            <div className="c-center">
                                <div className="c-rating">
                                    <h5 className="c-rateText">4.8</h5>
                                    <StarIcon className="c-rateIcon" />
                                </div>
                                <p className="c-label">20 Project</p>
                            </div>
                            <div className="c-btm">
                                <p className="c-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempus et erat nec pulvinar. Donec nulla justo, rutrum id lacinia faucibus, vestibulum quis sem. Aenean sagittis dolor eget justo pulvinar tempus.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="c-listingBox">
                        <div className="c-lbLeft">
                            <div className="c-img">
                                <img src={House1} />
                            </div>
                        </div>
                        <div className="c-lbRight">
                            <div className="c-lbrTop">
                                <div className="c-title">
                                    <h3 className="c-name">KKK Sdn. Bhd.</h3>
                                    <p className="c-subname">
                                        Interior Designer / Architect
                                    </p>
                                </div>
                                <div className="c-btn">
                                    <p>Enquiry</p>
                                </div>
                            </div>
                            <div className="c-center">
                                <div className="c-rating">
                                    <h5 className="c-rateText">4.8</h5>
                                    <StarIcon className="c-rateIcon" />
                                </div>
                                <p className="c-label">20 Project</p>
                            </div>
                            <div className="c-btm">
                                <p className="c-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempus et erat nec pulvinar. Donec nulla justo, rutrum id lacinia faucibus, vestibulum quis sem. Aenean sagittis dolor eget justo pulvinar tempus.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="c-listingBox">
                        <div className="c-lbLeft">
                            <div className="c-img">
                                <img src={House4} />
                            </div>
                        </div>
                        <div className="c-lbRight">
                            <div className="c-lbrTop">
                                <div className="c-title">
                                    <h3 className="c-name">KKK Sdn. Bhd.</h3>
                                    <p className="c-subname">
                                        Interior Designer / Architect
                                    </p>
                                </div>
                                <div className="c-btn">
                                    <p>Enquiry</p>
                                </div>
                            </div>
                            <div className="c-center">
                                <div className="c-rating">
                                    <h5 className="c-rateText">4.8</h5>
                                    <StarIcon className="c-rateIcon" />
                                </div>
                                <p className="c-label">20 Project</p>
                            </div>
                            <div className="c-btm">
                                <p className="c-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempus et erat nec pulvinar. Donec nulla justo, rutrum id lacinia faucibus, vestibulum quis sem. Aenean sagittis dolor eget justo pulvinar tempus.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="c-listingBox">
                        <div className="c-lbLeft">
                            <div className="c-img">
                                <img src={House5} />
                            </div>
                        </div>
                        <div className="c-lbRight">
                            <div className="c-lbrTop">
                                <div className="c-title">
                                    <h3 className="c-name">KKK Sdn. Bhd.</h3>
                                    <p className="c-subname">
                                        Interior Designer / Architect
                                    </p>
                                </div>
                                <div className="c-btn">
                                    <p>Enquiry</p>
                                </div>
                            </div>
                            <div className="c-center">
                                <div className="c-rating">
                                    <h5 className="c-rateText">4.8</h5>
                                    <StarIcon className="c-rateIcon" />
                                </div>
                                <p className="c-label">20 Project</p>
                            </div>
                            <div className="c-btm">
                                <p className="c-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempus et erat nec pulvinar. Donec nulla justo, rutrum id lacinia faucibus, vestibulum quis sem. Aenean sagittis dolor eget justo pulvinar tempus.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="c-listingBox">
                        <div className="c-lbLeft">
                            <div className="c-img">
                                <img src={House6} />
                            </div>
                        </div>
                        <div className="c-lbRight">
                            <div className="c-lbrTop">
                                <div className="c-title">
                                    <h3 className="c-name">KKK Sdn. Bhd.</h3>
                                    <p className="c-subname">
                                        Interior Designer / Architect
                                    </p>
                                </div>
                                <div className="c-btn">
                                    <p>Enquiry</p>
                                </div>
                            </div>
                            <div className="c-center">
                                <div className="c-rating">
                                    <h5 className="c-rateText">4.8</h5>
                                    <StarIcon className="c-rateIcon" />
                                </div>
                                <p className="c-label">20 Project</p>
                            </div>
                            <div className="c-btm">
                                <p className="c-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempus et erat nec pulvinar. Donec nulla justo, rutrum id lacinia faucibus, vestibulum quis sem. Aenean sagittis dolor eget justo pulvinar tempus.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectList;