import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";

const Storage = (cartItems) => {
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
};

const useCart = () => {
  const [cart, setCart] = useContext(CartContext);

  function getCart() {
    return cart;
  }

  function clearCart() {
    setCart([]);
    Storage([]);
  }

  function setCartFunction(cart = []) {
    setCart(cart);
  }

  // function addProduct(item) {
  //   const tempCart = [...cart];
  //   const newItem = { ...item, index: tempCart.length };
  //   tempCart.push(newItem);
  //   setCart(tempCart);
  //   Storage(tempCart);
  // }

  function addProduct(item) {
    console.log(item);
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var newItem = { ...item };

    newItem.price = getPriceByQuantity(newItem, newItem.quantity).toFixed(2);

    if (cartTemp.length === 0) {
      newItem["index"] = 0;
      cartTemp.push(newItem);
    } else {
      var existed = 0;
      var index = 0;

      for (var i = 0; i < cartTemp.length; i++) {
        if (cartTemp[i].product_id == newItem.product_id) {
          existed = 1;
          index = i;
        }
      }

      if (existed == 1) {
        var new_quantity =
          parseFloat(cartTemp[index].quantity) + parseFloat(newItem.quantity);

        if (existed == 1 && new_quantity > cartTemp[index].stock) {
          alert("Maximum stock reached for this product");
          return false;
        }

        cartTemp[index]["quantity"] = new_quantity;
        cartTemp[index]["price"] = parseFloat(
          getPriceByQuantity(cartTemp[index], new_quantity).toFixed(2)
        );
      } else {
        newItem["index"] = cartTemp.length;
        cartTemp.push(newItem);
      }
    }
    setCart(cartTemp);
    Storage(cartTemp);

    return true;

  }



  function getTotalCart() {
    let totalAmount = 0;
    cart.forEach(({ price }) => {
      totalAmount += parseFloat(price);
    });
    return totalAmount;
  }

  function removeProduct(index) {
    const tempCart = [...cart];
    tempCart.splice(index, 1);
    tempCart.forEach((item, i) => {
      item.index = i;
    });
    setCart(tempCart);
    Storage(tempCart);
  }

  function getPriceByQuantity({ single_price }, qty) {
    return single_price * qty;
  }

  function updateQuantity(index, delta) {
    const tempCart = [...cart];
    const item = tempCart[index];
    const newQuantity = item.quantity + delta;
    if (newQuantity <= 0) {
      tempCart.splice(index, 1);
    } else {
      item.quantity = newQuantity;
      item.price = getPriceByQuantity(item, newQuantity);
    }
    tempCart.forEach((item, i) => {
      item.index = i;
    });
    setCart(tempCart);
    Storage(tempCart);
  }

  function increaseProduct(index) {
    updateQuantity(index, 1);
  }

  function decreaseProduct(index) {
    updateQuantity(index, -1);
  }

  return {
    addProduct,
    clearCart,
    getTotalCart,
    removeProduct,
    getCart,
    setCartFunction,
    increaseProduct,
    decreaseProduct,
  };
};

export default useCart;
