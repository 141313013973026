import React from 'react'
import useStyle from "./styles"
import GoogleHeader from '../../../components/GoogleHeader'
import Loader from "react-js-loader";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paragraph from '../../../components/Typography/Paragraph';
import { get_order_by_seller_details, get_order_by_user_details, get_orders_by_seller, get_orders_by_user, get_user_profile } from '../../../api/API';
import { useParams } from 'react-router-dom';

export default function Invoice() {
    const classes = useStyle()
    const { order_id } = useParams()

    const [loading, setLoading] = React.useState(false);
    const [token, setToken] = React.useState("");

    React.useEffect(() => {
        let token = window.localStorage.getItem("token");
        setToken(token || "");
        console.log("CURRENT TOKEN", token);
    }, []);

    const [user, setUser] = React.useState([])
    const getProfile = () => {
        get_user_profile({}).then((json) => {
            setUser(json.data)
            setLoading(false)
        })
    }

    const [sellerOrders, setSellerOrders] = React.useState([])

    const getSeller = () => {
        get_orders_by_seller({}).then((json) => {
            setSellerOrders(json.data)
        })
    }

    const [userOrders, setUserOrders] = React.useState([]);

    const getUserOrder = () => {
        get_orders_by_user({}).then((json) => {
            setUserOrders(json.data)
        })
    }

    React.useEffect(() => {
        getSeller()
        getProfile()
        getUserOrder()
    }, [])


    const [userOrderDetails, setUserOrderDetails] = React.useState([])
    const [userProduct, setUserProduct] = React.useState([])

    const getUserOrderDetails = () => {
        get_order_by_user_details(order_id).then((json) => {
            if (json.status) {
                setUserOrderDetails(json.data)
                setUserProduct(json.data.products)

                console.log("product", json.data.products)
            } else {
                console.error(json.message)
            }
        })
    }


    const [sellerOrderDetails, setSellerOrderDetails] = React.useState([])
    const [sellerProduct, setSellerProduct] = React.useState([])

    const getSellerOrderDetails = () => {
        get_order_by_seller_details(order_id).then((json) => {
            if (json.status) {
                setSellerOrderDetails(json.data)
                setSellerProduct(json.data.products)

                console.log("product", json.data.products)
            } else {
                console.error(json.message)
            }
        })
    }


    React.useEffect(() => {
        getUserOrderDetails()
        getSellerOrderDetails()
    }, [])

    return (
        <div className="account_page">
            <GoogleHeader />
            {loading ? (
                <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
            ) : (
                <React.Fragment>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Paragraph
                            size="600"
                            color="#0d2964"
                            bold="600"
                            margin="0 0 1rem 0"
                        >
                            Invoice
                        </Paragraph>
                        <Paragraph
                            size="300"
                            margin="0 0 .5rem 0"
                            bold="600"
                        >
                            Order ID: {user.user_type == 3 ? sellerOrderDetails.order_id : userOrderDetails.order_id}
                        </Paragraph>

                        {/* <Button
            style={{ margin: "0 0 1rem 0" }}
            onClick={() => handleCreateProduct()}
        >
            Add Product
        </Button> */}
                    </div>
                    <hr />

                    <div style={{ margin: "1rem 0 0 0" }}>

                        {user.user_type == "3" ? (
                            <>
                                <div style={{ padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div>
                                            <Paragraph
                                                size="300"
                                                margin="0 0 .5rem 0"
                                                bold="600"
                                            >
                                                {sellerOrderDetails.seller_company_name}
                                            </Paragraph>
                                            <Paragraph
                                                size="300"
                                                margin="0 0 .5rem 0"
                                                bold="600"
                                            >
                                                {sellerOrderDetails.seller_name}
                                            </Paragraph>
                                            <Paragraph
                                                size="300"
                                                margin="0 0 .5rem 0"
                                            >
                                                {sellerOrderDetails.seller_contact}
                                            </Paragraph>
                                            <Paragraph
                                                size="300"
                                                margin="0"
                                            >
                                                {sellerOrderDetails.seller_username}
                                            </Paragraph>
                                        </div>
                                        <div style={{ height: "2px", backgroundColor: "#ccc", width: "100%", margin: "1rem 0" }}></div>
                                        <div>
                                            <Paragraph
                                                size="300"
                                                margin="0 0 .5rem 0"
                                                bold="600"
                                            >
                                                {sellerOrderDetails.name}
                                            </Paragraph>
                                            <Paragraph
                                                size="300"
                                                margin="0 0 .5rem 0"
                                            >
                                                {sellerOrderDetails.address}
                                            </Paragraph>
                                            <Paragraph
                                                size="300"
                                                margin="0 0 .5rem 0"
                                            >
                                                {sellerOrderDetails.contact}
                                            </Paragraph>
                                            <Paragraph
                                                size="300"
                                                margin="0"
                                            >
                                                {sellerOrderDetails.email}
                                            </Paragraph>
                                        </div>

                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Paragraph
                                            size="300"
                                            margin="0 0 .5rem 0"
                                            style={{ textAlign: "right" }}
                                        >
                                            {sellerOrderDetails.created_date}
                                        </Paragraph>
                                        <Paragraph
                                            size="300"
                                            margin="0 0 .5rem 0"
                                            style={{ textAlign: "right" }}
                                        >
                                            {sellerOrderDetails.status}
                                        </Paragraph>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div style={{ padding: "1rem 0 2rem 0", display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div>
                                            <Paragraph
                                                size="300"
                                                margin="0 0 .5rem 0"
                                                bold="600"
                                            >
                                                {userOrderDetails.seller_company_name}
                                            </Paragraph>
                                            <Paragraph
                                                size="300"
                                                margin="0 0 .5rem 0"
                                                bold="600"
                                            >
                                                {userOrderDetails.seller_name}
                                            </Paragraph>
                                            <Paragraph
                                                size="300"
                                                margin="0 0 .5rem 0"
                                            >
                                                {userOrderDetails.seller_contact}
                                            </Paragraph>
                                            <Paragraph
                                                size="300"
                                                margin="0"
                                            >
                                                {userOrderDetails.seller_username}
                                            </Paragraph>
                                        </div>
                                        <div style={{ height: "2px", backgroundColor: "#ccc", width: "100%", margin: "1rem 0" }}></div>
                                        <div>
                                            <Paragraph
                                                size="300"
                                                margin="0 0 .5rem 0"
                                                bold="600"
                                            >
                                                {userOrderDetails.name}
                                            </Paragraph>
                                            <Paragraph
                                                size="300"
                                                margin="0 0 .5rem 0"
                                            >
                                                {userOrderDetails.address}
                                            </Paragraph>
                                            <Paragraph
                                                size="300"
                                                margin="0 0 .5rem 0"
                                            >
                                                {userOrderDetails.contact}
                                            </Paragraph>
                                            <Paragraph
                                                size="300"
                                                margin="0"
                                            >
                                                {userOrderDetails.email}
                                            </Paragraph>
                                        </div>

                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Paragraph
                                            size="300"
                                            margin="0 0 .5rem 0"
                                            style={{ textAlign: "right" }}
                                        >
                                            {userOrderDetails.created_date}
                                        </Paragraph>
                                        <Paragraph
                                            size="300"
                                            margin="0 0 .5rem 0"
                                            style={{ textAlign: "right" }}
                                        >
                                            {userOrderDetails.status}
                                        </Paragraph>
                                    </div>
                                </div>
                            </>
                        )}

                        <TableContainer>
                            <Table>
                                <TableHead style={{ backgroundColor: "#e7e7e7" }}>
                                    <TableRow>
                                        {/* <TableCell>Date Created</TableCell>
                                        <TableCell>Order ID</TableCell> */}
                                        <TableCell style={{ fontWeight: "800" }}>Product</TableCell>
                                        <TableCell style={{ fontWeight: "800" }}>Quantity</TableCell>
                                        <TableCell style={{ fontWeight: "800" }}>Delivery Address</TableCell>
                                        <TableCell style={{ fontWeight: "800" }}>Total Amount</TableCell>
                                        <TableCell style={{ fontWeight: "800" }}>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {user.user_type == "3" ? (
                                        sellerProduct.map((item) => (
                                            <>
                                                <TableRow>
                                                    {/* <TableCell>{sellerOrderDetails.created_date}</TableCell>
                                                    <TableCell>{sellerOrderDetails.order_id}</TableCell> */}
                                                    <TableCell>{item.product_name}</TableCell>
                                                    <TableCell>{item.quantity}</TableCell>
                                                    <TableCell>{sellerOrderDetails.address}</TableCell>
                                                    <TableCell>MYR {item.total_price}</TableCell>
                                                    <TableCell>{sellerOrderDetails.status}</TableCell>
                                                </TableRow>
                                            </>
                                        ))
                                    ) : (
                                        userProduct.map((item) => (
                                            <>
                                                <TableRow>
                                                    {/* <TableCell>{userOrderDetails.created_date}</TableCell>
                                                    <TableCell>{userOrderDetails.order_id}</TableCell> */}
                                                    <TableCell>{item.product_name}</TableCell>
                                                    <TableCell>{item.quantity}</TableCell>
                                                    <TableCell>{userOrderDetails.address}</TableCell>
                                                    <TableCell>MYR {item.total_price}</TableCell>
                                                    <TableCell>{userOrderDetails.status}</TableCell>
                                                </TableRow>
                                            </>
                                        )))}
                                </TableBody >
                            </Table>
                        </TableContainer>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6}></Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "2rem 0 .5rem 0" }}>
                                    <div>
                                        <Paragraph
                                            size="300"
                                            margin="0 0 .5rem 0"
                                            bold="600"
                                        >
                                            Subtotal:
                                        </Paragraph>
                                        <Paragraph
                                            size="300"
                                            margin="0"
                                            bold="600"
                                        >
                                            Discount:
                                        </Paragraph>
                                    </div>

                                    <div>
                                        <Paragraph
                                            style={{ textAlign: "right" }}
                                            size="300"
                                            margin="0 0 .5rem 0"
                                        >
                                            MYR {user.user_type == 3 ? sellerOrderDetails.total_price : userOrderDetails.total_price}
                                        </Paragraph>
                                        <Paragraph
                                            style={{ textAlign: "right" }}
                                            size="300"
                                            margin="0"
                                        >
                                            - MYR {user.user_type == 3 ? sellerOrderDetails.discount_price : userOrderDetails.discount_price}
                                        </Paragraph>
                                    </div>
                                </div>
                                <hr />
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: ".5rem 0" }}>
                                    <Paragraph
                                        size="300"
                                        margin="0"
                                        bold="600"
                                    >
                                        Total:
                                    </Paragraph>
                                    <Paragraph
                                        style={{ textAlign: "right" }}
                                        size="300"
                                        margin="0"
                                        bold="600"
                                    >
                                        MYR {user.user_type == 3 ? sellerOrderDetails.final_price : userOrderDetails.final_price}
                                    </Paragraph>
                                </div>
                            </Grid>
                        </Grid>

                    </div>
                </React.Fragment>
            )
            }
        </div >
    )
}
