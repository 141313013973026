import { InputAdornment, TextField, MenuItem } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import React from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  edit_project,
  get_tag_by_type,
  get_area,
  create_project,
} from "../../../api/API";
import Editor from "./Editor";
import Paragraph from "../../../components/Typography/Paragraph";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./styles.css";
import useStyles from "./styles";
import Button from "../../../components/Button/Button";
import { Grid } from "@mui/material";

// import InputLabel from 'ui-component/extended/Form/InputLabel';

const GOOGLEMAPKEY = "AIzaSyDLXH_lZuKaQ0rwrl9WRgKiCcdS1qku8BQ";

const containerStyle = {
  width: "100%",
  height: "400px",
};

Geocode.setApiKey(GOOGLEMAPKEY);
Geocode.setLocationType("ROOFTOP");

const Project_info = (props) => {
  const classes = useStyles();
  const [token, setToken] = React.useState("");

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  console.log(props);

  const [form, setForm] = React.useState({
    title: "",
    year: 2021,
    description: "",
    styles: [],
    area: [],
    property: [],
    budget: 10000,
    area_id: "",
    duration: 6,
    unit: "",
    street: "",
    taman: "",
    postcode: "",
    city: "",
    state: "",
    project_status: 1,
  });

  //Map
  const [coordinates, setCoordinates] = React.useState({
    lat: 1.550289,
    lng: 103.77639,
  });
  const [center, setCenter] = React.useState({
    lat: 1.55028,
    lng: 103.77639,
  });

  const setLocationByCoordinates = (lat, lng) => {
    setCoordinates({
      lat: lat,
      lng: lng,
    });
    Geocode.fromLatLng(lat, lng).then((res) => {
      console.log(res);
      if (res.results.length) {
        var res_add = res.results[0];

        var unit = "";
        var address1 = "";
        var address2 = "";
        var postcode = "";
        var city = "";
        var state = "";
        for (var i = 0; i < res_add.address_components.length; i++) {
          if (
            res_add.address_components[i].types.indexOf("street_number") > -1
          ) {
            unit += " " + res_add.address_components[i].long_name;
          } else if (
            res_add.address_components[i].types.indexOf("route") > -1
          ) {
            address1 += " " + res_add.address_components[i].long_name;
          } else if (
            res_add.address_components[i].types.indexOf("sublocality") > -1
          ) {
            address2 += " " + res_add.address_components[i].long_name;
          } else if (
            res_add.address_components[i].types.indexOf("locality") > -1
          ) {
            city += " " + res_add.address_components[i].long_name;
          } else if (
            res_add.address_components[i].types.indexOf(
              "administrative_area_level_1"
            ) > -1
          ) {
            state += " " + res_add.address_components[i].long_name;
            // for (var j = 0; j < stateList.length; j++) {
            //     if (stateList[j]['state'] == (res_add.address_components[i].long_name.trim())) {
            //         state = stateList[j]['state_id'];
            //     }
            // }
          } else if (
            res_add.address_components[i].types.indexOf("postal_code") > -1
          ) {
            postcode += " " + res_add.address_components[i].long_name;
          }
        }

        setForm({
          ...form,
          unit: unit.trim(),
          street: address1.trim(),
          taman: address2.trim(),
          postcode: postcode.trim(),
          city: city.trim(),
          state: state.trim(),
        });
      }
    });
  };

  const [searchBox, setSearchBox] = React.useState(null);
  const [placesLoaded, setPlacesLoaded] = React.useState(false);

  const onPlacesChanged = () => {
    if (searchBox) {
      var places = searchBox.getPlaces();
      if (places.length) {
        var place = places[0];
        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();

        setCoordinates({
          lat: lat,
          lng: lng,
        });
        setCenter({
          lat: lat,
          lng: lng,
        });
        setPlacesLoaded(true);
        setLocationByCoordinates(lat, lng);
      }
    } else {
      alert("Google not loaded, please refresh");
      return;
    }
  };

  const pinDragEnd = (e) => {
    var latLng = e.latLng;
    var lat = latLng.lat();
    var lng = latLng.lng();
    setLocationByCoordinates(lat, lng);
  };

  const [stylesTag, setStylesTag] = React.useState([]);
  const [areaTag, setAreaTag] = React.useState([]);
  const [propertyTag, setPropertyTag] = React.useState([]);
  const [workTag, setWorkTag] = React.useState([]);
  const [locationTag, setLocationTag] = React.useState([]);
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeDescription = (e) => {
    setForm({
      ...form,
      description: e,
    });
  };
  const handleSave = () => {
    if (form.title == "") {
      alert("Please enter a title");
      return;
    }
    if (form.description == "") {
      alert("Please enter a description");
      return;
    }
    if (form.area_id == "") {
      alert("Please select an area/location");
      return;
    }

    if (!form.area) {
      alert("Please select an area");
      return;
    }

    if (!form.styles) {
      alert("Please select a style");
      return;
    }
    if (!form.property) {
      alert("Please select a property");
      return;
    }
    if (!form.work) {
      alert("Please select a work");
      return;
    }
    edit_project({
      ...form,
      project_id: props.projectId,
      area_id: JSON.stringify(form.area),
      area: JSON.stringify(form.area),
      property: JSON.stringify(form.property),
      styles: JSON.stringify(form.styles),
      work: JSON.stringify(form.work),
      project_status: 1,
    }).then((res) => {
      props.history.push("/dashboard/projects");
    });
  };

  const [images, setImages] = React.useState([]);

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...selectedImages]);
  };

  const handleDeleteImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if (
      !form.title ||
      !form.description ||
      !form.area ||
      !form.styles ||
      !form.property ||
      !form.work ||
      !images
    ) {
      alert("Fields cannot be empty");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();

    formData.append("title", form.title);
    formData.append("description", form.description);
    formData.append("area", JSON.stringify(form.area));
    formData.append("area_id", form.area_id);
    formData.append("styles", JSON.stringify(form.styles));
    formData.append("property", JSON.stringify(form.property));
    formData.append("work", JSON.stringify(form.work));
    formData.append("budget", form.budget);
    formData.append("duration", form.duration);
    formData.append("project_status", form.project_status);

    // formData.append("project_status", 2);

    images.forEach((image, index) => {
      formData.append(`image_${index}`, image);
    });
    console.log(formData);

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    create_project(formData, headers).then((json) => {
      if (json.status) {
        alert("Submitted");
        setIsLoading(false);
        console.log("image url", images);
        props.history.push("/dashboard/projects");
      } else {
        alert(json.message);
        setIsLoading(false);
        window.location.reload();
      }
    });
  };

  const get_tags = () => {
    get_tag_by_type(1).then((res) => {
      setPropertyTag(res.data);
    });
    get_tag_by_type(2).then((res) => {
      setAreaTag(res.data);
    });
    get_tag_by_type(3).then((res) => {
      setStylesTag(res.data);
    });

    get_tag_by_type(4).then((res) => {
      setWorkTag(res.data);
    });
    get_area().then((res) => {
      setLocationTag(res.data);
    });
  };
  React.useEffect(() => {
    get_tags();
  }, []);

  return (
    <LoadScript libraries={["places"]} googleMapsApiKey={GOOGLEMAPKEY}>
      <div>
        <h3>Create A New Project</h3>
        <Paragraph margin="1rem 0 35px 0" bold="500" size="300">
            Add images to this Project
          </Paragraph>
        <div>
          
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {images.map((image, index) => (
              <div className={classes.imageCenter}>
                <img
                  key={index}
                  src={URL.createObjectURL(image)} // Use createObjectURL to display the selected image
                  alt={`Selected ${index + 1}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <Button
                  label="REMOVE"
                  color="#c70000"
                  br="5px"
                  bg="#fff"
                  margin=".4rem 0"
                  pd="0"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteImage(index)}
                />
              </div>
            ))}
            <label for="upload" className={classes.badgeCenter}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AddCircleOutline />
              </div>
            </label>
          </div>
          <input
            type="file"
            id="upload"
            name="upload"
            multiple
            style={{
              opacity: 0,
            }}
            onChange={handleImageChange}
          />
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              label="Title"
              margin="dense"
              name="title"
              value={form.title}
              onChange={handleChange}
              variant="outlined"
              style={{
                width: "100%",
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              label="Year"
              margin="dense"
              name="year"
              value={form.year}
              onChange={handleChange}
              variant="outlined"
              style={{
                width: "100%",
              }}
            ></TextField>
          </Grid>
        </Grid>

        <Paragraph margin="1rem 0" bold="500" size="300">
          Describe this project
        </Paragraph>

        <div className="text-editor">
          <EditorToolbar />
          <ReactQuill
            style={{ color: "#ffffff", height: "120px" }}
            theme="snow"
            name="description"
            // value={state.value}
            onChange={handleChangeDescription}
            modules={modules}
            formats={formats}
          />
        </div>
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Duration"
              margin="dense"
              name="duration"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Weeks</InputAdornment>
                ),
              }}
              type="number"
              value={form.duration}
              onChange={handleChange}
              variant="outlined"
              style={{
                width: "100%",
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Budget"
              margin="dense"
              name="budget"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">MYR</InputAdornment>
                ),
              }}
              type="number"
              value={form.budget}
              onChange={handleChange}
              variant="outlined"
              style={{
                width: "100%",
              }}
            ></TextField>
          </Grid>
        </Grid>
        <br />
        <StandaloneSearchBox
          onLoad={(ref) => {
            setSearchBox(ref);
          }}
          onPlacesChanged={onPlacesChanged}
        >
          <TextField
            style={{ marginBottom: 10 }}
            fullWidth
            placeholder="Search An Address"
            variant="outlined"
          />
        </StandaloneSearchBox>
        <br />

        {placesLoaded && (
          <>
            <Paragraph
              bold="500"
              margin="0 0 .4rem 0"
              color="red"
              style={{ fontStyle: "italic" }}
            >
              Drag Pin
            </Paragraph>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={17}
            >
              <Marker
                position={center}
                draggable={true}
                onDragEnd={pinDragEnd}
              />
            </GoogleMap>
            <h1>Address:</h1>
            {/* <AddressComponent></AddressComponent> */}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  value={form.unit}
                  name="unit"
                  onChange={handleChange}
                  style={{ marginBottom: 10 }}
                  fullWidth
                  placeholder="Building/Unit No"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  value={form.street}
                  name="street"
                  onChange={handleChange}
                  style={{ marginBottom: 10 }}
                  fullWidth
                  placeholder="Address Line 1"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  value={form.taman}
                  name="taman"
                  onChange={handleChange}
                  style={{ marginBottom: 10 }}
                  fullWidth
                  placeholder="Address Line 2"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  value={form.postcode}
                  name="postcode"
                  onChange={handleChange}
                  style={{ marginBottom: 10 }}
                  fullWidth
                  placeholder="Postcode"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  value={form.city}
                  name="city"
                  onChange={handleChange}
                  style={{ marginBottom: 10 }}
                  fullWidth
                  placeholder="City"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  value={form.state}
                  name="state"
                  defaultValue={1}
                  // select
                  onChange={handleChange}
                  style={{ marginBottom: 10 }}
                  fullWidth
                  placeholder="State"
                  variant="outlined"
                >
                  {/* {stateList.map(row => (
                        <MenuItem value={row.state_id}>{row.state}</MenuItem>
                    ))} */}
                </TextField>
              </Grid>
            </Grid>
          </>
        )}
        <br />
        <hr />
        <br />
        <Paragraph bold="500" size="300" margin="1rem 0">
          Add tags to your project
        </Paragraph>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              select
              fullWidth
              variant="outlined"
              margin="dense"
              // value={currentLocationTag}
              label="Area/Location"
              placeholder="Area/Location"
              onChange={(e) =>
                // setCurrentLocationTag(e.target.value)
                setForm({
                  ...form,
                  area_id: e.target.value,
                })
              }
            >
              {locationTag.length > 0 &&
                locationTag.map((res) => (
                  <MenuItem value={res.area_id}>{res.area}</MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={stylesTag}
              getOptionLabel={(option) => option.tag}
              onChange={(event, newValue) => {
                setForm({
                  ...form,
                  styles: newValue,
                });
              }}
              // defaultValue={[top100Films[13]]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="dense"
                  label="Styles"
                  placeholder="Styles"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={areaTag}
              getOptionLabel={(option) => option.tag}
              // defaultValue={[top100Films[13]]}
              onChange={(event, newValue) => {
                setForm({
                  ...form,
                  area: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="dense"
                  label="Area"
                  placeholder="Area"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={propertyTag}
              getOptionLabel={(option) => option.tag}
              // defaultValue={[top100Films[13]]}
              onChange={(event, newValue) => {
                setForm({
                  ...form,
                  property: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="dense"
                  label="Property"
                  placeholder="Property Type"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={workTag}
              getOptionLabel={(option) => option.tag}
              // defaultValue={[top100Films[13]]}
              onChange={(event, newValue) => {
                setForm({
                  ...form,
                  work: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="dense"
                  label="Work"
                  placeholder="Work Type"
                />
              )}
            />
          </Grid>
        </Grid>

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "1rem 0",
            }}
          >
            {/* <Button
                // onClick={handleSave}
                >Save As Draft</Button> */}
            <Button
              label="SUBMIT"
              color="#000"
              br="5px"
              bg="#ccc"
              pd=".7rem 1rem .6rem"
              style={{ cursor: "pointer" }}
              onClick={() => handleSubmit()}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "1rem 0",
            }}
          >
            {/* <Button
                // onClick={handleSave}
                >Save As Draft</Button> */}
            <Button
              label="SUBMIT"
              color="#FFF"
              br="5px"
              bg="#2f8dcd"
              pd=".7rem 1rem .6rem"
              style={{ cursor: "pointer" }}
              onClick={() => handleSubmit()}
            />
          </div>
        )}
      </div>
    </LoadScript>
  );
};

const top100Films = [];
export default Project_info;
