import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import './Carousel.css';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';

export const CarouselItem = ({ children, width }) => {
    return (
        <div
            className="carousel-item"
            style={{ width: width }}
        >
            {children}
        </div>
    );
};

const Carousel = ({ children }) => {
    console.log('children', children)
    const [activeIndex, setActiveIndex] = useState(0);
    const updateIndex = (newIndex) => {
        if (newIndex < 0) {
            newIndex = React.Children.count(children) - 1;
        } else if (newIndex >= React.Children.count(children)) {
            newIndex = 0;
        }
        var position = document.getElementsByClassName('scrollIMG')[newIndex == 0 ? 0 : newIndex - 1].getBoundingClientRect()
        setActiveIndex(newIndex);
        // var rect = element.getBoundingClientRect();
        console.log(newIndex, 'newIndex', position.x)
        document.getElementsByClassName('IMGindicators')[0].scrollLeft += position.x;
        // this.refs.scroller.scrollLeft + 200;
        // scrollLeft
    }

    return (
        <div className='carousel'>
            <div
                className='inner'
                style={{ transform: 'translateX(-' + activeIndex * 100 + '%)' }}
            >
                {
                    React.Children.map(children, (child, index) => {
                        return React.cloneElement(child, { width: "100%" });
                    })
                }
            </div>
            <div className='indicators'>
                <div
                    className='indicator-previous'
                    onClick={() => updateIndex(activeIndex - 1)}>
                    <ArrowLeft
                        className='indicatorIcon'
                    />
                </div>
                <div
                    className='indicator-next'
                    onClick={() => updateIndex(activeIndex + 1)}>
                    <ArrowRight
                        className='indicatorIcon'
                    />
                </div>
            </div>
            <div className='Numbertext'>
                <p className='theNText'>
                    {activeIndex + 1}/{children.length}
                </p>
            </div>
            <div className='IMGindicators'>

                {
                    React.Children.map(children, (child, index) => {
                        // console.log(child.props.children.props)
                        return (
                            <div
                                onClick={() => updateIndex(index)}
                                className={activeIndex == index ? "activeImg scrollIMG" : "inActiveImg scrollIMG"}
                            >
                                {child.props.children.props.image != undefined &&
                                    <img
                                        src={child.props.children.props.image}
                                        alt="test" />
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Carousel;