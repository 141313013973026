import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { md } from "../../../styles/responsive";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 90%;
`;

const config = {
  theCardBox: {
    position: "relative",
    "& img": {
      width: "100%",
    },
  },

  theCatLink: {
    color: " #333",
    display: "block",
    fontSize: "14px",
    letterSpacing: ".4px",
    padding: "7px 20px",
    textTransform: "capitalize",
    transition: "all .3s ease 0s",
  },
  theProdBox: {
    position: "relative",
    width: "80%",
    "& img": {
      width: "100%",
      paddingBottom: "16px",
    },
  },
  product__price: {
    textAlign: "center",
    listStyle: "none",
    padding: 0,
    margin: 0,
    alignItems: "center",
    display: "flex",
    // marginTop: "9px"
  },

  old__price: {
    display: "inline-block",
    color: "#4d4d4d",
    textDecoration: "line-through",
    paddingRight: "12px",
    fontSize: "22px",
  },
  new__price: {
    display: "inline-block",
    color: "#ff4136",
    fontSize: "30px",
  },

  theTabList: {
    display: "flex",
    position: "relative",
    whiteSpace: "nowrap",
    width: "100%",
    marginBottom: "1rem",
    overflow: "scroll",
    width: "100%",
    borderBottom: "1px solid #d5d5d5",
    paddingBottom: ".6rem",
    paddingTop: "2rem",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  theTabActive: {
    position: "relative",
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "700",
    background: "#2f8dcd",
    // borderRadius: "5px",
    // textAlign: "center",
    // flexGrow: 1,
    // paddingBottom: ".5rem",
    padding: "14px 30px",
    margin: "0 8px",
    cursor: "pointer",

    "&:hover": {
      color: "#FFF",
    },
  },

  theTab: {
    position: "relative",
    color: "#878787",
    fontSize: "14px",
    fontWeight: "700",
    // borderBottom: "2px solid #E8E8E8",
    // textAlign: "center",
    // flexGrow: 1,
    // paddingBottom: ".5rem",
    // borderRadius: "5px",
    padding: "14px 30px",
    margin: "0 8px",
    cursor: "pointer",

    "&:hover": {
      color: "#878787",
    },
  },
  theGalleryList: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    // whiteSpace: "nowrap",
    height: "380px",

    "&::-webkit-scrollbar": {
      webkitAppearance: "none",
      width: "0px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "0px",
      backgroundColor: "rgba(0, 0, 0, .1)",
      boxShadow: "0 0 1px rgba(255, 255, 255, .5)",
    },
  },
  theGallery: {
    position: "relative",
    marginRight: "1rem",
    cursor: "pointer",
    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "contain",
    },
  },

  theProdIMG1: {
    cursor: "pointer",
    position: "relative",
    marginRight: "1rem",
    marginTop: "1rem",
    "& img": {
      width: "100px",
      height: "70px",
      objectFit: "cover",
      display: "flex",
    },
  },
  thePadding: {
    padding: "4rem 0",
    
    ...md("md", {
      padding: "8rem 0",
    })
  },
  theThumbnail: {
    height: "25rem",
    width: "100%",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
};

export default makeStyles(config);
