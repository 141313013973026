import React, {
    useState,
    useEffect,
    useRef
} from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    FormControl,
    Select,
    Button,
} from "@mui/material";
import Section from "../../../components/Section/Section";
import FormInput from "../../../components/FormInput/FormInput";
import Paragraph from "../../../components/Typography/Paragraph";
import Card from "../../../components/Card/Card";
import toast from "react-simple-toasts";
import Loader from "react-js-loader";
// import { Button } from "../../components/Button/Button";
import {
    SideBarContainer,
    SideBarBox,
    Icon,
    Circle,
    SiderBarList,
    NavFlexLink,
    SmallIcon,
    SmallIconIMG,
    ActiveBorder,
} from "../styles";
// import "./Account.css";
import useStyles from "../styles";
import './styles.css';
import useUser from "../../../hooks/useUser";
import {
    get_commission_list,
    get_plan_role,
    create_user,
    check_plan_limit,
    delete_user,
    edit_user
} from "../../../api/API";
import GoogleHeader from "../../../components/GoogleHeader";

const Commission = (props) => {
    const history = useHistory();
    const classes = useStyles();

    const { user, set_user } = useUser();

    const [loading, setLoading] = useState(false)

    //Pagination
    const [page, setPage] = React.useState(1);
    const [totalCount, setTotalCount] = React.useState(1);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const [formData, setFormData] = React.useState({
        email: "",
        name: "",
        contact: "",
        password: "",
        password2: "",
        plan_role_id: "",
        user_type: 1,
        join_type: 2,
    });

    const [enquiryDetail, setEnquiryDetail] = React.useState(null);

    //Get User List
    const [userList, setUserList] = useState(null)
    const [planRoleList, setPlanRoleList] = useState(null)
    const get_data = () => {
        setLoading(true)
        get_commission_list({ page: page }).then(res => {
            setUserList(res.data);
        });
        get_plan_role({}).then(res => {
            setPlanRoleList(res.data);
            setFormData({
                ...formData,
                plan_role_id: res.data[0].plan_role_id,
            });
            setLoading(false)
        });
    }

    useEffect(() => {
        get_data();
    }, [page])

    // console.log(userList.length)
    return (
        <div className="account_page">
            <GoogleHeader />
            {loading ?
                <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
                :
                <React.Fragment>
                    <div className={classes.theHeaderProfile}>

                        <div style={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            margin: '0 0 1rem'
                        }}>
                            <Paragraph
                                size="600"
                                color="#0d2964"
                                bold="600"
                            // margin="0 0 1rem 0"
                            >
                                My Commission List
                            </Paragraph>
                        </div>

                        <hr />
                    </div>
                    {userList != null && userList.length != 0 ?
                        <div>
                            <TableContainer>
                                <Table
                                    sx={{
                                        // minWidth: 650
                                    }} aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                align="left"
                                            >
                                                <Paragraph
                                                    size="14px"
                                                    bold="500"
                                                    color="#000"
                                                >
                                                    No.
                                                </Paragraph>
                                            </TableCell>
                                            {/* <TableCell
                                            align="left"
                                        >
                                            <Paragraph
                                                size="14px"
                                                bold="500"
                                                color="#000"
                                            >
                                               Company Name
                                            </Paragraph>
                                        </TableCell> */}
                                            <TableCell
                                                align="left"
                                            >
                                                <Paragraph
                                                    size="14px"
                                                    bold="500"
                                                    color="#000"
                                                >
                                                    Plan
                                                </Paragraph>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                            >
                                                <Paragraph
                                                    size="14px"
                                                    bold="500"
                                                    color="#000"
                                                >
                                                    Commission
                                                </Paragraph>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                            >
                                                <Paragraph
                                                    size="14px"
                                                    bold="500"
                                                    color="#000"
                                                >
                                                    Created Date
                                                </Paragraph>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userList != null && userList.map((row, index) =>
                                            <TableRow
                                                key={row.user_id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell
                                                    align="left"
                                                >
                                                    <Paragraph
                                                        color="#211D4E"
                                                        size="16px"
                                                        bold="500"
                                                    >
                                                        {index + 1}
                                                    </Paragraph>
                                                </TableCell>
                                                {/* <TableCell
                                                align="left"
                                            >
                                                <Paragraph
                                                    color="#211D4E"
                                                    size="16px"
                                                    bold="500"
                                                >
                                                    {row.company}
                                                </Paragraph>
                                            </TableCell> */}
                                                <TableCell
                                                    align="left"
                                                >
                                                    <Paragraph
                                                        color="#211D4E"
                                                        size="16px"
                                                        bold="500"
                                                    >
                                                        {row.plan}
                                                    </Paragraph>
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                >
                                                    <Paragraph
                                                        color="#211D4E"
                                                        size="16px"
                                                        bold="500"
                                                    >
                                                        {row.commission}
                                                    </Paragraph>
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                >
                                                    <Paragraph
                                                        color="#211D4E"
                                                        size="16px"
                                                        bold="500"
                                                    >
                                                        {row.created_date}
                                                    </Paragraph>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>


                            {totalCount != 0 &&
                                <div style={{ margin: "20px 0 0" }}>
                                    <div>
                                        <Pagination
                                            count={totalCount}
                                            variant="outlined"
                                            page={page}
                                            onChange={handleChangePage}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div>
                            <Paragraph
                                center
                                color="#211D4E"
                                size="500"
                                bold="600"
                                style={{ margin: "10px 0" }}
                            >
                                No Commission Yet
                            </Paragraph>
                        </div>
                    }
                </React.Fragment>
            }
        </div >
    )
};

export default Commission;