import React from "react";
import useStyles, { BadgeCount } from "./styles";
import Slider from "react-slick";

import { Box, Container, Grid } from "@material-ui/core";

import banner from "../../../assets/images/banner_1.jpg";
import collection001 from "../../../assets/images/banner-1.webp";
import collection002 from "../../../assets/images/banner-2.webp";
import prod001 from "../../../assets/images/prod001.webp";
import Paragraph from "../../../components/Typography/Paragraph";
// import { ITEMS } from "./ITEMS";
import { get_banner, get_categories, get_popular_products, get_product, get_products_by_category, get_products_by_subcategory } from "../../../api/API";
import { Accordion, AccordionDetails, AccordionSummary, Card, Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import FormInputNoLabel from "../../../components/FormInput/FormInputNoLabel/FormInputNoLabel";
// import "./styles.css";
import {
    Close,
    MenuBookRounded,
    MenuOutlined,
    Message,
    ShoppingCartOutlined,
} from "@material-ui/icons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useCart from "../../../hooks/useCart";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CachedIcon from '@mui/icons-material/Cached';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useParams } from "react-router-dom";
import ".././styles.css"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function CategoryProduct() {
    const classes = useStyles();
    const { category_id } = useParams()


    // SLIDER SETTING
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
    };

    const settings2 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    // dots: true
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const [categories, setCategories] = React.useState([])

    const getCategories = () => {
        get_categories().then((json) => {
            setCategories(json.data)
        })
    }



    const [chooseCategory, setChooseCategory] = React.useState(1);

    const [expanded, setExpanded] = React.useState(null);
    const [selectedCategory, setSelectedCategory] = React.useState(category_id);
    const [selectedSubcategory, setSelectedSubcategory] = React.useState(1);


    const [productCat, setProductCat] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    const productsPerPage = 20;

    const getProductsCategory = (category, page, per_page) => {
        get_products_by_category(category, page, per_page).then((json) => {
            if (json.status) {
                setProductCat(json.data.data)

                const totalCount = json.data.total_records;
                const pages = Math.ceil(totalCount / productsPerPage);
                setTotalPages(pages);

            } else {
                console.error(json.message)
            }
        })
    }

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            // Increment the current page and fetch data for the next page
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            getProductsCategory(nextPage);
            getProducts(nextPage)
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            // Decrement the current page (if not on the first page) and fetch data for the previous page
            const previousPage = currentPage - 1;
            setCurrentPage(previousPage);
            getProductsCategory(previousPage);
            getProducts(previousPage)
        }
    };

    React.useEffect(() => {
        getCategories();
        // getProducts(selectedSubcategories);
        getProductsCategory(selectedCategory, currentPage, productsPerPage);
    }, [
        // tabs,
        // selectedSubcategories,
        selectedCategory,
        currentPage,
        productsPerPage,
    ]);

    const [banners, setBanners] = React.useState([])

    const getBanner = () => {
        get_banner().then((json) => {
            setBanners(json.data)
        })
    }

    React.useEffect(() => {
        getBanner();
    }, [])


    // CART

    const { getCart, clearCart } = useCart();

    const getcart = JSON.stringify(getCart());

    const [cart, setCart] = React.useState(getCart);

    React.useEffect(() => {
        setCart(getCart());
    }, [getCart]);


    const [categoryHover, setCategoryHover] = React.useState(null)

    const handleCategoryHover = (categoryId) => {
        setCategoryHover(categoryId);
    };

    const handleCategoryMouseLeave = () => {
        setCategoryHover(null);
    };



    const [selectedSubcategories, setSelectedSubcategories] = React.useState([])

    const handleCheckboxChange = (subcategoryID) => {
        if (selectedSubcategories.includes(subcategoryID)) {
            // If the subcategory is already selected, remove it
            setSelectedSubcategories(selectedSubcategories.filter(id => id !== subcategoryID));
        } else {
            // If the subcategory is not selected, add it
            setSelectedSubcategories([...selectedSubcategories, subcategoryID]);
        }
    };

    console.log("select", selectedSubcategories)

    const [products, setProducts] = React.useState([])

    const getProducts = (subcategory, page, per_page) => {

        if (subcategory.length === 0) {
            // Do not make the API request if subcategory is empty
            return;
        }


        get_products_by_subcategory(subcategory, page, per_page).then((json) => {
            if (json.status) {
                setProducts(json.data.data);

                const totalCount = json.data.total_records;
                const pages = Math.ceil(totalCount / productsPerPage);
                setTotalPages(pages);
            } else {
                console.error(json.message)
            }
        })
    }

    React.useEffect(() => {
        getProducts(selectedSubcategories, currentPage, productsPerPage);
    }, [
        selectedSubcategories,
        currentPage,
        productsPerPage,
    ])


    const [searchKey, setSearchKey] = React.useState("");

    const handleSearch = (event) => {
        setSearchKey(event.target.value);
    };



    return (
        <React.Fragment>
            <div style={{ padding: "7rem 0" }}>
                {window.innerWidth >= 800 ? (
                    <div style={{ backgroundColor: "#f0f0f0", padding: "1rem 0", position: "fixed", width: "100%", zIndex: "999" }}>
                        <Container>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                {categories.map((category) => (
                                    <>
                                        <div
                                            key={category.category_id}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                position: "relative", // Position relative for subcategories
                                            }}
                                            onMouseEnter={() => handleCategoryHover(category.category_id)}
                                            onMouseLeave={handleCategoryMouseLeave}
                                        >
                                            <Paragraph>
                                                {category.name}
                                            </Paragraph>
                                            <ExpandMoreIcon />
                                            {categoryHover === category.category_id && (
                                                <a href={`/categoryproduct/${category.category_id}`}>
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "100%",
                                                            left: "0",
                                                            padding: "1rem",
                                                            background: "#fff",
                                                            border: "1px solid #ccc",
                                                            zIndex: "100",
                                                            minWidth: "200px",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {category.subcategories.map((subcategory) => (
                                                            <div key={subcategory.subcategory_id}>
                                                                <Paragraph
                                                                    margin=".4rem 0"
                                                                >
                                                                    {subcategory.subcategory_name}
                                                                </Paragraph>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </a>
                                            )}
                                        </div>
                                    </>
                                ))}
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <FormInputNoLabel
                                        placeholder="Search Here"
                                        border="1px solid #acacac"
                                        onChange={handleSearch}
                                        value={searchKey}
                                        bg="white"
                                    />
                                    <a href="/cart" style={{ position: "relative", display: "flex", alignItems: "center", marginLeft: "1.5rem" }}>
                                        <ShoppingCartOutlined style={{ color: "#000" }} />
                                        <BadgeCount bg="#000" color="#fff">
                                            {cart.length}
                                        </BadgeCount>
                                    </a>

                                </div>
                            </div>
                        </Container>
                    </div>
                ) : (
                    <></>
                )}
                <div className={classes.paddingBox}>
                    <Slider className={classes.root} {...settings}>
                        {banners.map((item) => (
                            <div className={classes.theBanner}>
                                <img src={item.image} />
                            </div>
                        ))}
                    </Slider>
                </div>
                <Box sx={{ background: "#FFF" }}>
                    <Container>
                        <Box sx={{ padding: "2rem 0 0 0" }}>
                            <Box sx={{ padding: "0 0 2rem 0" }}>
                                <Paragraph
                                    center
                                    size="400"
                                    bold="600"
                                >
                                    CATEGORY
                                </Paragraph>
                            </Box>
                            <Box sx={{ padding: "0 0 2rem 0" }}>
                                {/* <Grid container spacing={3}> */}
                                <div style={{ display: "flex", alignContent: "flex-start", flexWrap: "wrap" }}>
                                    {categories.map((cat) => (
                                        // <Grid item xs={4} sm={4} md={3}>
                                        <a href={`/categoryproduct/${cat.category_id}`}>
                                            <div style={{ padding: ".5rem" }}>
                                                <div className={classes.theCategory}>
                                                    <div className={classes.theCatImg}>
                                                        <img src={cat.image} />
                                                    </div>
                                                    <div>
                                                        <Paragraph
                                                            bold="600"
                                                            center
                                                        >
                                                            {cat.name}
                                                        </Paragraph>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        // </Grid>
                                    ))}
                                </div>
                                {/* </Grid> */}
                            </Box>
                        </Box>



                        <Box sx={{ padding: "2rem 0 2rem 0" }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={3}>
                                    {categories.map((category) => {
                                        if (category.category_id === category_id) {
                                            return (
                                                <h3
                                                    style={{
                                                        borderTop: "2px solid #2f8dcd",
                                                        borderLeft: "1px solid rgba(129,129,129,.2)",
                                                        borderRight: "1px solid rgba(129,129,129,.2)",
                                                        color: "#303030",
                                                        padding: "15px",
                                                        fontSize: "17px",
                                                        fontWeight: "500",
                                                        margin: 0,
                                                    }}
                                                >
                                                    {category.name}
                                                </h3>
                                            );
                                        }
                                        return null;

                                    })}
                                    <Box
                                        sx={{
                                            background: "#ffffff none repeat scroll 0 0",
                                            border: "1px solid #81818133",
                                            padding: "20px 10px",
                                        }}
                                    >
                                        <div>
                                            {categories.map((category) => {
                                                if (category.category_id === category_id) {
                                                    return (
                                                        <div key={category.category_id}>
                                                            <div>
                                                                {category.subcategories.map((subcategory) => (
                                                                    <div key={subcategory.subcategory_id}>
                                                                        <FormControlLabel
                                                                            style={{}}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={selectedSubcategories.includes(subcategory.subcategory_id)}
                                                                                    onChange={() => handleCheckboxChange(subcategory.subcategory_id)}
                                                                                />
                                                                            }
                                                                            label={subcategory.subcategory_name}
                                                                        />

                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null;

                                            })}
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9}>
                                    {selectedSubcategories.length >= 1 ? (
                                        <div style={{ display: "flex", alignContent: "flex-start", flexWrap: "wrap" }}>
                                            {products ? (
                                                products.map((item) => {
                                                    if (searchKey != "") {
                                                        if (
                                                            item.product_name
                                                                .toUpperCase()
                                                                .indexOf(searchKey.toUpperCase()) <= -1
                                                        ) {
                                                            return;
                                                        }
                                                    }
                                                    return (
                                                        <div key={item.product_id}>
                                                            <div style={{ display: "flex" }}>
                                                                <a href={`../productdetail/${item.product_id}`}>
                                                                    <div className={classes.theProduct}>
                                                                        <div className={classes.theProdImg}>
                                                                            <img src={item.image} alt={item.product_name} />
                                                                        </div>
                                                                        <div>
                                                                            <Paragraph
                                                                                color="#06c"
                                                                                bold="300"
                                                                                margin="0 0 .5rem 0"
                                                                            >
                                                                                {item.product_name.toUpperCase()}
                                                                            </Paragraph>
                                                                            <div className={classes.theLine}></div>
                                                                            <Paragraph
                                                                                color="#f30"
                                                                                margin="0 0 .6rem 0"
                                                                            >
                                                                                {item.currency} {item.price}
                                                                            </Paragraph>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div className="loading-spinner"></div>
                                            )}
                                        </div>
                                    ) : (
                                        <>
                                            <div style={{ display: "flex", alignContent: "flex-start", flexWrap: "wrap" }}>
                                                {productCat ? (
                                                    productCat.map((item) => {
                                                        if (searchKey != "") {
                                                            if (
                                                                item.product_name
                                                                    .toUpperCase()
                                                                    .indexOf(searchKey.toUpperCase()) <= -1
                                                            ) {
                                                                return;
                                                            }
                                                        }
                                                        return (
                                                            <div key={item.product_id}>
                                                                <div style={{ display: "flex" }}>
                                                                    <a href={`../productdetail/${item.product_id}`}>
                                                                        <div className={classes.theProduct}>
                                                                            <div className={classes.theProdImg}>
                                                                                <img src={item.image} alt={item.product_name} />
                                                                            </div>
                                                                            <div>
                                                                                <Paragraph
                                                                                    color="#06c"
                                                                                    bold="300"
                                                                                    margin="0 0 .5rem 0"
                                                                                >
                                                                                    {item.product_name.toUpperCase()}
                                                                                </Paragraph>
                                                                                <div className={classes.theLine}></div>
                                                                                <Paragraph
                                                                                    color="#f30"
                                                                                    margin="0 0 .6rem 0"
                                                                                >
                                                                                    {item.currency} {item.price}
                                                                                </Paragraph>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div className="loading-spinner"></div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', padding: "1rem 0 0 0" }}>
                                        <div style={{
                                            backgroundColor: currentPage === 1 ? "#CCCCCC" : "#2f8dcd",
                                            borderRadius: "10px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: ".3rem",
                                            cursor: "pointer",
                                        }}
                                            onClick={handlePreviousPage}
                                        >
                                            <KeyboardArrowLeftIcon sx={{
                                                fill: "#fff",
                                            }} />
                                        </div>

                                        <Paragraph
                                            margin="0 1rem"
                                            size="300"
                                        >
                                            {currentPage} of {totalPages}
                                        </Paragraph>

                                        <div style={{
                                            backgroundColor: currentPage === totalPages ? "#CCCCCC" : "#2f8dcd",
                                            borderRadius: "10px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: ".3rem",
                                            cursor: "pointer",
                                        }}
                                            onClick={handleNextPage}
                                        >
                                            <KeyboardArrowRightIcon sx={{
                                                fill: "#fff",
                                            }} />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box >
            </div>
        </React.Fragment >
    );
}

