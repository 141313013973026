import React from 'react'
import Product_Info from './Product_Info'

const CreateProduct = (props) => {
    
  return (
    <div>
        <Product_Info />
    </div>
  )
}

export default CreateProduct