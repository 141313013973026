import { useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./styles.scss";
import { Link } from "react-router-dom";

const ContractorLayout = (props) => {

    useState(() => {
        console.log(props);
    }, []);
    return (
        <>
            <Header style={{
                position: "relative"
            }} />
            <>
                <div className="contractor_layout">
                    <div className="_sidebar">
                        <div className="_item">
                            <Link
                                to="/contractors/jobs"
                            >Jobs</Link>
                        </div>
                        <div className="_item">
                            <Link
                                to="/contractors/list"
                            >Contractors</Link>
                        </div>
                        <div className="_item">
                            <Link
                                to="/contractors/bids"
                            >My Bids</Link>
                        </div>
                        <div className="_item">
                            <Link
                                to="/contractors/mypost"
                            >My Jobs</Link>
                        </div>
                    </div>
                    <div className="_content">
                        {props.children}
                    </div>
                </div>

            </>
            <Footer />
        </>
    )
};

export default ContractorLayout;