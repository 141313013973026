import React from 'react'
import useStyle from "./styles"
import { Box, Card, Checkbox, Container, FormControlLabel, Grid } from "@material-ui/core";
import Paragraph from '../../../components/Typography/Paragraph'
import { get_categories, get_product_details, get_seller_products, get_seller_products_by_category, get_seller_products_by_subcategory, get_seller_products_categorized, get_seller_profile } from '../../../api/API'
import Button from '../../../components/Button/Button'
import { useParams } from 'react-router-dom'
import {
    Close,
    MenuBookRounded,
    MenuOutlined,
    Message,
    ShoppingCartOutlined,
} from "@material-ui/icons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useCart from "../../../hooks/useCart";
import FormInputNoLabel from '../../../components/FormInput/FormInputNoLabel/FormInputNoLabel'
import { BadgeCount } from '../styles'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ".././styles.css"

export default function SellerProfieCategory() {
    const { user_id, category_id } = useParams()
    const classes = useStyle()

    const settings2 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    // dots: true
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const [sellerProfile, setSellerProfile] = React.useState([])

    // const getSellerProfile = (userId) => {
    //   get_seller_profile({ user_id: userId }).then((json) => {
    //     setSellerProfile(json.data)
    //   })
    // }

    const getSellerProfile = () => {
        get_seller_profile(user_id).then((json) => {
            console.log("Received data:", json);
            if (json.status && typeof json.data === 'object') {
                setSellerProfile(json.data);
            } else {
                console.error("Invalid data:", json);
            }
        });
        console.log("user_id", user_id)
    }

    const [sellerProductCategory, setSellerProductCategory] = React.useState([])
    const [product, setProduct] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    const productsPerPage = 10;

    // const getSellerProducts = (user_id, page, per_page) => {
    //     get_seller_products(user_id, page, per_page).then((json) => {
    //         if (json.status) {
    //             setSellerProduct(json.data.data)
    //             setProduct(json.data)

    //             const totalCount = json.data.total_records;
    //             const pages = Math.ceil(totalCount / productsPerPage);
    //             setTotalPages(pages);
    //         } else {
    //             console.error(json.message)
    //         }
    //     })
    // }

    const getSellerProductsCategory = (user_id, category_id, page, per_page) => {
        get_seller_products_by_category(user_id, category_id, page, per_page).then((json) => {
            if (json.status) {
                setSellerProductCategory(json.data.data)

                const totalCount = json.data.total_records;
                const pages = Math.ceil(totalCount / productsPerPage);
                setTotalPages(pages);
            } else {
                console.error(json.message)
            }
        })
    }

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            // Increment the current page and fetch data for the next page
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            getSellerProductsCategory(nextPage);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            // Decrement the current page (if not on the first page) and fetch data for the previous page
            const previousPage = currentPage - 1;
            setCurrentPage(previousPage);
            getSellerProductsCategory(previousPage);
        }
    };

    React.useEffect(() => {
        getSellerProfile()
        getSellerProductsCategory(user_id, category_id, currentPage, productsPerPage)
        getCategories()
    }, [
        user_id,
        category_id,
        currentPage,
        productsPerPage
    ]);

    const [categories, setCategories] = React.useState([])

    const getCategories = () => {
        get_categories({}).then((json) => {
            setCategories(json.data)
        })
    }

    const [selectedSubcategories, setSelectedSubcategories] = React.useState([])

    const handleCheckboxChange = (subcategoryID) => {
        if (selectedSubcategories.includes(subcategoryID)) {
            // If the subcategory is already selected, remove it
            setSelectedSubcategories(selectedSubcategories.filter(id => id !== subcategoryID));
        } else {
            // If the subcategory is not selected, add it
            setSelectedSubcategories([...selectedSubcategories, subcategoryID]);
        }
    };


    const [productSubcat, setProductSubcat] = React.useState([])

    const getSellerProductsSubcategory = (user_id, subcategoryId, page, per_page) => {

        if (subcategoryId.length === 0) {
            // Do not make the API request if subcategory is empty
            return;
        }

        get_seller_products_by_subcategory(user_id, subcategoryId, page, per_page).then((json) => {
            if (json.status) {
                setProductSubcat(json.data.data)

                const totalCount = json.data.total_records;
                const pages = Math.ceil(totalCount / productsPerPage);
                setTotalPages(pages);
            }
        })
    }

    React.useEffect(() => {
        getSellerProductsSubcategory(user_id, selectedSubcategories, currentPage, productsPerPage)
    }, [
        user_id,
        selectedSubcategories,
        currentPage,
        productsPerPage,
    ])

    const { getCart, clearCart } = useCart();

    const getcart = JSON.stringify(getCart());

    const [cart, setCart] = React.useState(getCart);

    React.useEffect(() => {
        setCart(getCart());
    }, [getCart]);


    const [categoryHover, setCategoryHover] = React.useState(null)

    const handleCategoryHover = (categoryId) => {
        setCategoryHover(categoryId);
    };

    const handleCategoryMouseLeave = () => {
        setCategoryHover(null);
    };


    const [searchKey, setSearchKey] = React.useState("");

    const handleSearch = (event) => {
        setSearchKey(event.target.value);
    };




    return (
        <React.Fragment>

            <Box className={classes.thePadding}>
                {window.innerWidth >= 800 ? (
                    <div style={{ backgroundColor: "#f0f0f0", padding: "1rem 0", position: "fixed", width: "100%", zIndex: "999" }}>
                        <Container>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                {categories.map((category) => (
                                    <>
                                        <div
                                            key={category.category_id}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                position: "relative", // Position relative for subcategories
                                            }}
                                            onMouseEnter={() => handleCategoryHover(category.category_id)}
                                            onMouseLeave={handleCategoryMouseLeave}
                                        >
                                            <Paragraph>
                                                {category.name}
                                            </Paragraph>
                                            <ExpandMoreIcon />
                                            {categoryHover === category.category_id && (
                                                <a href={`/categoryproduct/${category.category_id}`}>
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "100%",
                                                            left: "0",
                                                            padding: "1rem",
                                                            background: "#fff",
                                                            border: "1px solid #ccc",
                                                            zIndex: "100",
                                                            minWidth: "200px",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {category.subcategories.map((subcategory) => (
                                                            <div key={subcategory.subcategory_id}>
                                                                <Paragraph
                                                                    margin=".4rem 0"
                                                                >
                                                                    {subcategory.subcategory_name}
                                                                </Paragraph>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </a>
                                            )}
                                        </div>
                                    </>
                                ))}
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <FormInputNoLabel
                                        placeholder="Search Here"
                                        border="1px solid #acacac"
                                        onChange={handleSearch}
                                        value={searchKey}
                                        bg="white"
                                    />
                                    <a href="/cart" style={{ position: "relative", display: "flex", alignItems: "center", marginLeft: "1.5rem" }}>
                                        <ShoppingCartOutlined style={{ color: "#000" }} />
                                        <BadgeCount bg="#000" color="#fff">
                                            {cart.length}
                                        </BadgeCount>
                                    </a>

                                </div>
                            </div>
                        </Container>
                    </div>
                ) : (
                    <></>
                )}

                <Box sx={{ background: "#f6f6f6" }}>
                    <Box style={{ padding: "1rem 0" }}>
                        <Box className={classes.theProfile}>
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                <div className={classes.theLogo}>
                                    <img src={sellerProfile.image} />
                                </div>
                                <div className={classes.theInfo}>
                                    <Paragraph
                                        center
                                        margin="1rem 0 .4rem 0"
                                        size="500"
                                        bold="600"
                                    >
                                        {sellerProfile.name}
                                    </Paragraph>
                                    <Paragraph
                                        center
                                        margin="1rem 0 .4rem 0"
                                        size="300"
                                        bold="600"
                                    >
                                        {sellerProfile.company_name}
                                    </Paragraph>
                                    <Paragraph
                                        center
                                        margin="1rem 0 .4rem 0"
                                        size="300"
                                        bold="300"
                                    >
                                        Products: {product.total_records}
                                    </Paragraph>
                                </div>
                            </div>
                        </Box>
                    </Box>
                </Box >

                <Box sx={{ background: "#fff" }}>
                    <Container>

                        {/* <Box sx={{ padding: "2rem 0 0 0" }}>
                            <Box sx={{ padding: "0 0 2rem 0" }}>
                                <Paragraph
                                    center
                                    size="400"
                                    bold="600"
                                >
                                    CATEGORY
                                </Paragraph>
                            </Box>
                            <Box sx={{ padding: "0 0 2rem 0" }}>
                                <div style={{ display: "flex", alignContent: "flex-start", flexWrap: "wrap" }}>
                                    {categories.map((cat) => (
                                        <a href={`/sellerprofilecategory/${sellerProfile.user_id}/${cat.category_id}`}>
                                            <div style={{ padding: ".5rem" }}>
                                                <div className={classes.theCategory}>
                                                    <div className={classes.theCatImg}>
                                                        <img src={cat.image} />
                                                    </div>
                                                    <div>
                                                        <Paragraph
                                                            bold="600"
                                                            center
                                                        >
                                                            {cat.name}
                                                        </Paragraph>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </Box>
                        </Box> */}


                        <Box style={{ padding: "4rem 0" }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={3}>
                                    {categories.map((category) => {
                                        if (category.category_id === category_id) {
                                            return (
                                                <h3
                                                    style={{
                                                        borderTop: "2px solid #2f8dcd",
                                                        borderLeft: "1px solid rgba(129,129,129,.2)",
                                                        borderRight: "1px solid rgba(129,129,129,.2)",
                                                        color: "#303030",
                                                        padding: "15px",
                                                        fontSize: "17px",
                                                        fontWeight: "500",
                                                        margin: 0,
                                                    }}
                                                >
                                                    {category.name}
                                                </h3>
                                            );
                                        }
                                        return null;

                                    })}
                                    <Box
                                        sx={{
                                            background: "#ffffff none repeat scroll 0 0",
                                            border: "1px solid #81818133",
                                            padding: "20px 10px",
                                        }}
                                    >
                                        <div>
                                            {categories.map((category) => {
                                                if (category.category_id === category_id) {
                                                    return (
                                                        <div key={category.category_id}>
                                                            <div>
                                                                {category.subcategories.map((subcategory) => (
                                                                    <div key={subcategory.subcategory_id}>
                                                                        <FormControlLabel
                                                                            style={{}}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={selectedSubcategories.includes(subcategory.subcategory_id)}
                                                                                    onChange={() => handleCheckboxChange(subcategory.subcategory_id)}
                                                                                />
                                                                            }
                                                                            label={subcategory.subcategory_name}
                                                                        />

                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null;

                                            })}
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9}>
                                    {selectedSubcategories.length >= 1 ? (
                                        <div style={{ display: "flex", alignContent: "flex-start", flexWrap: "wrap" }}>
                                            {productSubcat ? (
                                                productSubcat.map((item) => {
                                                    if (searchKey != "") {
                                                        if (
                                                            item.product_name
                                                                .toUpperCase()
                                                                .indexOf(searchKey.toUpperCase()) <= -1
                                                        ) {
                                                            return;
                                                        }
                                                    }
                                                    return (
                                                        <div key={item.product_id}>
                                                            <div style={{ display: "flex" }}>
                                                                <a href={`/productdetail/${item.product_id}`}>
                                                                    <div className={classes.theProduct}>
                                                                        <div className={classes.theProdImg}>
                                                                            <img src={item.image} alt={item.product_name} />
                                                                        </div>
                                                                        <div>
                                                                            <Paragraph
                                                                                color="#06c"
                                                                                bold="300"
                                                                                margin="0 0 .5rem 0"
                                                                            >
                                                                                {item.product_name.toUpperCase()}
                                                                            </Paragraph>
                                                                            <div className={classes.theLine}></div>
                                                                            <Paragraph
                                                                                color="#f30"
                                                                                margin="0 0 .6rem 0"
                                                                            >
                                                                                {item.currency} {item.price}
                                                                            </Paragraph>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div className="loading-spinner"></div>
                                            )}
                                        </div>
                                    ) : (
                                        <>
                                            <div style={{ display: "flex", alignContent: "flex-start", flexWrap: "wrap" }}>
                                                {sellerProductCategory ? (
                                                    sellerProductCategory.map((item) => {
                                                        if (searchKey != "") {
                                                            if (
                                                                item.product_name
                                                                    .toUpperCase()
                                                                    .indexOf(searchKey.toUpperCase()) <= -1
                                                            ) {
                                                                return;
                                                            }
                                                        }
                                                        return (
                                                            <div key={item.product_id}>
                                                                <div style={{ display: "flex" }}>
                                                                    <a href={`/productdetail/${item.product_id}`}>
                                                                        <div className={classes.theProduct}>
                                                                            <div className={classes.theProdImg}>
                                                                                <img src={item.image} alt={item.product_name} />
                                                                            </div>
                                                                            <div>
                                                                                <Paragraph
                                                                                    color="#06c"
                                                                                    bold="300"
                                                                                    margin="0 0 .5rem 0"
                                                                                >
                                                                                    {item.product_name.toUpperCase()}
                                                                                </Paragraph>
                                                                                <div className={classes.theLine}></div>
                                                                                <Paragraph
                                                                                    color="#f30"
                                                                                    margin="0 0 .6rem 0"
                                                                                >
                                                                                    {item.currency} {item.price}
                                                                                </Paragraph>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div className="loading-spinner"></div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', padding: "1rem 0 0 0" }}>
                                        <div style={{
                                            backgroundColor: currentPage === 1 ? "#CCCCCC" : "#2f8dcd",
                                            borderRadius: "10px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: ".3rem",
                                            cursor: "pointer",
                                        }}
                                            onClick={handlePreviousPage}
                                        >
                                            <KeyboardArrowLeftIcon sx={{
                                                fill: "#fff",
                                            }} />
                                        </div>

                                        <Paragraph
                                            margin="0 1rem"
                                            size="300"
                                        >
                                            {currentPage} of {totalPages}
                                        </Paragraph>

                                        <div style={{
                                            backgroundColor: currentPage === totalPages ? "#CCCCCC" : "#2f8dcd",
                                            borderRadius: "10px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: ".3rem",
                                            cursor: "pointer",
                                        }}
                                            onClick={handleNextPage}
                                        >
                                            <KeyboardArrowRightIcon sx={{
                                                fill: "#fff",
                                            }} />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>

            </Box>


        </React.Fragment>
    )
}
