
import Blog1 from '../../assets/images/blog_1.jpg';
import LogoLong from '../../assets/images/logo_long.png';
import './styles.css';
import React from 'react';
import {
    get_blog_detail
} from '../../api/API';
import GoogleHeader from '../../components/GoogleHeader';

const Article = (props) => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [blogDetail, setBlogDetail] = React.useState([]);
    const get_data = (blog_id) => {
        get_blog_detail({ blog_id }).then(res => {
            setBlogDetail(res.data);
        });
    }
    React.useEffect(() => {
        get_data(props.match.params.blog_id);
    }, []);
    return (
        <div className="article_page">
            <GoogleHeader />
            <div className="banner_image">
                <img src={blogDetail.blog_image} />

            </div>

            <div class="page_padding">
                <h1>
                    {blogDetail.title}
                </h1>
                <p
                    dangerouslySetInnerHTML={{
                        __html: blogDetail.description,
                    }}
                />

                <hr />
                <div className="author">
                    <div className="author_img">
                        <img src={LogoLong} />
                    </div>
                    {/* <p className="title">{blogDetail.author_position}</p> */}
                </div>
            </div>
        </div>
    )
};

export default Article;