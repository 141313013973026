import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

export const config = {
  root: {},
  thePlan: {
    // width: '20%'
  },
  theSilverdPlanLabel: {
    width: 'fit-content',
    backgroundColor: '#72BB85',
    borderRadius: '5px',
    padding: '10px 16px',
    margin: '0 0 1rem'
  },
  theGoldPlanLabel: {
    width: 'fit-content',
    backgroundColor: '#3EA2DD',
    borderRadius: '5px',
    padding: '10px 16px',
    margin: '0 0 1rem'
  },
  thePlatinumPlanLabel: {
    width: 'fit-content',
    backgroundColor: '#636AB0',
    borderRadius: '5px',
    padding: '10px 16px',
    margin: '0 0 1rem'
  },
  theDiamondPlanLabel: {
    width: 'fit-content',
    backgroundColor: '#9574AD',
    borderRadius: '5px',
    padding: '10px 16px',
    margin: '0 0 1rem'
  },
  theSCLFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  theReferal: {
    border: "1px solid #707070",
    padding: "12px 70px",
    borderRadius: "9px",
  },
  theReferalExpired: {
    border: "1px solid #ff0000",
    padding: "12px 70px",
    borderRadius: "4px",
  },
  theCopy: {
    width: "40px",
    height: "40px",
    borderRadius: "9px",
    backgroundColor: "#1976d2",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    margin: "0 0 0 8px",

    "& img": {
      width: "50%",
    },

  },

  theCardBox: {
    position: "relative",
    "& img": {
      width: "100%",
    },
  },

  theCatLink: {
    color: " #333",
    display: "block",
    fontSize: "14px",
    letterSpacing: ".4px",
    padding: "7px 20px",
    textTransform: "capitalize",
    transition: "all .3s ease 0s",
  },
  theCatLinkActive: {
    color: " #2f8dcd",
    display: "block",
    fontSize: "14px",
    letterSpacing: ".4px",
    padding: "7px 20px",
    textTransform: "capitalize",
    transition: "all .3s ease 0s",
  },
  theProdBox: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      paddingBottom: "27px",
    },
  },
  product__price: {
    textAlign: "center",
    listStyle: "none",
    padding: 0,
    margin: 0,
    justifyContent: "center",
    display: "flex",
    marginTop: "9px",
  },
  old__price: {
    display: "inline-block",
    color: "#9c9b9b",
    textDecoration: "line-through",
    paddingRight: "12px",
    fontSize: "16px",
  },
  new__price: {
    display: "inline-block",
    color: "#ff4136",
    fontSize: "16px",
  },

};
export default makeStyles(config);
