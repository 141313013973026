import { useHistory } from "react-router-dom";
import "./styles.css";
import SearchIcon from "@material-ui/icons/Search";

import House1 from "../../assets/images/house_1.jpg";
import House2 from "../../assets/images/house_2.jpg";
import House3 from "../../assets/images/house_3.jpg";
import House4 from "../../assets/images/house_4.jpg";
import House5 from "../../assets/images/house_5.jpg";
import House6 from "../../assets/images/house_6.jpg";

import Slider from "react-slick";
import React from "react";

const DesignItem = (props) => {
  const history = useHistory();
  var settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // const [images, setImages] = React.useState([...props.images]);
  // const [images, setImages] = React.useState(Array.isArray(props.images) ? [...props.images] : []);
  const [images, setImages] = React.useState([]);
  // console.log(props)
  const [logo, setLogo] = React.useState([]);

  React.useEffect(() => {
    //   var newImages = [...images];
    //   newImages = shuffle(newImages);
    //   setImages(newImages);
    // }, []);
    // Shuffle the images if needed
    let newImages = Array.isArray(props.images) ? [...props.images] : [];
    newImages = shuffle(newImages);
    setImages(newImages);
  }, [props.images]);


  function shuffle(array) {
    var currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  function addAdminPrefixIfNeeded(imageUrl) {
    if (typeof imageUrl === "string" && !imageUrl.startsWith("https://indor.my/admin")) {
      return `https://indor.my/admin${imageUrl}`;
    }
    return imageUrl;
  }


  return (
    <div
      item
      className="design_item"
      onClick={() => {
        props.onClick();
      }}
    >
      <div className="search_overlay flex_center">
        <SearchIcon></SearchIcon>
      </div>

      <div className="_image_wrapper">
        <div className="cover_img">
          {/* {images.length >= 1 && <img src={images[0].image} />} */}
          {images.length >= 1 && <img src={addAdminPrefixIfNeeded(images[0].image)} />}
          <div className="images_tag"></div>
        </div>
        <div className="sub_img">
          {/* {images.length >= 2 && <img src={images[1].image} />}
          {images.length >= 3 && <img src={images[2].image} />}
          {images.length >= 4 && <img src={images[3].image} />} */}
          {images.length >= 2 && <img src={addAdminPrefixIfNeeded(images[1].image)} />}
          {images.length >= 3 && <img src={addAdminPrefixIfNeeded(images[2].image)} />}
          {images.length >= 4 && <img src={addAdminPrefixIfNeeded(images[3].image)} />}
        </div>
        {props.pathName == "/Dashboard/Projects" ? (
          <>
            {props.remarks.length > 0 ? (
              <div className="c-notiAbsolute">
                <p>You have a remark</p>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="design_footerFlex">
        <div className="design_footerImg">
          <img src={props.designerarray} />
        </div>
        <div className="design_footer">
          <h3>{props.name}</h3>
          <p>{props.designer}</p>
          {/* {props.tag && (
                    <div className="tag_wrapper">
                        {props.tag.map(tag => (
                            <div className="tag">
                                {tag.tag}
                            </div>
                        ))}
                    </div>
                )} */}
        </div>
      </div>
    </div>
  );
};

export default DesignItem;
