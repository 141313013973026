import React from 'react'
import { contact_form } from '../../api/API';
import useStyle from "./styles"
import toast from "react-simple-toasts";
import { Box, Container, FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import Slider from "@material-ui/core/Slider";
import Button from "../../components/Button/Button";



export default function RequirementForm() {
    const classes = useStyle()

    const [price, setPrice] = React.useState([10000, 1000000]);

    const handleChangePrice = (event, newValue) => {
        setPrice(newValue);
    };

    const [formData, setFormData] = React.useState({
        name: "",
        phone: "",
        home_address: "",
        house_type: "",
        // design_style: "",
        // budget: "",
    });

    const names = [
        'Minimalist',
        'Modern Contemporary',
        'Modern Classic',
        'Urban',
        'Muji',
        'Industrial',
        'Zen',
    ];

    const [designStyle, setDesignStyle] = React.useState([]);

    const handleDesignChange = (event) => {
        const {
            target: { value },
        } = event;
        setDesignStyle(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleFormChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });

    };

    const handleForm = () => {
        if (!formData.name) {
            alert("Please fill Name.");
            return;
        }
        if (!formData.phone) {
            alert("Please fill Phone Number")
            return;
        }
        if (!formData.home_address) {
            alert("Please fill Email")
            return;
        }
        if (!formData.house_type) {
            alert("Please select House Type")
            return;
        }

            contact_form({ ...formData, budget: price, design_style: designStyle }).then((res) => {
                if (res.status) {
                    toast("Form Submitted");
                    window.location = "/";
                } else {
                    toast("Please Submit Again");
                }
            });
    };

    return (
        <React.Fragment>

            <Box className={classes.thePadding}>
                <Container>
                    <Box style={{ padding: "2rem" }}>
                        <div>
                            <h2>Requirement Form</h2>
                        </div>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <p>Name(Mr/Mrs/Madam/Miss):</p>
                                <TextField
                                    className="theHome"
                                    id="outlined-basic"
                                    name="name"
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <p>Phone Number:</p>
                                <TextField
                                    type="number"
                                    className="theHome"
                                    id="outlined-basic"
                                    name="phone"
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <p>Email:</p>
                                <TextField
                                    className="theHome"
                                    id="outlined-basic"
                                    name="home_address"
                                    onChange={handleFormChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <p>House Type:</p>
                                <FormControl fullWidth>
                                    <Select
                                        name="house_type"
                                        onChange={handleFormChange}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        <MenuItem value="Studio">Studio</MenuItem>
                                        <MenuItem value="Apartment - 2 rooms">Apartment - 2 rooms</MenuItem>
                                        <MenuItem value="Apartment - 3 rooms">Apartment - 3 rooms</MenuItem>
                                        <MenuItem value="Town house">Town house</MenuItem>
                                        <MenuItem value="Double storey terrace house">Double storey terrace house</MenuItem>
                                        <MenuItem value="Double storey house corner or end lot">Double storey house corner or end lot</MenuItem>
                                        <MenuItem value="Three storey terrace house">Three storey terrace house</MenuItem>
                                        <MenuItem value="Cluster House">Cluster House</MenuItem>
                                        <MenuItem value="Land to build">Land to build</MenuItem>
                                        <MenuItem value="Semi-Detached">Semi-Detached</MenuItem>
                                        <MenuItem value="Bungalow">Bungalow</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <p>Design Style:</p>
                                <FormControl fullWidth>
                                    <Select
                                        name="design_style"
                                        onChange={handleDesignChange}
                                        // multiple
                                        value={designStyle}
                                    >
                                        {names.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <p
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Budget RM {price[0]} - {price[1]}

                                </p>
                                <Slider
                                    name="budget"
                                    value={price}
                                    onChange={handleChangePrice}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"
                                    min={10000}
                                    max={1000000}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Button
                                    label="SUBMIT"
                                    color="#FFF"
                                    bg="#2f8dcd"
                                    pd=".7rem 1rem .6rem"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleForm()}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box >

        </React.Fragment>
    )
}
