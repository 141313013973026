import "./styles.css";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo_long from "../../assets/images/logo_long.png";
import useUser from "../../hooks/useUser";
import { Fab, Container, Drawer, Button } from "@material-ui/core";
import React from "react";
import {
  Close,
  MenuBookRounded,
  MenuOutlined,
  Message,
  ShoppingCartOutlined,
} from "@material-ui/icons";

import SearchIcon from "@mui/icons-material/Search";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MessageDetails from "./MessageDetails";
import {
  get_messages,
  get_message_details,
  get_user_profile,
} from "../../api/API";
import useCart from "../../hooks/useCart";
import { BadgeCount, NavbarIconLink } from "./styles";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';

const Header = (props) => {
  const history = useHistory();
  const { user, get_user, set_user } = useUser();
  const [toggleDrawer, setToggleDrawer] = React.useState(false);
  const [showMessageDetail, setShowMessageDetail] = React.useState(false);
  const [toggleChat, setToggleChat] = React.useState(false);
  const [target, setTarget] = React.useState("");
  const [messageData, setMessageData] = React.useState([]);
  const [messageTimer, setMessageTimer] = React.useState(null);
  const [messageDetailsTimer, setMessageDetailsTimer] = React.useState(null);

  const [messages, setMessages] = React.useState([]);
  const [userChatId, setUserChatId] = React.useState("");
  console.log(showMessageDetail);
  const handleLoadMessage = (row) => {
    var user_chat_id = row.user_chat_id;

    if (row.user_1 != user.user_id) {
      setTarget(row.user_1);
    } else {
      setTarget(row.user_2);
    }
    setUserChatId(user_chat_id);

    get_message_details(user_chat_id).then((res) => {
      if (res.status) {
        setMessageData(res.data);
        var timer = setInterval(() => {
          get_message_details(user_chat_id).then((res) => {
            if (res.status) {
              setMessageData(res.data);
            }
          });
        }, 3000);

        setMessageDetailsTimer(timer);
      }
    });
    setShowMessageDetail(true);
  };
  const closeMessages = () => {
    setToggleChat(false);
    closeMessageDetails();
  };
  const get_data = () => {
    if (user) {
      get_messages().then((res) => {
        if (res.status) {
          setMessages(res.data);
        }

      }, []);
    }
  };
  const closeMessageDetails = () => {
    var timer = messageDetailsTimer;
    clearInterval(timer);
    setMessageDetailsTimer(null);
    setShowMessageDetail(false);
  };
  React.useEffect(() => {
    if (messageTimer == null) {
      // var timer = setInterval(() => {
      get_data();
      // }, 3000);
      // setMessageTimer(timer);
    }
    if (user != null) {
      getProfile();
    }
    // getProfile()
  }, []);

  const getProfile = () => {
    // console.log(user)
    get_user_profile({}).then((res) => {
      if (res.status) {
        // set_user(res.data);
      } else {
        if (res.message == "Please login again") {
          set_user(null);
          window.localStorage.clear();
          history.push("/");
        }
      }
    });
  };

  const [token, setToken] = React.useState("");

  React.useEffect(() => {
    let storedToken = window.localStorage.getItem("token");
    setToken(storedToken || "");
    console.log("CURRENT TOKEN", storedToken);
  }, []);

  // const [user, set_user] = React.useState([])

  // const getProfile = () => {
  //   get_user_profile({ token: token }).then((json) => {
  //     set_user(json.data)
  //   })
  // }

  // CART

  const { getCart, clearCart } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);


  const handleLogout = () => {
    set_user(null);
    window.localStorage.clear()
  }

  return (
    <div className="header"
      style={{
        ...props.style
      }}
    >
      <Container>
        <div className="header_content">
          <div
            className="flex_center hide_desktop"
            style={{
              marginRight: 30,
            }}
            onClick={() => {
              setToggleDrawer(true);
            }}
          >
            <MenuOutlined></MenuOutlined>
          </div>
          <div className="logo_wrapper">
            <img
              className="logo"
              src={logo_long}
              onClick={() => {
                history.push("/");
              }}
            ></img>
          </div>
          {/* {token ? (
            <NavbarIconLink href="/cart" style={{ marginLeft: "1.5rem" }} className="hide_desktop">
              <ShoppingCartOutlined style={{ color: "#000" }} />
              <BadgeCount bg="#000" color="#fff">
                {cart.length}
              </BadgeCount>
            </NavbarIconLink>
          ) : (
            <></>
          )} */}
          <div className="header_nav_wrapper flex_center hide_mobile">
            <nav className="header_nav">
              <HashLink class="flex_center_header" to="/#search">
                <div>
                  <SearchIcon />
                </div>
                <div class="dot"></div>
              </HashLink>
              <Link class="flex_center_header" to="/">
                <div>HOME</div>
                <div class="dot"></div>
              </Link>
              <Link class="flex_center_header" to="/about">
                <div>ABOUT</div>
                <div class="dot"></div>
              </Link>

              <Link class="flex_center_header" to="/allproject">
                <div>PROJECT</div>
                <div class="dot"></div>
              </Link>
              <Link class="flex_center_header" to="/marketplace">
                <div>MARKETPLACE</div>
                <div class="dot"></div>
              </Link>
              <Link class="flex_center_header" to="/news">
                <div>NEWS</div>
                <div class="dot"></div>
              </Link>

              {user ? (
                <>
                  {/* {user.user_type == '1' ? ( */}
                  <Link class="flex_center_header" to="/dashboard">
                    <div>DASHBOARD</div>
                    <div class="dot"></div>
                  </Link>
                  <Link class="flex_center_header"
                    to="/"
                    onClick={() => handleLogout()}
                  >
                    <div>LOGOUT</div>
                  </Link>
                  {/* ) : <></>} */}
                </>
              ) : (
                <>
                  <Link class="flex_center_header" to="/join">
                    <div>JOIN US</div>
                    <div class="dot"></div>
                  </Link>
                  <Link class="flex_center_header" to="/login">
                    <div>LOGIN</div>
                  </Link>
                </>
              )}
              {/* {token ? (
                <div style={{ display: "flex", marginTop: "-4px" }}>
                  <NavbarIconLink href="/cart" style={{ marginLeft: "1.5rem" }}>
                    <ShoppingCartOutlined style={{ color: "#000" }} />
                    <BadgeCount bg="#000" color="#fff">
                      {cart.length}
                    </BadgeCount>
                  </NavbarIconLink>
                </div>
              ) : (
                <></>
              )} */}

            </nav>
          </div>
        </div>
      </Container>

      <>
        <Drawer
          sx={{ width: "400px" }}
          anchor="left"
          open={toggleDrawer}
          onClose={() => {
            setToggleDrawer(false);
          }}
        >
          <div className="drawer_nav">
            <Link to="/">
              <div>Home</div>
            </Link>
            <Link to="/about">
              <div>About</div>
            </Link>

            <Link to="/allproject">
              <div>Project</div>
            </Link>
            <Link to="/marketplace">
              <div>Marketplace</div>
            </Link>
            <Link to="/news">
              <div>News</div>
            </Link>
            {user ? (
              <>
                {/* {user.user_type == '1' ? ( */}
                <Link to="/dashboard">
                  <div>Dashboard</div>
                </Link>
                {/* ) : <></>} */}
              </>
            ) : (
              <>
                <Link to="/join">
                  <div>Join Us</div>
                </Link>
                <Link to="/login">
                  <div>Login</div>
                </Link>
              </>
            )}
            <Link to="/requirementform">
              <div>Requirement Form</div>
            </Link>
          </div>

          <div style={{
            position: "absolute",
            bottom: 0,
            // backgroundColor: "gray",
            height: "50px",
            width: "100%",

          }}>
            <hr style={{ width: "100%" }} />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", padding: ".4rem" }}>
              <FacebookIcon />
              <InstagramIcon />
              <PinterestIcon />
            </div>
          </div>
        </Drawer>
      </>
      <>
        <Drawer

          anchor="right"
          open={toggleChat}
          onClose={() => {
            closeMessages();
          }}


        >
          <div className="message_drawer_wrapper">
            {!showMessageDetail ? (
              <>
                <List
                  class="message_container"
                  style={{
                    paddingLeft: 10,
                  }}
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      <Button
                        onClick={() => {
                          setToggleChat(false);
                        }}
                      >
                        <Close></Close>
                      </Button>
                      Messages
                    </ListSubheader>
                  }
                >
                  {messages.map((row) => (
                    <ListItemButton>
                      <div
                        className="message_wrapper"
                        onClick={() => {
                          handleLoadMessage(row);
                        }}
                      >
                        <p
                          style={{
                            width: " 100%",
                            fontWeight: "bold",
                            marginBottom: 4,
                          }}
                        >
                          {row.username}
                          <span
                            style={{
                              float: "right",
                              fontSize: "0.7em",
                            }}
                          >
                            {row.created_date}
                          </span>
                        </p>
                        <p
                          style={{
                            marginTop: 0,
                          }}
                        >
                          {row.last_message}
                        </p>
                      </div>
                    </ListItemButton>
                  ))}
                </List>

                <MessageDetails
                  data={messageData}
                  target={target}
                  user_chat_id={userChatId}
                  onClose={() => {
                    closeMessageDetails();
                  }}
                />
              </>
            ) : (
              <MessageDetails
                data={messageData}
                target={target}
                user_chat_id={userChatId}
                onClose={() => {
                  closeMessageDetails();
                }}
              />
            )}
          </div>
        </Drawer>
      </>
      {/* {user && (
        <Fab
          style={{
            position: "fixed",
            bottom: 20,
            right: 20,
            zIndex: 2,
          }}
          onClick={() => {
            setToggleChat(true);
          }}
        >
          <Message></Message>
        </Fab>
      )} */}
    </div>
  );
};

export default Header;
