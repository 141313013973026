import React from "react";
import useStyles, { BadgeCount } from "./styles";
import Slider from "react-slick";

import { Box, Container, Grid } from "@material-ui/core";

import banner from "../../../assets/images/banner_1.jpg";
import collection001 from "../../../assets/images/banner-1.webp";
import collection002 from "../../../assets/images/banner-2.webp";
import prod001 from "../../../assets/images/prod001.webp";
import Paragraph from "../../../components/Typography/Paragraph";

import {
  get_banner,
  get_banner_marketplace,
  get_categories,
  get_popular_products,
  get_product,
  get_products_by_category,
  get_products_by_subcategory,
} from "../../../api/API";

import FormInputNoLabel from "../../../components/FormInput/FormInputNoLabel/FormInputNoLabel";

import { ShoppingCartOutlined } from "@material-ui/icons";
import useCart from "../../../hooks/useCart";
import { useParams } from "react-router-dom";
import ".././styles.css";
export default function Collection() {
  const classes = useStyles();
  const { banner_id } = useParams();

  // SLIDER SETTING
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
  };

  const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [banners, setBanners] = React.useState([]);

  const getBanner = () => {
    get_banner().then((json) => {
      setBanners(json.data);
    });
  };

  React.useEffect(() => {
    getBanner();
  }, []);

  // CART

  const { getCart, clearCart } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  const [searchKey, setSearchKey] = React.useState("");

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
  };

  const [collection, setCollection] = React.useState([]);
  const getCollection = () => {
    get_banner_marketplace({ banner_id: banner_id }).then((json) => {
      setCollection(json.data);
    });
  };
  React.useEffect(() => {
    getCollection();
  }, []);

  return (
    <React.Fragment>
      <div style={{ padding: "7rem 0" }}>
        {window.innerWidth >= 800 ? (
          <div
            style={{
              backgroundColor: "#f0f0f0",
              padding: "1rem 0",
              position: "fixed",
              width: "100%",
              zIndex: "999",
            }}
          >
            <Container>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    position: "relative",
                  }}
                ></Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <FormInputNoLabel
                    placeholder="Search Here"
                    border="1px solid #acacac"
                    onChange={handleSearch}
                    value={searchKey}
                    bg="white"
                  />
                  <a
                    href="/cart"
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "1.5rem",
                    }}
                  >
                    <ShoppingCartOutlined style={{ color: "#000" }} />
                    <BadgeCount bg="#000" color="#fff">
                      {cart.length}
                    </BadgeCount>
                  </a>
                </div>
              </div>
            </Container>
          </div>
        ) : (
          <></>
        )}
        <div className={classes.paddingBox}>
          <Slider className={classes.root} {...settings}>
            {banners.map((item) => (
              <div className={classes.theBanner}>
                <img src={item.image} />
              </div>
            ))}
          </Slider>
        </div>
        <Box sx={{ background: "#FFF" }}>
          <Container>
            <Box sx={{ padding: "2rem 0 0 0" }}>
              <Box sx={{ padding: "0 0 2rem 0" }}>
                <Paragraph center size="400" bold="600">
                  COLLECTION
                </Paragraph>
              </Box>
            </Box>

            <Box sx={{ padding: "2rem 0 2rem 0" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    {collection ? (
                      collection.map((item) => {
                        if (searchKey != "") {
                          if (
                            item.product_name
                              .toUpperCase()
                              .indexOf(searchKey.toUpperCase()) <= -1
                          ) {
                            return;
                          }
                        }
                        return (
                          <div key={item.product_id}>
                            <div style={{ display: "flex" }}>
                              <a href={`../productdetail/${item.product_id}`}>
                                <div className={classes.theProduct}>
                                  <div className={classes.theProdImg}>
                                    <img
                                      src={item.url}
                                      alt={item.product_name}
                                    />
                                  </div>
                                  <div>
                                    <Paragraph
                                      color="#06c"
                                      bold="300"
                                      margin="0 0 .5rem 0"
                                    >
                                      {item.product_name.toUpperCase()}
                                    </Paragraph>
                                    <div className={classes.theLine}></div>
                                    <Paragraph
                                      color="#f30"
                                      margin="0 0 .6rem 0"
                                    >
                                      {item.currency} {item.price}
                                    </Paragraph>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="loading-spinner"></div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </div>
    </React.Fragment>
  );
}
