import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { md } from "../../styles/responsive";


export const BadgeCount = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: ${({ color }) => color};
  background: ${({ bg }) => bg};
  font-size: 12px;
`;

const config = {
  root: {
    position: "relative",
    height: "30rem",

    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
        display: "flex",
      },
      "& > div > div": {
        height: "30rem",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 100,
        }),

        ...md("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 100,
        }),

        ...md("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#BCBCBC",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 30,
            backgroundColor: "#BCBCBC",
          },
        },
      },
    },
  },

  root2: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",

      "& > div": {
        width: "90%",
        height: "100%",
        margin: ".5rem auto",
      },
      "& > div > div": {
        width: "90%",
        height: "100%",
        margin: "0 auto",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: -12,
        }),

        ...md("md", {
          right: -12,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#000",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: -20,
        }),

        ...md("md", {
          left: -20,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#000",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  theCardBox: {
    position: "relative",
    "& img": {
      width: "100%",
    },
  },

  theCatLink: {
    color: " #333",
    display: "block",
    fontSize: "14px",
    letterSpacing: ".4px",
    padding: "7px 20px",
    textTransform: "capitalize",
    transition: "all .3s ease 0s",
  },
  theCatLinkActive: {
    color: " #2f8dcd",
    display: "block",
    fontSize: "14px",
    letterSpacing: ".4px",
    padding: "7px 20px",
    textTransform: "capitalize",
    transition: "all .3s ease 0s",
  },
  theProdBox: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      paddingBottom: "27px",
    },
  },
  product__price: {
    textAlign: "center",
    listStyle: "none",
    padding: 0,
    margin: 0,
    justifyContent: "center",
    display: "flex",
    marginTop: "9px",
  },
  old__price: {
    display: "inline-block",
    color: "#9c9b9b",
    textDecoration: "line-through",
    paddingRight: "12px",
    fontSize: "16px",
  },
  new__price: {
    display: "inline-block",
    color: "#ff4136",
    fontSize: "16px",
  },
  theGallery: {
    position: "relative",
    width: "100%",
    marginBottom: "1rem",

    "& img": {
      width: "100%",
      display: "flex",
      height: "200px",
      objectFit: "cover"
    },
  },
  cardContainer: {
    boxShadow: "0px 0px 9px 3px rgba(0, 0, 0, 0.1)",
    padding: "1rem",
  },
  theBanner: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      display: "flex",
    },
  },
  theProduct: {
    padding: "1rem",
    height: "20rem",
    width: "14rem",
    cursor: "pointer",

    "&:hover": {
      border: "1px solid #bfbfbf"
    }
  },
  theProdImg: {
    width: "100%",
    height: "13rem",
    marginBottom: "1rem",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    }
  },
  theCategory: {
    padding: ".5rem",
    height: "17rem",
    width: "100%",
    border: "1px solid #bfbfbf"
  },
  // theCatImg: {
  //   width: "100%",
  //   height: "13rem",
  //   marginBottom: "1rem",

  //   "& img": {
  //     width: "100%",
  //     height: "100%",
  //     objectFit: "cover",
  //   }
  // },
  theCategory: {
    padding: ".5rem",
    // height: "17rem",
    width: "10rem",
    border: "1px solid #bfbfbf"
  },
  theCatImg: {
    width: "100%",
    height: "8rem",
    marginBottom: "1rem",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    }
  },
  theLine: {
    height: "1px",
    backgroundColor: "#bfbfbf",
    width: "10%",
    marginBottom: ".4rem"
  },
  paddingBox: {

    ...md("md", {
      padding: "4rem 0 0 0"
    })
  }
};

export default makeStyles(config);
