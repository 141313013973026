import React, { useState, useEffect } from "react";
import {
    Link,
    useNavigate,
    useLocation,
    useParams,
    useHistory,
} from "react-router-dom";
import { ArrowBackIos, Close } from "@mui/icons-material";
import { Box, Container, Grid } from "@material-ui/core";
import FormInput from "../../../components/FormInput/FormInput";
import Paragraph from "../../../components/Typography/Paragraph";
import useStyles from "./styles";

import Button from "../../../components/Button/Button";
import useCart from "../../../hooks/useCart";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";

import { checkout, get_profile, get_user_profile, submit_order } from "../../../api/API";

export default function BankDetails(props) {
    const classes = useStyles();
    const navigate = useHistory();

    const { addProduct, getCart, removeProduct, getTotalCart, clearCart } =
        useCart();

    const [isLoading, setIsLoading] = React.useState(false);

    // const [profile, setProfile] = React.useState([]);

    // const [bankData, setBankData] = React.useState([])

    // const getProfile = () => {
    //     get_user_profile({}).then((json) => {
    //         setProfile(json.data)
    //         setBankData(json.data.bank_account)
    //     })
    // }

    // React.useEffect(() => {
    //     getProfile()
    // }, [])

    const handleTransfer = () => {
        window.location = "/"
    }

    return (
        <React.Fragment>
            <Box className={classes.thePadding}>
                <Container>
                    <Box style={{ padding: "3rem 0" }}>
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <>
                                        <Paragraph style={{ fontSize: "1.2rem", padding: "1rem 0", fontWeight: "600" }}>
                                            Bank Details
                                        </Paragraph>
                                        <div style={{ border: "1px solid #ccc", padding: "1rem" }}>
                                            <Paragraph style={{ fontSize: "1rem", padding: ".5rem 0" }}>
                                                Bank Name
                                            </Paragraph>
                                            <Paragraph style={{ fontSize: "1rem", fontWeight: "600", padding: "0 0 .5rem 0" }}>
                                                CIMB
                                            </Paragraph>

                                            <hr />

                                            <Paragraph style={{ fontSize: "1rem", padding: ".5rem 0 .5rem 0" }}>
                                                Bank Account Number
                                            </Paragraph>
                                            <Paragraph style={{ fontSize: "1rem", fontWeight: "600" }}>
                                                76882123
                                            </Paragraph>
                                        </div>
                                        <div style={{ padding: "1rem 0" }}>
                                            <Button
                                                label="DONE"
                                                color="#fff"
                                                bg="#2f8dcd"
                                                br="6px"
                                                pd=".6rem 1rem"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleTransfer()}
                                            />
                                        </div>

                                    </>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>

                                    <div className={classes.theFlex}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            <Paragraph>Product</Paragraph>
                                        </div>
                                        <Paragraph
                                            style={{ textAlign: "center", width: "25%" }}
                                        >
                                            Quantity
                                        </Paragraph>
                                        <Paragraph
                                            style={{ textAlign: "center", width: "25%" }}
                                        >
                                            Price
                                        </Paragraph>
                                        <a
                                            // onClick={() => removeProduct(res)}
                                            style={{ display: "block", marginLeft: "1rem" }}
                                        >
                                            <Close sx={{ color: "#fff" }} />
                                        </a>
                                    </div>

                                    {getCart().length > 0 &&
                                        getCart().map((res) => (
                                            <div className={classes.theFlex}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <div className={classes.theProdIMG}>
                                                        <img src={res.images} />
                                                    </div>
                                                    <Paragraph>{res.name}</Paragraph>
                                                </div>
                                                <Paragraph
                                                    style={{ textAlign: "center", width: "25%" }}
                                                >
                                                    {res.quantity}
                                                </Paragraph>
                                                <Paragraph
                                                    style={{ textAlign: "right", width: "25%" }}
                                                >
                                                    MYR {parseFloat(res.price).toFixed(2)}
                                                </Paragraph>
                                            </div>
                                        ))}
                                    {/* <div className={classes.theFlex_}>
												<div className={classes.theWidth}>
													<FormInput
														placeholder="Promo Code"
														border="1px solid #707070"
														style={{ width: "100%" }}
													/>
												</div>

												<Button label="APPLY" color="#fff" bg="#D5AE35" br="6px" />
											</div> */}

                                    {/* <div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													marginBottom: "1rem",
													paddingTop: "1rem",
													borderTop: "1px solid #d7d7d7",
												}}
											>
												<Paragraph size="1.2rem">Subtotal</Paragraph>
												<Paragraph bold="500" size="1.2rem">
													RM {getTotalCart()}
												</Paragraph>
											</div>

											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													marginBottom: "1rem",
												}}
											>
												<Paragraph size="1.2rem">Shipping Fee</Paragraph>
												<Paragraph bold="500" size="1.2rem">
													RM 0.00
												</Paragraph>
											</div> */}
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginBottom: "1rem",
                                            paddingTop: "1rem",
                                            borderTop: "1px solid #d7d7d7",
                                        }}
                                    >
                                        <Paragraph style={{ fontSize: "1.2rem" }}>
                                            Total
                                        </Paragraph>
                                        <Paragraph
                                            color="#000"
                                            bold="700"
                                            style={{ fontSize: "1.2rem" }}
                                        >
                                            MYR {getTotalCart().toFixed(2)}
                                        </Paragraph>
                                    </div>
                                    <br />

                                    {isLoading ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                // height: "600px",
                                            }}
                                        >
                                            <Loader
                                                type="snipper-default"
                                                bgColor={"#878787"}
                                                size={30}
                                            />
                                        </div>
                                    ) : (
                                        <></>

                                        // <div className={classes.theFlexx}>
                                        //     <div className={classes.theRight}>
                                        //         <Button
                                        //             href="/cart"
                                        //             label="Edit Cart"
                                        //             br="4px"
                                        //             color="#2f8dcd"
                                        //             bd="1px solid #2f8dcd"
                                        //             pd=".6rem 1rem"
                                        //             style={{ cursor: "pointer" }}
                                        //         />
                                        //     </div>
                                        //     <div>
                                        //         <Button
                                        //             label="Pay Now"
                                        //             color="#fff"
                                        //             bg="#2f8dcd"
                                        //             br="6px"
                                        //             pd=".6rem 4rem"
                                        //             style={{ cursor: "pointer" }}
                                        //             onClick={() => handlePurchase()}
                                        //         />
                                        //     </div>
                                        // </div>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    );
}
