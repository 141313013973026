import React from 'react'
import useStyles from "./styles"
import {
    InputAdornment, TextField,
    MenuItem
} from "@material-ui/core"
import Paragraph from '../../../components/Typography/Paragraph'
import EditorToolbar, { modules, formats } from "../CreateProject/EditorToolbar"
import ReactQuill from 'react-quill'
import { AddCircleOutline, DeleteOutlineRounded } from "@material-ui/icons";
import { add_product, get_categories, get_product, get_products_by_category } from '../../../api/API'
import { useHistory } from 'react-router-dom'
import { Checkbox, FormControl, Grid, InputLabel, ListItemText, OutlinedInput, Select } from '@mui/material'
import Button from '../../../components/Button/Button'
import "./styles.css"

function Product_Info() {
    const classes = useStyles()
    const history = useHistory();
    const [token, setToken] = React.useState("");

    React.useEffect(() => {
        let token = window.localStorage.getItem("token");
        setToken(token || "");
        console.log("CURRENT TOKEN", token);
    }, []);

    const [form, setForm] = React.useState({
        product_name: "",
        description: "",
        subcategory_ids: [],
        commission_referral: "",
        price: "",
        quantity: "",
        variant_name: "",
    })

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };
    const handleChangeDescription = (e) => {
        setForm({
            ...form,
            description: e
        });
    };

    const [images, setImages] = React.useState([])

    const handleImageChange = (e) => {
        const selectedImages = Array.from(e.target.files);
        setImages((prevImages) => [...prevImages, ...selectedImages]);
    };

    const handleDeleteImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const [isLoading, setIsLoading] = React.useState(false)

    const [hasVariant, setHasVariant] = React.useState(0);

    function mapDataToVariants(inputSets) {
        const variants = [];

        // Populate the variants array with individual variants
        inputSets.forEach((inputSet) => {
            variants.push({
                // variant_name: form.variant_name,
                name: inputSet.subVariant,
                price: inputSet.variantPrice,
                commission_referral: inputSet.variantCommission,
            });
        });
        return variants;
    }

    // console.log(mapDataToVariants(inputSets));

    const handleSubmit = () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true)

        if (!form.product_name ||
            !form.description ||
            !form.subcategory_ids ||
            !form.commission_referral ||
            !form.price ||
            !images
        ) {
            alert("Fields cannot be empty");
            setIsLoading(false)
            return;
        }

        const formData = new FormData();

        formData.append("product_name", form.product_name);
        formData.append("description", form.description);
        formData.append("subcategory_ids", form.subcategory_ids);
        formData.append("commission_referral", form.commission_referral);
        formData.append("price", form.price);
        formData.append("quantity", form.quantity);

        images.forEach((image, index) => {
            formData.append(`image_${index}`, image);
        })


        formData.append("use_variants", hasVariant);

        if (hasVariant) {
            formData.append("variant_name", form.variant_name);
            const variantsData = mapDataToVariants(inputSets);

            // const variants = {
            //     variant_name: form.variant_name,
            //     sub_variant: [],
            //   };

            //   variantsData.forEach((variant) => {
            //     variants.sub_variant.push({
            //       name: variant.subVariant,
            //       price: variant.price,
            //     });
            //   });
              formData.append("variants", JSON.stringify(variantsData));

              // Now you can use the 'variants' array as needed
              console.log(variantsData);

            // Other submission logic
        }


        // formData.append("variant_name", form.variant_name);
        // inputSets.forEach((inputSet, inputSetIndex) => {
        //     formData.append(`sub_variant[${inputSetIndex}][name]`, inputSet.subVariant);
        //     formData.append(`sub_variant[${inputSetIndex}][price]`, inputSet.variantPrice);
        //     formData.append(`sub_variant[${inputSetIndex}][commission]`, inputSet.variantCommission);
        //     formData.append(`sub_variant[${inputSetIndex}][stocks]`, inputSet.variantStocks);

        // });



        console.log(formData)

        const headers = {
            Authorization: `Bearer ${token}`
        };

        add_product(formData, headers).then((json) => {
            if (json.status) {
                alert("submitted");
                setIsLoading(false)
                history.push("/dashboard/products")
            } else {
                alert(json.message)
                setIsLoading(false)
                window.location.reload();
            }
        });
    }


    const [categories, setCategories] = React.useState([])

    const getCategories = () => {
        get_categories({}).then((json) => {
            setCategories(json.data)
        })
    }

    const [selectedCategoryId, setSelectedCategoryId] = React.useState(""); // Step 1
    const [subcategories, setSubcategories] = React.useState([]); // Step 1

    const handleChooseCategory = (event) => {
        const categoryId = event.target.value;
        setSelectedCategoryId(categoryId);

        // Find the selected category from the categories array
        const selectedCategory = categories.find((category) => category.category_id === categoryId);

        // Update the subcategories state with the subcategories of the selected category
        setSubcategories(selectedCategory ? selectedCategory.subcategories : []);
    };


    // const [chooseCat, setChooseCat] = React.useState(0)
    const [chooseSubCat, setChooseSubCat] = React.useState(0)


    React.useEffect(() => {
        getCategories()
    }, [])

    const handleChangeCategory = (e) => {
        const { name, value, checked } = e.target;

        if (name === "category_ids") {
            if (checked) {
                setForm({
                    ...form,
                    category_ids: [...form.category_ids, value]
                });
            } else {
                setForm({
                    ...form,
                    category_ids: form.category_ids.filter((categoryId) => categoryId !== value)
                });
            }
        } else {
            setForm({
                ...form,
                [name]: value
            });
        }
    };

    const [personName, setPersonName] = React.useState([]);

    const handleChang = (event) => {
        const {
            target: { value },
        } = event;
        setChooseSubCat(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const ITEM_HEIGHT = 40;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const names = [
        'Oliver Hansen',
        'Van Henry',
        'April Tucker',
        'Ralph Hubbard',
        'Omar Alexander',
        'Carlos Abbott',
        'Miriam Wagner',
        'Bradley Wilkerson',
        'Virginia Andrews',
        'Kelly Snyder',
    ];


    const [inputSets, setInputSets] = React.useState([
        { subVariant: '', variantPrice: '', variantCommission: '', variantStocks: '', },
    ]);

    const handleAddInputSet = () => {
        setInputSets([...inputSets, { subVariant: '', variantPrice: '', variantCommission: '', variantStocks: '', }]);
    };

    const handleChangeVariant = (index, field, value) => {
        const updatedInputSets = [...inputSets];
        updatedInputSets[index][field] = value;
        setInputSets(updatedInputSets);
        // setHasVariant(1)
    };

    const handleVariantChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        setHasVariant(1)
    }


    const [variantImages, setVariantImages] = React.useState([])

    const handleImageVariantChange = (e, inputSetIndex) => {
        const selectedVariantImages = Array.from(e.target.files);
        setVariantImages((prevVariantImages) => {
            const updatedImages = [...prevVariantImages];
            updatedImages[inputSetIndex] = selectedVariantImages;
            console.log("working")
            console.log("iamges", variantImages)

            // Replace the entire array with selected images
            return updatedImages;
        });
    };

    const handleDeleteVariantImage = (index) => {
        setVariantImages((prevVariantImages) => prevVariantImages.filter((_, i) => i !== index));
    };


    return (
        <div>
            <h3>Create A New Product</h3>
            <TextField
                label="Product Name"
                margin="dense"
                name="product_name"
                variant="outlined"
                style={{
                    width: "100%"
                }}
                value={form.product_name}
                onChange={handleChange}
            ></TextField>

            <Paragraph margin="1rem 0">
                Describe this Product
            </Paragraph>
            <div>
                <EditorToolbar />
                <ReactQuill
                    style={{ color: "#ffffff", height: "120px" }}
                    theme="snow"
                    name="description"
                    onChange={handleChangeDescription}
                    modules={modules}
                    formats={formats}
                />
            </div>
            <br />

            <TextField
                label="Category"
                margin="dense"
                name="category_ids"
                select
                multiple
                value={selectedCategoryId}
                onChange={handleChooseCategory}
                variant="outlined"
                style={{
                    width: "100%",
                }}
            >
                <MenuItem value="">Select Category</MenuItem>
                {categories.map((item) => (
                    <MenuItem key={item.category_id} value={item.category_id}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>

            <FormControl
                variant="outlined"
                margin="dense"
                style={{
                    width: '100%',
                    marginTop: '16px',
                }}
            >
                <InputLabel id="subcategory-label">Subcategories</InputLabel>
                <Select
                    labelId="subcategory-label"
                    id="subcategory-select"
                    multiple
                    value={form.subcategory_ids}
                    onChange={(event) => setForm({ ...form, subcategory_ids: event.target.value })}
                    label="Subcategories"
                >
                    {/* Optionally, you can add an option for selecting all subcategories */}
                    {/* <MenuItem value="all">Select All Subcategories</MenuItem> */}
                    {subcategories.map((subcategory) => (
                        <MenuItem key={subcategory.subcategory_id} value={subcategory.subcategory_id}>
                            {subcategory.subcategory_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* <div style={{ margin: "0 0 1rem 0" }}>
                <Paragraph margin="1rem 0">Categories</Paragraph>
                <Grid container spacing={3}>
                    {categories.map((item) => (
                        <Grid item xs={4} sm={3} md={3}>
                            <div key={item.category_id}>
                                <label>
                                    <input
                                        type="checkbox"
                                        name="category_ids"
                                        value={item.category_id}
                                        checked={form.category_ids.includes(item.category_id)}
                                        onChange={handleChangeCategory}
                                    />
                                    {item.name}
                                </label>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div> */}

            {/* <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label">Sub Category</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={form.subcategory_ids}
                    onChange={handleChooseSubCat}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    style={{ width: "100%" }}
                >
                    {categories.map((category) => (
                        <div key={category.category_id}>
                            {chooseCat == category.category_id && category.subcategories.length > 0 && (
                                <>
                                    {category.subcategories.map((sub) => (
                                        <MenuItem key={sub.subcategory_id} value={sub.subcategory_id}>
                                            <Checkbox checked={form.subcategory_ids.includes(sub.subcategory_id)} />
                                            <ListItemText primary={sub.subcategory_name} />
                                        </MenuItem>
                                    ))}
                                </>
                            )}

                        </div>
                    ))}
                </Select>
            </FormControl> */}

            {/* <TextField
                label="Quantity"
                margin="dense"
                name="quantity"
                value={form.quantity}
                onChange={handleChange}
                type="number"
                variant="outlined"
                style={{
                    width: "100%"
                }}
            ></TextField> */}

            <TextField
                label="Commission"
                margin="dense"
                name="commission_referral"
                InputProps={{
                    endAdornment: <InputAdornment position="end">MYR</InputAdornment>
                }}
                value={form.commission_referral}
                onChange={handleChange}
                type="number"
                variant="outlined"
                style={{
                    width: "100%"
                }}
            ></TextField>
            <TextField
                label="Price"
                margin="dense"
                name="price"
                InputProps={{
                    endAdornment: <InputAdornment position="end">MYR</InputAdornment>
                }}
                value={form.price}
                onChange={handleChange}
                type="number"
                variant="outlined"
                style={{
                    width: "100%"
                }}
            ></TextField>

            <div>
                <Paragraph margin="1rem 0">
                    Add images to this Product
                </Paragraph>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {
                        images.map((image, index) => (
                            <div className={classes.imageCenter}>
                                <img
                                    key={index}
                                    src={URL.createObjectURL(image)} // Use createObjectURL to display the selected image
                                    alt={`Selected ${index + 1}`}
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                                <Button
                                    label="REMOVE"
                                    color="#c70000"
                                    br="5px"
                                    bg="#fff"
                                    margin=".4rem 0"
                                    pd=".7rem 1rem .6rem"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDeleteImage(index)}
                                />
                            </div>
                        ))
                    }
                    <label for="upload" className={classes.badgeCenter}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        >
                            <AddCircleOutline />
                        </div>
                    </label>
                </div>
                <input type="file"
                    id="upload" name="upload" multiple
                    style={{
                        opacity: 0
                    }}
                    onChange={handleImageChange}
                />
            </div>
            <div>
                <Paragraph margin="1rem 0 0 0">
                    Variant
                </Paragraph>
                <TextField
                    label="Variant"
                    margin="dense"
                    name="variant_name"
                    variant="outlined"
                    style={{
                        width: "100%"
                    }}
                    value={form.variant_name}
                    onChange={handleVariantChange}
                ></TextField>
            </div>
            <div>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "1rem 0 0 0"
                }}>
                    {/* <Button
                // onClick={handleSave}
                >Save As Draft</Button> */}
                    <Button
                        label="Add Sub Variant"
                        color="#FFF"
                        br="5px"
                        bg="#2f8dcd"
                        pd=".7rem 1rem .6rem"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleAddInputSet()}
                    />
                </div>
                {inputSets.map((inputSet, inputSetIndex) => (
                    <>
                        <div style={{ margin: "1rem 0" }} key={inputSetIndex}>
                            <TextField
                                label="Sub Variant"
                                margin="dense"
                                name="sub_variant"
                                variant="outlined"
                                style={{ width: "100%" }}
                                value={inputSet.subVariant}
                                onChange={(e) => handleChangeVariant(inputSetIndex, 'subVariant', e.target.value)}
                            />
                            <TextField
                                label="Variant Price"
                                margin="dense"
                                name="price_variant"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MYR</InputAdornment>
                                }}
                                variant="outlined"
                                style={{ width: "100%" }}
                                value={inputSet.variantPrice}
                                onChange={(e) => handleChangeVariant(inputSetIndex, 'variantPrice', e.target.value)}
                            />
                            <TextField
                                label="Variant Commission"
                                margin="dense"
                                name="commission_variant"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MYR</InputAdornment>
                                }}
                                variant="outlined"
                                style={{ width: "100%" }}
                                value={inputSet.variantCommission}
                                onChange={(e) => handleChangeVariant(inputSetIndex, 'variantCommission', e.target.value)}
                            />
                            {/* <TextField
                                label="Variant Stocks"
                                margin="dense"
                                name="stocks_variant"
                                variant="outlined"
                                style={{ width: "100%" }}
                                value={inputSet.variantStocks}
                                onChange={(e) => handleChangeVariant(inputSetIndex, 'variantStocks', e.target.value)}
                            /> */}
                        </div>
                        {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {variantImages[inputSetIndex] && variantImages[inputSetIndex].map((image, index) => (
                                <div key={index} className={classes.imageCenter}>
                                    <img
                                        src={URL.createObjectURL(image)}
                                        alt={`Selected ${index + 1}`}
                                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                    />
                                    <Button
                                        label="REMOVE"
                                        color="#c70000"
                                        br="5px"
                                        bg="#fff"
                                        margin=".4rem 0"
                                        pd=".7rem 1rem .6rem"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleDeleteVariantImage(inputSetIndex, index)}
                                    />
                                </div>
                            ))}
                            <label for={`upload_subvariant_${inputSetIndex}`} className={classes.badgeCenter}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    <AddCircleOutline />
                                </div>
                            </label>
                            <input
                                type="file"
                                id={`upload_subvariant_${inputSetIndex}`}
                                name={`upload_subvariant_${inputSetIndex}`}
                                multiple
                                style={{
                                    opacity: 0
                                }}
                                onChange={(e) => handleImageVariantChange(e, inputSetIndex)}
                            />
                        </div> */}
                    </>
                ))}
            </div>



            {isLoading ? (
                <div className="loading-spinner1"></div>
            ) : (
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "1rem 0"
                }}>
                    {/* <Button
                // onClick={handleSave}
                >Save As Draft</Button> */}
                    <Button
                        label="SUBMIT"
                        color="#FFF"
                        br="5px"
                        bg="#2f8dcd"
                        pd=".7rem 1rem .6rem"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSubmit()}
                    />
                </div>

            )
            }


        </div >
    )
}

export default Product_Info