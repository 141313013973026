import './styles.css';
import React from 'react';
import {
    Breadcrumbs,
    Grid,
    Select,
    MenuItem,
    TextField,
    FormControl
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import House1 from '../../assets/images/housedetail.jpg';
import logo_long from '../../assets/images/logo_long.png'
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import HttpsIcon from '@material-ui/icons/Https';
import { get_user, get_user_profile, projects_by_user, get_enquiry, send_message } from '../../api/API';
import DesignItem from '../../components/design_item/design_item';
import useUser from '../../hooks/useUser';
import GoogleHeader from '../../components/GoogleHeader';

const Designer = (props) => {

    const [property_type, setProperty_type] = React.useState(1);
    const [property_status, setProperty_status] = React.useState(1);
    const [key, setKey] = React.useState(1);
    const { user } = useUser();

    const [profile, setProfile] = React.useState(null);
    const [projects, setProjects] = React.useState([]);
    React.useEffect(() => {
        var user_id = props.match.params.designer_id;

        get_data(user_id);
        console.log("UISER", user);
    }, []);
    const get_data = (user_id) => {
        get_user(user_id).then((res) => {
            setProfile(res.data);
        });

        projects_by_user(user_id).then((res) => {
            setProjects(res.data);
        });
    }
    const openProject = (project_id) => {
        props.history.push("/project/" + project_id);
    }
    const [enquiryData, setEnquiryData] = React.useState("");

    const handleChange = (e) => {
        setEnquiryData(e.target.value);
    };

    const handleSubmit = () => {


        console.log(enquiryData)
        send_message({
            text: enquiryData,
            target: props.match.params.designer_id
        }).then((json) => {
            if (json.status) {
                alert('Enquiry Sent')
                window.location.reload();
            }
        });
    };

    return (
        <div>
            <GoogleHeader />
            {profile && (

                <div className="c-Project">
                    <div className="page_padding">
                        <div className="c-pHeaderBOX">

                            <div className="c-plHeader">
                                <div className="c-logo">
                                    <img src={profile.image} />
                                </div>
                                <div className="c-projectName">
                                    <h1 className="c-name">{profile.company_name}</h1>
                                    {/* <div className="c-addressFlex">
                                        <LocationOnIcon className="c-addressIcon" />
                                        <p className="c-address">
                                            {profile.address}
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="c-Projectsection">
                            <div className="c-psLeft">
                                {/* <div className="c-psbRelative">
                                    <div className="c-psBanner">
                                        <img src={House1} />
                                    </div>
                                    <div className="c-overlayText">
                                        <h3 className="c-name">KKK Resident</h3>
                                        <p className="c-subname">
                                            Condo - Lorem Ipsum
                                        </p>
                                    </div>
                                </div> */}
                                <p className="hide_mobile">Recent works</p>
                                <h3 className="hide_desktop" style={{
                                    textAlign: "center",
                                    margin: 0
                                }}>
                                    Recent Works</h3>
                                <div className="design_container">
                                    {
                                        projects.map(project => (
                                            <DesignItem
                                                onClick={() => { openProject(project.project_id) }}
                                                project={project}
                                                images={project.images}
                                                name={project.title}
                                                designer={project.user.company_name}
                                                designerarray={project.user.image}
                                            ></DesignItem>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Designer;