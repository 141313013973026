import React, { useEffect } from "react";
import useStyles, { CarouselContainer, CarouselIMG } from "./styles";
import Slider from "react-slick";

import { Box, Container, Grid } from "@material-ui/core";

import banner from "../../../assets/images/banner_1.jpg";
import Paragraph from "../../../components/Typography/Paragraph";
// import collection001 from "../../assets/images/banner-1.webp";
// import collection002 from "../../assets/images/banner-2.webp";
import prod001 from "../../../assets/images/prod001.webp";
import {
  Add,
  ArrowForward,
  CompareArrows,
  LocalShipping,
  Remove,
} from "@material-ui/icons";
import { Shield } from "@mui/icons-material";
import FormInputNoLabel from "../../../components/FormInput/FormInputNoLabel/FormInputNoLabel";
import Button from "../../../components/Button/Button";
import useCart from "../../../hooks/useCart";
import { useHistory, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import {
  get_product,
  get_product_details,
  get_seller_profile,
  variant_detail,
} from "../../../api/API";
import { ITEMS } from "../ITEMS";

export default function ProductDetail(props) {
  const classes = useStyles();
  const navigate = useHistory();
  let { product_id } = useParams();

  const settings1 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // SLIDER SETTING
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };

  // LOADING SCREEN

  const [isLoading, setIsLoading] = React.useState(true);

  // CHANGE TABS
  const [tabs, setTabs] = React.useState(0);

  const handleChangeTab = (txt) => {
    setTabs(txt);
  };

  // COUNT QUANTITY
  const [count, setCount] = React.useState(1);

  const handleChangeQty = (new_qty) => {
    if (new_qty < 1) {
      return;
    }
    setCount(new_qty);
  };

  // CHANGE PHOTO
  const [tabsPhoto, setTabsPhoto] = React.useState(0);

  const handleChangeTabPhoto = (text) => {
    setTabsPhoto(text);
  };

  // PRODUCT DETAIL
  const [product, setProduct] = React.useState([]);

  const getProduct = () => {
    // get_product({ product_id: product_id }).then((json) => {
    //   setProduct(json.data);
    // });
  };

  React.useEffect(() => {
    getProduct();
  }, []);

  // HANDLE ADD TO CART
  const { addProduct, getCart } = useCart();

  const [cart, setCart] = React.useState(getCart);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  const [token, setToken] = React.useState("");

  React.useEffect(() => {
    const storedToken = window.localStorage.getItem("token");
    setToken(storedToken || "");
  }, []);

  const handleAddToCart = () => {
    // if (!token) {
    //   navigate.push("/login")
    // } else {

    let price = selectedVariant ? getPriceForSelectedVariant(selectedVariant) : productDetail.price;

    let arr = {
      stock: productDetail.stock,
      name: productDetail.product_name,
      single_price: price,
      price: productDetail.price,
      quantity: count,
      images: productDetail.images.find(
        (image) =>
          image.product_image_id === productDetail.thumbnail_image_id
      )?.url,
      product_id: productDetail.product_id,
      product_variant_id: selectedVariant ? selectedVariant : "No variant",
    };
    addProduct(arr);
    navigate.push("/cart");
    // }
  };


  // React.useEffect(() => {
  //   setTimeout(() => setIsLoading(false), 1000);
  // }, []);

  const [productDetail, setProductDetail] = React.useState({}); // Initialize as an empty object

  const [variantName, setVariantName] = React.useState({
    variant_category: ''
  })
  const [productVariant, setProductVariant] = React.useState([])
  const [variantId, setVariantId] = React.useState([])

  const getProductDetail = () => {
    var id = props.match.params.product_id;
    get_product_details(id).then((json) => {
      console.log("Received data:", json);
      if (json.status && typeof json.data === "object") {
        console.log("Seller user ID:", json.data.seller_user_id);
        // setVariantName(json.data.variants[0])
        setProductDetail(json.data);
        // setUserId(json.data.seller_user_id)
        getSellerProfile(json.data.seller_user_id);
        setProductVariant(json.data.variants)


        if (json.data.variants && json.data.variants.length > 0) {
          const productVariantId = json.data.variants[0].product_variant_id;
          setVariantId(productVariantId);
          // setSelectedVariant(productVariantId);

          console.log("product_variant_id", productVariantId);
        } else {
          console.error("No variants found for this product.");
        }

        if (json.data.variants && json.data.variants.length > 0) {
          setVariantName(json.data.variants[0]);
        }
      } else {
        console.error("Invalid data:", json);
      }
    });
  };

  // console.log("name", variantName)

  const [sellerProfile, setSellerProfile] = React.useState([]);

  // const getSellerProfile = (userId) => {
  //   get_seller_profile({ user_id: userId }).then((json) => {
  //     setSellerProfile(json.data)
  //   })
  // }

  const getSellerProfile = (userId) => {
    // var userId = productDetail.seller_user_id;
    get_seller_profile(userId).then((json) => {
      console.log("Received data:", json);
      if (json.status && typeof json.data === "object") {
        setSellerProfile(json.data);
      } else {
        console.error("Invalid data:", json);
      }
    });
  };

  React.useEffect(() => {
    getProductDetail();
    // getSellerProfile()
  }, []);

  React.useEffect(() => {
    if (productDetail.seller_user_id) {
      getSellerProfile(productDetail.seller_user_id);
    }
  }, [productDetail]);

  const getImageByThumbnailImageId = () => {
    if (productDetail.images && Array.isArray(productDetail.images)) {
      return productDetail.images.find(
        (image) => image.images === productDetail.thumbnail_image_id
      );
    }
    return null;
  };

  const thumbnailImage = getImageByThumbnailImageId();

  const handleViewShop = (user_id) => {
    navigate.push(`/sellerprofile/${user_id}`);
  };

  const handleInputChange = (event) => {
    const newCount = parseInt(event.target.value, 10);
    if (!isNaN(newCount) && newCount >= 1) {
      if (newCount > productDetail.stock) {
        // Show an alert or take other appropriate action
        alert("Quantity exceeds available stock.");
      } else {
        // The value is a valid positive integer within the available stock
        setCount(newCount);
      }
    } else if (isNaN(newCount) || newCount < 1) {
      // Handle cases where the input is not a positive integer
      // You can show an error message or take other appropriate action
    }
  };

  const [selectedVariant, setSelectedVariant] = React.useState(null);
  const [variant, setVariant] = React.useState(null);

  // const getVariant = () => {
  //   if (selectedVariant) {
  //     variant_detail({ product_id: product_id, product_variant_id: selectedVariant.product_variant_id })
  //       .then((json) => {
  //         if (json.status) {
  //           setVariant(json.data);
  //         } else {
  //           console.error("Invalid data:", json);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching variant details:", error);
  //       });
  //   } else {
  //     // Handle the case when selectedVariant is null
  //     console.error("selectedVariant is null.");
  //   }
  // };

  // React.useEffect(() => {
  //   getVariant();
  // }, [product_id, selectedVariant]);

  const getPriceForSelectedVariant = (selectedVariantId) => {
    const selectedVariant = productVariant.find(variant => variant.product_variant_id === selectedVariantId);
    return selectedVariant ? selectedVariant.price : "Price information not available";
  };

  console.log("variant ID", selectedVariant)



  return (
    <React.Fragment>
      <div className={classes.thePadding}>
        {/* {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={30} />
          </div>
        ) : (
         
        )} */}
        <>
          <Box sx={{ background: "#FFF" }}>
            <Container>
              <Box style={{ padding: "4rem 0" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={5}>
                    <div className={classes.theThumbnail}>
                      {productDetail.images &&
                        productDetail.images.length > 0 && (
                          <img
                            src={
                              productDetail.images.find(
                                (image) =>
                                  image.product_image_id ===
                                  productDetail.thumbnail_image_id
                              )?.url
                            }
                            alt={`Thumbnail Image`}
                          />
                        )}
                    </div>
                    <div
                      style={{
                        overflow: "auto",
                        position: "relative",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div className={classes.theProdIMG1}>
                        <Slider className={classes.root} {...settings1}>
                          {productDetail.images &&
                            productDetail.images.map((image, index) => (
                              <CarouselContainer>
                                <CarouselIMG
                                  key={index}
                                  src={image.url}
                                  alt={`Product Image ${index}`}
                                />
                              </CarouselContainer>
                            ))}
                        </Slider>
                      </div>
                    </div>
                    <br />
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    <div>
                      <Paragraph
                        color="#444"
                        margin="0 0 1rem 0"
                        style={{ fontSize: "28px" }}
                      >
                        {productDetail.product_name}
                      </Paragraph>

                      <Paragraph
                        color="#666"
                        margin="0 0 1rem 0"
                        style={{ fontSize: "14px", lineHeight: "26px" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: productDetail.description,
                          }}
                        />
                      </Paragraph>

                      {selectedVariant ? (
                        <ul className={classes.product__price}>
                          <li className={""}>
                            <span className="money" data-currency-usd="$150.00">
                              {/* {variant && variant.currency && variant.price ? `${variant.currency} ${variant.price}` : "Price information not available"} */}
                              {getPriceForSelectedVariant(selectedVariant)}
                            </span>
                          </li>
                        </ul>
                      ) : (
                        <ul className={classes.product__price}>
                          <li className={""}>
                            <span className="money" data-currency-usd="$150.00">
                              {productDetail && productDetail.currency && productDetail.price ? `${productDetail.currency} ${productDetail.price}` : "Price information not available"}
                            </span>
                          </li>
                        </ul>
                      )}

                      <br />

                      <Box
                        sx={{
                          display: { xs: "flex", sm: "flex" },
                          alignItems: "center",
                          width: { xs: "100%", sm: "80%", md: "80%" },
                        }}
                      >
                        <Box sx={{ marginRight: "1.5rem" }}>
                          <Paragraph color="#666" style={{ fontSize: "18px" }}>
                            Quantity:{" "}
                          </Paragraph>
                        </Box>
                        <Box sx={{ width: "40%" }}>
                          <FormInputNoLabel
                            onChange={handleInputChange}
                            textLabelColor="#707070"
                            type="number"
                            value={count}
                            size="14px"
                            br="50px"
                            pd=".6rem 1rem"
                            mb="0"
                            center
                            sx={{
                              width: { xs: "100%", sm: "50%", md: "30%" },
                            }}
                            InputProps={{
                              startAdornment: (
                                <Remove
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => handleChangeQty(count - 1)}
                                  style={{ cursor: "pointer" }}
                                />
                              ),
                              endAdornment: (
                                <Add
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => handleChangeQty(count + 1)}
                                  style={{ cursor: "pointer" }}
                                />
                              ),
                            }}
                          // onChange={handleInputChange} // Add this line to handle input changes
                          />
                        </Box>
                      </Box>
                      <br />
                      <Paragraph
                        color="#666"
                        margin="0 0 1rem 0"
                        style={{ fontSize: "14px" }}
                      >
                        Availability: {productDetail.stock} stocks left
                      </Paragraph>

                      <Paragraph
                        color="#666"
                        margin="0 0 1rem 0"
                        style={{ fontSize: "14px" }}
                      >
                        Category: {variantName.variant_category}
                      </Paragraph>
                      <div style={{ display: "flex", flexWrap: "wrap", }}>
                        {productVariant.map((item) => (
                          <div style={{
                            border: "1px solid #000",
                            margin: "0 .5rem 0 0",
                            padding: ".5rem",
                            cursor: "pointer",
                            backgroundColor: selectedVariant === item.product_variant_id ? "#2f8dcd" : "#fafafa",
                            color: selectedVariant === item.product_variant_id ? "#fff" : "#000",
                          }}
                            onClick={() => setSelectedVariant(item.product_variant_id)}
                          >
                            {item.variant_name}
                          </div>
                        ))}
                      </div>



                      <br />
                      <Box sx={{ width: { xs: "100%", sm: "50%", md: "30%" } }}>
                        <Button
                          label="ADD TO CART"
                          color="#FFF"
                          bg="#2f8dcd"
                          pd=".7rem 1rem .6rem"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleAddToCart()}
                        />
                      </Box>
                      <br />
                      <br />
                      <Box>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                          <Paragraph color="#666" margin="0 1rem 0 0">
                            Category:
                          </Paragraph>
                          <Paragraph color="#666" margin="0 .4rem 0 0">
                            {productDetail.category_names}
                          </Paragraph>
                        </Box>
                        <br />
                      </Box>
                      <div style={{ background: "#f6f6f6", padding: "2rem" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                width: "5rem",
                                height: "5rem",
                                borderRadius: "999px",
                                margin: "0 1.5rem 0 0",
                              }}
                            >
                              <img
                                src={sellerProfile.image}
                                style={{
                                  width: "5rem",
                                  height: "5rem",
                                  objectFit: "cover",
                                  borderRadius: "999px",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexGrow: "1",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  margin: "0 0 .5rem 0",
                                }}
                              >
                                {sellerProfile.name}
                              </p>
                              <p
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  margin: "0",
                                }}
                              >
                                {sellerProfile.company_name}
                              </p>
                            </div>
                          </div>
                          <div>
                            <Button
                              label="VIEW SHOP"
                              color="#FFF"
                              bg="#2f8dcd"
                              pd=".7rem 1rem .6rem"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleViewShop(sellerProfile.user_id)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <br />

                <div className={classes.theTabList}>
                  <a
                    className={
                      tabs == 0 ? classes.theTabActive : classes.theTab
                    }
                    onClick={() => handleChangeTab(0)}
                  >
                    MORE INFO
                  </a>

                  <a
                    className={
                      tabs == 1 ? classes.theTabActive : classes.theTab
                    }
                    onClick={() => handleChangeTab(1)}
                  >
                    ADDITIONAL INFORMATION
                  </a>
                </div>
                {tabs == 0 && (
                  <Box sx={{ padding: "1rem 0" }}>
                    <h2
                      style={{
                        color: "#505050",
                        fontSize: "24px",
                        fontWeight: "400",
                      }}
                    >
                      Details
                    </h2>
                    <Paragraph
                      color="#666"
                      style={{ lineHeight: "26px", fontSize: "14px" }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: productDetail.description,
                        }}
                      />
                    </Paragraph>
                    <Paragraph
                      color="#666"
                      style={{ lineHeight: "26px", fontSize: "14px" }}
                    >
                      The standard chunk of Lorem Ipsum used since the 1500s is
                      reproduced below for those interested. Sections 1.10.32
                      and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero
                      are also reproduced in their exact original form,
                      accompanied by English versions from the 1914 translation
                      by H. Rackham.
                    </Paragraph>
                  </Box>
                )}
              </Box>
            </Container>
          </Box>
        </>
      </div>
    </React.Fragment>
  );
}
