import React from "react";
import useStyles, { IncrementAction } from "./styles";
import Button from "../../../components/Button/Button";
import Paragraph from "../../../components/Typography/Paragraph";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Add, Close, Mail, Phone, Remove, Room } from "@mui/icons-material";

import useCart from "../../../hooks/useCart";
// import useAuth from "../../hooks/useAuth";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "@material-ui/core";
import useUser from "../../../hooks/useUser";

export default function Cart() {
  const classes = useStyles();
  const navigate = useHistory();
  //   const { token } = useAuth();
  const { user, get_user, set_user } = useUser();
  // CART
  const {
    getCart,
    decreaseProduct,
    increaseProduct,
    removeProduct,
    getTotalCart,
  } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);

  const productCartList = getcart.replace(/\\/g, "");

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  // COUNT QUANTITY
  const [count, setCount] = React.useState(1);
  const [qtyCount, setQtyCount] = React.useState(0);

  const handlePayment = () => {
    // if (cart.length == 0) {
    //   alert("Please add an item to cart");
    // } else {
    //   if (!user) {
    //     alert("Please login to proceed");
    //     navigate.push("/login");
    //   } else {
    //     navigate.push("/payment");
    //   }
    // }
    if (cart.length == 0) {
      alert("Please add an item to cart");
    } else {
      navigate.push("/payment");
    }
  };

  let { Cart } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Cart]);

  return (
    <React.Fragment>
      <Box className={classes.thePadding}>
        <Box sx={{ padding: "4rem 0" }}>
          <Container>
            <Box
              sx={{
                background: "#FFF",
                padding: "1rem",
                border: "1px solid #d7d7d7",
                borderRadius: "4px",
              }}
            >
              {/* <Paragraph
                color="#194234"
                style={{ fontSize: "24px" }}
                bold="700"
                margin="0 0 1rem 0"
              >
                MY SHOPPING CART
              </Paragraph> */}
              <TableContainer style={{ height: "25rem", overflowY: "scroll" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "50%" }}>Product</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>Price</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        Quantity
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>Total</TableCell>
                      <TableCell sx={{ textAlign: "center" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {cart != undefined && cart.length > 0
                        ? cart.map((item, index) => (
                            <>
                              <TableRow>
                                <TableCell>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      width: {
                                        xs: "100vw",
                                        sm: "50vw",
                                        md: "100%",
                                      },
                                    }}
                                  >
                                    <div className={classes.theProdIMG}>
                                      <img src={item.images} />
                                    </div>
                                    <div style={{ marginLeft: "1rem" }}>
                                      <Paragraph
                                        margin="0 0 .2rem 0"
                                        bold="700"
                                        style={{ fontSize: "14px" }}
                                        className="theEllipsis"
                                      >
                                        {item.name}
                                      </Paragraph>
                                      <Paragraph
                                        margin="0 0 .2rem 0"
                                        style={{ fontSize: "12px" }}
                                        className="theEllipsis"
                                      >
                                        {item.brand}
                                      </Paragraph>
                                      <Paragraph
                                        margin="0 0 .2rem 0"
                                        style={{ fontSize: "12px" }}
                                        className="theEllipsis"
                                      >
                                        {item.size}
                                      </Paragraph>
                                      <Paragraph
                                        margin="0 0 .2rem 0"
                                        style={{ fontSize: "12px" }}
                                        className="theEllipsis"
                                      >
                                        {item.color}
                                      </Paragraph>
                                    </div>
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Box
                                    sx={{ width: { xs: "150px", md: "unset" } }}
                                  >
                                    <Paragraph center>
                                      MYR{" "}
                                      {parseFloat(item.single_price).toFixed(2)}
                                    </Paragraph>
                                  </Box>
                                </TableCell>

                                <TableCell>
                                  <Box
                                    sx={{ width: { xs: "150px", md: "unset" } }}
                                  >
                                    <div className={classes.theIncrement}>
                                      <IncrementAction
                                        bd="1px solid #2f8dcd"
                                        margin="0 1rem 0 0"
                                        onClick={() => {
                                          decreaseProduct(index);

                                          setQtyCount(qtyCount - 1);
                                        }}
                                      >
                                        <Remove
                                          sx={{
                                            color: "#2f8dcd",
                                            fontWeight: "600",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </IncrementAction>
                                      {count == 0 ? (
                                        <div className={classes.theBox}>
                                          <p className={classes.theCount}>0</p>
                                        </div>
                                      ) : (
                                        <div className={classes.theBox}>
                                          <p className={classes.theCount}>
                                            {item.quantity}
                                          </p>
                                        </div>
                                      )}
                                      <IncrementAction
                                        bg="#2f8dcd"
                                        margin="0 0 0 1rem"
                                        onClick={() => {
                                         
                                          if (item.quantity >= item.stock) {
                                            alert(
                                              "Quantity exceeds available stock."
                                            );
                                           return;
                                          } else {
                                            increaseProduct(index);
                                            setQtyCount(qtyCount + 1);
                                          }
                                        }}
                                      >
                                        <Add
                                          sx={{
                                            color: "#fff",
                                            fontWeight: "600",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </IncrementAction>
                                    </div>
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Box
                                    sx={{ width: { xs: "150px", md: "unset" } }}
                                  >
                                    <Paragraph center>
                                      MYR {parseFloat(item.price).toFixed(2)}
                                    </Paragraph>
                                  </Box>
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  <Close
                                    sx={{
                                      cursor: "pointer",
                                      color: "#F90000",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeProduct(index)}
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          ))
                        : null}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={7}></Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "1rem",
                      // paddingTop: "1rem",
                      // borderTop: "1px solid #d7d7d7",
                    }}
                  >
                    <Paragraph style={{ fontSize: "1.2rem" }}>
                      Subtotal
                    </Paragraph>
                    <span
                      style={{
                        color: "#194234",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                      }}
                    >
                      MYR {getTotalCart().toFixed(2)}
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <br />
            <br />
            <div className={classes.theFlexx}>
              <div className={classes.theRight}>
                <Button
                  href="/marketplace"
                  label="Continue Shopping"
                  br="4px"
                  color="#2f8dcd"
                  bd="1px solid #2f8dcd"
                  pd=".6rem 1rem"
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div>
                <Button
                  onClick={() => handlePayment()}
                  // href="/payment"
                  label="Proceed to Checkout"
                  br="4px"
                  color="#fff"
                  bg="#2f8dcd"
                  pd=".6rem 1rem"
                  hover
                  style={{ cursor: "pointer" }}
                />
                {/* <Button
                  // href="/payment"
                  onClick={() => handlePayment()}
                  label="Proceed to Checkout"
                  br="4px"
                  color="#fff"
                  bg="#2f8dcd"
                  pd=".6rem 1rem"
                  hover
                /> */}
              </div>
            </div>
          </Container>
        </Box>
      </Box>
    </React.Fragment>
  );
}
