import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Container, Grid, TextField } from "@material-ui/core";
import Icon001 from "../../assets/images/icon001.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import toast from "react-simple-toasts";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import "./Contactus.css";
import { contact_form } from "../../api/API";
import GoogleHeader from "../GoogleHeader";

export default function Contactus() {

    const [colorLike, setColorLike] = React.useState([
        { id: 1, color: "White", checked: false },
        { id: 2, color: "Black", checked: false },
        { id: 3, color: "Grey", checked: false },
        { id: 4, color: "Red", checked: false },
        { id: 5, color: "Blue", checked: false },
        { id: 6, color: "Yellow", checked: false },
        { id: 7, color: "Green", checked: false },
        { id: 8, color: "Pink", checked: false },
    ]);

    const [selectedColorLike, setselectedColorLike] = React.useState([]);

    const handlecheckbox = (index) => {
        // console.log(index, "index");
        var selected_data = [];
        var temp_data = [...colorLike];
        temp_data[index]["checked"] = !temp_data[index]["checked"];

        temp_data.map((data) => {
            if (data.checked == true) {
                selected_data.push(data.color);
            } else {
                selected_data.slice(data.color);
            }
        });
        // console.log(selected_data, temp_data);
        setColorLike(temp_data);
        setselectedColorLike(selected_data);
    };

    const [colorDisLike, setColorDisLike] = React.useState([
        { id: 1, color: "White", checked: false },
        { id: 2, color: "Black", checked: false },
        { id: 3, color: "Grey", checked: false },
        { id: 4, color: "Red", checked: false },
        { id: 5, color: "Blue", checked: false },
        { id: 6, color: "Yellow", checked: false },
        { id: 7, color: "Green", checked: false },
        { id: 8, color: "Pink", checked: false },
    ]);

    const [selectedColorDisLike, setselectedColorDisLike] = React.useState([]);

    const handlecheckboxDisLike = (index) => {
        // console.log(index, "index");
        var selected_data = [];
        var temp_data = [...colorDisLike];
        temp_data[index]["checked"] = !temp_data[index]["checked"];

        temp_data.map((data) => {
            if (data.checked == true) {
                selected_data.push(data.color);
            } else {
                selected_data.slice(data.color);
            }
        });
        // console.log(selected_data, temp_data);
        setColorDisLike(temp_data);
        setselectedColorDisLike(selected_data);
    };

    const [designArea, setDesignArea] = React.useState([
        { id: 1, area: "Living Hall", checked: false },
        { id: 2, area: "Dining Hall", checked: false },
        { id: 3, area: "Wet Kitchen", checked: false },
        { id: 4, area: "Dry Kitchen", checked: false },
        { id: 5, area: "Master bedroom", checked: false },
        { id: 6, area: "Bedroom 1", checked: false },
        { id: 7, area: "Bedroom 2", checked: false },
        { id: 8, area: "Guest Room", checked: false },
        { id: 9, area: "Study room", checked: false },
        { id: 10, area: "Music Room", checked: false },
        { id: 11, area: "Gym Room", checked: false },
    ]);

    const [selectedDesignArea, setselectedDesignArea] = React.useState([]);
    const [area, setArea] = React.useState([]);

    const handlecheckboxArea = (index) => {
        // console.log(index, "index");
        var selected_data = [];
        var temp_data = [...designArea];
        temp_data[index]["checked"] = !temp_data[index]["checked"];

        temp_data.map((data) => {
            if (data.checked == true) {
                selected_data.push(data.area);
            } else {
                selected_data.slice(data.area);
            }
        });
        // console.log(selected_data, temp_data);
        setDesignArea(temp_data);
        setselectedDesignArea(selected_data);
    };

    const [kitchen, setKitchen] = React.useState([
        { id: 1, area: "Gas Hob", checked: false },
        { id: 2, area: "Induction Hob", checked: false },
        { id: 3, area: "Chimney Hood", checked: false },
        { id: 4, area: "Island Hood", checked: false },
        { id: 5, area: "Build in Oven", checked: false },
        { id: 6, area: "Microwave Oven", checked: false },
        { id: 7, area: "2 Doors Fridge", checked: false },
        { id: 8, area: "4 Doors Fridge", checked: false },
        { id: 9, area: "Freezer", checked: false },
        { id: 10, area: "Dish Washer", checked: false },
        { id: 11, area: "Single Bowl Sink", checked: false },
        { id: 12, area: "Double Bowl Sink", checked: false },
        { id: 13, area: "Dish rack", checked: false },
    ]);

    const [selectedKitchen, setselectedKitchen] = React.useState([]);

    const handleKitchen = (index) => {
        // console.log(index, "index");
        var selected_data = [];
        var temp_data = [...kitchen];
        temp_data[index]["checked"] = !temp_data[index]["checked"];

        temp_data.map((data) => {
            if (data.checked == true) {
                selected_data.push(data.area);
            } else {
                selected_data.slice(data.area);
            }
        });
        // console.log(selected_data, temp_data);
        setKitchen(temp_data);
        setselectedKitchen(selected_data);
    };
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState("paper");

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const [formData, setFormData] = React.useState({
        email: "",
        name: "",
        status: "Single",
        nric: "",
        dob: "",
        home_address: "",
        phone: "",
        job_address: "",
        date_key: "",
        house_type: "Apartment - Studio",
        member: "",
        design_style: "Modern Contemporary",
        budget: "RM5,000.00 to RM10,000.00",
        quatation: "M&E",
        color_like: JSON.stringify(selectedColorLike),
        color_dislike: JSON.stringify(selectedColorDisLike),
        area_design: JSON.stringify(selectedDesignArea),
        living_hall: "",
        dining_hall: "",
        wet_kitchen: "",
        kitchen_appliances: JSON.stringify(selectedKitchen),
        dry_kitchen: "",
        master_badroom: "",
        bedroom_one: "",
        bedroom_two: "",
        bedroom_three: "",
        bedroom_four: "",
        guest_room: "",
        study_room: "",
        music_room: "",
        gym_room: "",
        other_requirement: "",
    });

    const handleFormChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleForm = () => {
        if (formData.email.length == 0) {
            toast("Email is required");
            return;
        }
        if (formData.name.length == 0) {
            toast("Name is required");
            return;
        }
        if (formData.nric.length == 0) {
            toast("I/C No is required");
            return;
        }
        if (formData.dob.length == 0) {
            toast("Birthday is required");
            return;
        }
        if (formData.home_address.length == 0) {
            toast("Your Current Home Address is required");
            return;
        }
        if (formData.phone.length == 0) {
            toast("Phone Number is required");
            return;
        }
        if (formData.job_address.length == 0) {
            toast("Job Site Address is required");
            return;
        }
        if (formData.date_key.length == 0) {
            toast("Date of Key Release is required");
            return;
        }
        if (formData.member.length == 0) {
            toast("Member of Household is required");
            return;
        }
        if (selectedColorLike.length == 0) {
            toast("Please Choose a colour(s) do you like");
            return;
        }
        if (selectedColorDisLike.length == 0) {
            toast("Please Choose a colour(s) do you dislike");
            return;
        }
        if (selectedDesignArea.length == 0) {
            toast("Please Choose which area(s) you wish to design?");
            return;
        }
        if (formData.living_hall.length == 0) {
            toast("Requirements for Living Hall is required");
            return;
        }
        if (formData.dining_hall.length == 0) {
            toast("Requirements for Dining Hall is required");
            return;
        }
        if (formData.wet_kitchen.length == 0) {
            toast("Requirements for Wet Kitchen is required");
            return;
        }
        if (selectedKitchen.length == 0) {
            toast("Please Choose a Kitchen Appliances");
            return;
        }
        if (formData.dry_kitchen.length == 0) {
            toast("Requirements for Dry Kitchen is required");
            return;
        }
        if (formData.master_badroom.length == 0) {
            toast("Requirements for Master Bedroom is required");
            return;
        }
        if (formData.bedroom_one.length == 0) {
            toast("Requirements for Bedroom 1 is required");
            return;
        }
        if (formData.bedroom_two.length == 0) {
            toast("Requirements for Bedroom 2 is required");
            return;
        }
        if (formData.bedroom_three.length == 0) {
            toast("Requirements for Bedroom 3 is required");
            return;
        }
        if (formData.bedroom_four.length == 0) {
            toast("Requirements for Bedroom 4 is required");
            return;
        }
        if (formData.guest_room.length == 0) {
            toast("Requirements for Guest Room is required");
            return;
        }
        if (formData.study_room.length == 0) {
            toast("Requirements for Study Room is required");
            return;
        }
        if (formData.music_room.length == 0) {
            toast("Requirements for Music Room is required");
            return;
        }
        if (formData.gym_room.length == 0) {
            toast("Requirements for GYM room is required");
            return;
        }
        var data = {
            ...formData,
            color_like: JSON.stringify(selectedColorLike),
            color_dislike: JSON.stringify(selectedColorDisLike),
            area_design: JSON.stringify(selectedDesignArea),
            kitchen_appliances: JSON.stringify(selectedKitchen),
        };
        contact_form(data).then((res) => {
            if (res.status) {
                toast("Form Submitted");
                window.location.reload();
            } else {
                toast("Please Submit Again");

                // set_user(res.data);
                // if (res.data.user_type == "1") {
                //     props.history.push("/dashboard");
                // } else {
                //     props.history.push("/");
                // }
            }
        });
    };
    return (
        <React.Fragment>
            <GoogleHeader />
            <Container>
                <div>
                    <p>
                        <b>Fill out the consultation request form now.</b><br></br>
                        <br></br>
                        Indor is a platform that brings Interior Designers, Sub-contractors, Suppliers and Property Owners together.<br></br>
                        <br></br>
                        This way you can pick and choose from all vendors without ever leaving our site.<br></br>
                        <br></br>
                        To start now, please fill in the request form so we can find the perfect match for you.<br></br>
                        <br></br>
                    </p>
                </div>

                <div>
                    <div className="" onClick={handleClickOpen("paper")}>
                        <Button
                            className="btnRequest"

                        >Request Form</Button>
                    </div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        scroll={scroll}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogTitle id="scroll-dialog-title">Requirement Form</DialogTitle>
                        <DialogContent dividers={scroll === "paper"}>
                            <DialogContentText
                                id="scroll-dialog-description"
                                ref={descriptionElementRef}
                                tabIndex={-1}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Email:</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="email"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Name(Mr/Mrs/Madam/Miss):</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="name"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Status:</p>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="gender"
                                                name="status"
                                                onChange={handleFormChange}
                                                value={formData.status_id}
                                            >
                                                <FormControlLabel
                                                    value="Single"
                                                    control={<Radio />}
                                                    label="Single"
                                                />
                                                <FormControlLabel
                                                    value="Married"
                                                    control={<Radio />}
                                                    label="Married"
                                                />
                                                <FormControlLabel
                                                    value="Going to"
                                                    control={<Radio />}
                                                    label="Going to"
                                                />
                                                {/* <FormControlLabel
                        value="Other"
                        control={<Radio />}
                        label="Other"
                      /> */}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>I/C No:</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="nric"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Birthday:</p>
                                        <Stack component="form" noValidate spacing={3}>
                                            <TextField
                                                id="date"
                                                type="date"
                                                sx={{ width: 220 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="dob"
                                                onChange={handleFormChange}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Your Current Home Address:</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="home_address"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Phone Number:</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="phone"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Job Site Address:</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="job_address"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Date of Key Release:</p>
                                        <Stack component="form" noValidate spacing={3}>
                                            <TextField
                                                id="date"
                                                type="date"
                                                sx={{ width: 220 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="date_key"
                                                onChange={handleFormChange}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>House Type:</p>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="gender"
                                                name="house_type"
                                                onChange={handleFormChange}
                                                value={formData.house_type}
                                            >
                                                <FormControlLabel
                                                    value="Apartment - Studio"
                                                    control={<Radio />}
                                                    label="Apartment - Studio"
                                                />
                                                <FormControlLabel
                                                    value="Apartment - 2 rooms"
                                                    control={<Radio />}
                                                    label="Apartment - 2 rooms"
                                                />
                                                <FormControlLabel
                                                    value="Apartment - 3 rooms"
                                                    control={<Radio />}
                                                    label="Apartment - 3 rooms"
                                                />
                                                <FormControlLabel
                                                    value="Town house"
                                                    control={<Radio />}
                                                    label="Town house"
                                                />
                                                <FormControlLabel
                                                    value="Double storey terrace house"
                                                    control={<Radio />}
                                                    label="Double storey terrace house"
                                                />
                                                <FormControlLabel
                                                    value="Double storey house corner or end lot"
                                                    control={<Radio />}
                                                    label="Double storey house corner or end lot"
                                                />
                                                <FormControlLabel
                                                    value="Three storey terrace house"
                                                    control={<Radio />}
                                                    label="Three storey terrace house"
                                                />
                                                <FormControlLabel
                                                    value="Three storey house corner or end lot"
                                                    control={<Radio />}
                                                    label="Three storey house corner or end lot"
                                                />
                                                <FormControlLabel
                                                    value="Cluster House"
                                                    control={<Radio />}
                                                    label="Cluster House"
                                                />
                                                <FormControlLabel
                                                    value="Semi Detached"
                                                    control={<Radio />}
                                                    label="Semi Detached"
                                                />
                                                <FormControlLabel
                                                    value="Bungalow"
                                                    control={<Radio />}
                                                    label="Bungalow"
                                                />
                                                <FormControlLabel
                                                    value="Land to build"
                                                    control={<Radio />}
                                                    label="Land to build"
                                                />
                                                {/* <FormControlLabel
                        value="Other: "
                        control={<Radio />}
                        label="Other: "
                      /> */}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Member of Household (How many people staying?)</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="member"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>What design style do u prefer?</p>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="gender"
                                                name="design_style"
                                                onChange={handleFormChange}
                                                value={formData.design_style}
                                            >
                                                <FormControlLabel
                                                    value="Modern Contemporary"
                                                    control={<Radio />}
                                                    label="Modern Contemporary"
                                                />
                                                <FormControlLabel
                                                    value="Modern Classic or Classic"
                                                    control={<Radio />}
                                                    label="Modern Classic or Classic"
                                                />
                                                <FormControlLabel
                                                    value="Urban"
                                                    control={<Radio />}
                                                    label="Urban"
                                                />
                                                <FormControlLabel
                                                    value="Muji"
                                                    control={<Radio />}
                                                    label="Muji"
                                                />
                                                <FormControlLabel
                                                    value="Industrial"
                                                    control={<Radio />}
                                                    label="Industrial"
                                                />
                                                <FormControlLabel
                                                    value="Zen"
                                                    control={<Radio />}
                                                    label="Zen"
                                                />
                                                {/* <FormControlLabel
                        value="Other:"
                        control={<Radio />}
                        label="Other:"
                      /> */}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Estimation Budget For Renovation</p>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="gender"
                                                name="budget"
                                                onChange={handleFormChange}
                                                value={formData.budget}
                                            >
                                                <FormControlLabel
                                                    value="RM5,000.00 to RM10,000.00"
                                                    control={<Radio />}
                                                    label="RM5,000.00 to RM10,000.00"
                                                />
                                                <FormControlLabel
                                                    value="RM10,000 to RM50,000"
                                                    control={<Radio />}
                                                    label="RM10,000 to RM50,000"
                                                />
                                                <FormControlLabel
                                                    value="RM50,000 to RM100,000"
                                                    control={<Radio />}
                                                    label="RM50,000 to RM100,000"
                                                />
                                                <FormControlLabel
                                                    value="RM100,000 to RM200,000"
                                                    control={<Radio />}
                                                    label="RM100,000 to RM200,000"
                                                />
                                                <FormControlLabel
                                                    value="RM200,000 to RM300,000"
                                                    control={<Radio />}
                                                    label="RM200,000 to RM300,000"
                                                />
                                                <FormControlLabel
                                                    value="RM300,000 to RM400,000"
                                                    control={<Radio />}
                                                    label="RM300,000 to RM400,000"
                                                />
                                                <FormControlLabel
                                                    value="RM400,000 to RM500,000"
                                                    control={<Radio />}
                                                    label="RM400,000 to RM500,000"
                                                />
                                                <FormControlLabel
                                                    value="RM500,000 to RM1million"
                                                    control={<Radio />}
                                                    label="RM500,000 to RM1million"
                                                />
                                                <FormControlLabel
                                                    value="RM1million above"
                                                    control={<Radio />}
                                                    label="RM1million above"
                                                />
                                                {/* <FormControlLabel
                        value="Other:"
                        control={<Radio />}
                        label="Other:"
                      /> */}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Quotation include</p>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="gender"
                                                name="quatation"
                                                onChange={handleFormChange}
                                                value={formData.quatation}
                                            >
                                                <FormControlLabel
                                                    value="M&E"
                                                    control={<Radio />}
                                                    label="M&E"
                                                />
                                                <FormControlLabel
                                                    value="Air Cond"
                                                    control={<Radio />}
                                                    label="Air Cond"
                                                />
                                                <FormControlLabel
                                                    value="Ceiling"
                                                    control={<Radio />}
                                                    label="Ceiling"
                                                />
                                                <FormControlLabel
                                                    value="Wet Work"
                                                    control={<Radio />}
                                                    label="Wet Work"
                                                />
                                                <FormControlLabel
                                                    value="Painting"
                                                    control={<Radio />}
                                                    label="Painting"
                                                />
                                                <FormControlLabel
                                                    value="Flooring"
                                                    control={<Radio />}
                                                    label="Flooring"
                                                />
                                                <FormControlLabel
                                                    value="Carpentry work"
                                                    control={<Radio />}
                                                    label="Carpentry work"
                                                />
                                                <FormControlLabel
                                                    value="Curtain"
                                                    control={<Radio />}
                                                    label="Curtain"
                                                />
                                                <FormControlLabel
                                                    value="Alarm"
                                                    control={<Radio />}
                                                    label="Alarm"
                                                />
                                                <FormControlLabel
                                                    value="CCTV"
                                                    control={<Radio />}
                                                    label="CCTV"
                                                />
                                                {/* <FormControlLabel
                        value="Other:"
                        control={<Radio />}
                        label="Other:"
                      /> */}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>What colour(s) do you like?</p>
                                        <FormControl>
                                            {colorLike.map((color, key) => (
                                                <>
                                                    <FormControlLabel
                                                        // name={property.tag}
                                                        onChange={(event) => handlecheckbox(key)}
                                                        checked={color.checked}
                                                        value={color.color}
                                                        control={<Checkbox color="primary" />}
                                                        label={color.color}
                                                        labelPlacement="end"
                                                    />
                                                </>
                                            ))}
                                            {/* <FormControlLabel
                    name="color_like"
                    onChange={(e) => console.log(e.target)}
                      value="White"
                      control={<Checkbox />}
                      label="White"
                    />
                    <FormControlLabel
                      value="Black"
                      control={<Checkbox />}
                      label="Black"
                    /> */}
                                        </FormControl>
                                        {/* <FormControlLabel
                    name="color_like"
                    onChange={(e) => console.log(e.target.name, e.target)}
                    value="White"
                    control={<Checkbox />}
                    label="White"
                  />
                  <FormControlLabel
                    name="color_like"
                    onChange={(e) => console.log(e.target.name, e.target)}
                    value="Black"
                    control={<Checkbox />}
                    label="Black"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Checkbox />}
                    label="Grey"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Checkbox />}
                    label="Red"
                  />
                  <FormControlLabel
                    value="5"
                    control={<Checkbox />}
                    label="Blue"
                  />
                  <FormControlLabel
                    value="6"
                    control={<Checkbox />}
                    label="Yellow"
                  />
                  <FormControlLabel
                    value="7"
                    control={<Checkbox />}
                    label="Green"
                  />
                  <FormControlLabel
                    value="8"
                    control={<Checkbox />}
                    label="Pink"
                  /> */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>What colour(s) do you dislike?</p>
                                        <FormControl>
                                            {colorDisLike.map((color, key) => (
                                                <>
                                                    <FormControlLabel
                                                        // name={property.tag}
                                                        onChange={(event) => handlecheckboxDisLike(key)}
                                                        checked={color.checked}
                                                        value={color.color}
                                                        control={<Checkbox color="primary" />}
                                                        label={color.color}
                                                        labelPlacement="end"
                                                    />
                                                </>
                                            ))}
                                        </FormControl>
                                        {/* <FormControlLabel
                    value="1"
                    control={<Checkbox />}
                    label="White"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Checkbox />}
                    label="Black"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Checkbox />}
                    label="Grey"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Checkbox />}
                    label="Red"
                  />
                  <FormControlLabel
                    value="5"
                    control={<Checkbox />}
                    label="Blue"
                  />
                  <FormControlLabel
                    value="6"
                    control={<Checkbox />}
                    label="Yellow"
                  />
                  <FormControlLabel
                    value="7"
                    control={<Checkbox />}
                    label="Green"
                  />
                  <FormControlLabel
                    value="8"
                    control={<Checkbox />}
                    label="Pink"
                  /> */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Which area(s) you wish to design?</p>
                                        <FormControl>
                                            {designArea.map((area, key) => (
                                                <>
                                                    <FormControlLabel
                                                        // name={property.tag}
                                                        onChange={(event) => handlecheckboxArea(key)}
                                                        checked={area.checked}
                                                        value={area.area}
                                                        control={<Checkbox area="primary" />}
                                                        label={area.area}
                                                        labelPlacement="end"
                                                    />
                                                </>
                                            ))}
                                        </FormControl>
                                        {/* <FormControlLabel
                    value="1"
                    control={<Checkbox />}
                    label="Living Hall"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Checkbox />}
                    label="Dining Hall"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Checkbox />}
                    label="Wet Kitchen"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Checkbox />}
                    label="Dry Kitchen"
                  />
                  <FormControlLabel
                    value="5"
                    control={<Checkbox />}
                    label="Master bedroom"
                  />
                  <FormControlLabel
                    value="6"
                    control={<Checkbox />}
                    label="Bedroom 1"
                  />
                  <FormControlLabel
                    value="7"
                    control={<Checkbox />}
                    label="Bedroom 2"
                  />
                  <FormControlLabel
                    value="8"
                    control={<Checkbox />}
                    label="Guest Room"
                  />
                  <FormControlLabel
                    value="9"
                    control={<Checkbox />}
                    label="Study room"
                  />
                  <FormControlLabel
                    value="10"
                    control={<Checkbox />}
                    label="Music Room"
                  />
                  <FormControlLabel
                    value="11"
                    control={<Checkbox />}
                    label="Gym Room"
                  /> */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for Living Hall (eg. Colour /Material /Lighting
                                            /TV Size /Praying Altar ...)
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="living_hall"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for Dining Hall (eg. Colour /Socket /Material
                                            /Lighting /Round Table /Rectangular Table /For how many
                                            people)
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="dining_hall"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for Wet Kitchen (eg. Colour /Socket/ Material
                                            /Lighting /Electrical Appliance)
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="wet_kitchen"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Kitchen Appliances</p>
                                        <FormControl>
                                            {kitchen.map((area, key) => (
                                                <>
                                                    <FormControlLabel
                                                        // name={property.tag}
                                                        onChange={(event) => handleKitchen(key)}
                                                        checked={area.checked}
                                                        value={area.area}
                                                        control={<Checkbox area="primary" />}
                                                        label={area.area}
                                                        labelPlacement="end"
                                                    />
                                                </>
                                            ))}
                                        </FormControl>
                                        {/* <FormControlLabel
                    value="1"
                    control={<Checkbox />}
                    label="Gas Hob"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Checkbox />}
                    label="Induction Hob"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Checkbox />}
                    label="Chimney Hood"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Checkbox />}
                    label="Island Hood"
                  />
                  <FormControlLabel
                    value="5"
                    control={<Checkbox />}
                    label="Build in Oven"
                  />
                  <FormControlLabel
                    value="6"
                    control={<Checkbox />}
                    label="Microwave Oven"
                  />
                  <FormControlLabel
                    value="7"
                    control={<Checkbox />}
                    label="2 Doors Fridge"
                  />
                  <FormControlLabel
                    value="8"
                    control={<Checkbox />}
                    label="4 Doors Fridge"
                  />
                  <FormControlLabel
                    value="9"
                    control={<Checkbox />}
                    label="Freezer"
                  />
                  <FormControlLabel
                    value="10"
                    control={<Checkbox />}
                    label="Dish Washer"
                  />
                  <FormControlLabel
                    value="11"
                    control={<Checkbox />}
                    label="Single Bowl Sink"
                  />
                  <FormControlLabel
                    value="12"
                    control={<Checkbox />}
                    label="Double Bowl Sink"
                  />
                  <FormControlLabel
                    value="13"
                    control={<Checkbox />}
                    label="Dish rack"
                  /> */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for Dry Kitchen (eg. Colour /Socket /Material
                                            /Lighting /Electrical Appliance)
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="dry_kitchen"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for Master Bedroom (eg. Colour /Socket
                                            /Material /Lighting /Lots of Cloth or handbag /Standing Make
                                            up or Sitting Make Up /TV Console /Study Table...)
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="master_badroom"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for Bedroom 1 (eg. Colour /Socket /Material
                                            /Lighting /TV Console /Study Table...). Please indicate for
                                            boys or girls and age.
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="bedroom_one"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for Bedroom 2 (eg. Colour /Socket /Material
                                            /Lighting /TV Console /Study Table...). Please indicate for
                                            boys or girls and age.
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="bedroom_two"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for Bedroom 3 (eg. Colour /Socket /Material
                                            /Lighting /TV Console /Study Table...). Please indicate for
                                            boys or girls and age.
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="bedroom_three"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for Bedroom 4 (eg. Colour /Socket /Material
                                            /Lighting /TV Console /Study Table...). Please indicate for
                                            boys or girls and age.
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="bedroom_four"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for Guest Room (eg. Colour /Socket /Material
                                            /Lighting /TV Console /Study Table...). Please indicate for
                                            guest or parents.
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="guest_room"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for Study room (eg. Book Cabinet /Study Table
                                            for how many person /Who will use this room)
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="study_room"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for Music Room (eg. Piano /Drum /Violin, etc)
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="music_room"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>
                                            Requirements for GYM room (eg. How many exercise equiments
                                            /Full mirror, etc)
                                        </p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="gym_room"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p>Other Requirements</p>
                                        <TextField
                                            className="theHome"
                                            id="outlined-basic"
                                            name="other_requirement"
                                            onChange={handleFormChange}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleForm}>Submit</Button>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </div>


            </Container>
        </React.Fragment>
    );
}