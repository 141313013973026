import "./App.css";
import CartContextProvider from "./context/CartContext";
import UserContextProvider, { UserContext } from "./context/UserContext";
import Routes from "./Routes";

function App() {
  return (
    <UserContextProvider>
      <CartContextProvider>
        <div className="App">
          <Routes></Routes>
        </div>
      </CartContextProvider>
    </UserContextProvider>
  );
}

export default App;
