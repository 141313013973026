import { BrowserRouter as Router, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Article from "./pages/article/Article";
import Account from "./pages/dashboard/Account/Account";
import Users from "./pages/dashboard/User/User";
import Enquiry from "./pages/dashboard/Enquiry/Enquiry";
import Point from "./pages/dashboard/Point/Point";
import Commission from "./pages/dashboard/Commission/Commission";
import Referral from "./pages/dashboard/Referral/Referral";
import CreateProject from "./pages/dashboard/CreateProject/CreateProject";
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardProject from "./pages/dashboard/DashboardProject/DashboardProject";

import ProjectDetail from "./pages/dashboard/ProjectDetail/ProjectDetail";
import Designer from "./pages/Designer/Designer";
import Home from "./pages/home/Home";
import Join from "./pages/Join/Join";
import Location from "./pages/location/Location";
import Login from "./pages/login/Login";
import Project from "./pages/project/Project";
import ProjectList from "./pages/projectlist/ProjectList";
import About from "./pages/About/About";
import News from "./pages/News/News";
import AllProject from "./pages/allproject/allproject";
import JoinUs from "./components/Joinus/Joinus";
import ContactUs from "./components/Contactus/Contactus";
import TermAndCondition from "./components/Termandcondition/Termandcondistion";
import Marketplace from "./pages/Marketplace/Marketplace";
import ProductDetail from "./pages/Marketplace/Product/ProductDetail";
import Cart from "./pages/Marketplace/Cart/Cart";
import Payment from "./pages/Marketplace/Payment/Payment";
import DashboardProducts from "./pages/dashboard/Products/Products";
import CreateProduct from "./pages/dashboard/CreateProduct/CreateProduct";
import Profile from "./pages/dashboard/Profile/Profile";
import Orders from "./pages/dashboard/Orders/Orders";
import EditProduct from "./pages/dashboard/EditProduct/EditProduct";
import SellerProfile from "./pages/Marketplace/SellerProfile/SellerProfile";
import RequirementForm from "./pages/RequirementForm/RequirementForm";
import CategoryProduct from "./pages/Marketplace/CategoryProduct/CategoryProduct";
import SellerProfieCategory from "./pages/Marketplace/SellerProfile/SellerProfieCategory";
import CreateProfile from "./pages/dashboard/Profile/CreateProfile";
import EditProfile from "./pages/dashboard/Profile/EditProfile";
import Invoice from "./pages/dashboard/Orders/Invoice";
import Purchases from "./pages/dashboard/Orders/Purchases";
import Collection from "./pages/Marketplace/Collection/Collection";
import BankDetails from "./pages/Marketplace/BankDetails.js/BankDetails";
import ContractorProjectDetail from "./pages/Contractor/ProjectDetail/ProjectDetail";
import ContractorJobs from "./pages/Contractor/Jobs/ContractorJobs";
import ContractorBids from "./pages/Contractor/Bids/ContractorsBids";
import EditVariant from "./pages/dashboard/EditProduct/EditVariant"
import PostProject from "./pages/Contractor/PostProject/PostProject";
import MyPost from "./pages/Contractor/MyPost/MyPost";
import ContractorList from "./pages/Contractor/List/ContractorList";
import MyPostBid from "./pages/Contractor/MyPostBid/MyPostBid";

const Routes = () => {
  return (
    <Router>
      <Route
        path="/location/:location_name/:filter_id/:selected_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Location {...props}></Location>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/location/:location_name/:filter_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Location {...props}></Location>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Home {...props}></Home>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/project/:project_id"
        exact
        render={(props) => (
          <>
            <Project {...props}></Project>
          </>
        )}
      ></Route>
      <Route
        path="/Article/:blog_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Article {...props}></Article>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/join"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Join {...props}></Join>
          </>
        )}
      ></Route>

      <Route
        path="/join/:refer_code"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Join {...props}></Join>
          </>
        )}
      ></Route>

      <Route
        path="/login"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Login {...props}></Login>
          </>
        )}
      ></Route>
      <Route
        path="/projectlist"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <ProjectList {...props}></ProjectList>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/project"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Project {...props}></Project>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/designer/:designer_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Designer {...props}></Designer>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <Account {...props}></Account>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/user"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <Users {...props}></Users>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/products"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <DashboardProducts {...props} />
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/enquiry"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <Enquiry {...props}></Enquiry>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/point"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <Point {...props}></Point>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/commission"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <Commission {...props}></Commission>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/referral"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <Referral {...props}></Referral>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/projects"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <DashboardProject {...props}></DashboardProject>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/createproject"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <CreateProject {...props}></CreateProject>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/projectdetail/:project_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <ProjectDetail {...props}></ProjectDetail>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/about"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <About {...props}></About>
          </>
        )}
      ></Route>

      <Route
        path="/news"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <News {...props}></News>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/allproject"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <AllProject {...props}></AllProject>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/joinus"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <JoinUs {...props}></JoinUs>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/contactus"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <ContactUs {...props}></ContactUs>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/termandcondition"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <TermAndCondition {...props}></TermAndCondition>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/marketplace"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Marketplace {...props}></Marketplace>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/productdetail/:product_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <ProductDetail {...props}></ProductDetail>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/cart"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Cart {...props}></Cart>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/payment"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Payment {...props}></Payment>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/createProduct"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <CreateProduct {...props}></CreateProduct>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/profile"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <Profile {...props}></Profile>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/orders"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <Orders {...props}></Orders>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/editproduct/:product_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <EditProduct {...props}></EditProduct>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/dashboard/editvariant/:product_id/:product_variant_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <EditVariant {...props}></EditVariant>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/sellerprofile/:user_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <SellerProfile {...props}></SellerProfile>
            <Footer></Footer>
          </>
        )}
      ></Route>
      <Route
        path="/requirementform"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <RequirementForm {...props}></RequirementForm>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/categoryproduct/:category_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <CategoryProduct {...props}></CategoryProduct>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/sellerprofilecategory/:user_id/:category_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <SellerProfieCategory {...props}></SellerProfieCategory>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/dashboard/createservices"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <CreateProfile {...props}></CreateProfile>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/dashboard/editservices/:service_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <EditProfile {...props}></EditProfile>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/dashboard/invoice/:order_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <Invoice {...props}></Invoice>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/dashboard/purchases"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Dashboard {...props}>
              <Purchases {...props}></Purchases>
            </Dashboard>
            <Footer></Footer>
          </>
        )}
      ></Route>


      <Route
        path="/collection/:banner_id"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <Collection {...props}></Collection>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/bankdetails"
        exact
        render={(props) => (
          <>
            <Header></Header>
            <BankDetails {...props}></BankDetails>
            <Footer></Footer>
          </>
        )}
      ></Route>

      <Route
        path="/contractors/jobs"
        exact
        render={(props) => (<ContractorJobs />)}
      />
      <Route
        path="/contractors/bids"
        exact
        render={(props) => (<ContractorBids />)}
      />
      <Route
        path="/contractors/post"
        exact
        render={(props) => (<PostProject />)}
      />
      <Route
        path="/contractors/mypost"
        exact
        render={(props) => (<MyPost />)}
      />
      <Route path="/contractors/list"
        exact
        render={(props) => <ContractorList />} />
      <Route
        path="/contractors/jobs/:project_id"
        exact
        render={(props) => (<ContractorProjectDetail />)}
      />
      <Route
        path="/contractors/mypost/bid/:bid_id"
        exact
        render={(props) => (<MyPostBid />)}
      />
    </Router>
  );
};

export default Routes;
