import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { md } from "../../styles/responsive";

export const config = {
    root: {},
    theHeaderProfile: {
        position: "relative",
        // borderBottom: "1px solid #eaeaea",
    },
    theForm: {
        position: "relative",
        marginBottom: "1rem",
    },
    theBox: {
        margin: "2rem 0",
        justifyContent: "flex-end",
        display: "flex",
    },

    theIMG: {
        width: "70%",
        margin: "0 auto",
    },
    thePadding: {
        background: "#fff",
        padding: "4rem 0",
        ...md("sm", {
            padding: "6rem 0",
        }),
        ...md("md", {
            padding: "8rem 0",
        })
    },
};

export const SideBarContainer = styled.div`
  position: relative;
  border-radius: 10px;
  // box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 8%);
  padding: 1rem 2rem;

  @media screen and (min-width: 992px) {
    /* width: 90%; */
  }
`;

export const SideBarBox = styled.div`
  position: relative;
  text-align: center;
`;

export const Icon = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const Circle = styled.span`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  color: #000;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 0.6rem 0.8rem;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 6%);

  @media screen and (min-width: 992px) {
    padding: 0.3rem 0.5rem;
  }

  @media screen and (min-width: 1440px) {
    padding: 0.6rem 0.8rem;
  }
`;

export const SiderBarList = styled.div`
  position: relative;
`;

export const NavFlexLink = styled(Link)`
  align-items: center;
  display: flex;
  padding: 1rem 0;
`;

export const SmallIcon = styled.div`
  position: relative;
  width: 8%;
  display: flex;
  margin-right: 1rem;
  @media screen and (min-width: 768px) {
    width: 4%;
  }
  @media screen and (min-width: 992px) {
    width: 15%;
  }
`;

export const SmallIconIMG = styled.img`
  width: 100%;
`;

export const ActiveBorder = styled.img`
  position: relative;
  background: #74deca;
  width: 4px;
  height: 30px;
  margin-right: 1rem;
`;

export default makeStyles(config);
