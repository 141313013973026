import React from 'react'
import useStyle, { CarouselContainer, CarouselIMG } from "./styles"
import { delete_product, delete_variant, edit_product, edit_product_description, edit_variant, get_categories, get_product_details, get_user_products, remove_variant_image, update_variant_image, variant_detail } from '../../../api/API';
import GoogleHeader from '../../../components/GoogleHeader';
import Loader from "react-js-loader";
import { Box, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import Paragraph from '../../../components/Typography/Paragraph';
import { useParams } from 'react-router-dom';
import EditorToolbar, { modules, formats } from "../CreateProject/EditorToolbar"
import ReactQuill from 'react-quill'
import FormInput from '../../../components/FormInput/FormInput';
import Slider from 'react-slick';
import FormInputNoLabel from '../../../components/FormInput/FormInputNoLabel/FormInputNoLabel';
// import { Button } from '@material-ui/core';
import Button from '../../../components/Button/Button';
import { AddCircleOutline, DeleteOutlineRounded } from "@material-ui/icons";
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';


function EditVariant(props) {
    const classes = useStyle()
    const { product_id, product_variant_id } = useParams();
    const history = useHistory();


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };


    const settings1 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    // dots: true
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const [token, setToken] = React.useState("");

    React.useEffect(() => {
        let storedToken = window.localStorage.getItem("token");
        setToken(storedToken || "");
        console.log("CURRENT TOKEN", storedToken);
    }, []);


    const [loading, setLoading] = React.useState(false);

    const [productDetail, setProductDetail] = React.useState({});

    const [productData, setProductData] = React.useState(null);

    const [productDesc, setProductDesc] = React.useState({
        product_id: product_id,
        description: "",
    })

    const [variantId, setVariantId] = React.useState([])

    const getProductDetail = () => {
        get_product_details(product_id).then((json) => {
            if (json.status) {
                console.log('source', json.data);
                setProductData(json.data);


                if (json.data.variants && json.data.variants.length > 0) {
                    const productVariantId = json.data.variants[0].product_variant_id;
                    setVariantId(productVariantId);

                    console.log("product_variant_id", productVariantId);
                } else {
                    console.error("No variants found for this product.");
                }

                console.log("sub", productDetail.subcategory_names)


                console.log(JSON.stringify(json.data.subcategory_ids))


                // setForm({
                //     ...form,
                //     subcategory_ids: json.data.subcategory_ids
                // });
                // setSubcategories(json.data.subcategory_ids)

            } else {
                console.error("Invalid data:", json);
            }
        });
    };


    React.useEffect(() => {

        getProductDetail()


    }, [])


    const [variant, setVariant] = React.useState([null])

    const getVariant = () => {
        variant_detail({ product_id: product_id, product_variant_id: product_variant_id }).then((json) => {
            if (json.status) {
                setVariant(json.data)
            } else {
                console.error("Invalid data:", json);
            }
        })
    }

    React.useEffect(() => {
        getVariant()
    }, [product_id, variantId])




    const handleChange = (e) => {
        setVariant({
            ...variant,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = () => {


        if (!variant.variant_name) {
            alert("Please fill Product Name.");
            return;
        }
        if (!variant.price) {
            alert("Please fill Description")
            return;
        }
        if (!variant.commission_referral) {
            alert("Please fill Commission")
            return;
        }

        const postparam = {
            product_id: product_id,
            product_variant_id: product_variant_id,
            Authorization: `Bearer ${token}`,
            name: variant.variant_name,
            price: variant.price,
            commission_referral: variant.commission_referral,
        }


        const headers = {
            Authorization: `Bearer ${token}`
        };

        const ID = {
            product_id: product_id,
        }

        const variantID = {
            product_variant_id: variant.product_variant_id,
        }

        edit_variant(postparam).then((json) => {
            if (json.status) {
                alert("submitted");
                history.push("/dashboard/products");
            } else {
                alert(json.message)
            }
        });
    }

    const handleDelete = () => {
        const confirmed = window.confirm("Are you sure you want to delete this variant?");
        if (!confirmed) {
            return;
        }

        const headers = {
            Authorization: `Bearer ${token}`
        };

        // Create a new FormData object
        const formData = new FormData();
        formData.append('product_id', product_id);
        formData.append('product_variant_id', product_variant_id);

        // Call the delete_product API with formData and headers
        delete_variant(formData, headers).then((json) => {
            if (json.status) {
                alert("Deleted successfully");
                history.push("/dashboard/products");
            } else {
                alert("Failed to delete variant.");
            }
        }).catch((error) => {
            console.error("Error deleting variant:", error);
            alert("An error occurred while deleting the variant.");
        });
    };

    const handleDeleteImage = () => {
        const confirmed = window.confirm("Are you sure you want to delete this image?");
        if (!confirmed) {
            return;
        }

        const headers = {
            Authorization: `Bearer ${token}`
        };

        // Create a new FormData object
        const formData = new FormData();
        formData.append('product_id', product_id);
        formData.append('product_variant_id', product_variant_id);
        formData.append("variant_image_id", variant.variant_image_id);

        // Call the delete_product API with formData and headers
        remove_variant_image(formData, headers).then((json) => {
            if (json.status) {
                alert("Deleted successfully");
                // history.push("/dashboard/products");
                window.location.reload()
            } else {
                alert("Failed to delete Image.");
            }
        }).catch((error) => {
            console.error("Error deleting image:", error);
            alert("An error occurred while deleting the image.");
        });
    };

    // const [selectedImage, setSelectedImage] = React.useState(null);
    const [isModalOpen, setIsModalOpen] = React.useState(false);


    const openModal = () => {
        setIsModalOpen(true);
        // Fetch images using getProductDetail or another suitable API call
        getProductDetail();
    };


    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [selectedImage, setSelectedImage] = React.useState(null)

    const handleSaveChanges = () => {
        if (selectedImage) {

            const formData = new FormData();

            formData.append("product_id", product_id);
            formData.append("product_variant_id", product_variant_id);
            formData.append("variant_image_id", selectedImage.product_image_id)

            const headers = {
                Authorization: `Bearer ${token}`
            }

            update_variant_image(formData, headers).then((json) => {
                if (json.status) {
                    alert("Submitted")
                    closeModal()
                    window.location.reload()
                } else {
                    alert(json.message)
                }
            })
        }
    }

    return (
        <div className="account_page">
            <GoogleHeader />
            {loading || variant == null ? (
                <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
            ) : (
                <React.Fragment>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Paragraph
                            size="600"
                            color="#0d2964"
                            bold="600"
                        >
                            Edit Variant
                        </Paragraph>

                        <Button
                            label="DELETE"
                            color="#FFF"
                            br="5px"
                            bg="#c70000"
                            margin=".4rem 0"
                            pd=".7rem 1rem .6rem"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDelete()}
                        />
                    </div>
                    <hr />

                    <div style={{ margin: "1rem 0 0 0" }}>
                        {/* <FormInput
                            label="Variant Name"
                            bg="#FFF"
                            border="1px solid #d7d7d7"
                            name="variant_category"
                            onChange={handleChange}
                            value={variant.variant_category}
                            disable={true}
                        /> */}

                        {/* <br /> */}
                        <FormInput
                            label="Subvariant Name"
                            bg="#FFF"
                            border="1px solid #d7d7d7"
                            name="variant_name"
                            onChange={handleChange}
                            value={variant.variant_name}
                        />

                        <FormInput
                            label="Subvariant Price"
                            bg="#FFF"
                            border="1px solid #d7d7d7"
                            name="price"
                            onChange={handleChange}
                            value={variant.price}
                        />

                        <FormInput
                            label="Subvariant Commission"
                            bg="#FFF"
                            border="1px solid #d7d7d7"
                            name="commission_referral"
                            onChange={handleChange}
                            value={variant.commission_referral}
                        />





                        <div style={{ display: "flex", flexWrap: "wrap", margin: "1rem 0" }}>
                            {variant.variant_image_url == null || "" ? (
                                <></>
                            ) : (
                                <div className={classes.imageVariant}>
                                    <div style={{ height: "100px", width: "150px", margin: "1rem 0" }}>
                                        <img src={variant.variant_image_url} width="100%" height="100%" objectFit="cover" />
                                    </div>
                                    <Button
                                        label="REMOVE"
                                        color="#c70000"
                                        br="5px"
                                        bg="#fff"
                                        margin=".4rem 0"
                                        pd=".7rem 1rem .6rem"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleDeleteImage()}
                                    />
                                </div>
                            )}

                            <label for="upload" className={classes.badgeCenter} onClick={openModal}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                >
                                    <AddCircleOutline />
                                </div>
                            </label>
                        </div>


                        {/* {productData && productData.images && (
                            productData.images.map((image) => (
                                <img
                                    key={image.product_image_id}
                                    src={image.url}
                                    alt={`Product Image ${image.product_image_id}`}
                                    onClick={() => {
                                        setSelectedImage(image);
                                        closeModal(); // Close the modal when an image is selected
                                    }}
                                />
                            ))
                        )} */}

                        < div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}>

                            <Button
                                label="SUBMIT"
                                color="#FFF"
                                bg="#2f8dcd"
                                br="5px"
                                pd=".7rem 1rem .6rem"
                                style={{ cursor: "pointer", margin: "0 0 0 1rem" }}
                                onClick={() => handleSubmit()}
                            />
                        </div>
                    </div>

                    <Modal
                        isOpen={isModalOpen}
                        // onRequestClose={closeModal}
                        contentLabel="Image Selection Modal"
                        style={customStyles}
                    >
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {productData &&
                                productData.images &&
                                productData.images.map((image, index) => (
                                    <div
                                        key={image.product_image_id}
                                        style={{
                                            width: '33.33%', // Set to 33.33% for three images per row
                                            height: "200px",
                                            padding: '1rem',
                                            boxSizing: 'border-box',
                                            border: selectedImage === image ? '2px solid blue' : '2px solid transparent',
                                        }}
                                    >
                                        <img
                                            src={image.url}
                                            alt={`Product Image ${image.product_image_id}`}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                            onClick={() => {
                                                setSelectedImage(image);
                                                // closeModal();
                                            }}
                                        />
                                    </div>
                                ))}
                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                label="Close"
                                color="#FFF"
                                bg="#a6a4a5"
                                br="5px"
                                pd=".7rem 1rem .6rem"
                                style={{ cursor: "pointer", margin: "1rem 1rem" }}
                                onClick={() => closeModal()}
                            />
                            <Button
                                label="Save Changes"
                                color="#FFF"
                                bg="#2f8dcd"
                                br="5px"
                                pd=".7rem 1rem .6rem"
                                style={{ cursor: "pointer", margin: "1rem 0" }}
                                onClick={() => handleSaveChanges()}
                            />
                        </div>

                    </Modal>


                </React.Fragment>
            )}
        </div>
    )
}

export default EditVariant