// import axios from "axios";

import axios from "axios";
// const host = 'http://localhost/invento_backend/';
const host = "https://indor.my/admin/";
const access_endpoint_link = host + "API/";

const format_request = (postparam) => {
  if (postparam) {
    let token = window.localStorage.getItem("token");
    if (token) {
      // postparam["token"] = token;
      postparam["Authorization"] = `Bearer ${token}`;
    }
  }
  let body = "";
  for (let k in postparam) {
    body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
  }
  var postOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...(postparam["Authorization"] && {
        Authorization: postparam["Authorization"],
      }),
    },
    body: body,
  };
  return postOptions;
};

const format_request2 = (postparam) => {
  if (postparam) {
    let token = window.localStorage.getItem("token");
    if (token) {
      // postparam["token"] = token;
      // postparam["Authorization"] = `Bearer ${token}`;
    }
  }
  let body = "";
  for (let k in postparam) {
    body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
  }
  var postOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...(postparam["Authorization"] && {
        Authorization: postparam["Authorization"],
      }),
    },
    body: body,
  };
  return postOptions;
};

const format_request3 = (postparam) => {
  if (postparam) {
    let token = window.localStorage.getItem("token");
    if (token) {
      postparam["Authorization"] = `Bearer ${token}`;
    }
  }
  var postOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // Use application/json content type
      ...(postparam["Authorization"] && {
        Authorization: postparam["Authorization"],
      }),
    },
    body: JSON.stringify(postparam), // Send the data as JSON
  };
  return postOptions;
};

export const register = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "register",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      alert(json.message);
    }
    return false;
  }
};

export const register_new = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "register_new",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      alert(json.message);
    }
    return false;
  }
};

export const login_user = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "login_user",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    window.localStorage.setItem("user_id", json.data.user_id);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      alert(json.message);
    }
    return false;
  }
};

export const get_product = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_product",
    format_request({ postparam })
  );
  var json = await response.json();
  return json;
};

export const get_banner = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_banner",
    format_request({ postparam })
  );
  var json = await response.json();
  return json;
};

export const get_blog = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_blog",
    format_request({ postparam })
  );
  var json = await response.json();
  return json;
};

export const get_blog_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_blog_detail",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const profile = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "profile",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_user_profile = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_user_profile",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const forgot_password = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "forgot_password",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

// export const upload_logo = async (data) => {
//   let res = await axios.post(access_endpoint_link + "upload_logo", data);
//   if (res) {
//     return true;
//   } else {
//     return false;
//   }
// }

export const send_message = async (data) => {
  var response = await fetch(
    access_endpoint_link + "send_message",
    format_request(data)
  );
  var json = await response.json();
  return json;
};

// export const upload_project_images = async (data) => {
//   let res = await axios.post(
//     access_endpoint_link + "upload_project_images",
//     data
//   );
//   if (res) {
//     return res.data;
//   } else {
//     return false;
//   }
// };

export const upload_project_images = async (data, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "upload_project_images", {
      method: "POST",
      body: data,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const get_messages = async (data) => {
  var response = await fetch(
    access_endpoint_link + "get_messages",
    format_request({})
  );
  var json = await response.json();
  return json;
};
export const get_tag_by_type = async (type) => {
  var response = await fetch(
    access_endpoint_link + "get_tag_by_types",
    format_request({
      type: type,
    })
  );
  var json = await response.json();
  return json;
};

export const get_user = async (user_id) => {
  var response = await fetch(
    access_endpoint_link + "get_user",
    format_request({
      user_id: user_id,
    })
  );
  var json = await response.json();
  return json;
};

export const projects_by_user = async (user_id) => {
  var response = await fetch(
    access_endpoint_link + "get_projects_by_user",
    format_request({
      user_id: user_id,
    })
  );
  var json = await response.json();
  return json;
};

// export const edit_project = async (postparam) => {
//   var response = await fetch(
//     access_endpoint_link + "edit_project",
//     format_request(postparam)
//   );
//   var json = await response.json();
//   return json;
// };

export const edit_project = async (formData, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "edit_project", {
      method: "POST",
      body: formData,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// export const create_project = async (postparam) => {
//   var response = await fetch(
//     access_endpoint_link + "create_project",
//     format_request(postparam)
//   );
//   var json = await response.json();
//   return json;
// };

export const create_project = async (formData, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "create_project", {
      method: "POST",
      body: formData,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};


export const get_message_details = async (user_chat_id) => {
  var response = await fetch(
    access_endpoint_link + "get_message_details",
    format_request({
      user_chat_id: user_chat_id,
    })
  );
  var json = await response.json();
  return json;
};

export const get_project = async (project_id) => {
  var response = await fetch(
    access_endpoint_link + "get_project",
    format_request({
      project_id: project_id,
    })
  );
  var json = await response.json();
  return json;
};
export const get_my_projects = async () => {
  var response = await fetch(
    access_endpoint_link + "get_my_projects",
    format_request({})
  );
  var json = await response.json();
  return json;
};

export const delete_image = async (id) => {
  var response = await fetch(
    access_endpoint_link + "delete_image_from_project",
    format_request({
      project_images_id: id,
    })
  );
  var json = await response.json();
  return json;
};

export const get_enquiry = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_enquiry",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_point_list = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_point_list",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_commission_list = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_commission_list",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_refer_list = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_refer_list",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_good_read = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_good_read",
    format_request({ postparam })
  );
  var json = await response.json();
  return json;
};

export const get_good_read_top_2 = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_good_read_top_2",

    format_request({ postparam })
  );
  var json = await response.json();
  return json;
};

export const get_area = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_area",

    format_request({ postparam })
  );
  var json = await response.json();
  return json;
};

export const new_get_area = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "new_get_area",

    format_request({ postparam })
  );
  var json = await response.json();
  return json;
};

export const filter_projects = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "filter_projects",

    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_home_projects = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_home_projects",
    format_request({ postparam })
  );
  var json = await response.json();
  return json;
};

export const contact_form = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "contact_form",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const join_us_form_new = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "join_us_form_new",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_diffrent_type_of_project = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_diffrent_type_of_project",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_testimonials = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_testimonials",
    format_request({ postparam })
  );
  var json = await response.json();
  return json;
};

export const check_plan_limit = async () => {
  var response = await fetch(
    access_endpoint_link + "check_plan_limit",
    format_request({})
  );
  var json = await response.json();
  return json;
};

export const get_user_list = async () => {
  var response = await fetch(
    access_endpoint_link + "get_user_list",
    format_request({})
  );
  var json = await response.json();
  return json;
};

export const get_plan_role = async () => {
  var response = await fetch(
    access_endpoint_link + "get_plan_role",
    format_request({})
  );
  var json = await response.json();
  return json;
};

export const create_user = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "create_user",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const edit_user = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "edit_user",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const delete_user = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "delete_user",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_enquiry_listing = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_enquiry_listing",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const send_email_forgot_password = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "send_email_forgot_password",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const change_password = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "change_password",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

// export const edit_profile = async (postparam) => {
//   var response = await fetch(
//     access_endpoint_link + "edit_profile",
//     format_request(postparam)
//   );
//   var json = await response.json();
//   return json;
// }

export const get_contractor_service = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_contractor_service",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_state = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_state",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_categories = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_categories",
    format_request({})
  );
  var json = await response.json();
  return json;
};

export const get_subcategories_by_category = async (category) => {
  var response = await fetch(
    access_endpoint_link + "get_subcategories_by_category",
    format_request({ category: category })
  );
  var json = await response.json();
  return json;
};

// export const get_products_by_category = async (categoryId) => {
//   try {
//     const response = await fetch(
//       access_endpoint_link + "get_products_by_category",
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           category: categoryId,
//         }),
//       }
//     );
//     const json = await response.json();
//     return json;
//   } catch (error) {
//     console.error("Error fetching products:", error);
//     throw error;
//   }
// };

export const get_products_by_category = async (category, page, per_page) => {
  var response = await fetch(
    access_endpoint_link + "get_products_by_category",
    format_request2({
      category_id: category,
      page: page,
      per_page: per_page,
    })
  );
  var json = await response.json();
  return json;
};

export const get_products_by_subcategory = async (
  subcategory,
  page,
  per_page
) => {
  var response = await fetch(
    access_endpoint_link + "get_products_by_subcategory",
    format_request2({
      subcategory_id: subcategory,
      page: page,
      per_page: per_page,
    })
  );
  var json = await response.json();
  return json;
};

export const add_product = async (formData, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "add_product", {
      method: "POST",
      body: formData,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const get_user_products = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_user_products",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_product_details = async (id) => {
  var response = await fetch(
    access_endpoint_link + "get_product_details",
    format_request({
      product_id: id,
    })
  );
  var json = await response.json();
  return json;
};

// export const get_product_details = async (product_id) => {
//   var response = await fetch(
//     access_endpoint_link + "get_product_details",
//     format_request({
//       product_id: product_id
//     })
//   );
//   var json = await response.json();
//   return json;
// }

// export const checkout = async (postparam) => {
//   var response = await fetch(
//     access_endpoint_link + "checkout",
//     format_request3(postparam)
//   );
//   var json = await response.json();
//   return json;
// };

export const checkout = async (formData, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "checkout", {
      method: "POST",
      body: formData,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const upload_logo = async (formData, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "upload_logo", {
      method: "POST",
      body: formData,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const edit_product = async (formData, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "edit_product", {
      method: "POST",
      body: formData,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const edit_variant = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "edit_variant",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const edit_product_description = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "edit_product_description",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const edit_profile = async (formData, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "edit_profile", {
      method: "POST",
      body: formData,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const get_orders_by_seller = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_orders_by_seller",
    format_request3(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_orders_by_user = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_orders_by_user",
    format_request3(postparam)
  );
  var json = await response.json();
  return json;
};

export const delete_product = async (formData, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "delete_product", {
      method: "POST",
      body: formData,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// export const get_seller_profile = async (postparam) => {
//   var response = await fetch(
//     access_endpoint_link + "get_seller_profile",
//     format_request3(postparam)
//   );
//   var json = await response.json();
//   return json;
// }

export const get_seller_profile = async (userId) => {
  var response = await fetch(
    access_endpoint_link + "get_seller_profile",
    format_request({
      user_id: userId,
    })
  );
  var json = await response.json();
  return json;
};

export const get_seller_products = async (userId, page, per_page) => {
  var response = await fetch(
    access_endpoint_link + "get_seller_products",
    format_request({
      user_id: userId,
      page: page,
      per_page: per_page,
    })
  );
  var json = await response.json();
  return json;
};

export const get_popular_products = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_popular_products",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_all_products = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_all_products",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_seller_products_categorized = async (userId) => {
  var response = await fetch(
    access_endpoint_link + "get_seller_products_categorized",
    format_request({
      user_id: userId,
    })
  );
  var json = await response.json();
  return json;
};

export const get_seller_products_by_category = async (
  userId,
  categoryId,
  page,
  per_page
) => {
  var response = await fetch(
    access_endpoint_link + "get_seller_products_by_category",
    format_request({
      user_id: userId,
      category_id: categoryId,
      page: page,
      per_page: per_page,
    })
  );
  var json = await response.json();
  return json;
};

export const get_seller_products_by_subcategory = async (
  userId,
  subcategoryId,
  page,
  per_page
) => {
  var response = await fetch(
    access_endpoint_link + "get_seller_products_by_subcategory",
    format_request({
      user_id: userId,
      subcategory_id: subcategoryId,
      page: page,
      per_page: per_page,
    })
  );
  var json = await response.json();
  return json;
};

export const add_service = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "add_service",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const edit_service = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "edit_service",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_service = async (userId) => {
  var response = await fetch(
    access_endpoint_link + "get_service",
    format_request({
      user_id: userId,
    })
  );
  var json = await response.json();
  return json;
};

export const get_service_details = async (id) => {
  var response = await fetch(
    access_endpoint_link + "get_service_details",
    format_request({
      service_id: id,
    })
  );
  var json = await response.json();
  return json;
};

export const get_order_by_user_details = async (id) => {
  var response = await fetch(
    access_endpoint_link + "get_order_by_user_details ",
    format_request({
      order_id: id,
    })
  );
  var json = await response.json();
  return json;
};

export const get_order_by_seller_details = async (id) => {
  var response = await fetch(
    access_endpoint_link + "get_order_by_seller_details ",
    format_request({
      order_id: id,
    })
  );
  var json = await response.json();
  return json;
};

export const get_banner_collection = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_banner_collection",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_banner_marketplace = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_banner_marketplace",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const variant_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "variant_detail",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const update_variant_image = async (formData, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "update_variant_image", {
      method: "POST",
      body: formData,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const delete_variant = async (formData, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "delete_variant", {
      method: "POST",
      body: formData,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const remove_variant_image = async (formData, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "remove_variant_image", {
      method: "POST",
      body: formData,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const update_thumbnail = async (formData, headers) => {
  try {
    const response = await fetch(access_endpoint_link + "update_thumbnail", {
      method: "POST",
      body: formData,
      headers: headers,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};