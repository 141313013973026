import { Button, Card, Grid, Step, StepLabel, Stepper, TextField } from "@material-ui/core";
import ContractorLayout from "../ContractorLayout";
import './styles.scss';
import Slider from "react-slick";
import { CalendarTodayOutlined, Folder, Money, PinDrop } from "@material-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";


const ContractorProjectDetail = () => {

    const [hasBid, setHasBid] = useState(false);
    const [hasOffer, setHasOffer] = useState(false);
    const params = useParams();
    useEffect(() => { 
        if(params.project_id == 2){
            setHasBid(true);
        }
        if(params.project_id == 3){
            setHasBid(true);
            setHasOffer(true);
        }

    }, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4
    }
    return (
        <>
            <ContractorLayout>
                <Grid container spacing={hasOffer ? 2 : 0}>
                    <Grid item xs={12} md={hasOffer ? 8 : 12}>
                        <Card className="_project_detail">
                            <div className="_head">
                                <div className="_profile">
                                    <img src="https://cdn1.npcdn.net/images/np_23950_1661319124.png" />
                                </div>
                                <div className="_name">
                                    <p>Indor Malaysia</p>
                                </div>
                            </div>
                            <div className="">
                                <p className="posting_date">
                                    Posted on 28 Oct 2023
                                </p>
                                <h3>
                                    Water plumbing work required
                                </h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                                </p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                                </p>
                            </div>
                            <div className="">
                                <h3>
                                    Details
                                </h3>
                                <div style={{
                                    display: "flex",
                                    marginBottom: 4
                                }}>
                                    <PinDrop />

                                    <div style={{
                                        marginLeft: 10,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        Taman Megah
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex"
                                }}>
                                    <CalendarTodayOutlined />

                                    <div style={{
                                        marginLeft: 10,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        22 Oct 2023 - 26 Oct 2023
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex"
                                }}>
                                    <Money />

                                    <div style={{
                                        marginLeft: 10,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        MYR 20,000 - MYR 40,000
                                    </div>
                                </div>
                            </div>
                            <h3
                                style={{
                                    marginBottom: 0
                                }}
                            >Attachments</h3>
                            <div className="_project_images">

                                {IMAGES.map(r => (
                                    <div className="_item">
                                        <img src="https://www.bhg.com/thmb/H9VV9JNnKl-H1faFXnPlQfNprYw=/1799x0/filters:no_upscale():strip_icc()/white-modern-house-curved-patio-archway-c0a4a3b3-aa51b24d14d0464ea15d36e05aa85ac9.jpg" />
                                    </div>
                                ))}

                            </div>
                            <div className="_project_docs">
                                <a href="" target="_blank">
                                    <div className="_item">
                                        <Folder
                                            style={{
                                                color: "#6093ed"
                                            }}
                                        />
                                        <div className="_name">
                                            Project Blueprint
                                        </div>
                                    </div>
                                </a>
                                <a href="" target="_blank">
                                    <div className="_item">
                                        <Folder
                                            style={{
                                                color: "#6093ed"
                                            }}
                                        />
                                        <div className="_name">
                                            Project Blueprint
                                        </div>
                                    </div>
                                </a>
                                <a href="" target="_blank">
                                    <div className="_item">
                                        <Folder
                                            style={{
                                                color: "#6093ed"
                                            }}
                                        />
                                        <div className="_name">
                                            Project Blueprint
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Card>
                    </Grid>
                    {
                        hasOffer && (
                            <Grid item xs={12} md={4}>
                                <Card className="_project_detail">

                                    <div className="">
                                        <p>Offered Amount</p>
                                        <h2
                                        >MYR 19,000</h2>
                                        <h4
                                            style={{
                                                margin: 0,
                                                marginBottom: 6
                                            }}
                                        >Details</h4>
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <CalendarTodayOutlined />

                                            <div style={{
                                                marginLeft: 10,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                22 Oct 2023 - 26 Oct 2023
                                            </div>
                                        </div>
                                    </div>

                                    <h3
                                        style={{
                                            marginBottom: 6
                                        }}
                                    >Payment Terms</h3>

                                    <Stepper orientation="vertical">
                                        <Step>
                                            <StepLabel>50% upfront</StepLabel>
                                        </Step>
                                        <Step>
                                            <StepLabel>30% upon completion</StepLabel>
                                        </Step>
                                        <Step>
                                            <StepLabel>20% upon inspection</StepLabel>
                                        </Step>
                                    </Stepper>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end"
                                    }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{
                                                marginRight: 10
                                            }}
                                        >Accept</Button>
                                        <Button
                                            variant="contained"
                                            style={{
                                                background: "#e73c5d",
                                                color: "white"
                                            }}
                                        >Reject</Button>
                                    </div>
                                </Card>
                            </Grid>
                        )
                    }
                </Grid>

                <br />

                {!hasBid && (
                    <Card className="_project_detail">
                        <div>
                            <h3>Bid for project</h3>
                            <TextField multiline={true} rows={4}
                                variant="outlined"
                                placeholder="Write why should they choose you"
                                style={{
                                    width: "100%"
                                }}
                            />
                            <br />
                            <br />
                            <p>Upload Quotation</p>
                            <TextField type="file"
                                variant="outlined"
                                style={{
                                    width: "100%"

                                }}></TextField>
                            <br />
                            <p>Total Amount</p>
                            <TextField type="number"
                                placeholder="100,000"
                                variant="outlined"
                                style={{
                                    width: "100%"

                                }}></TextField>
                            <br />
                            <br />
                            <div style={{
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end"
                            }}>
                                <Button
                                    color="primary"
                                    variant="contained">Submit Bid</Button>
                            </div>
                        </div>
                    </Card>
                )}

                {hasBid && (
                    <Card className="_project_detail">
                        <div>
                            <div style={{
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end"
                            }}>
                                <Button
                                    style={{
                                        background: "#e73c5d",
                                        color: "white"
                                    }}
                                    variant="contained">Cancel Bid</Button>
                            </div>
                            <h3>My Bid</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
                            </p>
                            <div className="_project_docs">
                                <a href="" target="_blank">
                                    <div className="_item">
                                        <Folder
                                            style={{
                                                color: "#6093ed"
                                            }}
                                        />
                                        <div className="_name">
                                            Quotation
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <p>Total Amount</p>
                            <p style={{
                                fontWeight: "bold",
                                fontSize: "1.2rem"
                            }}>MYR 20,000</p>
                            <hr />
                            {
                                REPLIES.map(r => (
                                    <>
                                        <div>
                                            <div className="_head">
                                                <div className="_profile">
                                                    <img src={r.senderImg} />
                                                </div>
                                                <div className="_name">
                                                    <p>{r.sender}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="posting_date">
                                                    28 Oct 2023 11:00 a.m.
                                                </p>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                                            </div>
                                        </div>
                                    </>
                                ))
                            }


                            <>
                                <TextField style={{
                                    width: "100%"
                                }}
                                    multiline={true}
                                    rows={3}
                                    variant="outlined"
                                    placeholder="Write a message..."
                                ></TextField>
                                <br />
                                <br />
                                <div style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    justifyContent: "flex-end"
                                }}>
                                    <Button
                                        color="primary"
                                        variant="contained">Send</Button>
                                </div>
                            </>
                        </div>
                    </Card>
                )}
            </ContractorLayout>
        </>
    )
};

export default ContractorProjectDetail;

const IMAGES = [1, 1, 1, 1, 1, 1, 1,];

const REPLIES = [
    {
        senderImg: "https://cdn1.npcdn.net/images/np_23950_1661319124.png",
        sender: "4in1",
    },
    {
        senderImg: "https://indor.my/static/media/p5.9d840036.png",
        sender: "Kimway stone",
    },
    {
        senderImg: "https://cdn1.npcdn.net/images/np_23950_1661319124.png",
        sender: "4in1",
    },
    {
        senderImg: "https://indor.my/static/media/p5.9d840036.png",
        sender: "Kimway stone",
    }
];