import React, { useState } from "react";

import { Container, Grid, TextField } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { Link, useLocation, useHistory } from "react-router-dom";
import Banner from "../../assets/images/banner_1.jpg";
import S1 from "../../assets/images/s1.png";
import S2 from "../../assets/images/s2.png";
import S3 from "../../assets/images/s3.png";
import S4 from "../../assets/images/s4.png";
import S5 from "../../assets/images/s5.png";
import S6 from "../../assets/images/s6.png";
import { ReactComponent as TextBanner } from "../../assets/images/text_banner.svg";
import Card from "@mui/material/Card";
import SliderBanner from "react-slick";
import $, { nodeName } from "jquery";
import styled from "styled-components";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import Slider from "@material-ui/core/Slider";
import "./styles.css";
import DesignItem from "../../components/design_item/design_item";
import Icon001 from "../../assets/images/icon001.png";
import banner001 from "../../assets/images/banner001.jpg";
import banner002 from "../../assets/images/banner002.jpg";
import banner003 from "../../assets/images/banner003.jpg";
import {
  get_home_projects,
  get_blog,
  get_area,
  new_get_area,
  get_good_read,
  get_good_read_top_2,
  get_tag_by_type,
  contact_form,
  get_testimonials,
} from "../../api/API";
import toast from "react-simple-toasts";
import GoogleHeader from "../../components/GoogleHeader"

const Home = (props) => {
  const history = useHistory();

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const settingTest = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  let { Home } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Home]);

  const [colorLike, setColorLike] = React.useState([
    { id: 1, color: "White", checked: false },
    { id: 2, color: "Black", checked: false },
    { id: 3, color: "Grey", checked: false },
    { id: 4, color: "Red", checked: false },
    { id: 5, color: "Blue", checked: false },
    { id: 6, color: "Yellow", checked: false },
    { id: 7, color: "Green", checked: false },
    { id: 8, color: "Pink", checked: false },
  ]);

  const [selectedColorLike, setselectedColorLike] = React.useState([]);

  const handlecheckbox = (index) => {
    // console.log(index, "index");
    var selected_data = [];
    var temp_data = [...colorLike];
    temp_data[index]["checked"] = !temp_data[index]["checked"];

    temp_data.map((data) => {
      if (data.checked == true) {
        selected_data.push(data.color);
      } else {
        selected_data.slice(data.color);
      }
    });
    // console.log(selected_data, temp_data);
    setColorLike(temp_data);
    setselectedColorLike(selected_data);
  };

  const [colorDisLike, setColorDisLike] = React.useState([
    { id: 1, color: "White", checked: false },
    { id: 2, color: "Black", checked: false },
    { id: 3, color: "Grey", checked: false },
    { id: 4, color: "Red", checked: false },
    { id: 5, color: "Blue", checked: false },
    { id: 6, color: "Yellow", checked: false },
    { id: 7, color: "Green", checked: false },
    { id: 8, color: "Pink", checked: false },
  ]);

  const [selectedColorDisLike, setselectedColorDisLike] = React.useState([]);

  const handlecheckboxDisLike = (index) => {
    // console.log(index, "index");
    var selected_data = [];
    var temp_data = [...colorDisLike];
    temp_data[index]["checked"] = !temp_data[index]["checked"];

    temp_data.map((data) => {
      if (data.checked == true) {
        selected_data.push(data.color);
      } else {
        selected_data.slice(data.color);
      }
    });
    // console.log(selected_data, temp_data);
    setColorDisLike(temp_data);
    setselectedColorDisLike(selected_data);
  };

  const [designArea, setDesignArea] = React.useState([
    { id: 1, area: "Living Hall", checked: false },
    { id: 2, area: "Dining Hall", checked: false },
    { id: 3, area: "Wet Kitchen", checked: false },
    { id: 4, area: "Dry Kitchen", checked: false },
    { id: 5, area: "Master bedroom", checked: false },
    { id: 6, area: "Bedroom 1", checked: false },
    { id: 7, area: "Bedroom 2", checked: false },
    { id: 8, area: "Guest Room", checked: false },
    { id: 9, area: "Study room", checked: false },
    { id: 10, area: "Music Room", checked: false },
    { id: 11, area: "Gym Room", checked: false },
  ]);

  const [selectedDesignArea, setselectedDesignArea] = React.useState([]);
  const [area, setArea] = React.useState([]);

  const handlecheckboxArea = (index) => {
    // console.log(index, "index");
    var selected_data = [];
    var temp_data = [...designArea];
    temp_data[index]["checked"] = !temp_data[index]["checked"];

    temp_data.map((data) => {
      if (data.checked == true) {
        selected_data.push(data.area);
      } else {
        selected_data.slice(data.area);
      }
    });
    // console.log(selected_data, temp_data);
    setDesignArea(temp_data);
    setselectedDesignArea(selected_data);
  };

  const [kitchen, setKitchen] = React.useState([
    { id: 1, area: "Gas Hob", checked: false },
    { id: 2, area: "Induction Hob", checked: false },
    { id: 3, area: "Chimney Hood", checked: false },
    { id: 4, area: "Island Hood", checked: false },
    { id: 5, area: "Build in Oven", checked: false },
    { id: 6, area: "Microwave Oven", checked: false },
    { id: 7, area: "2 Doors Fridge", checked: false },
    { id: 8, area: "4 Doors Fridge", checked: false },
    { id: 9, area: "Freezer", checked: false },
    { id: 10, area: "Dish Washer", checked: false },
    { id: 11, area: "Single Bowl Sink", checked: false },
    { id: 12, area: "Double Bowl Sink", checked: false },
    { id: 13, area: "Dish rack", checked: false },
  ]);

  const [selectedKitchen, setselectedKitchen] = React.useState([]);

  const handleKitchen = (index) => {
    // console.log(index, "index");
    var selected_data = [];
    var temp_data = [...kitchen];
    temp_data[index]["checked"] = !temp_data[index]["checked"];

    temp_data.map((data) => {
      if (data.checked == true) {
        selected_data.push(data.area);
      } else {
        selected_data.slice(data.area);
      }
    });
    // console.log(selected_data, temp_data);
    setKitchen(temp_data);
    setselectedKitchen(selected_data);
  };

  const [goodReadTop, setGoodReadTop] = React.useState([]);
  const [goodRead, setGoodRead] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [blogs, setBlogs] = React.useState([]);

  const [colorChosen, setColorChosen] = React.useState("");

  const get_data = () => {
    get_home_projects().then((res) => {
      setProjects(res.data);
    });
    get_blog().then((res) => {
      setBlogs(res.data);
      setColorChosen(res.data[0].color);
    });
    get_good_read().then((res) => {
      setGoodRead(res.data);
    });
    get_good_read_top_2().then((res) => {
      setGoodReadTop(res.data);
    });

    new_get_area().then((res) => {
      setArea(res.data);
    });

  };
  const resizeFunc = () => {
    var unit_witdh = $(".resizeIMG").width();
    $(".resizeIMG").height(unit_witdh);
  };

  const onImgLoad = ({ target: img }) => {
    const { offsetHeight, offsetWidth } = img;
    $(".resizeIMG").height(offsetWidth);
  };

  React.useEffect(() => {
    get_data();

    // resizeFunc();
    $(window).on("resize", function () {
      resizeFunc();
    });
    getTag();
  }, []);
  const openProject = (project_id) => {
    props.history.push("/project/" + project_id);
  };

  const [selectedPropertyTag, setSelectedPropertyTag] = React.useState([]);
  const [selectedStylesTag, setSelectedStylesTag] = React.useState([]);
  const [selectedAreaTag, setSelectedAreaTag] = React.useState([]);
  const [selectedFillter, setSelectedFillter] = React.useState(0);
  const [propertyTag, setPropertyTag] = React.useState(null);
  const [stylesTag, setStylesTag] = React.useState(null);
  const [areaTag, setAreaTag] = React.useState(null);

  const handleChange = (event) => {
    if (event.target.name == "property_type") {
      setSelectedPropertyTag(event.target.value);
    } else if (event.target.name == "property_styles") {
      setSelectedStylesTag(event.target.value);
    } else if (event.target.name == "property_area") {
      setSelectedAreaTag(event.target.value);
    } else {
      setSelectedFillter(event.target.value);
    }
  };

  const handleSearch = () => {
    var selectedArray = [
      ...selectedPropertyTag,
      ...selectedStylesTag,
      ...selectedAreaTag,
    ];
    if (selectedFillter == 0) {
      toast("Please select filter");
      return;
    }
    var data = selectedArray.filter((arr) => arr != 0);
    // console.log(data)
    props.history.push("/location/mountaustin/" + selectedFillter + "/" + data);
  };

  const getTag = () => {
    get_tag_by_type(1).then((res) => {
      setPropertyTag(res.data);
    });
    get_tag_by_type(2).then((res) => {
      setAreaTag(res.data);
    });
    get_tag_by_type(3).then((res) => {
      setStylesTag(res.data);
    });
    get_tag_by_type(1).then((res) => {
      setPropertyTag(res.data);
    });
  };

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  // const [formData, setFormData] = React.useState({
  //   email: "",
  //   name: "",
  //   status: "Single",
  //   nric: "",
  //   dob: "",
  //   home_address: "",
  //   phone: "",
  //   job_address: "",
  //   date_key: "",
  //   house_type: "Apartment - Studio",
  //   member: "",
  //   design_style: "Modern Contemporary",
  //   budget: "RM5,000.00 to RM10,000.00",
  //   quatation: "M&E",
  //   color_like: JSON.stringify(selectedColorLike),
  //   color_dislike: JSON.stringify(selectedColorDisLike),
  //   area_design: JSON.stringify(selectedDesignArea),
  //   living_hall: "",
  //   dining_hall: "",
  //   wet_kitchen: "",
  //   kitchen_appliances: JSON.stringify(selectedKitchen),
  //   dry_kitchen: "",
  //   master_badroom: "",
  //   bedroom_one: "",
  //   bedroom_two: "",
  //   bedroom_three: "",
  //   bedroom_four: "",
  //   guest_room: "",
  //   study_room: "",
  //   music_room: "",
  //   gym_room: "",
  //   other_requirement: "",
  // });

  const [price, setPrice] = React.useState([10000, 1000000]);

  const handleChangePrice = (event, newValue) => {
    setPrice(newValue);
  };

  const [formData, setFormData] = React.useState({
    name: "",
    phone: "",
    home_address: "",
    house_type: "",
    // design_style: "",
    // budget: "",
  });

  const names = [
    'Minimalist',
    'Modern Contemporary',
    'Modern Classic',
    'Urban',
    'Muji',
    'Industrial',
    'Zen',
  ];

  const [designStyle, setDesignStyle] = React.useState([]);

  const handleDesignChange = (event) => {
    const {
      target: { value },
    } = event;
    setDesignStyle(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleFormChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });

  };

  // const handleForm = () => {
  //   if (formData.email.length == 0) {
  //     toast("Email is required");
  //     return;
  //   }
  //   if (formData.name.length == 0) {
  //     toast("Name is required");
  //     return;
  //   }
  //   if (formData.nric.length == 0) {
  //     toast("I/C No is required");
  //     return;
  //   }
  //   if (formData.dob.length == 0) {
  //     toast("Birthday is required");
  //     return;
  //   }
  //   if (formData.home_address.length == 0) {
  //     toast("Your Current Home Address is required");
  //     return;
  //   }
  //   if (formData.phone.length == 0) {
  //     toast("Phone Number is required");
  //     return;
  //   }
  //   if (formData.job_address.length == 0) {
  //     toast("Job Site Address is required");
  //     return;
  //   }
  //   if (formData.date_key.length == 0) {
  //     toast("Date of Key Release is required");
  //     return;
  //   }
  //   if (formData.member.length == 0) {
  //     toast("Member of Household is required");
  //     return;
  //   }
  //   if (selectedColorLike.length == 0) {
  //     toast("Please Choose a colour(s) do you like");
  //     return;
  //   }
  //   if (selectedColorDisLike.length == 0) {
  //     toast("Please Choose a colour(s) do you dislike");
  //     return;
  //   }
  //   if (selectedDesignArea.length == 0) {
  //     toast("Please Choose which area(s) you wish to design?");
  //     return;
  //   }
  //   if (formData.living_hall.length == 0) {
  //     toast("Requirements for Living Hall is required");
  //     return;
  //   }
  //   if (formData.dining_hall.length == 0) {
  //     toast("Requirements for Dining Hall is required");
  //     return;
  //   }
  //   if (formData.wet_kitchen.length == 0) {
  //     toast("Requirements for Wet Kitchen is required");
  //     return;
  //   }
  //   if (selectedKitchen.length == 0) {
  //     toast("Please Choose a Kitchen Appliances");
  //     return;
  //   }
  //   if (formData.dry_kitchen.length == 0) {
  //     toast("Requirements for Dry Kitchen is required");
  //     return;
  //   }
  //   if (formData.master_badroom.length == 0) {
  //     toast("Requirements for Master Bedroom is required");
  //     return;
  //   }
  //   if (formData.bedroom_one.length == 0) {
  //     toast("Requirements for Bedroom 1 is required");
  //     return;
  //   }
  //   if (formData.bedroom_two.length == 0) {
  //     toast("Requirements for Bedroom 2 is required");
  //     return;
  //   }
  //   if (formData.bedroom_three.length == 0) {
  //     toast("Requirements for Bedroom 3 is required");
  //     return;
  //   }
  //   if (formData.bedroom_four.length == 0) {
  //     toast("Requirements for Bedroom 4 is required");
  //     return;
  //   }
  //   if (formData.guest_room.length == 0) {
  //     toast("Requirements for Guest Room is required");
  //     return;
  //   }
  //   if (formData.study_room.length == 0) {
  //     toast("Requirements for Study Room is required");
  //     return;
  //   }
  //   if (formData.music_room.length == 0) {
  //     toast("Requirements for Music Room is required");
  //     return;
  //   }
  //   if (formData.gym_room.length == 0) {
  //     toast("Requirements for GYM room is required");
  //     return;
  //   }
  //   var data = {
  //     ...formData,
  //     color_like: JSON.stringify(selectedColorLike),
  //     color_dislike: JSON.stringify(selectedColorDisLike),
  //     area_design: JSON.stringify(selectedDesignArea),
  //     kitchen_appliances: JSON.stringify(selectedKitchen),
  //   };
  //   contact_form(data).then((res) => {
  //     if (res.status) {
  //       toast("Form Submitted");
  //       window.location.reload();
  //     } else {
  //       toast("Please Submit Again");
  //     }
  //   });
  // };

  const handleForm = () => {

    contact_form({ ...formData, budget: price, design_style: designStyle }).then((res) => {
      if (res.status) {
        toast("Form Submitted");
        window.location.reload();
      } else {
        toast("Please Submit Again");
      }
    });
  };

  const [testimonialData, setTestimonialData] = React.useState([]);

  const getTestimonial = () => {
    get_testimonials().then((res) => {
      setTestimonialData(res.data);
    })
  }

  React.useEffect(() => {
    getTestimonial();
  }, []);

  const isMobile = window.innerWidth <= 768;

  return (
    <div className="home_page">
      <GoogleHeader />
      <SliderBanner {...settings}>
        <a target="_blank" href="/joinus">
          <div className="homeBanner">
            <img src={banner001} />
            {/* <div className="homePosition">
            <div className="homeCurve">
              <img src={Curve} />
              <div className="homeWord">
                <p>Indor</p>
                <p>Contractors</p>
                <p>Interior Designer Home</p>
                <p>Furnishing Enthusiasts</p>
              </div>
            </div>
          </div> */}
          </div>
        </a>

        <a target="_blank" href="/contactus">
          <div className="homeBanner">
            <img src={banner002} />
            {/* <div className="homePosition">
            <div className="homeCurve">
              <img src={Curve} />
              <div className="homeWord">
                <p>Indor</p>
                <p>Contractors</p>
                <p>Interior Designer Home</p>
                <p>Furnishing Enthusiasts</p>
              </div>
            </div>
          </div> */}
          </div>
        </a>

        <a target="_blank" href="/about">
          <div className="homeBanner">
            <img src={banner003} />
            {/* <div className="homePosition">
            <div className="homeCurve">
              <img src={Curve} />
              <div className="homeWord">
                <p>Indor</p>
                <p>Contractors</p>
                <p>Interior Designer Home</p>
                <p>Furnishing Enthusiasts</p>
              </div>
            </div>
          </div> */}
          </div>
        </a>
      </SliderBanner>

      <div>
        <Container>
          <div className="homeSearch">
            <div className="homeFlex" id="search">
              <p>Search your favourite interior-designed projects</p>
              <div className="homeFilter">
                <Grid container spacing={2}>
                  {/* <Grid item xs={12} sm={6} md={6}>
                    <FormControl className="homeSelect" fullWidth>
                      <Select
                        defaultValue=""
                        name="property_type"
                        value={
                          selectedPropertyTag == "" ? [0] : selectedPropertyTag
                        }
                        // multiple
                        onChange={handleChange}
                      >
                        <MenuItem disabled value={0}>
                          Property Type
                        </MenuItem>
                        {propertyTag != null &&
                          propertyTag.map((property) => (
                            <MenuItem value={property.tag_id}>
                              {property.tag}
                            </MenuItem>
                          ))}
                      
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12} sm={6} md={2}>
                    <select
                      className="homeAllFilter"
                      defaultValue=""
                      name="property_type"
                      value={
                        selectedPropertyTag == "" ? [0] : selectedPropertyTag
                      }
                      // multiple
                      onChange={handleChange}
                    >
                      <option selected>Property Type</option>
                      {propertyTag != null &&
                        propertyTag.map((property) => (
                          <option value={property.tag_id}>
                            {property.tag}
                          </option>
                        ))}
                    </select>
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={6}>
                    <FormControl className="homeSelect" fullWidth>
                      
                      <Select
                        name="property_styles"
                        value={
                          selectedStylesTag == "" ? [0] : selectedStylesTag
                        }
                        // multiple
                        onChange={handleChange}
                      >
                        <MenuItem disabled value={0}>
                          Property Style
                        </MenuItem>
                        {stylesTag != null &&
                          stylesTag.map((property) => (
                            <MenuItem value={property.tag_id}>
                              {property.tag}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={2}>
                    <select
                      className="homeAllFilter"
                      name="property_styles"
                      value={selectedStylesTag == "" ? [0] : selectedStylesTag}
                      // multiple
                      onChange={handleChange}
                    >
                      <option selected>Property Style</option>
                      {stylesTag != null &&
                        stylesTag.map((property) => (
                          <option value={property.tag_id}>
                            {property.tag}
                          </option>
                        ))}
                    </select>
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={6}>
                    <FormControl className="homeSelect" fullWidth>
                      <Select
                        name="property_area"
                        value={selectedAreaTag == "" ? [0] : selectedAreaTag}
                        // multiple
                        onChange={handleChange}
                      >
                        <MenuItem disabled value={0}>
                          Property Area
                        </MenuItem>
                        {areaTag != null &&
                          areaTag.map((property) => (
                            <MenuItem value={property.tag_id}>
                              {property.tag}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12} sm={6} md={2}>
                    <select
                      className="homeAllFilter"
                      name="property_area"
                      value={selectedAreaTag == "" ? [0] : selectedAreaTag}
                      // multiple
                      onChange={handleChange}
                    >
                      <option selected>Property Area</option>
                      {areaTag != null &&
                        areaTag.map((property) => (
                          <option value={property.tag_id}>
                            {property.tag}
                          </option>
                        ))}
                    </select>
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={6}>
                    <FormControl className="homeSelect" fullWidth>
                      <Select
                        name="filter"
                        value={selectedFillter}
                        onChange={handleChange}
                      >
                        <MenuItem disabled value={0}>
                          Filter
                        </MenuItem>
                        <MenuItem value={1}>Latest</MenuItem>
                        <MenuItem value={2}>Popular</MenuItem>
                        <MenuItem value={3}>Nearby</MenuItem>
                        <MenuItem value={4}>Favourite</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={2}>
                    <select
                      className="homeAllFilter"
                      name="filter"
                      value={selectedFillter}
                      onChange={handleChange}
                    >
                      <option selected>Filter</option>
                      <option value={1}>Latest</option>
                      <option value={2}>Popular</option>
                      <option value={3}>Nearby</option>
                      <option value={4}>Favourite</option>
                    </select>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className="homeBtnStyle">
                      <Button
                        onClick={handleSearch}
                        className="homeSearchBtn"
                        variant="contained"
                      >
                        Search
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {/* <div className="homeTextField">
                <TextField
                  className="homeText"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Search..."
                />
              </div> */}
            </div>
          </div>
        </Container>
      </div>

      {/* <Container>
        <Grid container className="page_padding ">
            <Grid className="hide_mobile" item md={5}>
                <img style={{
                    width: '80%',
                    marginTop: '5%'
                }} src={FloorPlan}></img>
            </Grid>
            <Grid className="hide_desktop" item md={5}>
                <img style={{
                    width: '80%',
                    marginLeft: " 10%",
                    marginTop: '5%'
                }} src={FloorPlan}></img>
            </Grid>
            <Grid item md={7} className="short_desc">
                <h2>Choose Your Home Design <br />
                    From A Variety of Renovation Experts
                </h2>
                <p>
                    Indor is a revolutionary marketplace, connecting homeowners with renovation experts to provide a seamless and stress-free renovation journey from home design to completion.

                </p>
                <h2>Why Choose Us
                </h2>
                <p>
                    A trusted marketplace connecting homeowners with skilled renovation experts who will help you renovate a home. </p>
                <p>Indor provides a platform where homeowners can manage their renovation through the eyes of experienced contractor throughout its progress in an easier way. </p>

                <p> Keeping track of your renovations is important to you, so we made it easy for you

                </p>
            </Grid>
        </Grid>
      </Container> */}

      <div className="c-section2">
        <div className="page_padding">
          <Container>
            <div className="theTab">
              <a href="#findyourunit" className="theNav">
                Find Your Unit
              </a>
              <a href="#recentproject" className="theNav">
                Recent Project
              </a>
              {isMobile ? (
                <a href="#goodread1" className="theNav">
                  Good Read
                </a>
              ) : (
                <a href="#goodread" className="theNav">
                  Good Read
                </a>
              )}
            </div>
            <div className="c-section2TextWrap">
              <h2 className="c-title">Why Choose Indor?</h2>
              <p>
                Your one-stop solution for home owners, interior designers,
                sub-contractors, and direct suppliers
              </p>
            </div>

            <div className="c-section2Box">
              <div className="c-flexWrap">
                <div className="theCard">
                  <div className="theProd1">
                    <img src={S1} alt="" />
                  </div>
                  <p>
                    Systematic monitoring and management of your renovation
                    projects.
                  </p>
                </div>
                <div className="theCard">
                  <div className="theProd2">
                    <img src={S2} alt="" />
                  </div>
                  <p>
                    Safer and faster 3rd party platform to execute your
                    transaction.
                  </p>
                </div>
                <div className="theCard">
                  <div className="theProd3">
                    <img src={S3} alt="" />
                  </div>
                  <p>Connects you with top-notch interior designers.</p>
                </div>
                <div className="theCard">
                  <div className="theProd4">
                    <img src={S4} alt="" />
                  </div>
                  <p>
                    From planning and consultation to installation, our
                    professionals are with you every step of the way.
                  </p>
                </div>
                <div className="theCard">
                  <div className="theProd5">
                    <img src={S5} alt="" />
                  </div>
                  <p>
                    Offers the ultimate protection to homeowners, interior
                    designers, and suppliers’ rights.
                  </p>
                </div>
                <div className="theCard">
                  <div className="theProd6">
                    <img src={S6} alt="" />
                  </div>
                  <p>
                    Opportunity to work with the direct supplier of materials
                    for an exclusive offer.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="c-section3" id="findyourunit">
        <div className="page_padding">
          <Container>
            <div className="c-section3Box">
              <h2 className="c-title">Find Your Unit</h2>
              <div className="c-section3TextWrap">
                <p>
                  We'd love to help you plan the home of your dreams. Browse the following units and see the designs that match your house types or near the region where you stay.
                </p>
              </div>
              <div className="c-gridBox">
                <Grid container spacing={3}>

                  {area.map((data, key) => (
                    <Grid item md={4} sm={4} xs={6} className="_item">
                      <div
                        className="location_item"
                        onClick={() => {
                          props.history.push("/location/" + data.slug + "/1");

                        }}
                      >
                        <div className="location_overlay flex_center">
                          {data.area}
                        </div>
                        <img className="resizeIMG" onLoad={onImgLoad} src={data.area_image} />
                      </div>
                    </Grid>
                  ))}
                  {/* <Grid item md={4} sm={4} xs={6} className="_item">
                    <div
                      className="location_item"
                      onClick={() => {
                        props.history.push("/location/mountaustin");
                      }}
                    >
                      <div className="location_overlay flex_center">
                        Taman Daya
                      </div>
                      <img className="resize" src={Daya} />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={4} xs={6} className="_item">
                    <div
                      className="location_item"
                      onClick={() => {
                        props.history.push("/location/mountaustin");
                      }}
                    >
                      <div className="location_overlay flex_center">
                        Mahsing
                      </div>
                      <img className="resize" src={Mahsing} />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={4} xs={6} className="_item">
                    <div
                      className="location_item"
                      onClick={() => {
                        props.history.push("/location/mountaustin");
                      }}
                    >
                      <div className="location_overlay flex_center">
                        Setia Indah
                      </div>
                      <img className="resize" src={SetiaIndah} />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={4} xs={6} className="_item">
                    <div
                      className="location_item"
                      onClick={() => {
                        props.history.push("/location/mountaustin");
                      }}
                    >
                      <div className="location_overlay flex_center">
                        JP Perdana
                      </div>
                      <img className="resize" src={Perdana} />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={4} xs={6} className="_item">
                    <div
                      className="location_item"
                      onClick={() => {
                        props.history.push("/location/mountaustin");
                      }}
                    >
                      <div className="location_overlay flex_center">
                        Austin Heights
                      </div>
                      <img className="resize" src={AustinHeight} />
                    </div>
                  </Grid> */}
                </Grid>
              </div>
            </div>
          </Container>
        </div>
      </div>

      {/* <Container>
          <Grid container spacing={2} style={{
              justifyContent: "space-between"
          }}>
              <Grid item xs={2}>
                  <p>Exclusive deals, accumulate points to redeem products</p>
              </Grid>
              <Grid item xs={2}>
                  <p>Match you with the pros who know what they are doing </p>
              </Grid>
              <Grid item xs={2}>
                  <p>A wide variety of IDs and contractors available </p>
              </Grid>
              <Grid item xs={2}>
                  <p>You will find trustworthy interior designers for your project</p>
              </Grid>
              <Grid item xs={2}>
                  <p>Sign a written contract and rest assured that work will begin and be finished</p>
              </Grid>
          </Grid>
      </Container> */}

      {/* <h3 style={{
                textAlign: "center"
            }}>Find Your Unit</h3>
            <Slider {...settings} className="hide_mobile">
                <div className="location_item" onClick={() => { props.history.push("/location/mountaustin") }}>
                    <div className="location_overlay flex_center">
                        Mount Austin
                    </div>
                    <img src={MountAustin} />
                </div>
                <div className="location_item" onClick={() => { props.history.push("/location/mountaustin") }}>
                    <div className="location_overlay flex_center">
                        Kulai
                    </div>
                    <img src={Kulai} />
                </div>
                <div className="location_item" onClick={() => { props.history.push("/location/mountaustin") }}>
                    <div className="location_overlay flex_center">
                        RNF
                    </div>
                    <img src={RNF} />
                </div>
                <div className="location_item" onClick={() => { props.history.push("/location/mountaustin") }}>
                    <div className="location_overlay flex_center">
                        Sutera
                    </div>
                    <img src={Sutera} />
                </div>
                <div className="location_item" onClick={() => { props.history.push("/location/mountaustin") }}>
                    <div className="location_overlay flex_center">
                        Skudai
                    </div>
                    <img src={Skudai} />
                </div>
                <div className="location_item" onClick={() => { props.history.push("/location/mountaustin") }}>
                    <div className="location_overlay flex_center">
                        Puteri Harbour
                    </div>
                    <img src={PuteriHarbour} />
                </div>
            </Slider> */}
      {/* <div className="hide_desktop location_wrapper">

                <div className="location_item" onClick={() => { props.history.push("/location/mountaustin") }}>
                    <div className="location_overlay flex_center">
                        Mount Austin
                    </div>
                    <img src={MountAustin} />
                </div>
                <div className="location_item" onClick={() => { props.history.push("/location/mountaustin") }}>
                    <div className="location_overlay flex_center">
                        Kulai
                    </div>
                    <img src={Kulai} />
                </div>
                <div className="location_item" onClick={() => { props.history.push("/location/mountaustin") }}>
                    <div className="location_overlay flex_center">
                        RNF
                    </div>
                    <img src={RNF} />
                </div>
                <div className="location_item" onClick={() => { props.history.push("/location/mountaustin") }}>
                    <div className="location_overlay flex_center">
                        Sutera
                    </div>
                    <img src={Sutera} />
                </div>
                <div className="location_item" onClick={() => { props.history.push("/location/mountaustin") }}>
                    <div className="location_overlay flex_center">
                        Skudai
                    </div>
                    <img src={Skudai} />
                </div>
                <div className="location_item" onClick={() => { props.history.push("/location/mountaustin") }}>
                    <div className="location_overlay flex_center">
                        Puteri Harbour
                    </div>
                    <img src={PuteriHarbour} />
                </div>
            </div> */}

      <div className="page_padding" id="recentproject">
        <Container>
          <h2 className="design_subtitle">Recent Project</h2>
          <div className="design_container">
            {projects.map((proj) => (
              <DesignItem
                onClick={() => {
                  openProject(proj.project_id);
                }}
                images={proj.images}
                name={proj.title}
                tag={proj.tags}
                designer={proj.user.company_name}
                designerarray={proj.user.image}
              ></DesignItem>
            ))}
          </div>
        </Container>
      </div>

      <div
        className=" hide_mobile"
        style={{
          background: "#dadbdb",
          padding: "20px 0",
          marginTop: 20,
        }}
      >
        <Container>
          <div className="page_padding" id="goodread" >
            <h2 className="news_subtitle">Good Read</h2>

            <Grid container className="news_wrapper">
              {/* {blogs.map((blog) => (
                <>
                  {blog.blog_id == 1 || blog.blog_id == 8 ? (
                    <>
                      <Grid
                        item
                        md={5}
                        sm={6}
                        xs={6}
                        className="_item _large"
                        onClick={() => {
                          props.history.push("/article/" + blog.blog_id);
                        }}
                      >
                        <img src={blog.blog_image} />
                        <div className="overlay">
                          <p>{blog.title}</p>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        md={2}
                        sm={6}
                        xs={6}
                        className="_item"
                        onClick={() => {
                          props.history.push("/article/" + blog.blog_id);
                        }}
                      >
                        <div className="item_image">
                          <img src={blog.blog_image} />
                        </div>
                        <p>{blog.title}</p>
                        <p className="read_more">Read More</p>
                      </Grid>
                    </>
                  )}
                </>
              ))} */}

              {blogs.map((row, index) => (
                <>
                  {/* <p>{index}</p> */}
                  {index == 0 || index == 1 ? (
                    // <p>index not 1 and 2{row.blog_image}</p>

                    <Grid item md={6} sm={6} xs={12}>
                      <a href={'/Article/' + row.blog_id}>
                        <div className="_item _large">
                          <div className="img_position">
                            <img className="c-blogIMG" src={row.blog_image} />

                            <div className="svg_img">
                              {/* <StyledSVG
                                  className="svgStyleTop"
                                  // style={{fill: row.color }}
                                  color={colorChosen}
                                  src={TextBanner}
                                  // fill={colorChosen}
                                /> */}
                              <TextBanner
                                className="svgStyleTop"
                                style={{ fill: row.color }}
                              />
                              <p className="read_moreTop">{row.title}</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </Grid>
                  ) : (
                    // <p>index 1 and 2{row.blog_image}</p>

                    <Grid item md={4} sm={4} xs={12}>
                      <a href={'/Article/' + row.blog_id}>

                        <div className="_item _large">
                          <div className="img_position">
                            <img className="c-blogIMG" src={row.blog_image} />


                            <div className="svg_img">
                              <TextBanner
                                className="svgStyleTop"
                                style={{ fill: row.color }}
                              />
                              <p className="read_moreBtm">{row.title}</p>
                            </div>
                          </div>
                        </div>
                      </a>

                    </Grid>
                  )}
                </>
              ))}

              {/* {goodRead.map((row) => (
             
             <Grid item md={4} sm={4} xs={12}>
               <div className="_item _large">
                 <div className="img_position">
                   <img src={row.image} />

                   <div className="svg_img">
                     <TextBanner className="svgStyleTop" style={{color : row.color_code}} />
                     <p className="read_moreTop">{row.title}</p>
                   </div>
                 </div>
               </div>
             </Grid>
           ))} */}
              {/* <Grid item md={4} sm={4} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House003} />
                    <TextBanner className="svgStyleBtm" />
                    <div>
                      <p className="read_moreBtm">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House004} />
                    <TextBanner className="svgStyleBtm" />
                    <div>
                      <p className="read_moreBtm">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House005} />
                    <TextBanner className="svgStyleBtm" />
                    <div>
                      <p className="read_moreBtm">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House006} />
                    <TextBanner className="svgStyleBtm" />
                    <div>
                      <p className="read_moreBtm">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House007} />
                    <TextBanner className="svgStyleBtm" />
                    <div>
                      <p className="read_moreBtm">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House008} />
                    <TextBanner className="svgStyleBtm" />
                    <div>
                      <p className="read_moreBtm">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid> */}
            </Grid>
          </div>
        </Container>
      </div>

      <div
        className=" hide_desktop"
        style={{
          background: "white",
          padding: "20px 0",
          marginTop: 20,
        }}
      >
        <Container>
          <div className="page_padding" id="goodread1">
            <h3 className="news_subtitle">Good Reads</h3>
            <Grid container className="news_wrapper">
              {/* {blogs.map((blog) => (
                <Grid
                  item
                  md={2}
                  sm={6}
                  xs={6}
                  className="_item"
                  onClick={() => {
                    props.history.push("/article/" + blog.blog_id);
                  }}
                >
                  <div className="item_image">
                    <img src={blog.blog_image} />
                  </div>
                  <p>{blog.title}</p>
                  <p className="read_more">Read More</p>
                </Grid>
              ))} */}
              {blogs.map((row, index) => (
                <>
                  {/* <p>{index}</p> */}
                  {index == 0 || index == 1 ? (
                    // <p>index not 1 and 2{row.blog_image}</p>
                    <Grid item md={6} sm={6} xs={12}>
                      <a href={'/Article/' + row.blog_id}>
                      <div className="_item _large">
                        <div className="img_position">
                          <img className="c-blogIMG" src={row.blog_image} />

                          <div className="svg_img">
                            <TextBanner
                              className="svgStyleTop"
                              style={{ fill: row.color }}
                            />
                            <p className="read_moreTop">{row.title}</p>
                          </div>
                        </div>
                      </div>
                      </a>
                    </Grid>
                  ) : (
                    // <p>index 1 and 2{row.blog_image}</p>

                    <Grid item md={4} sm={4} xs={12}>
                      <a href={'/Article/' + row.blog_id}>
                      <div className="_item _large">
                        <div className="img_position">
                          <img className="c-blogIMG" src={row.blog_image} />

                          <div className="svg_img">
                            <TextBanner
                              className="svgStyleTop"
                              style={{ fill: row.color }}
                            />
                            <p className="read_moreBtm">{row.title}</p>
                          </div>
                        </div>
                      </div>
                      </a>
                    </Grid>
                  )}
                </>
              ))}
              {/* <Grid item md={6} sm={6} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House002} />
                    <div className="svg_img">
                      <TextBanner className="svgStyleTop" />
                      <p className="read_moreTop">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House002} />
                    <div className="svg_img">
                      <TextBanner className="svgStyleTop" />
                      <p className="read_moreTop">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House003} />
                    <TextBanner className="svgStyleBtm" />
                    <div>
                      <p className="read_moreBtm">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House004} />
                    <TextBanner className="svgStyleBtm" />
                    <div>
                      <p className="read_moreBtm">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House005} />
                    <TextBanner className="svgStyleBtm" />
                    <div>
                      <p className="read_moreBtm">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House006} />
                    <TextBanner className="svgStyleBtm" />
                    <div>
                      <p className="read_moreBtm">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House007} />
                    <TextBanner className="svgStyleBtm" />
                    <div>
                      <p className="read_moreBtm">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="_item _large">
                  <div className="img_position">
                    <img src={House008} />
                    <TextBanner className="svgStyleBtm" />
                    <div>
                      <p className="read_moreBtm">Read More</p>
                    </div>
                  </div>
                </div>
              </Grid> */}
            </Grid>
          </div>
        </Container>
      </div>
      {window.innerWidth < 800 ? (
        <></>
      ) : (
        <div>
          <div className="homeBtn" onClick={handleClickOpen("paper")}>
            <img src={Icon001} />
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">Requirement Form</DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <p>Name(Mr/Mrs/Madam/Miss):</p>
                    <TextField
                      className="theHome"
                      id="outlined-basic"
                      name="name"
                      onChange={handleFormChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <p>Phone Number:</p>
                    <TextField
                      className="theHome"
                      id="outlined-basic"
                      name="phone"
                      onChange={handleFormChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <p>Email:</p>
                    <TextField
                      className="theHome"
                      id="outlined-basic"
                      name="home_address"
                      onChange={handleFormChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <p>House Type:</p>
                    <FormControl fullWidth>
                      <Select
                        name="house_type"
                        onChange={handleFormChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="Studio">Studio</MenuItem>
                        <MenuItem value="Apartment - 2 rooms">Apartment - 2 rooms</MenuItem>
                        <MenuItem value="Apartment - 3 rooms">Apartment - 3 rooms</MenuItem>
                        <MenuItem value="Town house">Town house</MenuItem>
                        <MenuItem value="Double storey terrace house">Double storey terrace house</MenuItem>
                        <MenuItem value="Double storey house corner or end lot">Double storey house corner or end lot</MenuItem>
                        <MenuItem value="Three storey terrace house">Three storey terrace house</MenuItem>
                        <MenuItem value="Cluster House">Cluster House</MenuItem>
                        <MenuItem value="Land to build">Land to build</MenuItem>
                        <MenuItem value="Semi-Detached">Semi-Detached</MenuItem>
                        <MenuItem value="Bungalow">Bungalow</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <p>Design Style:</p>
                    <FormControl fullWidth>
                      <Select
                        name="design_style"
                        onChange={handleDesignChange}
                        // multiple
                        value={designStyle}
                      >
                        {names.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Budget RM {price[0]} - {price[1]}

                    </p>
                    <Slider
                      name="budget"
                      value={price}
                      onChange={handleChangePrice}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      min={10000}
                      max={1000000}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12}>
                  <p>Email:</p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="email"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid> */}
                  {/* <Grid item xs={12} sm={12} md={12}>
                  <p>Status:</p>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="status"
                      onChange={handleFormChange}
                      value={formData.status_id}
                    >
                      <FormControlLabel
                        value="Single"
                        control={<Radio />}
                        label="Single"
                      />
                      <FormControlLabel
                        value="Married"
                        control={<Radio />}
                        label="Married"
                      />
                      <FormControlLabel
                        value="Going to"
                        control={<Radio />}
                        label="Going to"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid> */}
                  {/* <Grid item xs={12} sm={12} md={12}>
                  <p>I/C No:</p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="nric"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>Birthday:</p>
                  <Stack component="form" noValidate spacing={3}>
                    <TextField
                      id="date"
                      type="date"
                      sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="dob"
                      onChange={handleFormChange}
                    />
                  </Stack>
                </Grid> */}


                  {/* <Grid item xs={12} sm={12} md={12}>
                  <p>Job Site Address:</p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="job_address"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>Date of Key Release:</p>
                  <Stack component="form" noValidate spacing={3}>
                    <TextField
                      id="date"
                      type="date"
                      sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="date_key"
                      onChange={handleFormChange}
                    />
                  </Stack>
                </Grid> */}
                  {/* <Grid item xs={12} sm={12} md={12}>
                  <p>House Type:</p>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="house_type"
                      onChange={handleFormChange}
                      value={formData.house_type}
                    >
                      <FormControlLabel
                        value="Apartment - Studio"
                        control={<Radio />}
                        label="Apartment - Studio"
                      />
                      <FormControlLabel
                        value="Apartment - 2 rooms"
                        control={<Radio />}
                        label="Apartment - 2 rooms"
                      />
                      <FormControlLabel
                        value="Apartment - 3 rooms"
                        control={<Radio />}
                        label="Apartment - 3 rooms"
                      />
                      <FormControlLabel
                        value="Town house"
                        control={<Radio />}
                        label="Town house"
                      />
                      <FormControlLabel
                        value="Double storey terrace house"
                        control={<Radio />}
                        label="Double storey terrace house"
                      />
                      <FormControlLabel
                        value="Double storey house corner or end lot"
                        control={<Radio />}
                        label="Double storey house corner or end lot"
                      />
                      <FormControlLabel
                        value="Three storey terrace house"
                        control={<Radio />}
                        label="Three storey terrace house"
                      />
                      <FormControlLabel
                        value="Three storey house corner or end lot"
                        control={<Radio />}
                        label="Three storey house corner or end lot"
                      />
                      <FormControlLabel
                        value="Cluster House"
                        control={<Radio />}
                        label="Cluster House"
                      />
                      <FormControlLabel
                        value="Semi Detached"
                        control={<Radio />}
                        label="Semi Detached"
                      />
                      <FormControlLabel
                        value="Bungalow"
                        control={<Radio />}
                        label="Bungalow"
                      />
                      <FormControlLabel
                        value="Land to build"
                        control={<Radio />}
                        label="Land to build"
                      />
                      
                    </RadioGroup>
                  </FormControl>
                </Grid> */}
                  {/* <Grid item xs={12} sm={12} md={12}>
                  <p>Member of Household (How many people staying?)</p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="member"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid> */}
                  {/* <Grid item xs={12} sm={12} md={12}>
                  <p>What design style do u prefer?</p>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="design_style"
                      onChange={handleFormChange}
                      value={formData.design_style}
                    >
                      <FormControlLabel
                        value="Modern Contemporary"
                        control={<Radio />}
                        label="Modern Contemporary"
                      />
                      <FormControlLabel
                        value="Modern Classic or Classic"
                        control={<Radio />}
                        label="Modern Classic or Classic"
                      />
                      <FormControlLabel
                        value="Urban"
                        control={<Radio />}
                        label="Urban"
                      />
                      <FormControlLabel
                        value="Muji"
                        control={<Radio />}
                        label="Muji"
                      />
                      <FormControlLabel
                        value="Industrial"
                        control={<Radio />}
                        label="Industrial"
                      />
                      <FormControlLabel
                        value="Zen"
                        control={<Radio />}
                        label="Zen"
                      /> */}
                  {/* <FormControlLabel
                        value="Other:"
                        control={<Radio />}
                        label="Other:"
                      /> */}
                  {/* </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>Estimation Budget For Renovation</p>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="budget"
                      onChange={handleFormChange}
                      value={formData.budget}
                    >
                      <FormControlLabel
                        value="RM5,000.00 to RM10,000.00"
                        control={<Radio />}
                        label="RM5,000.00 to RM10,000.00"
                      />
                      <FormControlLabel
                        value="RM10,000 to RM50,000"
                        control={<Radio />}
                        label="RM10,000 to RM50,000"
                      />
                      <FormControlLabel
                        value="RM50,000 to RM100,000"
                        control={<Radio />}
                        label="RM50,000 to RM100,000"
                      />
                      <FormControlLabel
                        value="RM100,000 to RM200,000"
                        control={<Radio />}
                        label="RM100,000 to RM200,000"
                      />
                      <FormControlLabel
                        value="RM200,000 to RM300,000"
                        control={<Radio />}
                        label="RM200,000 to RM300,000"
                      />
                      <FormControlLabel
                        value="RM300,000 to RM400,000"
                        control={<Radio />}
                        label="RM300,000 to RM400,000"
                      />
                      <FormControlLabel
                        value="RM400,000 to RM500,000"
                        control={<Radio />}
                        label="RM400,000 to RM500,000"
                      />
                      <FormControlLabel
                        value="RM500,000 to RM1million"
                        control={<Radio />}
                        label="RM500,000 to RM1million"
                      />
                      <FormControlLabel
                        value="RM1million above"
                        control={<Radio />}
                        label="RM1million above"
                      /> */}
                  {/* <FormControlLabel
                        value="Other:"
                        control={<Radio />}
                        label="Other:"
                      /> */}
                  {/* </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>Quotation include</p>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="quatation"
                      onChange={handleFormChange}
                      value={formData.quatation}
                    >
                      <FormControlLabel
                        value="M&E"
                        control={<Radio />}
                        label="M&E"
                      />
                      <FormControlLabel
                        value="Air Cond"
                        control={<Radio />}
                        label="Air Cond"
                      />
                      <FormControlLabel
                        value="Ceiling"
                        control={<Radio />}
                        label="Ceiling"
                      />
                      <FormControlLabel
                        value="Wet Work"
                        control={<Radio />}
                        label="Wet Work"
                      />
                      <FormControlLabel
                        value="Painting"
                        control={<Radio />}
                        label="Painting"
                      />
                      <FormControlLabel
                        value="Flooring"
                        control={<Radio />}
                        label="Flooring"
                      />
                      <FormControlLabel
                        value="Carpentry work"
                        control={<Radio />}
                        label="Carpentry work"
                      />
                      <FormControlLabel
                        value="Curtain"
                        control={<Radio />}
                        label="Curtain"
                      />
                      <FormControlLabel
                        value="Alarm"
                        control={<Radio />}
                        label="Alarm"
                      />
                      <FormControlLabel
                        value="CCTV"
                        control={<Radio />}
                        label="CCTV"
                      /> */}
                  {/* <FormControlLabel
                        value="Other:"
                        control={<Radio />}
                        label="Other:"
                      /> */}
                  {/* </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>What colour(s) do you like?</p>
                  <FormControl>
                    {colorLike.map((color, key) => (
                      <>
                        <FormControlLabel
                          // name={property.tag}
                          onChange={(event) => handlecheckbox(key)}
                          checked={color.checked}
                          value={color.color}
                          control={<Checkbox color="primary" />}
                          label={color.color}
                          labelPlacement="end"
                        />
                      </>
                    ))} */}
                  {/* <FormControlLabel
                    name="color_like"
                    onChange={(e) => console.log(e.target)}
                      value="White"
                      control={<Checkbox />}
                      label="White"
                    />
                    <FormControlLabel
                      value="Black"
                      control={<Checkbox />}
                      label="Black"
                    /> */}
                  {/* </FormControl> */}
                  {/* <FormControlLabel
                    name="color_like"
                    onChange={(e) => console.log(e.target.name, e.target)}
                    value="White"
                    control={<Checkbox />}
                    label="White"
                  />
                  <FormControlLabel
                    name="color_like"
                    onChange={(e) => console.log(e.target.name, e.target)}
                    value="Black"
                    control={<Checkbox />}
                    label="Black"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Checkbox />}
                    label="Grey"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Checkbox />}
                    label="Red"
                  />
                  <FormControlLabel
                    value="5"
                    control={<Checkbox />}
                    label="Blue"
                  />
                  <FormControlLabel
                    value="6"
                    control={<Checkbox />}
                    label="Yellow"
                  />
                  <FormControlLabel
                    value="7"
                    control={<Checkbox />}
                    label="Green"
                  />
                  <FormControlLabel
                    value="8"
                    control={<Checkbox />}
                    label="Pink"
                  /> */}
                  {/* </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>What colour(s) do you dislike?</p>
                  <FormControl>
                    {colorDisLike.map((color, key) => (
                      <>
                        <FormControlLabel
                          // name={property.tag}
                          onChange={(event) => handlecheckboxDisLike(key)}
                          checked={color.checked}
                          value={color.color}
                          control={<Checkbox color="primary" />}
                          label={color.color}
                          labelPlacement="end"
                        />
                      </>
                    ))} */}
                  {/* </FormControl> */}
                  {/* <FormControlLabel
                    value="1"
                    control={<Checkbox />}
                    label="White"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Checkbox />}
                    label="Black"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Checkbox />}
                    label="Grey"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Checkbox />}
                    label="Red"
                  />
                  <FormControlLabel
                    value="5"
                    control={<Checkbox />}
                    label="Blue"
                  />
                  <FormControlLabel
                    value="6"
                    control={<Checkbox />}
                    label="Yellow"
                  />
                  <FormControlLabel
                    value="7"
                    control={<Checkbox />}
                    label="Green"
                  />
                  <FormControlLabel
                    value="8"
                    control={<Checkbox />}
                    label="Pink"
                  /> */}
                  {/* </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>Which area(s) you wish to design?</p>
                  <FormControl>
                    {designArea.map((area, key) => (
                      <>
                        <FormControlLabel
                          // name={property.tag}
                          onChange={(event) => handlecheckboxArea(key)}
                          checked={area.checked}
                          value={area.area}
                          control={<Checkbox area="primary" />}
                          label={area.area}
                          labelPlacement="end"
                        />
                      </>
                    ))}
                  </FormControl> */}
                  {/* <FormControlLabel
                    value="1"
                    control={<Checkbox />}
                    label="Living Hall"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Checkbox />}
                    label="Dining Hall"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Checkbox />}
                    label="Wet Kitchen"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Checkbox />}
                    label="Dry Kitchen"
                  />
                  <FormControlLabel
                    value="5"
                    control={<Checkbox />}
                    label="Master bedroom"
                  />
                  <FormControlLabel
                    value="6"
                    control={<Checkbox />}
                    label="Bedroom 1"
                  />
                  <FormControlLabel
                    value="7"
                    control={<Checkbox />}
                    label="Bedroom 2"
                  />
                  <FormControlLabel
                    value="8"
                    control={<Checkbox />}
                    label="Guest Room"
                  />
                  <FormControlLabel
                    value="9"
                    control={<Checkbox />}
                    label="Study room"
                  />
                  <FormControlLabel
                    value="10"
                    control={<Checkbox />}
                    label="Music Room"
                  />
                  <FormControlLabel
                    value="11"
                    control={<Checkbox />}
                    label="Gym Room"
                  /> */}
                  {/* </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for Living Hall (eg. Colour /Material /Lighting
                    /TV Size /Praying Altar ...)
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="living_hall"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for Dining Hall (eg. Colour /Socket /Material
                    /Lighting /Round Table /Rectangular Table /For how many
                    people)
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="dining_hall"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for Wet Kitchen (eg. Colour /Socket/ Material
                    /Lighting /Electrical Appliance)
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="wet_kitchen"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>Kitchen Appliances</p>
                  <FormControl>
                    {kitchen.map((area, key) => (
                      <>
                        <FormControlLabel
                       
                          onChange={(event) => handleKitchen(key)}
                          checked={area.checked}
                          value={area.area}
                          control={<Checkbox area="primary" />}
                          label={area.area}
                          labelPlacement="end"
                        />
                      </>
                    ))}
                  </FormControl> */}
                  {/* <FormControlLabel
                    value="1"
                    control={<Checkbox />}
                    label="Gas Hob"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Checkbox />}
                    label="Induction Hob"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Checkbox />}
                    label="Chimney Hood"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Checkbox />}
                    label="Island Hood"
                  />
                  <FormControlLabel
                    value="5"
                    control={<Checkbox />}
                    label="Build in Oven"
                  />
                  <FormControlLabel
                    value="6"
                    control={<Checkbox />}
                    label="Microwave Oven"
                  />
                  <FormControlLabel
                    value="7"
                    control={<Checkbox />}
                    label="2 Doors Fridge"
                  />
                  <FormControlLabel
                    value="8"
                    control={<Checkbox />}
                    label="4 Doors Fridge"
                  />
                  <FormControlLabel
                    value="9"
                    control={<Checkbox />}
                    label="Freezer"
                  />
                  <FormControlLabel
                    value="10"
                    control={<Checkbox />}
                    label="Dish Washer"
                  />
                  <FormControlLabel
                    value="11"
                    control={<Checkbox />}
                    label="Single Bowl Sink"
                  />
                  <FormControlLabel
                    value="12"
                    control={<Checkbox />}
                    label="Double Bowl Sink"
                  />
                  <FormControlLabel
                    value="13"
                    control={<Checkbox />}
                    label="Dish rack"
                  /> */}
                  {/* </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for Dry Kitchen (eg. Colour /Socket /Material
                    /Lighting /Electrical Appliance)
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="dry_kitchen"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for Master Bedroom (eg. Colour /Socket
                    /Material /Lighting /Lots of Cloth or handbag /Standing Make
                    up or Sitting Make Up /TV Console /Study Table...)
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="master_badroom"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for Bedroom 1 (eg. Colour /Socket /Material
                    /Lighting /TV Console /Study Table...). Please indicate for
                    boys or girls and age.
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="bedroom_one"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for Bedroom 2 (eg. Colour /Socket /Material
                    /Lighting /TV Console /Study Table...). Please indicate for
                    boys or girls and age.
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="bedroom_two"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for Bedroom 3 (eg. Colour /Socket /Material
                    /Lighting /TV Console /Study Table...). Please indicate for
                    boys or girls and age.
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="bedroom_three"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for Bedroom 4 (eg. Colour /Socket /Material
                    /Lighting /TV Console /Study Table...). Please indicate for
                    boys or girls and age.
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="bedroom_four"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for Guest Room (eg. Colour /Socket /Material
                    /Lighting /TV Console /Study Table...). Please indicate for
                    guest or parents.
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="guest_room"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for Study room (eg. Book Cabinet /Study Table
                    for how many person /Who will use this room)
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="study_room"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for Music Room (eg. Piano /Drum /Violin, etc)
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="music_room"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    Requirements for GYM room (eg. How many exercise equiments
                    /Full mirror, etc)
                  </p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="gym_room"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <p>Other Requirements</p>
                  <TextField
                    className="theHome"
                    id="outlined-basic"
                    name="other_requirement"
                    onChange={handleFormChange}
                    variant="outlined"
                  />
                </Grid> */}
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleForm}>Submit</Button>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}


      <div className="c-section4">
        <Container>
          <div className="homeTestimonial">
            <h2>Testimonial</h2>
            <p>
              Read what our clients said about our services and craftsmanship
            </p>
          </div>

          <div>
            <SliderBanner {...settingTest}>
              {testimonialData.map((item) => (
                <div>
                  <Card className="homeTestCard">
                    <div className="cardImg">
                      <img src={item.testimonials_image} />
                    </div>
                    <div className="testText">
                      <p>{item.name}</p>
                      <p>
                        {item.description}
                      </p>
                    </div>
                  </Card>
                </div>
              ))}
              {/* <div>
                <Card className="homeTestCard">
                  <div className="cardImg">
                    <img src={Xulu} />
                  </div>
                  <div className="testText">
                    <p>Mr.Ong Chuan</p>
                    <p>
                      I was looking for a company to do a complete kitchen
                      remodel and also help me with the kitchen design. I wanted
                      to get as much feedback as possible and get a feel of all
                      my options before making any decisions. The designer at
                      Indor was very professional and took his time going over
                      all my options with me, which I really appreciated!
                    </p>
                  </div>
                </Card>
              </div>
              <div>
                <Card className="homeTestCard">
                  <div className="cardImg">
                    <img src={Xulu} />
                  </div>
                  <div className="testText">
                    <p>Ms.Lim</p>
                    <p>
                      The quality of the finished product is excellent, and
                      their professionalism was very impressive. I would
                      recommend Indor without hesitation to anyone looking for
                      an honest, high quality company to build their home.
                    </p>
                  </div>
                </Card>
              </div> */}
            </SliderBanner>
          </div>

        </Container>
      </div>
    </div>
  );
};

export default Home;

const StyledSVG = styled.svg`
  background-color: ${(props) => props.color};
  background-image: url(${(props) => props.src});
  fill: ${(props) => props.fill};
`;
