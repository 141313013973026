import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { md, media } from "../../styles/responsive";

const config = {
  theBanner: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
    },
  },
  theOverlay: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "99.3%",
    width: "100%",
  },
  cProcedure: {
    padding: "2rem 0 4rem",
    ...md("sm", {
      padding: "2rem 0",
    }),
  },

  procedureCard: {
    // border: "1px solid #000",
    padding: "10px",
    boxShadow: "0px 3px 6px rgb(0 0 0 / 9%)",
    backgroundColor: "#fff",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "flex-start",
    ...md("sm", {
      height: "500px",
    }),
    ...md("md", {
      height: "490px",
    }),
    ...md("xmd", {
      height: "530px",
    }),
  },

  procedureIMG: {
    width: "100%",

    "& img": {
      width: "100%",
    },
  },

  cslider: {
    // "& .slick-dots": {
    // 	position: "absolute",
    // 	bottom: "8px",
    // 	...md("sm", {
    // 		bottom: "20px",
    // 	}),
    // },
  },

  procedureTOP: {
    height: "auto",
    ...md("sm", {
      height: "400px",
    }),
    ...md("md", {
      height: "345px",
    }),
    ...md("xmd", {
      height: "415px",
    }),
  },

  cTitle: {
    // margin: '0',
    fontSize: "14px",

    ...md("md", {
      fontSize: "16px",
    }),
  },

  cDesc: {
    marginTop: "0",
    fontSize: "12px",

    ...md("md", {
      fontSize: "14px",
    }),
  },

  theBigTitle: {
    position: "absolute",
    top: "50%",
    left: "28%",
    transform: `translate(-50%,-50%)`,
    color: "#fff",
    fontSize: "1.5rem",
    fontWeight: "700",
    textShadow: "4px 7px 20px #000000",
    ...md("sm", {
      top: "51.5%",
      left: "30%",
      fontSize: "4rem",
      fontWeight: "700",
    }),
    ...md("md", {
      top: "51.5%",
      left: "29%",
      fontSize: "5rem",
      fontWeight: "700",
    }),
    ...md("xmd", {
      top: "51.5%",
      left: "29%",
      fontSize: "7.1rem",
      fontWeight: "700",
    }),
  },

  theSection: {
    position: "relative",
    margin: "0 auto",
  },

  theIMG: {
    position: "relative",
    width: "100%",
    margin: "0 auto",

    ...md("md", {
      width: "100%",
    }),

    "& img": {
      width: "100%",
    },
  },

  theDetail: {
    padding: "0 2rem",

    ...md("md", {
      padding: "0 4rem",
    }),
  },

  theBtn: {
    width: "60%",

    ...md("sm", {
      width: "50%",
    }),

    ...md("xmd", {
      width: "34%",
    }),
  },

  theProduct: {
    position: "relative",
    width: "100%",
    display: "flex",
    "& img": {
      width: "100%",
    },
  },

  theProd: {
    position: "relative",
    width: "100%",
    margin: "0 auto",
    display: "block",

    ...md("sm", {
      width: "100%",
    }),
    "& img": {
      width: "100%",
    },
  },

  theProdTitle: {
    margin: "1rem 0rem 0rem 0rem",
    ...md("sm", {
      margin: "3rem 0rem 1rem 0rem",
    }),
  },

  theProdDetail: {
    position: "relative",
    display: "inline-block",
    zIndex: "1",
    color: "#fff",
    background:
      "linear-gradient(90deg, rgba(82,192,173,1) 0%, rgba(56,141,203,1) 100%)",
    width: "167%",
    ...md("sm", {
      width: "157%",
    }),
    ...md("md", {
      width: "154%",
    }),
    ...md("xmd", {
      width: "153%",
    }),
  },

  theDetail: {
    fontSize: "6px",
    fontWeight: "normal",
    margin: "1rem 1rem",
    ...md("sm", {
      fontSize: "0.8rem",
      fontWeight: "normal",
      margin: "1rem 2rem",
    }),
    ...md("md", {
      fontSize: "16px",
      fontWeight: "normal",
      margin: "2rem 2rem",
    }),
    ...md("xmd", {
      fontSize: "1.4rem",
      margin: "2rem 2rem",
    }),
  },

  theProdDetailText: {
    fontSize: "14px",
    fontWeight: "normal",
    margin: "1rem 1rem",
  },
  //...md("sm") is mediascreen min-width:768

  theCount: {
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    padding: "2rem 0",
  },

  theTextBox: {
    position: "relative",
    width: "25%",
    borderRight: "1px solid #eaeaea",

    "&:last-child": {
      borderRight: "unset",
    },
  },

  theIconList: {
    position: "relative",
    // alignItems: "center",
    // justifyContent: "space-between",
    // display: "flex",
    padding: "2rem",
    background: "#eaeaea",
  },

  theIconText: {
    width: "100%",
    margin: "0 auto",

    ...md("sm", {
      width: "50%",
    }),
  },

  theIcon: {
    position: "relative",
    width: "50%",
    margin: "0 auto",
    "& img": {
      width: "100%",
    },
  },

  theBackground: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theTitle: {
    position: "absolute",
    top: "50%",
    left: "45%",
    transform: `translate(-50%,-50%)`,
  },

  theTitle_: {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: `translate(-50%,-50%)`,
  },

  thePadd: {
    alignItems: "center",
    display: "flex",
  },

  theMobile: {
    display: "flex",
    flexDirection: "column-reverse",

    ...md("sm", {
      flexDirection: "row",
    }),
  },

  theCurveLeft: {
    position: "absolute",
    transform: `translate(0%,-100%)`,
    width: "57.5%",
  },

  theTitleAboutUS: {
    position: "relative",
    fontSize: "14px",
    fontWeight: "700",
    color: "#000000",
    ...md("sm", {
      fontSize: "40px",
    }),
    ...md("xmd", {
      fontSize: "40px",
    }),
  },

  theSecondBanner: {
    width: "100%",
    backgroundColor: "#fff",
    "& img": {
      width: "100%",
    },
  },

  theTitleValue: {
    position: "absolute",
    top: "0",
    right: "0",
    fontSize: "14px",
    fontWeight: "700",
    color: "#fff",
    transform: "translate(-90%, 75%)",
    ...md("sm", {
      fontSize: "40px",
      // right: "11%",
    }),
    ...md("md", {
      fontSize: "50px",
    }),
    ...md("xmd", {
      fontSize: "75px",
    }),
  },

  theFirstContentValue: {
    position: "absolute",
    top: "20%",
    right: "3%",
    fontSize: "5px",
    fontWeight: "lighter",
    color: "#fff",
    lineHeight: "0rem",
    ...md("sm", {
      fontSize: "12px",
      fontWeight: "normal",
      top: "23%",
      right: "5%",
      color: "#fff",
      lineHeight: "0.2rem",
    }),
    ...md("md", {
      fontSize: "14px",
      fontWeight: "normal",
      top: "23%",
      right: "7%",
      color: "#fff",
      lineHeight: "0.4rem",
    }),
    ...md("xmd", {
      fontSize: "1.3rem",
    }),
  },

  theSecondContentValue: {
    position: "absolute",
    top: "34%",
    right: "3%",
    fontSize: "5px",
    fontWeight: "lighter",
    color: "#fff",
    lineHeight: "0rem",
    ...md("sm", {
      fontSize: "12px",
      fontWeight: "normal",
      top: "46%",
      right: "5%",
      color: "#fff",
      lineHeight: "0.2rem",
    }),
    ...md("md", {
      fontSize: "14px",
      fontWeight: "normal",
      top: "46%",
      right: "7%",
      color: "#fff",
      lineHeight: "0.4rem",
    }),
    ...md("xmd", {
      fontSize: "1.3rem",
    }),
  },

  theThirdContentValue: {
    position: "absolute",
    top: "48%",
    right: "3%",
    fontSize: "5px",
    fontWeight: "lighter",
    color: "#fff",
    lineHeight: "0rem",
    ...md("sm", {
      fontSize: "12px",
      fontWeight: "normal",
      top: "69%",
      right: "5%",
      color: "#fff",
      lineHeight: "0.2rem",
    }),
    ...md("md", {
      fontSize: "14px",
      fontWeight: "normal",
      top: "69%",
      right: "7%",
      color: "#fff",
      lineHeight: "0.4rem",
    }),
    ...md("xmd", {
      fontSize: "1.3rem",
    }),
  },

  theNumberOne: {
    position: "absolute",
    top: "20%",
    right: "28%",
    width: "7%",
    ...md("sm", {
      position: "absolute",
      top: "21%",
      right: "32%",
      width: "9%",
    }),
    ...md("md", {
      position: "absolute",
      top: "21%",
    }),
  },

  theNumberTwo: {
    position: "absolute",
    top: "34%",
    right: "32%",
    width: "7%",
    ...md("sm", {
      position: "absolute",
      top: "44%",
      right: "37%",
      width: "9%",
    }),
    ...md("md", {
      position: "absolute",
      top: "45%",
      right: "37%",
    }),
  },

  theNumberThree: {
    position: "absolute",
    top: "49%",
    right: "36%",
    width: "7%",
    ...md("sm", {
      position: "absolute",
      top: "68%",
      right: "42%",
      width: "9%",
    }),
    ...md("md", {
      position: "absolute",
      top: "69%",
      right: "42%",
    }),
  },

  theFlexValue: {
    display: "flex",
    flexDirection: "row",
  },

  theParallelogram: {
    width: "71.8%",
    position: "absolute",
    transform: "translate(71%,-100%)",
    ...md("sm", {
      transform: "translate(66%,-100%)",
    }),
  },

  theMSFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  theMissionVision: {
    position: "absolute",
    transform: `translate(20%, -30%)`,
    left: "0",
    bottom: "0",
    ...md("sm", {
      transform: `translate(20%,-35%)`,
    }),
    ...md("md", {
      transform: `translate(30%,-70%)`,
    }),
    ...md("xmd", {
      transform: `translate(20%,-60%)`,
    }),
    ...md("slg", {
      transform: `translate(20%,-90%)`,
    })
  },

  theMissionTitle: {
    position: "relative",
    fontSize: "11px",
    fontWeight: "700",
    color: "#000000",
    top: "0",
    ...md("sm", {
      fontSize: "35px",
    }),
    ...md("md", {
      fontSize: "40px",
    }),
    ...md("xmd", {
      fontSize: "50px",
    }),
  },

  theMissionContent: {
    position: "relative",
    fontSize: "6px",
    color: "#000000",
    lineHeight: "0.2rem",
    top: "0",
    ...md("sm", {
      fontSize: "14px",
    }),
    ...md("xmd", {
      fontSize: "24px",
    }),
  },

  theVisionTitle: {
    position: "relative",
    fontSize: "11px",
    fontWeight: "700",
    color: "#000000",
    ...md("sm", {
      fontSize: "35px",
      top: "15px",
    }),
    ...md("md", {
      fontSize: "40px",
      top: "56px",
    }),
    ...md("xmd", {
      fontSize: "50px",
    }),
  },

  theVisionContent: {
    position: "relative",
    fontSize: "6px",
    color: "#000000",
    lineHeight: "0.2rem",
    ...md("sm", {
      fontSize: "14px",
      top: "15px",
    }),
    ...md("md", {
      fontSize: "14px",
      top: "56px",
    }),
    ...md("xmd", {
      fontSize: "24px",
    }),
  },

  theCurveRight: {
    position: "relative",
    transform: `translate(73.8%,-0.5%)`,
    width: "57.5%",
  },

  theLasttitle: {
    position: "absolute",
    top: "0",
    left: "0",
    color: "#000000",
    fontSize: "0.8rem",
    fontWeight: "700",
    transform: `translate(40%, 80%)`,
    ...md("sm", {
      fontSize: "2rem",
      fontWeight: "700",
    }),
    ...md("md", {
      fontSize: "2.7rem",
      fontWeight: "700",
    }),
    ...md("xmd", {
      fontSize: "3.8rem",
      fontWeight: "700",
    }),
    ...md("slg", {
      transform: `translate(45%, 80%)`,
    }),
    ...md("lg", {
      transform: `translate(60%,80%)`,
    })
  },

  theBorder: {
    width: "100%",
    position: "absolute",
    top: "50%",
    border: "2px solid rgba(242, 242, 242, 0.7)",
    right: "0%",
    transform: "translate(-50%, -50%) !important",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "250px",
    width: "250px",
  },

  theSecondBorder: {
    backgroundColor: "#fff",
    borderRadius: "50%",
    transition: "none 0s ease 0s",
    textAlign: "inherit",
    lineHeight: "0px",
    borderWidth: "100px",
    margin: "0px",
    paddingLeft: "0px",
    letterSpacing: "0px",
    fontWeight: "700",
    whiteSpace: "nowrap",
    opacity: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "150px",
    width: "150px",
  },

  theHidden: {
    overflow: "hidden",
  },

  footer_content: {
    display: "flex",
    width: "100%",
    padding: "1rem 0",
    display: "none",
    ...md("md", {
      display: "block",
    }),
  },

  flex_center_footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "500",
  },

  cFLink: {
    color: "#000000",
    fontWeight: "bold",
    cursor:"pointer",
  },

  dot: {
    height: "20px",
    width: "1px",
    backgroundColor: "#000000",
    display: "inline-block",
    margin: "0 15px",
  },

  footer_nav: {
    display: "flex",
    justifyContent: "right",
    padding: "1rem 0",
    flexDirection: "row",
  },

  footerTitle: {
    fontWeight: "600",
    display: "flex",
    justifyContent: "left",
    padding: "1rem 0",
    flexDirection: "row",
  },

  footerFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  thePadding: {
    padding: "4rem 0",

    ...md("md", {
      padding: "8rem 0",
    })
  },

};

export default makeStyles(config);
