import React from 'react'
import useStyle, { CarouselContainer, CarouselIMG } from "./styles"
import { delete_product, edit_product, edit_product_description, get_categories, get_product_details, get_user_products, update_thumbnail } from '../../../api/API';
import GoogleHeader from '../../../components/GoogleHeader';
import Loader from "react-js-loader";
import { Box, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import Paragraph from '../../../components/Typography/Paragraph';
import { Link, useParams } from 'react-router-dom';
import EditorToolbar, { modules, formats } from "../CreateProject/EditorToolbar"
import ReactQuill from 'react-quill'
import FormInput from '../../../components/FormInput/FormInput';
import Slider from 'react-slick';
import FormInputNoLabel from '../../../components/FormInput/FormInputNoLabel/FormInputNoLabel';
// import { Button } from '@material-ui/core';
import Button from '../../../components/Button/Button';
import { AddCircleOutline, DeleteOutlineRounded } from "@material-ui/icons";
import { useHistory } from 'react-router-dom';
import { WindowSharp } from '@mui/icons-material';
import Modal from 'react-modal';


function EditProduct(props) {
    const classes = useStyle()
    const { product_id } = useParams();
    const history = useHistory();


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };



    const settings1 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    // dots: true
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const [token, setToken] = React.useState("");

    React.useEffect(() => {
        let storedToken = window.localStorage.getItem("token");
        setToken(storedToken || "");
        console.log("CURRENT TOKEN", storedToken);
    }, []);


    const [productDetail, setProductDetail] = React.useState({});

    const [productData, setProductData] = React.useState(null);

    const [productVariant, setProductVariant] = React.useState([])

    const [productDesc, setProductDesc] = React.useState({
        product_id: product_id,
        description: "",
    })



    const getProductDetail = () => {
        get_product_details(product_id).then((json) => {
            if (json.status) {
                console.log('source', json.data);
                setProductData(json.data);
                setSelectedCategoryId(json.data.category_ids)
                setSelectedSubcategoryIds(json.data.subcategory_ids)
                setProductDetail(json.data)
                setProductVariant(json.data.variants)


                console.log("sub", productDetail.subcategory_names)


                console.log(JSON.stringify(json.data.subcategory_ids))


                // setForm({
                //     ...form,
                //     subcategory_ids: json.data.subcategory_ids
                // });
                // setSubcategories(json.data.subcategory_ids)

            } else {
                console.error("Invalid data:", json);
            }
        });
    };

    console.log("sss", productData)


    const handleDeleteImage = (index) => {
        const updatedImages = [...productData.images];
        updatedImages.splice(index, 1);
        setProductData({ ...productData, images: updatedImages });

        // Update form.removed_images with the removed image URL
        setForm({ ...form, removed_images: [...form.removed_images, productData.images[index].url] });
    };

    const handleDeleteImageNew = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const [categories, setCategories] = React.useState([])

    const getCategories = () => {
        get_categories({}).then((json) => {
            setCategories(json.data)
        })
    }

    const [loading, setLoading] = React.useState(false);


    React.useEffect(() => {


        getCategories()



    }, [])


    React.useEffect(() => {

        getProductDetail()


    }, [categories])


    const [form, setForm] = React.useState({
        // product_id: product_id,
        // product_name: "",
        // description: "",
        subcategory_ids: [],
        category_ids: "",
        // commission_referral: "",
        // price: "",
        // stock: 999,
        removed_images: [],
        stock_description: "",
    })

    const [selectedCategoryId, setSelectedCategoryId] = React.useState(""); // Step 1
    const [subcategories, setSubcategories] = React.useState([]); // Step 1
    const [selectedSubcategoryIds, setSelectedSubcategoryIds] = React.useState([])

    // Assuming you have the productDetails API response
    // const productCategoryIds = form.category_ids;

    // // Set the initial selectedCategoryId to the first category ID from the productDetails
    // React.useEffect(() => {
    //     if (productCategoryIds && productCategoryIds.length > 0) {
    //         setSelectedCategoryId(productCategoryIds[0]);
    //     }
    // }, [productCategoryIds]);

    // console.log(selectedCategoryId)

    const handleChooseCategory = (event) => {

        const categoryId = event.target.value;
        setSelectedCategoryId(categoryId);

        console.log("category value", categoryId)

        // Find the selected category from the categories array
        const selectedCategory = categories.find((category) => category.category_id === categoryId);

        // Update the subcategories state with the subcategories of the selected category
        setSubcategories(selectedCategory ? selectedCategory.subcategories : []);
    };

    const handleChangeSubcategories = (e) => {

        const subCatId = e.target.value;
        setProductData({
            ...productData,
            subcategory_ids: subCatId
        });

        setSelectedSubcategoryIds(subCatId)

        console.log("subcat", subCatId);

    }

    const handleChange = (e) => {
        console.log(productData);
        setProductData({
            ...productData,
            [e.target.name]: e.target.value
        });

    };
    const handleChangeDescription = (e) => {
        console.log(e);
        setProductData({
            ...productData,
            description: e
        });
        // setForm({
        //     ...form,
        //     description: e
        // });
        // setProductDesc({
        //     ...productDesc,
        //     description: e
        // });
    };

    const [images, setImages] = React.useState([])

    const handleImageChange = (e) => {
        const selectedImages = Array.from(e.target.files);
        setImages((prevImages) => [...prevImages, ...selectedImages]);
    };

    const handleSaveDesc = () => {

        if (!productDesc.description) {
            alert("Please fill Description")
            return;
        }


        edit_product_description({
            ...productDesc,
        }).then((json => {
            if (json.status) {
                alert("Saved")
            } else {
                alert(json.message)
            }
        }))


    }

    const handleSubmit = () => {
        if (!productData.product_name) {
            alert("Please fill Product Name.");
            return;
        }
        if (!productData.description) {
            alert("Please fill Description")
            return;
        }
        if (!productData.commission_referral) {
            alert("Please fill Commission")
            return;
        }
        if (!productData.price) {
            alert("Please fill Price")
            return;
        }

        let postparam = {
            ...productData,
            subcategory_ids: selectedSubcategoryIds,
        }
        var formData = new FormData();

        for (var key in productData) {
            formData.append(key, productData[key]);
        }

        if (form.removed_images && form.removed_images.length > 0) {
            formData.append("removed_images", JSON.stringify(form.removed_images));
        }

        // Handle stock description
        if (form.stock_description) {
            formData.append("stock_description", form.stock_description);
        }

        images.forEach((image, index) => {
            formData.append(`image_${index}`, image);
        });

        // console.log(formData);

        const headers = {
            Authorization: `Bearer ${token}`
        };

        const ID = {
            product_id: product_id,
        }

        edit_product(formData, headers, ID, postparam).then((json) => {
            if (json.status) {
                alert("submitted");
                history.push("/dashboard/products");
            } else {
                alert(json.message)
            }
        });
    }

    const handleDelete = () => {
        const confirmed = window.confirm("Are you sure you want to delete this product?");
        if (!confirmed) {
            return;
        }

        const headers = {
            Authorization: `Bearer ${token}`
        };

        // Create a new FormData object
        const formData = new FormData();
        formData.append('product_id', product_id); // Append the product_id

        // Call the delete_product API with formData and headers
        delete_product(formData, headers).then((json) => {
            if (json.status) {
                alert("Deleted successfully");
                history.push("/dashboard/products");
            } else {
                alert("Failed to delete product.");
            }
        }).catch((error) => {
            console.error("Error deleting product:", error);
            alert("An error occurred while deleting the product.");
        });
    };




    const [isModalOpen, setIsModalOpen] = React.useState(false);


    const openModal = () => {
        setIsModalOpen(true);
        // Fetch images using getProductDetail or another suitable API call
        getProductDetail();
    };


    const closeModal = () => {
        setIsModalOpen(false);
    };


    const [selectedProductImage, setSelectedProductImage] = React.useState(null);

    const handleChangeThumbnail = () => {
        if (selectedProductImage) {

            const formData = new FormData();

            formData.append("product_id", product_id);
            formData.append("product_image_id", selectedProductImage.product_image_id);

            const headers = {
                Authorization: `Bearer ${token}`
            }

            update_thumbnail(formData, headers).then((json) => {
                if (json.status) {
                    alert("Submitted")
                    window.location.reload()
                } else {
                    alert(json.message)
                }
            })
        }
    }


    return (
        <div className="account_page">
            <GoogleHeader />
            {loading || productData == null ? (
                <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
            ) : (
                <React.Fragment>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Paragraph
                            size="600"
                            color="#0d2964"
                            bold="600"
                        >
                            Edit Product
                        </Paragraph>

                        <Button
                            label="DELETE"
                            color="#FFF"
                            br="5px"
                            bg="#c70000"
                            margin=".4rem 0"
                            pd=".7rem 1rem .6rem"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDelete()}
                        />
                    </div>
                    <hr />

                    <div style={{ margin: "1rem 0 0 0" }}>
                        <FormInput
                            label="Product Name"
                            bg="#FFF"
                            border="1px solid #d7d7d7"
                            name="product_name"
                            onChange={handleChange}
                            value={productData.product_name}
                        />

                        {/* <FormInput
                            placeholder="Description"
                            bg="#FFF"
                            border="1px solid #d7d7d7"
                            name="name"
                            // onChange={handleChange}
                            value={productDetail.description}
                        /> */}

                        <Paragraph margin="1rem 0">
                            Describe this Product
                        </Paragraph>
                        <div>
                            <EditorToolbar />

                            <ReactQuill
                                style={{ color: "#ffffff", height: "120px" }}
                                theme="snow"
                                name="description"
                                value={productData.description}
                                onChange={handleChangeDescription}
                                modules={modules}
                                formats={formats}
                            />
                            {/* <div style={{
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                                <Button
                                    label="SAVE"
                                    color="#FFF"
                                    bg="#2f8dcd"
                                    margin=".4rem 0"
                                    pd=".7rem 1rem .6rem"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleSaveDesc()}
                                />
                            </div> */}
                        </div>
                        <br />
                        <TextField
                            label="Category"
                            margin="dense"
                            name="category_ids"
                            select
                            multiple
                            value={selectedCategoryId}
                            onChange={handleChooseCategory}
                            variant="outlined"
                            style={{
                                width: "100%",
                            }}
                        >
                            <MenuItem value="">Select Category</MenuItem>
                            {categories.map((item) => (
                                <MenuItem key={item.category_id} value={item.category_id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>


                        <FormControl
                            variant="outlined"
                            margin="dense"
                            style={{
                                width: '100%',
                                marginTop: '16px',
                            }}
                        >
                            <InputLabel id="subcategory-label">{productDetail.subcategory_names}</InputLabel>
                            <Select
                                labelId="subcategory-label"
                                id="subcategory-select"
                                multiple
                                defaultValue={[]}
                                onChange={handleChangeSubcategories}
                                label="Subcategories"
                            >
                                {subcategories.map((subcategory) => (
                                    <MenuItem key={subcategory.subcategory_id} value={subcategory.subcategory_id}>
                                        {subcategory.subcategory_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* <FormInput
                            label="Quantity"
                            name="stock"
                            value={productData.stock}
                            onChange={handleChange}
                            type="number"
                            style={{
                                width: "100%"
                            }}
                        />
                        <FormInput
                            label="Stock Description"
                            bg="#FFF"
                            border="1px solid #d7d7d7"
                            name="stock_description"
                            onChange={handleChange}
                            value={form.stock_description}
                        /> */}
                        <FormInput
                            label="Commission"
                            name="commission_referral"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">MYR</InputAdornment>
                            }}
                            value={productData.commission_referral}
                            onChange={handleChange}
                            type="number"
                            style={{
                                width: "100%"
                            }}
                        />
                        <FormInput
                            label="Price"
                            name="price"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">MYR</InputAdornment>
                            }}
                            value={productData.price}
                            onChange={handleChange}
                            type="number"
                            style={{
                                width: "100%"
                            }}
                        />
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                            {productData.images && productData.images.map((image, index) => (
                                <div key={index}
                                    style={{
                                        width: "290px",
                                        height: "290px",
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: "10px",

                                    }}>
                                    <img
                                        src={image.url}
                                        alt={`Product Image ${index}`}
                                        style={{
                                            width: "100%",
                                            height: "70%",
                                            objectFit: "cover",
                                        }}
                                    />

                                    <Button
                                        label="REMOVE"
                                        color="#c70000"
                                        br="5px"
                                        bg="#fff"
                                        margin=".4rem 0"
                                        pd=".7rem 1rem .6rem"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleDeleteImage(index)}
                                    />
                                </div>
                            ))}
                        </div>
                        <div>
                            <Paragraph margin="1rem 0">
                                Add images to this Product
                            </Paragraph>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {
                                    images.map((image, index) => (
                                        <div className={classes.imageCenter}>
                                            {/* <div className={classes.deleteButton} onClick={() => handleDeleteImage(index)}>
                                            <DeleteOutlineRounded></DeleteOutlineRounded>
                                        </div> */}
                                            <img
                                                key={index}
                                                src={URL.createObjectURL(image)} // Use createObjectURL to display the selected image
                                                alt={`Selected ${index + 1}`}
                                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                            />
                                            {/* <Button onClick={() => handleDeleteImageNew(index)} style={{ color: "red" }}>Remove</Button> */}
                                            <Button
                                                label="REMOVE"
                                                color="#c70000"
                                                br="5px"
                                                bg="#fff"
                                                margin=".4rem 0"
                                                pd=".7rem 1rem .6rem"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleDeleteImageNew(index)}
                                            />
                                        </div>
                                    ))
                                }
                                <label for="upload" className={classes.badgeCenter}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    >
                                        <AddCircleOutline />
                                    </div>
                                </label>
                            </div>
                            <input type="file"
                                id="upload" name="upload" multiple
                                style={{
                                    opacity: 0
                                }}
                                onChange={handleImageChange}
                            />
                        </div>
                        <div>
                            <Paragraph margin="1rem 0">
                                Variant
                            </Paragraph>
                            <TableContainer>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Variant Name
                                            </TableCell>
                                            <TableCell>
                                                Variant Image
                                            </TableCell>
                                            <TableCell>
                                                Subvariant Name
                                            </TableCell>
                                            <TableCell>
                                                Price
                                            </TableCell>
                                            <TableCell>
                                                Commission
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {productVariant.map((item) => (

                                            <TableRow>
                                                <Link to={`/dashboard/editvariant/${product_id}/${item.product_variant_id}`} style={{ display: "contents" }}>
                                                    <TableCell>
                                                        {item.variant_category}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.variant_image_url == null || "" ? (
                                                            <></>
                                                        ) : (
                                                            <div className={classes.imageCenter}>
                                                                <img src={item.variant_image_url}
                                                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                                                />
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.variant_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.price}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.commission_referral}
                                                    </TableCell>
                                                </Link>
                                            </TableRow>
                                        ))}

                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "1rem 0 0 0"
                        }}>

                            <Button
                                label="CHANGE THUMBNAIL"
                                color="#FFF"
                                bg="#2f8dcd"
                                br="5px"
                                pd=".7rem 1rem .6rem"
                                style={{ cursor: "pointer" }}
                                onClick={openModal}
                            />

                            <Button
                                label="SUBMIT"
                                color="#FFF"
                                bg="#2f8dcd"
                                br="5px"
                                pd=".7rem 1rem .6rem"
                                style={{ cursor: "pointer", margin: "0 0 0 1rem" }}
                                onClick={() => handleSubmit()}
                            />
                        </div>
                    </div>

                    <Modal
                        isOpen={isModalOpen}
                        // onRequestClose={closeModal}
                        contentLabel="Image Selection Modal"
                        style={customStyles}
                    >
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {productData &&
                                productData.images &&
                                productData.images.map((image, index) => (
                                    <div
                                        key={image.product_image_id}
                                        style={{
                                            width: '33.33%', // Set to 33.33% for three images per row
                                            height: "200px",
                                            padding: '1rem',
                                            boxSizing: 'border-box',
                                            border: selectedProductImage === image ? '2px solid blue' : '2px solid transparent',
                                        }}
                                    >
                                        <img
                                            src={image.url}
                                            alt={`Product Image ${image.product_image_id}`}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                            onClick={() => {
                                                setSelectedProductImage(image);
                                                // closeModal();
                                            }}
                                        />
                                    </div>
                                ))}
                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                label="Close"
                                color="#FFF"
                                bg="#a6a4a5"
                                br="5px"
                                pd=".7rem 1rem .6rem"
                                style={{ cursor: "pointer", margin: "1rem 1rem" }}
                                onClick={() => closeModal()}
                            />
                            <Button
                                label="Save Changes"
                                color="#FFF"
                                bg="#2f8dcd"
                                br="5px"
                                pd=".7rem 1rem .6rem"
                                style={{ cursor: "pointer", margin: "1rem 0" }}
                                onClick={() => handleChangeThumbnail()}
                            />
                        </div>



                    </Modal>


                </React.Fragment>
            )}
        </div>
    )
}

export default EditProduct