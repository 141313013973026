import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import FormInput from "../../components/FormInput/FormInput";
import Curve from "../../assets/images/curve_005.png";
import default001 from "../../assets/images/floor.jpg";
import { ReactComponent as TextBanner } from "../../assets/images/text_banner.svg";
import { ReactComponent as TextBannerSmall } from "../../assets/images/text_banner_small.svg";
import "./styles.css";
import iso from "../../assets/images/home_banner.jpg";
import rectangle001 from "../../assets/images/rectangle001.png";
import rectangle002 from "../../assets/images/rectangle002.png";
import banner_ from "../../assets/images/banner_5.jpg";
import procedure1 from "../../assets/images/form_stock.jpg";
import styled from "styled-components";
import useStyles from "./styles";
import { get_blog } from "../../api/API";
import { ClassNames } from "@emotion/react";
import GoogleHeader from "../../components/GoogleHeader";

export default function News(props) {
  const classes = useStyles();


  let { News } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [News]);

  const [colorNew, setColorNew] = React.useState("");
  const [blogs, setBlogs] = React.useState([]);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let { Product } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Product]);

  // API

  const [isBusy, setIsBusy] = React.useState(true);

  React.useEffect(() => {
    setIsBusy(true);
    get_blog().then((res) => {
      setBlogs(res.data);
      setColorNew(res.data[0].color);
    });
  }, []);

  return (
    <React.Fragment>
      <GoogleHeader />
      <div className={classes.thePadding}>
        {/* <div className={classes.theBanner}>
          <img src={banner_} />
          <div className={classes.theOverlay}></div>
          <div className={classes.theCurveRight}>
            <img src={Curve} />
          </div>
          <div className={classes.theBigTitle}>News</div>
        </div> */}

        <div className="newBanner">
          <img src={banner_} />
          <div className="newPosition">
            <div className="newCurve">
              <div className="newCurveDesign">
                <img src={Curve} />
                <div className="newWord">
                  <p>News</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className=" hide_mobile"
          style={{
            background: "white",
            padding: "20px 0",
            marginTop: 20,
          }}
        > */}
        <Container>
          <div className="page_padding">
            <h2 className="news_subtitle">Good Reads</h2>

            <Grid container spacing={1} className="news_wrapper">
              {blogs.map((blog, index) => (
                <>
                  {index == 0 || index == 7 ? (
                    <>
                      <Grid
                        item
                        md={5}
                        sm={6}
                        xs={12}
                        // className="_item _large"
                        onClick={() => {
                          props.history.push("/article/" + blog.blog_id);
                        }}
                      >
                        <div className={classes.theBlogBOX}>
                          <img
                            className={classes.theBtmImg}
                            src={blog.blog_image}
                          />

                          <div className={classes.theBlogBanner}>
                            <TextBanner
                              className={classes.theTextBannerStyle}
                              style={{ fill: blog.color }}
                            />
                            <div className="Overlay">
                              <p>{blog.title}</p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        md={2}
                        sm={6}
                        xs={12}
                        // className="_item"
                        onClick={() => {
                          props.history.push("/article/" + blog.blog_id);
                        }}
                      >
                        <div className={classes.theBlogBOX}>
                          <img
                            className={classes.theBtmImgSmall}
                            src={blog.blog_image}
                          />
                          <div className={classes.theSmallBlogBanner}>
                            <TextBannerSmall
                              className={classes.theTextBannerSmall}
                              style={{ fill: blog.color }}
                            />
                            <TextBanner
                              className={classes.theTextBanner}
                              style={{ fill: blog.color }}
                            />
                            <div className="SmallOverlay">
                              <p>{blog.title}</p>
                            </div>
                          </div>

                          {/* <p className="read_more">Read More</p> */}
                        </div>
                      </Grid>
                    </>
                  )}
                </>
              ))}
            </Grid>
          </div>
        </Container>
        {/* </div> */}
      </div>
    </React.Fragment>
  );
}

const StyledSVG = styled.svg`
  background-color: ${(props) => props.color};
  background-image: url(${(props) => props.src});
  fill: ${(props) => props.fill};
`;
