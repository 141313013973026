import React from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import { Button } from "@mui/material";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";

import Section from "../../../components/Section/Section";
import FormInput from "../../../components/FormInput/FormInput";
import Paragraph from "../../../components/Typography/Paragraph";
import Card from "../../../components/Card/Card";
// import { Button } from "../../components/Button/Button";
import {
  SideBarContainer,
  SideBarBox,
  Icon,
  Circle,
  SiderBarList,
  NavFlexLink,
  SmallIcon,
  SmallIconIMG,
  ActiveBorder,
} from "../styles";
import CopyIcon from "../../../assets/images/copy.svg";
import ShareIcon from "../../../assets/images/ShareIcon.svg";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
// import "./Account.css";
import useStyles from "./styles";
import "./styles.css";
import useUser from "../../../hooks/useUser";
import {
  get_user_profile,
  upload_logo,
  change_password,
  check_plan_limit,
  edit_profile,
} from "../../../api/API";
import GoogleHeader from "../../../components/GoogleHeader";
import { AddCircleOutline, DeleteOutlineRounded } from "@material-ui/icons";

const Account = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [logo, setLogo] = React.useState(null);
  // const [token, setToken] = React.useState("");
  const [projectLimit, setProjectLimit] = React.useState(null);
  const [loading, setLoading] = React.useState(false);



  const [token, setToken] = React.useState("");

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);



  const { set_user } = useUser();

  const [user, setUser] = React.useState(null)
  const [originalUser, setOriginalUser] = React.useState([])

  const getProfile = () => {
    get_user_profile({}).then((json) => {
      if (json.status) {
        setOriginalUser(json.data)
        setUser(json.data)
        setLoading(false)
        setprofileData({
          ...profileData,
          company_name: json.data.company_name,
          name: json.data.name,
          contact: json.data.contact,
          bank_name: json.data.bank_account.bank_name,
          bank_account_no: json.data.bank_account.bank_account_no,
          bank_account_name: json.data.bank_account.bank_account_name,
        });
      } else {
        alert(json.message);
        set_user(null);
        window.localStorage.clear();
      }

    })
  }

  React.useEffect(() => {
    getProfile();
  }, [])


  console.log(user);
  const [passwordData, setPasswordData] = React.useState({
    old_password: "",
    password: "",
    password2: "",
  });
  const handleChangePassword = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSaveChangePassword = () => {
    if (passwordData.old_password.length == 0) {
      toast("Old Password is required");
      return;
    }
    if (passwordData.password.length == 0) {
      toast("New Password is required");
      return;
    }
    if (passwordData.password2.length == 0) {
      toast("Confirm New Password is required");
      return;
    }
    change_password({
      ...passwordData,
      user_id: user.user_id,
    }).then((res) => {
      // console.log(res);
      if (res.status) {
        toast("Password change successfully");
        setPasswordData({
          old_password: "",
          password: "",
          password2: "",
        });
      } else {
        toast(res.message);
      }
    });
  };

  const [profileData, setprofileData] = React.useState({
    company_name: "",
    name: "",
    contact: "",
    bank_name: "",
    bank_account_no: "",
    bank_account_name: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setprofileData({
      ...profileData,
      [name]: value,
    });
  };



  const handleEditProfile = () => {

    const formData = new FormData();

    formData.append("company_name", profileData.company_name);
    formData.append("name", profileData.name);
    formData.append("contact", profileData.contact);
    formData.append("bank_name", profileData.bank_name);
    formData.append("bank_account_no", profileData.bank_account_no);
    formData.append("bank_account_name", profileData.bank_account_name);

    for (const key in profileData) {
      if (profileData.hasOwnProperty(key)) {
        if (profileData[key] !== originalUser[key]) {
          formData.append(key, profileData[key]);
        }
      }
    }

    const headers = {
      Authorization: `Bearer ${token}`
    };

    edit_profile(formData, headers).then((json) => {
      if (json.status) {
        toast("Profile Changed")
        history.push("/dashboard")
        // window.location.reload()
      }
    })
  }

  // const handleEditProfile = () => {
  //   if (user.bank_name.length == 0) {
  //     toast("Bank name is required");
  //     return;
  //   }
  //   if (user.bank_acc.length == 0) {
  //     toast("Bank account is required");
  //     return;
  //   }
  //   if (user.acc_name.length == 0) {
  //     toast("Bank account name is required");
  //     return;
  //   }
  //   edit_profile({
  //     ...user,
  //     // user_id: user.user_id
  //   }).then((res) => {
  //     // console.log(res);
  //     if (res.status) {
  //       toast("Profile Save successfully");
  //       //     setPasswordData({
  //       //         old_password: "",
  //       //         password: "",
  //       //         password2: ""
  //       //     });
  //     } else {
  //       toast(res.message);
  //     }
  //   });
  // };

  React.useEffect(() => {
    // let postparam = {
    //   token: token,
    // };
    // get_user_profile(postparam).then((json) => {
    //   setUser(json.data);
    // });
    getplan();
    // get_data()
  }, []);

  // UPDATE PROFILE
  // const handleLogoChange = (e) => {
  //   setLogo(e.target.files[0]);
  //   var data = new FormData();
  //   data.append("token", user.token);
  //   data.append("logo", e.target.files[0]);

  //   upload_logo(data).then((res) => {
  //     console.log(res);
  //     alert("success");
  //     get_data();
  //   });
  // };
  const get_data = () => {
    setLoading(true);
    // get_user_profile({}).then((res) => {
    //   console.log(res);
    //   set_user(res.data);
    //   console.log(user);
    //   setLoading(false);
    // });
  };
  const getplan = () => {
    setLoading(true);
    check_plan_limit().then((resLimit) => {
      setProjectLimit(resLimit);
      setLoading(false);
    });
  };

  const [edituser, setEdituser] = React.useState({});



  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: "Check out this referral code!",
        text: `Join me at https://indor.my/join/${user.refer_code}`,
        url: `https://indor.my/join/${user.refer_code}`,
      })
        .then(() => {
          // Success
          console.log("Shared successfully!");
        })
        .catch((error) => {
          // Error
          console.error("Error sharing:", error);
        });
    } else {
      // Fallback for browsers that do not support Web Share API
      console.log("Web Share API not supported.");
    }
  };


  const [images, setImages] = React.useState(null)

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImages(file);
  }

  const handleUploadImage = () => {
    const formData = new FormData();

    formData.append("logo", images)

    const headers = {
      Authorization: `Bearer ${token}`
    };

    upload_logo(formData, headers).then((json) => {
      if (json.status) {
        toast("Image Changed")
        history.push("/dashboard")
        window.location.reload()
      } else {
        alert(json.message)
        window.location.reload()
      }
    })
  }


  return (
    <>
      {
        user ? (
          <div className="account_page" >
            <GoogleHeader />
            {loading || user == null ? (
              <Loader type="bubble-loop" bgColor={"#2d3ca5"} size={50} />
            ) : (
              <React.Fragment>
                <div className={classes.theHeaderProfile}>
                  <Paragraph
                    size="600"
                    color="#0d2964"
                    bold="600"
                    margin="0 0 1rem 0"
                  >
                    My Account
                  </Paragraph>
                  <Paragraph size="500" color="#000" bold="700" margin="1rem 0">
                    Account Information
                  </Paragraph>
                </div>
                <div className={classes.theForm}>
                  <Paragraph
                    color="#000"
                    bold="600"
                    style={{ fontSize: "16px" }}
                    margin="0 0 10px 0"
                  >
                    My Referral Code
                  </Paragraph>
                  <div className={classes.theSCLFlex}>
                    {projectLimit != null && (
                      <div>
                        {user.near_expire == 1 ||
                          user.is_expired == 1 ||
                          projectLimit.data.name == "Silver Plan" ? (
                          <div className={classes.theReferalExpired}>
                            <Paragraph
                              color="#707070"
                              bold="bold"
                              center
                              style={{ fontSize: "12px" }}
                            >
                              {user.refer_code}
                            </Paragraph>
                          </div>
                        ) : (
                          <div className={classes.theReferal}>
                            <Paragraph
                              color="#707070"
                              bold="bold"
                              center
                              style={{ fontSize: "12px" }}
                            >
                              {user.refer_code}
                            </Paragraph>
                          </div>
                        )}
                        {user.is_expired == 0 && user.near_expire == 1 && (
                          <Paragraph
                            color="#ff0000"
                            bold="bold"
                            center
                            style={{ fontSize: "12px", margin: "10px 0" }}
                          >
                            Plan Expiring Soon
                          </Paragraph>
                        )}
                        {user.is_expired == 1 && (
                          <Paragraph
                            color="#ff0000"
                            bold="bold"
                            center
                            style={{ fontSize: "12px", margin: "10px 0" }}
                          >
                            Plan Expired
                          </Paragraph>
                        )}
                      </div>
                    )}
                    {/* {user.is_expired == 0 ? (
                      <div
                        className={classes.theCopy}
                        onClick={() => {
                          // navigator.clipboard.writeText(user.refer_code)
                          navigator.clipboard.writeText(
                            "https://indor.my/join/" + user.refer_code
                          );
                          toast("Copy succesfully");
                        }}
                      >
                        <img src={CopyIcon} />
                      </div>
                    ) : (
                      <div
                        className={classes.theCopy}
                        onClick={() => {
                          // navigator.clipboard.writeText("https://indor.my/join/" + user.refer_code)
                          toast("Your plan was expired, please contact support");
                        }}
                      >
                        <img src={CopyIcon} />
                      </div>
                    )} */}

                    {user.user_type == "1" ? (
                      <>
                        {user.is_expired == 0 ?
                          <div className={classes.theCopy}
                            onClick={handleShare}
                          >
                            <img src={ShareIcon} />
                          </div>
                          :
                          <div className={classes.theCopy}
                            onClick={() => {
                              // navigator.clipboard.writeText("https://indor.my/join/" + user.refer_code)
                              toast("Your plan was expired, please contact support")
                            }}
                          >
                            <img src={ShareIcon} />
                          </div>
                        }
                      </>
                    ) : (
                      <div className={classes.theCopy}
                        onClick={handleShare}
                      >
                        <img src={ShareIcon} />
                      </div>
                    )}
                  </div>
                  <br />


                  <div className="account_info_wrapper">
                    {/* <label for="logo">
                      <div className="account_img flex_center">
                        <img src={user.logo} />
                      </div>
                    </label> */}

                    <div style={{ display: "flex", flexDirection: "column", margin: "1rem 0 0" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "300px", height: "300px" }}>
                          <img src={user.image}
                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                          />
                        </div>
                        {images && (
                          <div style={{ width: "300px", height: "300px", margin: "0 0 0 1rem" }}>
                            <img src={URL.createObjectURL(images)}
                              style={{ width: "100%", height: "100%", objectFit: "cover" }}
                            />
                          </div>
                        )}
                      </div>

                      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "1rem 0 0" }}>
                        <input
                          type="file"
                          id="upload"
                          name="upload"
                          onChange={handleImageChange}
                        />
                        <Button onClick={handleUploadImage}>Submit</Button>
                      </div>
                    </div>



                    {/* <label for="upload" className={classes.badgeCenter}>
                      <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      >
                        <AddCircleOutline />
                      </div>
                    </label> */}



                    {/* <div className="account_info c-changtext">
                      <p
                        style={{
                          margin: 0,
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        click to change logo
                      </p>
                      <input
                        id="logo"
                        name="logo"
                        type="file"
                        onChange={handleLogoChange}
                        style={{
                          opacity: 0,
                        }}
                      />
                    </div> */}
                  </div>
                  <br />
                  <br />


                  <div className="account_info_wrapper">
                    <div className="account_info">
                      <Grid container spacing={3}>
                        <Grid item sm={6}>
                          <div className="form_wrapper">
                            <FormInput
                              margin="0 0 10px"
                              placeholder="Email"
                              name="email"
                              label="Email"
                              value={user.username}
                              // onChange={handleChange}
                              disabled
                            />
                          </div>
                        </Grid>
                        <Grid item sm={6}>
                          <div className="form_wrapper">
                            <FormInput
                              margin="0 0 10px"
                              mb="0"
                              placeholder="Company Name"
                              name="company_name"
                              label="Company"
                              value={profileData.company_name}
                              onChange={handleChange}
                            />
                          </div>
                        </Grid>
                        <Grid item sm={6}>
                          <div className="form_wrapper">
                            <FormInput
                              margin="0 0 10px"
                              mb="0"
                              placeholder="Contact Number"
                              name="contact"
                              label="Contact"
                              type="number"
                              value={profileData.contact}
                              onChange={handleChange}
                            />
                          </div>
                        </Grid>
                        <Grid item sm={6}>
                          <div className="form_wrapper">
                            <FormInput
                              margin="0 0 10px"
                              placeholder="Name"
                              name="name"
                              label="Name"
                              value={profileData.name}
                              onChange={handleChange}
                            />
                          </div>
                        </Grid>
                        <Grid item sm={6}>
                          <div className="form_wrapper">
                            <FormInput
                              margin="0 0 10px"
                              placeholder="Bank Name"
                              name="bank_name"
                              label="Bank Name"
                              value={profileData.bank_name}
                              onChange={handleChange}
                            />
                          </div>
                        </Grid>
                        <Grid item sm={6}>
                          <div className="form_wrapper">
                            <FormInput
                              margin="0 0 10px"
                              placeholder="Bank Account"
                              name="bank_account_no"
                              label="Bank Account"
                              value={profileData.bank_account_no}
                              onChange={handleChange}
                            />
                          </div>
                        </Grid>
                        <Grid item sm={6}>
                          <div className="form_wrapper">
                            <FormInput
                              margin="0 0 10px"
                              placeholder="Bank Account Name"
                              name="bank_account_name"
                              label="Bank Account Name"
                              value={profileData.bank_account_name}
                              onChange={handleChange}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                  {user.user_type == "1" ? (
                    <>
                      {user.is_expired == 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "1rem",
                          }}
                        >
                          <Button onClick={() => handleEditProfile()} variant="contained">
                            Save Profile
                          </Button>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "1rem",
                      }}
                    >
                      <Button onClick={() => handleEditProfile()} variant="contained">
                        Save Profile
                      </Button>
                    </div>
                  )}
                  <hr />
                  <br />


                  <div className={classes.theHeaderProfile}>
                    <Paragraph
                      size="600"
                      color="#0d2964"
                      bold="600"
                      margin="1rem 0 1rem 0"
                    >
                      My Password
                    </Paragraph>
                    <Paragraph size="500" color="#000" bold="700" margin="1rem 0">
                      Reset Password
                    </Paragraph>
                  </div>

                  <div className="account_info_wrapper">
                    <div className="account_info">
                      <Grid container spacing={3}>
                        <Grid item sm={6}>
                          <div className="form_wrapper">
                            <FormInput
                              margin="0 0 10px"
                              mb="0"
                              placeholder="Old Password"
                              name="old_password"
                              label="Old Password"
                              type="password"
                              value={passwordData.old_password}
                              onChange={handleChangePassword}
                            />
                          </div>
                        </Grid>
                        <Grid item sm={6}>
                          <div className="form_wrapper">
                            <FormInput
                              margin="0 0 10px"
                              mb="0"
                              placeholder="New Password"
                              name="password"
                              label="New Password"
                              type="password"
                              value={passwordData.password}
                              onChange={handleChangePassword}
                            />
                          </div>
                        </Grid>
                        <Grid item sm={6}>
                          <div className="form_wrapper">
                            <FormInput
                              margin="0 0 10px"
                              mb="1rem"
                              placeholder="Confirm New Password"
                              name="password2"
                              label="Confirm New Password"
                              type="password"
                              value={passwordData.password2}
                              onChange={handleChangePassword}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "1rem",
                    }}
                  >
                    <Button
                      onClick={() => handleSaveChangePassword()}
                      variant="contained"
                    >
                      Save Password
                    </Button>
                  </div>

                  {user.user_type == "1" ? (
                    <>
                      <hr />
                      {projectLimit != null && (
                        <React.Fragment>
                          {user.user_type != "5" && (
                            <React.Fragment>
                              <div className={classes.theHeaderProfile}>
                                <Paragraph
                                  size="600"
                                  color="#0d2964"
                                  bold="600"
                                  margin="1rem 0 1rem 0"
                                >
                                  My Plan
                                </Paragraph>
                                <Paragraph
                                  size="500"
                                  color="#000"
                                  bold="700"
                                  margin="1rem 0"
                                >
                                  Plan Information
                                </Paragraph>
                              </div>
                              <div className={classes.thePlan}>
                                {projectLimit.data.name == "Silver Plan" && (
                                  <div className={classes.theSilverdPlanLabel}>
                                    <Paragraph size="500" color="#fff" bold="bold">
                                      Silver Plan
                                    </Paragraph>
                                  </div>
                                )}
                                {projectLimit.data.name == "Gold Plan" && (
                                  <div className={classes.theGoldPlanLabel}>
                                    <Paragraph size="500" color="#fff" bold="bold">
                                      Gold Plan
                                    </Paragraph>
                                  </div>
                                )}
                                {projectLimit.data.name == "Platinum Plan" && (
                                  <div className={classes.thePlatinumPlanLabel}>
                                    <Paragraph size="500" color="#fff" bold="bold">
                                      Platinum Plan
                                    </Paragraph>
                                  </div>
                                )}
                                {projectLimit.data.name == "Diamond Plan" && (
                                  <div className={classes.theDiamondPlanLabel}>
                                    <Paragraph size="400" color="#fff" bold="bold">
                                      Diamond Plan
                                    </Paragraph>
                                  </div>
                                )}
                                <Paragraph
                                  size="300"
                                  color="#000"
                                  bold="400"
                                  margin="0 0 6px"
                                >
                                  Accounts:{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {projectLimit.data.name == "Diamond Plan"
                                      ? "Unlimited"
                                      : projectLimit.data.account_no}
                                  </span>
                                </Paragraph>
                                {/* <Paragraph size="500" color="#000" bold="bold" margin="0 0 6px">
                          Current Accounts: <span>{projectLimit.user_count}</span>
                      </Paragraph> */}
                                <Paragraph
                                  size="300"
                                  color="#000"
                                  bold="400"
                                  margin="0 0 6px"
                                >
                                  Designs:{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {projectLimit.data.name == "Diamond Plan"
                                      ? "Unlimited"
                                      : projectLimit.data.design_no}
                                  </span>
                                </Paragraph>
                                {/* <Paragraph size="500" color="#000" bold="bold" margin="0 0 6px">
                          Current Designs: <span>{projectLimit.project_count}</span>
                      </Paragraph> */}
                                <Paragraph
                                  size="300"
                                  color="#000"
                                  bold="400"
                                  margin="0 0 6px"
                                >
                                  Photos Per Design:{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {projectLimit.data.photo_no}
                                  </span>
                                </Paragraph>
                                <Paragraph
                                  size="300"
                                  color="#000"
                                  bold="400"
                                  margin="0 0 6px"
                                >
                                  Customer Contact Type:{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {projectLimit.data.contact_type}
                                  </span>
                                </Paragraph>
                                <Paragraph
                                  size="300"
                                  color="#000"
                                  bold="400"
                                  margin="0 0 6px"
                                >
                                  Subscription Duration:{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {projectLimit.data.duration}
                                  </span>
                                </Paragraph>
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                </div>
              </React.Fragment>
            )
            }
          </div >
        ) : <></>}
    </>
  );
};

export default Account;
